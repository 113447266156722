/* eslint-disable consistent-return */
/* eslint-disable default-case */
import Stepper from '@atoms/Stepper/Stepper'
import { fetchOnboarding } from '@services/app.service'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import styled from 'styled-components'

import { BillingContacts } from './billing-contacts'
import css from './onboarding.module.scss'
import { PartnerProfileComponent } from './partner-profile/partner-profile.component'
import { SuccessOnboardingComponent } from './success-onboarding/success-onboarding.component'
import { TermsComponent } from './terms/terms.component'

const getTitle = (step = 1) => {
  switch (step) {
    case 1:
      return {
        title: (
          <span className={css.title}>
            Please review <br /> <span>Terms of Service</span> to be able to
            continue using the platform
          </span>
        ),
        description: 'Read to the end and click agree',
      }

    case 2:
      return {
        title: <span className={css.title}>Complete your registration</span>,
        description:
          'Check your account information and add more details if needed',
      }

    case 3:
      return {
        title: <span className={css.title}>Required documents</span>,
        description:
          'Please add document type we requested employee in onboarding',
      }

    case 4:
      return {
        title: <span className={css.title}>Add billing contacts</span>,
        description:
          'Assign the person responsible for invoices and payments in your company',
      }
  }
}

export const OnboardingComponent = ({ profile, onLogout }) => {
  const [activeStep, setStep] = useState(1)

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: (body) => fetchOnboarding(profile.id, body),
  })

  const form = useForm({
    defaultValues: {
      terms_of_services: {
        terms_of_services_agreed: profile.terms_of_services_agreed,
      },
      partner_information: {
        name: profile.name,
        phone_number: profile.user.profile.phone_number,
        default_currency: profile.default_currency.id,
        legal_address: {
          ...profile.legal_address,
          living_country: profile.allowed_countries[0].id,
        },
      },
    },
  })

  const handleNext = () => {
    setStep((prev) => Number(prev) + 1)
  }

  const handleSubmit = () => {
    mutate(form.getValues())
  }

  return (
    <FormProvider {...form}>
      <ContentWrapper className="d-flex h-100">
        <Stepper
          activeStep={activeStep}
          title={getTitle(activeStep).title}
          description={getTitle(activeStep).description}
          logout={onLogout}
        >
          <Stepper.Step title="Terms & Conditions" setStep={setStep}>
            <Stepper.StepContent>
              <TermsComponent onNext={handleNext} />
            </Stepper.StepContent>
          </Stepper.Step>
          <Stepper.Step title="Review account information" setStep={setStep}>
            <Stepper.StepContent>
              {isSuccess && <SuccessOnboardingComponent />}

              {!isSuccess && (
                <PartnerProfileComponent
                  countryName={profile.allowed_countries[0].name}
                  currency={
                    profile.default_currency.name ||
                    profile.default_currency.short_code
                  }
                  loading={isLoading}
                  onSubmit={handleSubmit}
                />
              )}
            </Stepper.StepContent>
          </Stepper.Step>
          {/* <Stepper.Step title="Required documents" setStep={setStep}>
            <Stepper.StepContent>
              <PartnerDocumentsComponent onNext={handleNext} />
            </Stepper.StepContent>
          </Stepper.Step> */}

          {/* <Stepper.Step title="Add billing contacts" setStep={setStep}>
            <Stepper.StepContent>
              {isSuccess && <SuccessOnboardingComponent />}
              {!isSuccess && (
                <BillingContacts
                  loading={isLoading}
                  profile={profile}
                  onSubmit={handleSubmit}
                />
              )}
            </Stepper.StepContent>
          </Stepper.Step> */}
        </Stepper>
      </ContentWrapper>
    </FormProvider>
  )
}

const ContentWrapper = styled('div')`
  aside {
    max-width: fit-content;
    min-width: 578px;
    padding: 0 !important;
  }

  header {
    display: none !important;
  }

  main {
    overflow: unset !important;
  }

  main.col {
    overflow: auto !important;
  }

  .step-content {
    height: inherit !important;
  }
`
