import '../onboarding-checklist.styles.scss'

import Typography from '@atoms/Typography/Typography'
import { Button } from '@mui/material'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
  }
`

export const CountryGuideModal = ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20 color_black">
          Complete country guide
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="text_regular__14">
          The Country Guides provide essential insights into local regulations,
          business practices, and cultural nuances in the regions where our
          partners operate. By transitioning this step to our platform, we aim
          to centralize information within our platform ensuring a single,
          reliable source of country-specific knowledge, facilitating easy
          access, and promoting consistency.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Click Complete to access the Country Guides questionnaire.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Refer to{' '}
          <a
            data-testid="country-guide-modal.component-1CBB76"
            href="https://remofirst.atlassian.net/wiki/external/ODExYzhmYmRjYzliNDM4OGFhNTNkYWQxNWVjNWEyNWU"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            our guides
          </a>{' '}
          for more information.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            data-testid="country-guide-modal.component-01DAD7"
            variant="outlined"
            color="secondary"
            className="mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="country-guide-modal.component-8DD6C9"
            variant="contained"
            color="primary"
            priority="primary"
            onClick={onSave}
          >
            Complete
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
