import React, { MouseEvent, useState } from 'react'
import { v4 } from 'uuid'

import { Styled } from './attachment.styles'
import {
  checkFileSizeIsTooLarge,
  ERROR_FILE_SIZE_TOO_LARGE,
} from './attachment.utils'
import { AttachmentDropzone } from './attachment-dropzone/attachment-dropzone'
import { AttachmentFile } from './attachment-file/attachment-file'

export const Attachment = ({
  accept,
  onAttach,
  onDelete,
  multiple = false,
}) => {
  const [attachments, setAttachments] = useState([])
  const [errorText, setErrorText] = useState('')

  const handleDrop = async (acceptedFiles) => {
    setErrorText('')
    const filteredFiles = acceptedFiles.filter(({ size }) => {
      if (checkFileSizeIsTooLarge(size)) {
        setErrorText(ERROR_FILE_SIZE_TOO_LARGE)
      }
      return !checkFileSizeIsTooLarge(size)
    })

    const newAttachments = filteredFiles.map((file) => ({
      id: v4(),
      file,
      loading: false,
      error: '',
      success: false,
      fileId: null,
    }))

    if (multiple) {
      onAttach([...filteredFiles, ...attachments.map(({ file }) => file)])
      setAttachments([...newAttachments, ...attachments])
    } else {
      onAttach(filteredFiles)
      setAttachments(newAttachments)
    }
  }

  const handleDelete = (e, index) => {
    e.stopPropagation()
    setAttachments((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ])
    onDelete(index)
  }

  return multiple ? (
    <Styled.Multiple>
      <AttachmentDropzone
        multiple={multiple}
        accept={accept}
        onDrop={handleDrop}
        errorText={errorText}
      />
      {attachments.length > 0 && (
        <Styled.Files>
          {attachments.map(({ id, file, error, loading }, index) => (
            <AttachmentFile
              key={id}
              name={file.name}
              size={file.size}
              url={URL.createObjectURL(file)}
              loading={loading}
              error={error}
              onDelete={(e) => handleDelete(e, index)}
            />
          ))}
        </Styled.Files>
      )}
    </Styled.Multiple>
  ) : (
    <Styled.Single>
      {attachments.length > 0 ? (
        <AttachmentFile
          name={attachments[0].file.name}
          size={attachments[0].file.size}
          url={URL.createObjectURL(attachments[0].file)}
          onDelete={(e) => handleDelete(e, 0)}
          error={attachments[0].error}
        />
      ) : (
        <AttachmentDropzone
          multiple={multiple}
          accept={accept}
          onDrop={handleDrop}
          errorText={errorText}
        />
      )}
    </Styled.Single>
  )
}
