/* eslint-disable default-case */
import React from 'react'

/* eslint-disable consistent-return */
const renderSwitch = (documents_state) => {
  switch (documents_state) {
    case 'waiting_document':
      return 'Waiting for required documents'

    case 'waiting_document_approval':
      return 'Waiting for documents approval'

    case 'waiting_declined_documents':
      return 'Waiting for declined documents'

    case 'waiting_contract_sign_by_partner':
      return 'Waiting for contract signing by partner'

    case 'waiting_contract_sign_by_candidate':
      return 'Waiting for agreement signing'

    case 'signed':
      return 'New hire signs Employment Agreement documentation'
  }
}

export default ({ documents_state }) => {
  return <div>{renderSwitch(documents_state)}</div>
}
