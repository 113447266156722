import { Api } from '@api/Api'

export const deactivateCompany = async (id) => Api.delete(`companies/${id}/`)

export const getCompaniesShort = async (params) => {
  const { data } = await Api.get(`companies-short-list/`, {
    params,
  })
  return data
}

export const getCompanies = async (params) => {
  const { data } = await Api.get('companies/', {
    params,
  })
  return data
}

export const getCompanyManager = async (params) => {
  const { data } = await Api.get(`companies/managers/`, {
    params,
  })
  return data
}
