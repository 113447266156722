import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import usePagination from '@core/hooks/usePagination'
import {
  addContractDocuments,
  deleteDocumentsById,
  getContractDocuments,
} from '@services/document.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useCallback, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { getDocumentFields } from './mock'

export default function DocumentDetailEmployee({ id }) {
  // const [documents, setDocuments] = useState([])
  const fileInputRef = useRef(null)
  const [documentId, setDocumentId] = useState()
  const confirmDelete = useBoolean(false)
  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })
  const { data, isLoading, refetch } = useQuery(
    [getContractDocuments.key, page, id],
    {
      queryFn: () =>
        getContractDocuments.fetch({
          page,
          limit,
          contract_id: id,
          document_type_is_not_in: 'payslip,agreement',
          year: new Date().getUTCFullYear(),
        }),
    }
  )

  const addDocument = useMutation({
    mutationFn: (payload) => addContractDocuments(payload),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteDocument = useMutation({
    mutationFn: (payload) =>
      deleteDocumentsById(payload.id, payload.contractId),
    onSuccess: () => {
      refetch()
    },
  })

  const onChangeFile = (event) => {
    const fd = new FormData()
    const file = event.target.files[0]
    fd.append('file', file)
    fd.append('contract', id)

    addDocument.mutate(fd)

    // addDocumentsByContractId(data).then((res) => {
    //   setDocuments((prevState) => [...prevState, res.data])
    // })
  }

  const onClickAddDocument = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onClickDownload = useCallback((row) => {
    window.open(row.file, '_blank')
  }, [])

  const onClickDelete = useCallback(
    (row) => {
      setDocumentId(row.id)
      confirmDelete.setTrue()
    },
    [confirmDelete]
  )

  const onDeleteFile = useCallback(() => {
    deleteDocument.mutate({ id: documentId, contractId: id })
    confirmDelete.setFalse()
  }, [confirmDelete, documentId])

  // useEffect(() => {
  //   getDocumentsByContractId(id).then((res) => setDocuments(res.data.results))
  // }, [id])

  // const getDocumentsTable = useMemo(
  //   () =>
  //     documents.map((document) => ({
  //       id: document.id,
  //       file: document.file,
  //       name: getFileName(document.file),
  //       uploaded: moment(document.created_at).format('MMMM DD, YYYY'),
  //     })),
  //   [documents]
  // )
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Document </PageTitle>
        <Button
          data-testid="DocumentDetailEmployee-5ED461"
          priority="secondary"
          size="small"
          onClick={onClickAddDocument}
        >
          Add document
        </Button>
        <input
          data-testid="DocumentDetailEmployee-B7DEA3"
          type="file"
          className="d-none"
          ref={fileInputRef}
          multiple
          onChange={onChangeFile}
        />
      </div>

      {data && data.results && (
        <CustomTable
          isLoading={
            isLoading || deleteDocument.isLoading || addDocument.isLoading
          }
          fields={getDocumentFields(onClickDownload, onClickDelete)}
          data={data.results.map((document) => ({
            id: document.id,
            file: document.file,
            name: document.filename,
            uploaded: moment(document.created_at).format('DD MMM YYYY'),
          }))}
          total={data?.count || 0}
          page={page}
          pageSize={limit}
          onPage={setPage}
        />
      )}
      {confirmDelete.value && (
        <Confirm
          title="Are you sure you want to delete this document?"
          yesButtonProps={{
            text: 'Delete',
          }}
          cancelButtonProps={{
            text: 'Cancel',
          }}
          isLoading={deleteDocument.isLoading}
          onCancel={confirmDelete.setFalse}
          onYes={onDeleteFile}
        >
          <Typography>This file will be deleted from the platform</Typography>
        </Confirm>
      )}
    </div>
  )
}
