import { css, styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight: '100vh',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))

const Sidebar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.product.background2,
  borderRight: `1px solid ${theme.palette.product.outline1}`,
  display: 'flex',
  flexDirection: 'column',
  width: 'clamp(300px, 35vw, 340px)',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}))

const SidebarHeader = styled('div')(({ theme }) => ({
  // padding: `clamp(${theme.spacing(3)}, 1.5vw + 0.5rem, ${theme.spacing(4)})`,
  padding: theme.spacing(4),
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(3),
  },
}))

const SidebarBody = styled('div')(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(4, 0),
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
    '.MuiStep-root': {
      marginBottom: theme.spacing(1.5),
      '&:last-of-type': {
        marginBottom: theme.spacing(3),
      },
    },
  },
}))

const SidebarFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(3),
  },
}))

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('tablet')]: {
    marginBottom: theme.spacing(2),
  },
}))

const Main = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('tablet')]: {
    // flex: '1 1 600px',
  },
}))

const MainHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.product.outline2}`,
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(2, 3),
  },
}))

const MainBody = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(3),
    alignItems: 'flex-start',
  },
}))

const Container = styled('div')(({ theme }) => ({
  width: '400px',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}))

export const Styled = {
  Root,
  Sidebar,
  SidebarHeader,
  SidebarBody,
  SidebarFooter,
  LogoContainer,
  Main,
  MainHeader,
  MainBody,
  Container,
}
