import { EditForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Attachment } from '@components/attachment/attachment.component'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

export const TimeOffBalancesAdHocForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm()

  const handleDelete = (fileId) => {
    resetField('documents')
  }

  return (
    <EditForm onSubmit={handleSubmit(onSubmit)}>
      <div className="remo-form-input">
        <Input
          {...register('days', {
            required: 'No. of days is required',
          })}
          type="number"
          label="No. of days"
          placeholder="Type a number"
          isRequired
        />
        {errors?.days && (
          <Typography className="text_regular__14 color_red">
            {errors.days.message}
          </Typography>
        )}
      </div>
      <div className="remo-form-input">
        <TextArea
          {...register('note', {
            required: 'Note is required',
          })}
          label="Note"
          placeholder="Provide more details of the adjustment"
          isRequired
        />

        {errors?.note && (
          <Typography className="text_regular__14 color_red">
            {errors.note.message}
          </Typography>
        )}
      </div>
      <div className="remo-form-input">
        <Controller
          control={control}
          name="documents"
          rules={{
            required: 'Document is required',
          }}
          render={({ field }) => (
            <Attachment
              multiple={false}
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
              }}
              onAttach={field.onChange}
              onDelete={(fileId) => handleDelete(fileId)}
              helperText="Supported file formats: pdf, jpeg, docx; file size up to 3 MB."
            />
          )}
        />
        {errors.documents && (
          <Typography className="text_regular__14 color_red">
            {errors.documents.message}
          </Typography>
        )}
      </div>
    </EditForm>
  )
}
