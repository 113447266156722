import { styled } from '@mui/material'

const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  borderRadius: theme.spacing(1),
  background: 'rgba(31, 31, 31, 0.04)',
  padding: theme.spacing(2),
}))

const Doc = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
}))

export const Styled = { Card, Doc }
