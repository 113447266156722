import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import NotFound from '@atoms/NotFound/NotFound'
import Typography from '@atoms/Typography/Typography'
import { deleteIcon, downloadCloud } from '@core/icons/icons'
import {
  deleteDocumentsById,
  getContractByIdPayslips,
} from '@services/document.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const Styled = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 48px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`
export default ({ contractId, month, year, callback }) => {
  const [deletePayslipId, setDeletePayslipId] = useState()

  const { isFetching, data, refetch } = useQuery(
    ['getContractByIdPayslipsDetail', contractId, month],
    {
      queryFn: () =>
        getContractByIdPayslips({
          month: month + 1,
          contract_id: contractId,
          document_type: 'payslip',
          year,
        }),
    }
  )
  const confirmDelete = useBoolean(false)

  const deleteDocument = useMutation({
    mutationFn: (payload) =>
      deleteDocumentsById(payload.id, payload.contractId),
    onSuccess: (_, variables) => {
      refetch()
      callback(variables.contractId)
      confirmDelete.setFalse()
    },
  })

  const onDownloadFile = (item) => {
    window.open(item.file, '_blank')
  }
  const onDeleteFile = () => {
    deleteDocument.mutate({
      id: deletePayslipId,
      contractId,
    })
  }
  if (isFetching) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }
  return (
    <Styled>
      <Typography className="heading_semibold__18">Documents</Typography>
      <div style={{ border: '1px dashed #DFDFDF', margin: '12px 0' }} />
      <div className="d-flex justify-content-between mt-2">
        <Typography
          className="text_light__12 mb-3 color_text_300 text-uppercase"
          style={{ letterSpacing: '0.4px' }}
        >
          Document name
        </Typography>
        <Typography
          className="text_light__12 mb-3 color_text_300 mr-2"
          style={{ letterSpacing: '0.4px' }}
        >
          Actions
        </Typography>
      </div>
      <StyledTable>
        {!data.results.length && (
          <NotFound
            illustration="/assets/img/document-search.png"
            title="There are no payslips yet"
            description="We'll let you know when we get any news for you"
            className="empty-payslips"
          />
        )}
        {data.results.map((item) => (
          <tr>
            <th>
              <Typography
                className="text_regular-normal__14 ml-2"
                style={{ letterSpacing: '0.4px' }}
              >
                {item.filename}
              </Typography>
            </th>
            <td align="right">
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  data-testid="PayslipsDetail-09011B"
                  priority="secondary"
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0 mr-2"
                  onClick={() => onDownloadFile(item)}
                >
                  <Icon fill="none" icon={downloadCloud} />
                </Button>
                <Button
                  data-testid="PayslipsDetail-FA1DEB"
                  priority="secondary"
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                  onClick={() => {
                    confirmDelete.setTrue()
                    setDeletePayslipId(item.id)
                  }}
                >
                  <Icon fill="none" icon={deleteIcon} />
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </StyledTable>
      {confirmDelete.value && (
        <Confirm
          title="Delete payslip"
          yesButtonProps={{
            text: 'Delete',
          }}
          cancelButtonProps={{
            text: 'Cancel',
          }}
          isLoading={deleteDocument.isLoading}
          onCancel={confirmDelete.setFalse}
          onYes={onDeleteFile}
        >
          <Typography>
            Are you sure you want to delete this payslip? The document will no
            longer be accessible to the employee. This action cannot be undone.
          </Typography>
        </Confirm>
      )}
    </Styled>
  )
}
