import { Address, AddressDto } from './address.types-v2'
import { Currency } from './currency.types-v2'
import { BillingAddress } from './partners.types'

/* eslint-disable no-use-before-define */
export interface BillingInfoDTO {
  bank_account: BankAccountDTO
  billing_address: _BillingAddress
}

export enum BankAccountType {
  local = 'local',
  global = 'global',
}

type _BillingAddress = Omit<BillingAddress, 'id' | 'living_country'> & {
  living_country: number
}

export interface BankAccount {
  id: number
  currency: Currency
  account_type: BankAccountType
  account_holder: string
  swift_bic: string
  iban: string
  address: Address
  is_default: boolean
}

export interface PartnerBankAccounts {
  bank_accounts: BankAccount[]
}

export type BankAccountDTO = Omit<
  BankAccount,
  'currency' | 'id' | 'address'
> & {
  currency: number
  address: AddressDto
}
