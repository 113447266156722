import { Pagination as MuiPagination, PaginationProps } from '@mui/material'

type Props = Omit<PaginationProps, 'count'> & {
  total: number
  pageSize: number
}

export const Pagination = ({ total, pageSize, ...props }: Props) => {
  return <MuiPagination {...props} count={Math.ceil(total / pageSize)} />
}
