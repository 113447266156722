import { SWIFT_FEE_PAY_ROLE } from '../company.types'
import { Address, AddressDto } from './address.types-v2'
import { ProfileMyAgreementDocument } from './agreement.types-v2'
import { CompanyShort } from './company.types-v2'
import {
  ContractState,
  DirectManager,
  Insurance,
  PAYMENT_METHOD,
  ResidencyType,
} from './contract.types-v2'
import { Country } from './country.types-v2'
import { Currency } from './currency.types-v2'
import { Occupation } from './occupations.types-v2'
import {
  CompensationAdditionalPayment,
  PaymentDetails,
} from './payment.types-v2'

export interface ProfileMeResponse {
  id: string
  role: string
  profile: Profile
  onboarding_state: string
  state: string
  get_state_display: string
  is_billing_contact: boolean
  enable_mfa: boolean
}

export interface Profile {
  first_name: string
  last_name: string
  middle_name?: string
  phone_number: string
  citizenship_country?: Omit<Country, 'has_regions'>
  avatar: string | null
  needs_work_permit?: boolean
  nationality: string
  address: Address | null
  home_address: Address | null
  birth_date: string
  email: string
  sex: string
  is_billing_contact: boolean
  enable_mfa: boolean
  pronouns?: Pronouns
  job_title?: string
  tax_number?: string | null
}

export type Pronouns = 'SHE_HER' | 'HE_HIM' | 'THEY_THEM' | 'PREFER_NOT_TO_SAY'

export type PronounsList = Array<{ value: Pronouns; label: string }>

export interface UpdateProfileDto
  extends Partial<
    Omit<Profile, 'citizenship_country' | 'avatar' | 'address' | 'home_address'>
  > {
  citizenship_country?: Pick<Country, 'id'> | number
  address?: Partial<AddressDto>
  home_address?: Partial<AddressDto>
  has_proposed_agreement_amendment?: boolean
}

export interface EEUpdateProfileDto {
  job?: {
    occupation: number
  }
  region?: number
  payment_details?: Omit<PaymentDetails, 'id'>
}

export interface ProfileMyResponse {
  id: number
  profile: Profile
  job: Job
  compensation: Compensation
  benefit?: Benefit
  working_country: Country
  bank_account_country: Omit<Country, 'has_regions'> | null
  payment_details?: PaymentDetails
  contract_type: string
  company: CompanyShort
  state: ContractState
  is_test: boolean
  partner_fee?: number
  department?: string
  direct_manager?: DirectManager
  w8ben_agreement_document?: ProfileMyAgreementDocument | null
  w9_agreement_document?: ProfileMyAgreementDocument | null
  contractor_employment_agreement_document?: ProfileMyAgreementDocument | null
  insurances: Insurance[]
  region?: {
    id: number
    name: string
  }
  documents_state?: DocumentsStateType
  job_agreement_document: {
    signature: {
      state: string
      id: number
    }
  } | null
  has_agreement_to_sign: boolean
  user_id: string
  need_to_provide_documents: boolean
  work_permit: {
    created_at: string
    expiration_date: string
    status: WorkPermitStatusType
  }

  contractor_fee: ContractorFee
  payment_method: PAYMENT_METHOD
  residency_type: ResidencyType
  tax_number: string
}

export interface ContractorFee {
  id: number
  fee: number
  fee_currency: FeeCurrency
  swift_fee_pay_role: SWIFT_FEE_PAY_ROLE | null
}

export interface FeeCurrency {
  id: number
  name: string
  short_code: string
  sign: string
  allow_for_company: boolean
  priority: number
  allow_for_contractor: boolean
}

type WorkPermitStatusType = 'invalid' | 'approved' | 'expired'

export type DocumentsStateType =
  | 'waiting_document'
  | 'waiting_document_approval'
  | 'waiting_declined_documents'
  | 'waiting_contract_sign_by_partner'
  | 'waiting_contract_sign_by_candidate'
  | 'signed'

export interface Job {
  position: string
  position_description?: string
  requested_starts_at: string | null
  starts_at: string | null
  ends_at?: string | null
  is_permanent?: boolean
  probation_period?: string
  occupation?: Occupation
  reason?: ContractJobStartDelayReason
  comment?: string
  employment_type?: EmploymentType
}

export enum EmploymentType {
  FullTime = 'full_time',
  PartTime = 'part_time',
}

export enum ContractJobStartDelayReason {
  CLIENT_DELAY = 'client_delay',
  EE_DELAY = 'ee_delay',
  VISA_DELAY = 'visa_delay',
  DEPOSIT_INVOICE_DELAY = 'deposit_invoice_delay',
  LP_DELAY = 'lp_delay',
  REMOFIRST_DELAY = 'remofirst_delay',
  OTHER = 'other',
}

export const CONTRACT_JOB_START_DELAY_REASON = {
  client_delay: 'Client delay',
  ee_delay: 'EE delay',
  visa_delay: 'Visa delay',
  deposit_invoice_delay: 'Deposit invoice delay',
  other: 'Other',
}

export interface Compensation {
  id: number
  currency: Currency
  monthly_salary: number
  yearly_gross_salary?: number
  yearly_gross_salary_in_country_currency?: number
  contractor_wage_type?: string
  contractor_rate?: number
  estimated_working_hours?: number
  contractor_payment_frequency?: PaymentFrequency
  receiving_currency?: Currency
  working_country_currency: Currency
  receiving_currency_is_local: boolean
  signing_bonus: CompensationAdditionalPayment
  allowances: Array<CompensationAdditionalPayment>
  additional_compensations: Array<CompensationAdditionalPayment>
  employee_pay_type: EmployeePayType
  employee_payment_frequency: EmployeePaymentFrequency
  employee_base_hourly_rate?: number
  employee_work_hours_per_week?: number
}

export enum EmployeePayType {
  Salaried = 'salaried',
  Hourly = 'hourly',
}

export enum EmployeePaymentFrequency {
  Monthly = 'monthly',
  SemiMonthly = 'semi_monthly',
  EveryTwoWeeks = 'every_two_weeks',
  Weekly = 'weekly',
}

// export interface AdditionalCompensation {
//   id: number;
//   name: string;
//   ap_type?: AdditionalCompensationType;
//   amount?: number;
//   currency: Currency;
//   tax?: number;
//   is_recurrent?: boolean;
//   approved_by_partner?: boolean;
//   recurrence_frequency?: string;
//   is_contractual?: boolean;
//   is_indefinite?: boolean;
//   occurences?: number;
// }

export type AdditionalCompensationType = 'BONUS' | 'ALLOWANCE' | 'OTHER'

export type PaymentFrequency =
  | 'weekly'
  | 'monthly'
  | 'biweekly'
  | 'semi_monthly'
  | 'one_time_payment'

export interface CompensationDto
  extends Omit<
    Compensation,
    | 'id'
    | 'currency'
    | 'monthly_salary'
    | 'receiving_currency'
    | 'working_country_currency'
  > {
  currency: number | null
  receiving_currency: number
}

export interface Benefit {
  created_at: string
  provides_work_equipment: boolean
  equipment_specification?: string
  delivered_at?: string | null
  provides_health_insurance: boolean
  provides_dental_insurance?: boolean
}
export type BenefitDto = Omit<Benefit, 'created_at'>

export type UploadAvatarDto = {
  file: File
}
