import { styled } from '@mui/material/styles'

const width = '640px'

const Header = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.product.background2,
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: `min(100vw, ${width})`,
}))

const Body = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 1,
  width: `min(100vw, ${width})`,
}))

const Footer = styled('footer')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.product.outline2}`,
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: `min(100vw, ${width})`,
  gap: theme.spacing(1.5),
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}))

export const Styled = {
  Header,
  Body,
  Footer,
}
