import { Box } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  index: number
  value: number
  children?: ReactNode
}

export const TabPanel = ({ children, value, index, ...other }: Props) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
