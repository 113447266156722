import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  AsyncButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@remoteam-front/ui'
import { getContractById, updateCompensation } from '@services/app.service'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { recurrenceFrequency } from './consts'
import { StyledTextField } from './onboarding-tracker-required-compensation.styles'

const apTypeOptions = [
  {
    label: 'Bonus',
    value: 'BONUS',
  },
  {
    label: 'Allowance',
    value: 'ALLOWANCE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

export const CompensationUpdateDrawer = ({ compensation, onClose }) => {
  const { currencies } = useApp()
  const queryClient = useQueryClient()
  const { successAlert } = useToast()

  const updateMutation = useMutation({
    mutationFn: (payload) => updateCompensation(compensation.id, payload),
    onSuccess: () => {
      onClose()
      queryClient.refetchQueries(getContractById.key)
      successAlert('Compensation successfully updated!')
    },
  })

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: compensation.name,
      ap_type: compensation.ap_type,
      currency: compensation.currency.id,
      is_contractual: compensation.is_contractual,
      is_indefinite: compensation.is_indefinite,
      isEndAfter: !compensation.is_indefinite,
      occurences: compensation.occurences,
      is_recurrent: compensation.is_recurrent,
      amount: compensation.amount,
      recurrence_frequency: compensation.recurrence_frequency || 'once',
      tax: compensation.tax,
      isTaxable: compensation.tax ? 'true' : 'false',
    },
  })

  const compensationType = compensation.ap_type
  const frequency = watch('recurrence_frequency')
  const isTaxable = watch('isTaxable')
  const isEndAfter = watch('isEndAfter')

  const onSubmit = (formData) => {
    const submitData = { ...formData }
    if (
      formData.recurrence_frequency &&
      formData.recurrence_frequency !== 'once'
    ) {
      submitData.is_recurrent = true
    }
    if (formData.recurrence_frequency === 'once') {
      submitData.is_indefinite = false
      delete submitData.recurrence_frequency
    }

    if (formData.isTaxable === 'false') {
      submitData.tax = 0
    }
    delete submitData.isTaxable

    if (!formData.isEndAfter) {
      submitData.occurences = undefined
    }
    submitData.is_indefinite = !formData.isEndAfter
    delete submitData.isEndAfter

    updateMutation.mutate(submitData)
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <DrawerHeader title="Edit compensation" onClose={onClose} />
        <DrawerBody>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Title"
              fullWidth
              required
              error={!!errors.name}
              helperText={
                errors.name?.message || 'This term will be used in the payroll'
              }
              {...register('name')}
            />
            <Controller
              control={control}
              name="ap_type"
              render={({ field: { ...field } }) => (
                <FormControl error={!!errors.ap_type} required fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    disabled
                    error={!!errors.ap_type}
                    helperText={errors.ap_type?.message}
                    {...field}
                  >
                    {apTypeOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="currency"
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.currency} required fullWidth>
                  <InputLabel id="select-label">Currency</InputLabel>
                  <Select
                    inputRef={ref}
                    error={!!errors.currency}
                    helperText={errors.currency?.message}
                    {...field}
                  >
                    {currencies.map(({ id, name, short_code }) => (
                      <MenuItem key={id} value={id}>
                        {name || short_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <TextField
              label="Amount"
              fullWidth
              required
              type="number"
              error={!!errors.amount}
              helperText={errors.amount?.message}
              {...register('amount', {
                min: {
                  value: 1,
                  message: 'Amount cannot be less than 0',
                },
              })}
              onInput={(e) => {
                e.target.value = Math.max(
                  1,
                  parseFloat(e.target.value)
                ).toString()
              }}
            />

            {compensationType !== 'BONUS' && (
              <>
                <Controller
                  control={control}
                  name="recurrence_frequency"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl
                      error={!!errors.recurrence_frequency}
                      required
                      fullWidth
                    >
                      <InputLabel id="select-label">Frequency</InputLabel>
                      <Select
                        inputRef={ref}
                        error={!!errors.recurrence_frequency}
                        helperText={errors.recurrence_frequency?.message}
                        {...field}
                      >
                        <MenuItem key="once" value="once">
                          One-time
                        </MenuItem>
                        {recurrenceFrequency.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {frequency !== 'once' && (
                  <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{ my: 2 }}
                  >
                    <Controller
                      name="isEndAfter"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label={
                            <Typography variant="body2">End after</Typography>
                          }
                        />
                      )}
                    />
                    <Box display="flex" gap={1} alignItems="center">
                      <StyledTextField
                        required
                        type="number"
                        disabled={!isEndAfter}
                        error={!!errors.occurences}
                        helperText={errors.occurences?.message}
                        {...register('occurences', {
                          min: {
                            value: 0,
                            message: 'Amount cannot be negative',
                          },
                        })}
                        onInput={(e) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          ).toString()
                        }}
                      />

                      <Typography>occurrences</Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}

            <FormControl fullWidth required>
              <Controller
                control={control}
                name="isTaxable"
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value || ''}>
                    <InputLabel required>Tax</InputLabel>
                    <Box display="flex" gap={2}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non-taxable"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Taxable"
                      />
                    </Box>
                  </RadioGroup>
                )}
              />
            </FormControl>

            {isTaxable === 'true' && (
              <TextField
                label="Tax amount"
                fullWidth
                required
                type="number"
                placeholder="Type exact amount"
                error={!!errors.tax}
                helperText={errors.tax?.message}
                {...register('tax', {
                  min: {
                    value: 0,
                    message: 'Tax cannot be negative',
                  },
                })}
                onInput={(e) => {
                  e.target.value = Math.max(
                    0,
                    parseFloat(e.target.value)
                  ).toString()
                }}
              />
            )}
            <Controller
              name="is_contractual"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={
                    <Typography variant="body2">
                      Include this allowance in the EoR contract
                    </Typography>
                  }
                />
              )}
            />
          </Box>
        </DrawerBody>
        <Box
          display="flex"
          justifyContent="end"
          flexDirection="column"
          height="40%"
        >
          <DrawerFooter
            primaryActions={[
              <AsyncButton
                data-testid="compensation-update-drawer.component-1E483B"
                type="submit"
                isFetching={updateMutation.isLoading}
                variant="contained"
                color="primary"
                size="medium"
                key={1}
              >
                Save
              </AsyncButton>,
            ]}
            secondaryActions={[
              <Button
                data-testid="compensation-update-drawer.component-E6B42B"
                variant="contained"
                color="secondary"
                size="medium"
                key={2}
                onClick={onClose}
              >
                Cancel
              </Button>,
            ]}
          />
        </Box>
      </form>
    </Drawer>
  )
}
