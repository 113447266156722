import { styled } from '@mui/material'

type Props = {
  isActive: boolean
}

const Root = styled('div')<Props>(({ theme, isActive }) => ({
  width: 336,
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  border: `1px solid ${
    isActive ? theme.palette.primary.main : 'rgba(31, 31, 31, 0.08)'
  }`,
  background: `${isActive && 'rgba(4, 197, 58, 0.05)'}`,
  cursor: 'pointer',
}))

export const Styled = { Root }
