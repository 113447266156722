import { PartnerProfile, PartnerProfileDTO } from '../partner.types'
import { CompanyShort } from './company.types-v2'

export type PartnerBusinessModel = Pick<
  PartnerProfileDTO,
  'eor_type' | 'invoice_type' | 'service_fee_option'
> & {
  id: number
  partner_profile: Pick<PartnerProfile, 'id' | 'name'>
  company: CompanyShort
}

export type PartnerBusinessModelList = {
  partner_companies_business_models: PartnerBusinessModel[]
  partnered_companies: Pick<PartnerProfile, 'id' | 'name'>[]
}

export type BusinessModelDTO = Pick<
  PartnerProfileDTO,
  'eor_type' | 'invoice_type' | 'service_fee_option'
> & {
  company: number
}

export type BusinessModelUpdateDTO = Pick<
  PartnerProfileDTO,
  'eor_type' | 'invoice_type' | 'service_fee_option'
> & {
  id: number
  companyName?: string
}

export enum EORTypeEntity {
  TRADITIONAL_EOR = 'traditional_eor',
  SAAS_EOR = 'saas_eor',
}
export const EORTypeEntityValue = {
  [EORTypeEntity.TRADITIONAL_EOR]: 'Traditional EOR',
  [EORTypeEntity.SAAS_EOR]: 'SAAS EOR',
}
