import '../ProfilePage.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { parseProfileTeams } from '@pages/profile/parser'
import { getResetPassword } from '@services/app.service'
import {
  fetchAddPartnerTeam,
  fetchDeletePartnerTeam,
  getPartnerUsersByOwner,
  updatePartnerUsers,
} from '@services/team.service'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { fields } from '../mock'
import { EditManagerModal } from './edit-manager-modal'
import { AddManagerModal } from './new-manager-modal'

export const ManagersTab = () => {
  const { successAlert } = useToast()
  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const [selectedManagerDelete, setSelectedManagerDelete] = useState(null)

  const { profile } = useContext(AppContext)
  const [selectedManager, setSelectedManager] = useState(null)
  const [resentUserIds, setResentUserIds] = useState([])
  const newManagerOpen = useBoolean(false)

  const usersQuery = useQuery(getPartnerUsersByOwner.key, {
    queryFn: () =>
      getPartnerUsersByOwner.fetch(profile.id, { limit: 1000, offset: 0 }),
  })

  const resetPassword = useMutation({
    mutationFn: (payload) => getResetPassword(payload.email),
    onSuccess: () => {
      successAlert('Check your email for a link to set your password.')
    },
  })

  const handleReset = (row) => {
    setResentUserIds([...resentUserIds, row.id])
    resetPassword.mutate({ email: row.email })
  }

  const updatePartnerUserMutation = useMutation({
    mutationFn: ({ id, body }) => updatePartnerUsers(id, body),
    onSuccess: () => {
      setSelectedManager(null)
      usersQuery.refetch()
    },
  })

  const addMutate = useMutation({
    mutationFn: fetchAddPartnerTeam,
    onSuccess: () => {
      usersQuery.refetch()
      newManagerOpen.setFalse()
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (managerId) => fetchDeletePartnerTeam(managerId),
    onSuccess: () => {
      usersQuery.refetch()
      toggleDelete()
    },
  })

  const handleEdit = (row) => {
    setSelectedManager({
      ...row,
      id: row.id,
      partner: profile.id,
      email: row.email || '',
      first_name: row.first_name || '',
      last_name: row.last_name || '',
    })
  }

  const handleSave = (payload) => {
    updatePartnerUserMutation.mutate({
      id: selectedManager.uuid,
      body: payload,
    })
  }

  const handleAddNewManager = (payload) => {
    addMutate.mutate({
      partner: profile.id,
      ...payload,
    })
  }

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedManagerDelete(row.id)
  }

  if (usersQuery.isLoading || !profile.user) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <strong>Managers</strong>
        <Button
          data-testid="managers-tab-F112C0"
          priority="secondary "
          size="small"
          onClick={newManagerOpen.setTrue}
        >
          Add manager
        </Button>
      </div>

      {profile && usersQuery.data && (
        <div className="team-tab-table">
          <CustomTable
            fields={fields({ handleDelete, handleEdit, handleReset })}
            data={parseProfileTeams(
              usersQuery.data.results || [],
              resentUserIds
            )}
          />
        </div>
      )}

      {!!selectedManager && (
        <EditManagerModal
          selectedManager={selectedManager}
          loading={updatePartnerUserMutation.isLoading}
          onHide={() => setSelectedManager(null)}
          onSubmit={handleSave}
        />
      )}

      {newManagerOpen.value && (
        <AddManagerModal
          loading={addMutate.isLoading}
          onHide={newManagerOpen.setFalse}
          onSubmit={handleAddNewManager}
        />
      )}

      <EditModal
        key="Delete"
        visible={visibleDelete}
        title="Delete manager"
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this manager?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="managers-tab-C448AB"
            priority="secondary"
            size="small"
            className="mr-3"
            disabled={deleteMutate.isLoading}
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="managers-tab-F59FF4"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            disabled={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedManagerDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
    </>
  )
}
