import { CircularProgress } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

interface AsyncButtonProps extends ButtonProps {
  isFetching: boolean
}

const AsyncButton = ({ isFetching, children, ...rest }: AsyncButtonProps) => {
  return (
    <Button data-testid="async-button-7CB5E8" disabled={isFetching} {...rest}>
      {isFetching ? (
        <CircularProgress color="inherit" style={{ padding: '8px' }} />
      ) : (
        children
      )}
    </Button>
  )
}

export default AsyncButton
