/* eslint-disable consistent-return */
import Icon from '@atoms/Icon/Icon'
import { keyboardArrowLeft } from '@core/icons/icons'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const BackButton = ({ path }) => {
  const history = useHistory()
  const location = useLocation()

  const handleBack = () => {
    if (location.key) {
      return history.goBack()
    }

    history.push(path)
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: 40,
        width: 40,
      }}
      role="button"
      aria-hidden
      onClick={handleBack}
    >
      <Icon
        icon={keyboardArrowLeft}
        fill="black"
        style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
      />
    </div>
  )
}

export default BackButton
