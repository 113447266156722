import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import React, { memo, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

const Form = ({ payments, isLoading, onSave }) => {
  const { currencies } = useContext(AppContext)
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      salary: payments.gross_pay_in_partner_currency,
      salary_currency: {
        label:
          payments.partner_currency.short_code ||
          payments.partner_currency.sign,
        value: payments.partner_currency.id,
      },
      vat: payments.vat_in_partner_currency,
      vat_currency: {
        label: payments.partner_currency?.short_code,
        value: payments.partner_currency?.id,
      },
      private_insurance: payments.private_insurance_in_partner_currency,

      employer_burden: payments.employer_burden_in_partner_currency,
      partner_fee: payments.partner_fee_in_partner_currency,
      private_insurance_currency: {
        label:
          payments.partner_currency.short_code ||
          payments.partner_currency.sign,
        value: payments.partner_currency.id,
      },
      employer_burden_currency: {
        label:
          payments.partner_currency.short_code ||
          payments.partner_currency.sign,
        value: payments.partner_currency.id,
      },
      partner_fee_currency: {
        label:
          payments.partner_currency.short_code ||
          payments.partner_currency.sign,
        value: payments.partner_currency.id,
      },
    },
  })

  const submit = (formValues) => {
    onSave({
      salary:
        +formValues.salary !== payments.gross_pay_in_partner_currency
          ? formValues.salary
          : null,
      vat:
        +formValues.vat !== payments.vat_in_partner_currency
          ? formValues.vat
          : null,
      vat_currency:
        // eslint-disable-next-line no-nested-ternary
        +formValues.vat !== payments.vat_in_partner_currency
          ? formValues.vat_currency?.value !== payments.partner_currency?.id
            ? formValues.vat_currency?.value
            : payments.partner_currency?.id
          : null,
      employer_burden:
        +formValues.employer_burden !==
        payments.employer_burden_in_partner_currency
          ? formValues.employer_burden
          : null,
      partner_fee:
        +formValues.partner_fee !== payments.partner_fee_in_partner_currency
          ? formValues.partner_fee
          : null,
      private_insurance:
        +formValues.private_insurance !==
        payments.private_insurance_in_partner_currency
          ? formValues.private_insurance
          : null,
      salary_currency:
        // eslint-disable-next-line no-nested-ternary
        +formValues.salary !== payments.gross_pay_in_partner_currency
          ? formValues.salary_currency.value !== payments.partner_currency?.id
            ? formValues.salary_currency.value
            : payments.partner_currency?.id
          : null,
      private_insurance_currency:
        // eslint-disable-next-line no-nested-ternary
        +formValues.private_insurance !==
        payments.private_insurance_in_partner_currency
          ? formValues.private_insurance_currency.value !==
            payments.partner_currency?.id
            ? formValues.private_insurance_currency.value
            : payments.partner_currency?.id
          : null,
      employer_burden_currency:
        // eslint-disable-next-line no-nested-ternary
        +formValues.employer_burden !==
        payments.employer_burden_in_partner_currency
          ? formValues.employer_burden_currency.value !==
            payments.partner_currency?.id
            ? formValues.employer_burden_currency.value
            : payments.partner_currency?.id
          : null,
      partner_fee_currency:
        // eslint-disable-next-line no-nested-ternary
        +formValues.partner_fee !== payments.partner_fee_in_partner_currency
          ? formValues.partner_fee_currency.value !==
            payments.partner_currency?.id
            ? formValues.partner_fee_currency.value
            : payments.partner_currency?.id
          : null,
    })
  }

  useEffect(() => {
    if (payments) {
      reset({
        salary: payments.gross_pay_in_partner_currency,
        salary_currency: {
          label:
            payments.partner_currency.short_code ||
            payments.partner_currency.sign,
          value: payments.partner_currency.id,
        },
        vat: payments.vat_in_partner_currency,
        vat_currency: {
          label: payments.partner_currency?.short_code,
          value: payments.partner_currency?.id,
        },
        private_insurance: payments.private_insurance_in_partner_currency,

        employer_burden: payments.employer_burden_in_partner_currency,
        partner_fee: payments.partner_fee_in_partner_currency,
        private_insurance_currency: {
          label:
            payments.partner_currency.short_code ||
            payments.partner_currency.sign,
          value: payments.partner_currency.id,
        },
        employer_burden_currency: {
          label:
            payments.partner_currency.short_code ||
            payments.partner_currency.sign,
          value: payments.partner_currency.id,
        },
        partner_fee_currency: {
          label:
            payments.partner_currency.short_code ||
            payments.partner_currency.sign,
          value: payments.partner_currency.id,
        },
      })
    }
  }, [isLoading, payments, reset])
  return (
    <StyledForm className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            rules={{ required: 'Field is required' }}
            name="salary"
            render={({ field }) => {
              return (
                <CurrencyInput
                  {...field}
                  label="Salary"
                  // currency={payments.partner_currency.short_code}
                  placeholder="Enter the salary"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )
            }}
          />
          {errors.salary && (
            <Typography className="text_regular__14 color_red">
              {errors.salary.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="salary_currency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="InvoiceForm-C1CD61"
                  {...field}
                  label="Salary currency"
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: `${currency.short_code || currency.sign}`,
                  }))}
                />
              )
            }}
          />
          {errors.salary_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.salary_currency.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="employer_burden"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <CurrencyInput
                  {...field}
                  // currency={payments.partner_currency.short_code}
                  label="Employer tax & contributions"
                  placeholder="Enter the field"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )
            }}
          />

          {errors.employer_burden && (
            <Typography className="text_regular__14 color_red">
              {errors.employer_burden.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="employer_burden_currency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="InvoiceForm-9671C8"
                  {...field}
                  label="Employer tax currency"
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: `${currency.short_code || currency.sign}`,
                  }))}
                />
              )
            }}
          />
          {errors.employer_burden_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.employer_burden_currency.message}
            </Typography>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="private_insurance"
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                // currency={payments.partner_currency.short_code}
                label="Private insurance"
                placeholder="Enter the field"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />

          {errors.private_insurance && (
            <Typography className="text_regular__14 color_red">
              {errors.private_insurance.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="private_insurance_currency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="InvoiceForm-73E843"
                  {...field}
                  label="Private insurance currency"
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: `${currency.short_code || currency.sign}`,
                  }))}
                />
              )
            }}
          />
          {errors.private_insurance_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.private_insurance_currency.message}
            </Typography>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="partner_fee"
            rules={{
              required: 'Field is required',
            }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                // currency={payments.partner_currency.short_code}
                disabled={!payments.partner_fee_allowed}
                label="Partner fee"
                placeholder="Enter the field"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />

          {errors.partner_fee && (
            <Typography className="text_regular__14 color_red">
              {errors.partner_fee.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="partner_fee_currency"
            rules={{
              required: 'Field is required',
            }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="InvoiceForm-B67033"
                  {...field}
                  label="Partner fee currency"
                  isDisabled={!payments.partner_fee_allowed}
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: `${currency.short_code || currency.sign}`,
                  }))}
                />
              )
            }}
          />
          {errors.partner_fee_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.partner_fee_currency.message}
            </Typography>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            rules={{ required: 'Field is required' }}
            name="vat"
            render={({ field }) => (
              <CurrencyInput
                {...field}
                // currency={watchVatCurrency?.label || 'USD'}
                label="VAT"
                placeholder="Enter the field"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />

          {errors.vat && (
            <Typography className="text_regular__14 color_red">
              {errors.vat.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="vat_currency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="InvoiceForm-468FCA"
                  {...field}
                  label="VAT currency"
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: `${currency.short_code || currency.sign}`,
                  }))}
                />
              )
            }}
          />
          {errors.vat_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.vat_currency.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="align-self-end mb-0">
        <Button
          data-testid="InvoiceForm-1B405C"
          size="small"
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </StyledForm>
  )
}

export default memo(Form)
