export const lists = [
  {
    label: 'Company name',
    value: 'Mastercard',
  },
  {
    label: 'Contact email',
    value: 'team@mastercard.com',
  },
  {
    label: 'Contact phone number',
    value: '+31 7530 283 978',
  },
]

export const getMainList = (company) => [
  {
    label: 'Company name',
    value: company.name,
  },
  {
    label: 'Default currency',
    value: `${
      company.default_currency.sign || company.default_currency.short_code
    } ${company.default_currency.name}`,
  },
]

export const getContactPersonList = (company) => [
  {
    label: 'Title',
    value: company.title,
  },
  {
    label: 'Full name',
    value: `${company?.owner?.profile?.first_name || ''} ${
      company?.owner?.profile?.last_name || ''
    }`,
  },
  {
    label: 'Email',
    value: company?.owner?.profile?.email || '',
  },
]

export const getLocationList = (company) => [
  {
    label: 'Headquarters',
    value: company.head_quarters,
  },
  {
    label: 'Country',
    value: company.legal_address?.living_country?.name,
  },
  {
    label: 'State',
    value: company.legal_address?.state,
  },
  {
    label: 'City',
    value: company.legal_address?.city,
  },
  {
    label: 'Street address',
    value: company.legal_address?.street_address,
  },
  {
    label: 'Address line',
    value: company.legal_address?.address_line,
  },
  {
    label: 'Postal code',
    value: company.legal_address?.postal_code,
  },
]

export const fields = [
  {
    title: '№',
    key: 'number',
    type: 'string',
    maxWidth: 50,
    minWidth: 50,
    width: 50,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Department',
    key: 'department',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Role',
    key: 'job_position',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
]
