import PayslipsDetail from '@pages/employeePayslips/PayslipsDetail'
import moment from 'moment'
import React from 'react'

export const parsePayslips = (payslipsResponse, month, year, callback) => {
  if (!payslipsResponse || !payslipsResponse.length) return []
  return payslipsResponse.map((item) => ({
    id: item.id,
    name: item.full_name,
    avatar: item.avatar,
    company: item?.company.name,
    status: item?.status || 'Pending',
    original: { ...item, job: { starts_at: item.starts_at } },
    collapseContent: (
      <PayslipsDetail
        contractId={item.id}
        callback={callback}
        month={month}
        year={year}
      />
    ),
  }))
}

export const effectiveDateHistoryOptions = (isPending) => {
  const date = new Date()
  const months = moment.months()

  if (isPending) {
    date.setMonth(date.getMonth() + 1)
  } else {
    date.setMonth(date.getMonth() + 2)
  }
  return months.map(() => {
    date.setMonth(date.getMonth() - 1)
    return {
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: moment(date).format('YYYY-MM-DD'),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    }
  })
}

export const filterDateHistoryOptions = (startDate, endDate) => {
  const date = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const filterStartDate = new Date(moment(startDate).format('YYYY-MM-DD'))
  const filterEndDate = new Date(moment(endDate).format('YYYY-MM-DD'))
  const months = moment.months()
  date.setMonth(date.getMonth() + 2)
  currentDate.setMonth(currentDate.getMonth() + 2)
  date.setDate(filterStartDate.getDate())
  currentDate.setDate(filterEndDate.getDate())
  const filterMonth = []
  months.forEach(() => {
    date.setMonth(date.getMonth() - 1)
    currentDate.setMonth(currentDate.getMonth() - 1)
    if (endDate) {
      if (date >= filterStartDate && currentDate <= filterEndDate)
        filterMonth.push({
          label: `${months[date.getMonth()]} ${date.getFullYear()}`,
          value: moment(date).format('YYYY-MM-DD'),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        })
    } else if (date >= filterStartDate) {
      filterMonth.push({
        label: `${months[date.getMonth()]} ${date.getFullYear()}`,
        value: moment(date).format('YYYY-MM-DD'),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      })
    }
  })
  return filterMonth
}

export const effectiveDateOptions = () => {
  const date = new Date()
  const months = moment.months()
  date.setDate(-6)

  return months.map(() => {
    date.setMonth(date.getMonth() + 1)
    return {
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: moment(date).format('YYYY-MM-DD'),
    }
  })
}
