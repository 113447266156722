import Typography from '@atoms/Typography/Typography'
import { SplitNumber } from '@core/utils'
import React from 'react'

export const payrollsField = [
  {
    title: 'Company',
    key: 'company_name',
    type: 'string',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
  },
  {
    title: 'Month',
    key: 'current_month',
    type: 'string',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
  },
  {
    title: 'Approved employees',
    key: 'count',
    type: 'custom',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item.total_count - item.count}/{item.total_count}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total Amount',
    key: 'total_amount',
    type: 'custom',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {SplitNumber(item.total_amount)}{' '}
            {item?.currency?.sign || item?.currency?.short_code}{' '}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
]

export const approvedPayrollsField = [
  {
    title: 'Company',
    key: 'company_name',
    type: 'string',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
  },
  {
    title: 'Month',
    key: 'current_month',
    type: 'string',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
  },
  {
    title: 'Approved employees',
    key: 'count',
    type: 'custom',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item.total_count}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total Amount',
    key: 'total_amount',
    type: 'custom',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {SplitNumber(item.total_amount)}{' '}
            {item?.currency?.sign || item?.currency?.short_code}{' '}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
]
