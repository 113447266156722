import { Box, Typography } from '@mui/material'
import React from 'react'

export const EmptyState = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={4}
    >
      <svg
        data-testid="empty-state-2BF765"
        width="129"
        height="146"
        viewBox="0 0 129 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M84.7686 34.6818L92.5569 20"
          stroke="#04C53A"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M97.749 43.1741L108.855 34.2499"
          stroke="#04C53A"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M69.9132 33.2423L62.9902 0.999878"
          stroke="#04C53A"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M58.3756 34.5377L49.8662 22.015"
          stroke="#04C53A"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M0.540039 110.969L18.4243 97.5831L20.7319 111.689L71.7601 144.856L0.540039 110.969Z"
          fill="#04C53A"
        />
        <path
          d="M50.875 92.5945L67.0285 83.8257L93.1337 99.8812"
          stroke="#121212"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M50.8755 92.5945L75.8651 109.051C76.044 109.168 76.2735 109.177 76.4612 109.074L93.1343 99.8813L126.592 81.5661C126.98 81.3539 126.995 80.8023 126.619 80.5693L65.2857 42.5422C65.1144 42.436 64.9001 42.4269 64.7204 42.5181L14.8397 67.8304C14.4446 68.0309 14.4134 68.5834 14.7835 68.8271L50.8755 92.5945Z"
          stroke="#121212"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M65.0098 42.3712L67.029 83.8257"
          stroke="#121212"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M76.156 109.242L72.0373 143.881C71.9818 144.348 72.4785 144.682 72.89 144.454L114.445 121.444C114.574 121.373 114.671 121.255 114.716 121.115L127.46 81.0909"
          stroke="#121212"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
        <path
          d="M72.4815 144.568L21.0207 111.318L14.502 68.4243"
          stroke="#121212"
          strokeWidth="1.44289"
          strokeLinecap="round"
        />
      </svg>
      <Typography variant="textLarge2">There is no agreement yet.</Typography>
    </Box>
  )
}
