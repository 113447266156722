import { EMPLOYEE_STATES } from '@core/constants'
import { formatDate, numberWithSpaces } from '@core/utils'
import moment from 'moment'

export const employeeTableParse = (contracts, page) =>
  contracts.map((contract, index) => ({
    id: contract.id,
    number: (page - 1) * 10 + index + 1,
    full_name: contract.full_name,
    avatar: contract.avatar,
    customer: contract.company.name,
    job_position: contract.job_position,
    department: contract.department,
    salary:
      contract.contract_type === 'contractor'
        ? `${contract.currency?.sign || contract.currency?.short_code}
        ${numberWithSpaces(contract.contractor_rate)} / ${
            contract.contractor_wage_type
          }`
        : `${numberWithSpaces(contract.yearly_gross_salary)} ${
            contract.currency.sign || contract.currency?.short_code
          } / y`,
    status: contract.state,
    location: contract.living_country?.name || '',
    start_at:
      contract.state === EMPLOYEE_STATES.ACTIVE
        ? formatDate(contract.starts_at)
        : formatDate(contract.requested_starts_at),
    documents_state: contract.documents_state,
    ...contract,
  }))
