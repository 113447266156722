export interface ProbationPeriod {
  country: ProbationPeriodCountry
  fixed_contract_is_applicable: boolean
  fixed_contract_minimum_day?: number | null
  fixed_contract_maximum_day?: number | null
  fixed_contract_suggested_day?: number | null
  fixed_contract_note: string
  probation_type: ProbationType
  permanent_contract_is_applicable: boolean
  permanent_contract_minimum_day: number | null
  permanent_contract_maximum_day: number | null
  permanent_contract_suggested_day: number | null
  permanent_contract_note: string
  calculation_type?: string | null
  segments?: ProbationSegment[] | null
}

export interface ProbationSegment {
  min_period_in_months: number
  max_period_in_months?: number
  probation_days?: number
}
export interface ProbationSegmentValidate {
  minPeriodError: boolean
  maxPeriodError: boolean
  probationDaysError: boolean
}

export enum ProbationType {
  STANDARD = 'standard',
  SPECIFIC_CALCULATION = 'specific_calculation',
  SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT = 'specific_to_duration_of_fixed_contract',
}

export interface ProbationPeriodCountry {
  id: number
  name: string
}

export type ProbationPeriodDto = Omit<ProbationPeriod, 'country'> & {
  country: number
  rule?: SpecificCalculationRule
}

export const ProbationTypes = {
  [ProbationType.STANDARD]: 'Standard',
  [ProbationType.SPECIFIC_CALCULATION]: 'Specific calculation',
  [ProbationType.SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT]:
    'Based on Contract duration',
}

export enum SpecificCalculationRule {
  OneQuarterOfContractDuration = 1,
  OneThirdOfContractDuration,
  HalfOfContractDuration,
}

export const CalculationTypeMap: Record<SpecificCalculationRule, string> = {
  [SpecificCalculationRule.HalfOfContractDuration]: '1/2',
  [SpecificCalculationRule.OneThirdOfContractDuration]: '1/3',
  [SpecificCalculationRule.OneQuarterOfContractDuration]: '1/4',
}
export const SpecificCalculationRuleValue = [
  {
    value: SpecificCalculationRule.OneQuarterOfContractDuration,
    name: 'One quarter of contract duration',
  },
  {
    value: SpecificCalculationRule.OneThirdOfContractDuration,
    name: 'One-third of contract duration',
  },
  {
    value: SpecificCalculationRule.HalfOfContractDuration,
    name: 'Half of contract duration',
  },
]

export enum SpecificToDurationOfFixedContractType {
  YEARS,
  MONTHS,
}
export const SpecificToDurationOfFixedContractTypeValue = [
  {
    value: SpecificToDurationOfFixedContractType.MONTHS,
    name: 'Months',
  },
  {
    value: SpecificToDurationOfFixedContractType.YEARS,
    name: 'Years',
  },
]
