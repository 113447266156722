import { DotWave } from '@uiball/loaders'
import React from 'react'
import styled from 'styled-components'

const AbsoluteDIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ffffffd4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: relative;
`

export const AbsoluteSpinner = ({ children, spinner, isFetching, ...rest }) => {
  if (!isFetching) {
    return (
      <div className="w-100" {...rest}>
        {children}
      </div>
    )
  }

  return (
    <Wrapper {...rest}>
      {children}
      <AbsoluteDIV>
        {spinner || <DotWave size={48} speed={1} color="black" />}
      </AbsoluteDIV>
    </Wrapper>
  )
}
