import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { LeaveType } from '@core/constants'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

export const TimeOffReviewForm = ({ onSubmit, formId }) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="leave_type"
        rules={{ required: 'Choose one option' }}
        render={({ field }) => (
          <RadioButton
            {...field}
            label="Is this time off paid or unpaid?"
            options={[
              {
                id: 'paid',
                text: 'Paid',
                value: LeaveType.PAID,
              },
              {
                id: 'unpaid',
                text: 'Unpaid',
                value: LeaveType.UNPAID,
              },
            ]}
          />
        )}
      />
      <TextArea
        placeholder="Please leave any comment or flag an issue for the company to see and take into consideration when reviewing the time off request"
        rows={4}
        {...register('issue_flag_note')}
      />
    </form>
  )
}
