import './style.scss'

import Icon from '@atoms/Icon/Icon'
import { edit, emptyAvatar } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React, { useRef } from 'react'

const imageFileExtensions = ['image/jpeg', 'image/png', '.jpg', '.jpeg', '.png']

function EditAvatar(props) {
  const {
    src,
    width = 100,
    height = 100,
    className = '',
    onUpload,
    isLoading,
  } = props

  const fileInputRef = useRef(null)

  const onEditClick = () => {
    fileInputRef.current.click()
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0]
    if (file) {
      onUpload(file)
    }
  }

  return (
    <div
      className={`edit-avatar ${
        isLoading ? 'edit-avatar--loading' : ''
      } ${className}`}
      style={{ width, height }}
    >
      {isLoading ? (
        <div className="edit-avatar__loader" style={{ width, height }}>
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        <>
          {src ? (
            <img src={src} alt="avatar" className="edit-avatar__img" />
          ) : (
            <Icon width={width} height={height} icon={emptyAvatar} />
          )}
          <Icon
            color="white"
            className="edit-avatar__edit"
            icon={edit}
            onClick={onEditClick}
          />

          <input
            data-testid="EditAvatar-03182A"
            type="file"
            className="edit-avatar__input"
            ref={fileInputRef}
            accept={imageFileExtensions.join(', ')}
            onChange={onChangeFile}
          />
          <div
            className="edit-avatar__overlay"
            onClick={onEditClick}
            onKeyDown={onEditClick}
            role="button"
            tabIndex={0}
          />
        </>
      )}
    </div>
  )
}

export default EditAvatar
