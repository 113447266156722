import styled from 'styled-components'

// copy-paste from current table
const Table = styled('table')`
  width: 100%;
  text-align: left;

  th {
    border-top: 0;
    border-bottom: 1px solid #f3f3f3;
    height: 70px;
    line-height: 150%;
    color: #878787;
  }

  th:last-child {
    div {
      // justify-content: end;
    }
  }

  tr {
    height: 70px;
    td:last-child {
      div {
        // display: inline-block;
      }
    }
  }

  td {
    vertical-align: middle;
    border-bottom: 1px solid #f3f3f3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #121212;
    div {
      font-family: 'Inter', sans-serif !important;
    }
    word-break: 'break-word';
  }
  .cursor-pointer {
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
    &:active {
      background: #dfdfdf;
    }
  }

  .status_inactive {
    opacity: 0.67;
  }
`

export const Styled = { Table }
