import { useApp } from '@core/context'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import {
  deletePartnerBankAccounts,
  getPartnerBankAccounts,
} from '@remoteam-front/services'
import { BankAccount } from '@remoteam-front/types/legacy'
import { AsyncButton } from '@remoteam-front/ui'
import { mapCurrency } from '@remoteam-front/utils/currency'
import { useMutation, useQueryClient } from 'react-query'

type Props = { onClose(): void; isLast?: boolean } & BankAccount

export const BankAccountConfirm = ({
  iban,
  id,
  isLast,
  currency,
  onClose,
}: Props) => {
  const { profile } = useApp()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: deletePartnerBankAccounts,
    onSuccess: () => {
      queryClient.refetchQueries(getPartnerBankAccounts.key)

      onClose()
    },
  })

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Remove bank account</DialogTitle>
      <DialogContent>
        Are you sure you want to remove the bank account SWIFT ending in{' '}
        {iban.slice(-4)} in {mapCurrency(currency)}?
        <br />
        <br />
        {isLast &&
          'This is the only bank account available. After removing it you will not receive any payment'}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="bank-account-confirm.component-2AED5C"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="bank-account-confirm.component-EFB545"
          isFetching={isLoading}
          variant="contained"
          color="error"
          onClick={() =>
            mutate({ partnerId: profile!.id, bank_account_pk: id })
          }
        >
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
