import { Box, Button, Chip, Typography } from '@mui/material'
import { BankAccount } from '@remoteam-front/types/legacy'
import { theme } from '@remoteam-front/ui'
import { BuildingBankRegular } from '@remoteam-front/ui/icons'
import { mapCurrency } from '@remoteam-front/utils/currency'

type Props = { onMange(): void } & BankAccount

export const BankAccountCard = ({
  is_default,
  iban,
  currency,
  onMange,
}: Props) => {
  return (
    <Box sx={{ background: '#fff', padding: 2, borderRadius: 2, width: 504 }}>
      <Box display="flex" alignItems="center" gap={1.5}>
        <BuildingBankRegular data-testid="bank-account-card.component-E51EE5" />

        <Box display="flex" flexDirection="column">
          <Box display="flex" gap={0.5} alignItems="center">
            <Typography
              data-testid="bank-account-card.component-E84C33"
              variant="textLarge2"
            >
              Bank account
            </Typography>
            {is_default && <Chip size="small" label="DEFAULT" color="info" />}
          </Box>
          <Typography
            variant="textSmall1"
            color={theme.palette.grey[600]}
            data-testid="bank-account-card.component-05EA5A"
          >
            SWIFT ending in {iban.slice(-4)} | {mapCurrency(currency)}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
          <Button
            data-testid="bank-account-card.component-B344A5"
            variant="outlined"
            size="small"
            color="secondary"
            onClick={onMange}
          >
            Manage
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
