import { Box, Button, Chip, Link, Typography } from '@mui/material'
import {
  CheckmarkFilledIcon,
  DismissFilledIcon,
  InfoOutline,
} from '@remoteam-front/ui/icons'
import {
  approveDocument,
  declineDocument,
  getOnboardingDocuments,
} from '@services/app.service'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Styled } from './onboarding-tracker-required-documents.styles'
import { RejectDialog } from './reject-dialog.component'

export const OnboardingTrackerRequiredDocuments = ({ employeeDocs }) => {
  const [docForDecline, setDocForDecline] = useState(null)

  const queryClient = useQueryClient()

  const approveMutation = useMutation({
    mutationFn: approveDocument,
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingDocuments.key)
    },
  })
  const rejectMutation = useMutation({
    mutationFn: (decline_reason) =>
      declineDocument(docForDecline.id, { decline_reason }),
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingDocuments.key)
      setDocForDecline(null)
    },
  })

  const handleApprove = (employeeDocId) => () => {
    approveMutation.mutate(employeeDocId)
  }

  const renderButtons = (state, child) => {
    if (state === 'approved') {
      return <Chip color="success" size="small" label="Approved" />
    }
    if (state === 'declined') {
      return <Chip color="error" size="small" label="Rejected" />
    }
    if (state === 'not_uploaded' || state === 'unable_to_provide') {
      return (
        <Box display="flex" gap={1}>
          <Button
            data-testid="onboarding-tracker-required-documents.component-A19F56"
            variant="contained"
            size="small"
            startIcon={<CheckmarkFilledIcon />}
            disabled
          >
            Approve
          </Button>
          <Button
            data-testid="onboarding-tracker-required-documents.component-DDCCDF"
            variant="contained"
            color="error"
            size="small"
            startIcon={<DismissFilledIcon />}
            disabled
          >
            Reject
          </Button>
        </Box>
      )
    }
    return child
  }

  const renderContent = (employeeDoc, onEditDecline) => {
    if (employeeDoc.state === 'not_uploaded') {
      return (
        <Styled.Doc>
          <Typography variant="body1">
            Documents uploaded by the employee will appear here.
          </Typography>
        </Styled.Doc>
      )
    }

    if (employeeDoc.state === 'unable_to_provide') {
      return (
        <Styled.Doc>
          <Chip
            color="warning"
            label="The employee declares that they cannot provide the document."
            icon={<InfoOutline />}
          />
        </Styled.Doc>
      )
    }

    if (employeeDoc.state === 'declined') {
      return (
        <>
          <Styled.Doc>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography component="span">
                Rejection reason:{' '}
                <Typography color="#6D6D6C" component="span">
                  {employeeDoc.decline_reason}
                </Typography>{' '}
              </Typography>
              <Button size="small" onClick={onEditDecline}>
                Edit
              </Button>
            </Box>
          </Styled.Doc>

          <Styled.Doc>
            {employeeDoc.attachments.map((attachment) => (
              <Link
                data-testid="onboarding-tracker-required-documents.component-DF85BB"
                key={attachment.file_uuid}
                color="primary"
                target="_blank"
                href={attachment.file}
                rel="noreferrer"
                underline="hover"
              >
                {attachment.filename}
              </Link>
            ))}
          </Styled.Doc>
        </>
      )
    }

    return (
      <Styled.Doc>
        {employeeDoc.attachments.map((attachment) => (
          <Link
            key={attachment.file_uuid}
            color="primary"
            target="_blank"
            href={attachment.file}
            rel="noreferrer"
            underline="hover"
          >
            {attachment.filename}
          </Link>
        ))}
      </Styled.Doc>
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} sx={{ width: 528 }}>
        {employeeDocs.map((doc) => (
          <Styled.Card key={doc.id}>
            <Box key={doc.id}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Typography variant="textLarge2">
                    {doc.employee_document.name}
                  </Typography>
                  {!doc.is_required && '(Optional)'}
                </Box>

                {renderButtons(
                  doc.state,
                  <Box display="flex" gap={1}>
                    <Button
                      data-testid="onboarding-tracker-required-documents.component-53ABF0"
                      variant="contained"
                      size="small"
                      startIcon={
                        !approveMutation.isLoading && <CheckmarkFilledIcon />
                      }
                      disabled={approveMutation.isLoading}
                      onClick={handleApprove(doc.id)}
                    >
                      Approve
                    </Button>
                    <Button
                      data-testid="onboarding-tracker-required-documents.component-46F18F"
                      variant="contained"
                      color="error"
                      size="small"
                      startIcon={<DismissFilledIcon />}
                      onClick={() => setDocForDecline(doc)}
                    >
                      Reject
                    </Button>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={1} mt="10px">
                {renderContent(doc, () => setDocForDecline(doc))}
              </Box>
            </Box>
          </Styled.Card>
        ))}
      </Box>

      {docForDecline && (
        <RejectDialog
          reason={docForDecline.decline_reason}
          isLoading={rejectMutation.isLoading}
          onClose={() => setDocForDecline(null)}
          onReject={rejectMutation.mutate}
        />
      )}
    </>
  )
}
