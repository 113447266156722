export const leaveTypes = [
  { value: 'PAID', label: 'Paid' },
  { value: 'UNPAID', label: 'Unpaid' },
]

export const reasonTypes = {
  PAID: [
    { value: 'VACATION', label: 'Vacation', is_paid: true, name: 'paid_leave' },
    {
      value: 'PARENTAL_LEAVE',
      label: 'Parental leave',
      is_paid: true,
      name: 'paid_parental_leave',
    },
    {
      value: 'BEREAVEMENT',
      label: 'Bereavement',
      is_paid: true,
      name: 'paid_bereavement_leave',
    },
    {
      value: 'SICK_LEAVE',
      label: 'Sick Leave',
      is_paid: true,
      name: 'paid_sick_leave',
    },
  ],
  UNPAID: [
    { value: 'PARENTAL_LEAVE', label: 'Parental leave', is_paid: false },
    {
      value: 'BEREAVEMENT',
      label: 'Bereavement',
      is_paid: false,
    },
    {
      value: 'SICK_LEAVE',
      label: 'Sick Leave',
      is_paid: false,
    },
    { value: 'CAREGIVER_LEAVE', label: 'Caregiver leave', is_paid: false },
    { value: 'EDUCATIONAL_LEAVE', label: 'Education leave', is_paid: false },
    { value: 'MARRIAGE_LEAVE', label: 'Marriage leave', is_paid: false },
    {
      value: 'MILITARY_TRAINING_ASSEMBLY',
      label: 'Military training assembly',
      is_paid: false,
    },
    { value: 'MILITARY_LEAVE', label: 'Military leave', is_paid: false },
    {
      value: 'MILITARY_MEDICAL_EXAMINATION',
      label: 'Military medical examination',
      is_paid: false,
    },
    {
      value: 'SABBATICAL',
      label: 'Sabbatical',
      is_paid: false,
    },
    {
      value: 'CIVIC_DUTIES',
      label: 'Civic duties',
      is_paid: false,
    },
    {
      value: 'RELIGIOUS_OBSERVANCE',
      label: 'Religious observance',
      is_paid: false,
    },
  ],
}
