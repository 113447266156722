import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const patchProfile = async (data) =>
  Api.patch(`partners/profile/my/`, data)

export const getStats = async () => {
  const { data } = await Api.get(`/partners/stats/`)
  return data
}

export const getOnboardingChecklist = generateService(async (id) => {
  const { data } = await Api.get(`/partners/${id}/onboarding/check-list/`)
  return data
})

export const getSumsubAccessToken = async (payload) => {
  const { data } = await Api.post(`/sumsub/get-access-token/`, payload)
  return data
}

export const setCheckCountryGuide = async (partnerId) => {
  const { data } = await Api.put(
    `/partners/${partnerId}/onboarding/check-list/country-guide-complete/`
  )

  return data
}
