import { DotWave } from '@uiball/loaders'
import React, { PropsWithChildren } from 'react'

import { Styled } from './spinner.styles'

type Props = {
  isFetching: boolean
  spinner?: React.JSX.Element
}

export const Spinner = ({
  spinner = <DotWave />,
  isFetching,
  children,
}: Props & PropsWithChildren) => {
  if (!isFetching) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <Styled.Root>
      {isFetching && (
        <Styled.SpinContainer>
          <Styled.SpinWrapper>{spinner}</Styled.SpinWrapper>
        </Styled.SpinContainer>
      )}
      <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
    </Styled.Root>
  )
}
