import { useAxiosCall } from '@api/Api'
import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import EditAvatar from '@atoms/EditAvatar/EditAvatar'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { getPersonalInformation } from '@pages/employeeDetailPage/mock'
import {
  deactivateContract,
  mutationUploadAvatar,
} from '@services/contract.service'
import { parseISO } from 'date-fns'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

export default function Personal({ employee, setEmployee, refetch }) {
  const { countries, profile } = useContext(AppContext)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [visibleDeactivate, setVisibleDeactivate] = useState(false)
  const [editState, setEditState] = useState({
    first_name: employee.profile.first_name,
    middle_name: employee.profile.middle_name,
    last_name: employee.profile.last_name,
    email: employee.profile.email,
    avatar: employee.profile.avatar,
    phone_number: employee.profile.phone_number,
    birth_date: employee.profile.birth_date
      ? parseISO(employee.profile.birth_date)
      : null,
    living_country: employee.profile.address?.living_country?.id || '',
    street_address: employee.profile.address?.street_address,
    state: employee.profile.address?.state,
    city: employee.profile.address?.city,
    postal_code: employee.profile.address?.postal_code,
    address_line: employee.profile?.address?.address_line,
    needs_work_permit: `${employee.profile?.needs_work_permit}`,
    work_permit_expiration: employee.work_permit?.expiration_date
      ? parseISO(employee.work_permit?.expiration_date)
      : null,
  })
  const [, patchContractById] = useAxiosCall(
    {
      url: `contracts/${employee.id}/`,
      method: 'patch',
    },
    {
      manual: true,
    }
  )

  const deactivateEmployee = useMutation({
    mutationFn: (id) => deactivateContract(id),
    onSuccess: () => {
      toast.success('Successfully deactivated')
      setVisibleDeactivate(false)
      refetch()
    },
  })

  const uploadProfileAvatar = useMutation({
    mutationFn: (payload) => mutationUploadAvatar(payload.id, payload.file),
    onSuccess: ({ data }) => {
      setEmployee(data)
      setEditState((prevState) => ({
        ...prevState,
        avatar: data.profile.avatar,
      }))
      toast.success('Profile image has been updated successfully')
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeBirthDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      birth_date: value,
    }))
  }

  const onChangeWorkPermit = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      needs_work_permit: e.target.value,
    }))
  }

  const onResidentTypeChange = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      residency_type: e.target.value,
    }))
  }

  const onChangeExpirationDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      work_permit_expiration: value,
    }))
  }

  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }

  const onSaveEdit = () => {
    const compensation =
      employee.contract_type === 'contractor'
        ? {
            contractor_rate: employee.compensation.contractor_rate,
            estimated_working_hours:
              employee.compensation.estimated_working_hours || undefined,
            contractor_wage_type: employee.compensation.contractor_wage_type,
            contractor_payment_frequency:
              employee.compensation.contractor_payment_frequency,
          }
        : {
            yearly_gross_salary: employee.compensation.yearly_gross_salary,
          }
    const data = {
      profile: {
        first_name: editState.first_name,
        middle_name: editState.middle_name,
        last_name: editState.last_name,
        phone_number: editState.phone_number,
        email: editState.email,
        birth_date: editState.birth_date
          ? moment(editState.birth_date).format('YYYY-MM-DD')
          : null,
        address: {
          living_country: editState.living_country,
          street_address: editState.street_address,
          city: editState.city,
          postal_code: editState.postal_code,
          state: editState.state,
          address_line: editState.address_line,
        },
        needs_work_permit: editState.needs_work_permit,
      },
      compensation,
      work_permit: {
        expiration_date:
          editState.needs_work_permit === 'true'
            ? editState.work_permit_expiration &&
              moment(editState.work_permit_expiration).format('YYYY-MM-DD')
            : null,
      },
      residency_type: editState.residency_type ?? '',
    }
    patchContractById({ data }).then((res) => {
      toast.success('Successfully updated')
      setVisibleEdit(false)
      setEmployee(res.data)
    })
  }

  const onAvatarUpload = (file) => {
    uploadProfileAvatar.mutate({ id: employee.id, file })
  }

  const onClickDeactivate = () => {
    deactivateEmployee.mutate(employee.id)
  }

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">
            Personal information
          </Typography>
          <Button
            data-testid="Personal-B48745"
            priority="secondary"
            size="small"
            onClick={() => setVisibleEdit(true)}
          >
            Edit
          </Button>
        </div>
        <Avatar
          src={editState.avatar}
          width={100}
          height={100}
          className="mt-2 mb-5"
        />
        <List
          lists={getPersonalInformation(
            employee,
            profile.hide_names_in_invoice
          ).map((v) => ({
            ...v,
            value: v.value?.join(', '),
          }))}
        />
        {employee.state !== 'inactive' && (
          <Button
            data-testid="Personal-72A8AB"
            onClick={() => setVisibleDeactivate(true)}
            style={{ maxWidth: 'fit-content' }}
            priority="primary_black"
            size="small"
          >
            Deactivate contract
          </Button>
        )}
      </div>

      <EditModal
        visible={visibleDeactivate}
        title="Deactivate contract"
        onSave={onSaveEdit}
        footer={false}
        closeModal={() => setVisibleDeactivate(false)}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to deactivate this employee from your company
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Personal-068A03"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={() => setVisibleDeactivate(false)}
          >
            Cancel
          </Button>
          <Button
            data-testid="Personal-3C95AD"
            loading={deactivateEmployee.isLoading}
            priority="primary_dangerous"
            size="small"
            onClick={onClickDeactivate}
          >
            Deactivate
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={visibleEdit}
        title="Personal information"
        onSave={onSaveEdit}
        closeModal={() => setVisibleEdit(false)}
      >
        <EditAvatar
          src={editState.avatar}
          isLoading={uploadProfileAvatar.isLoading}
          className="mt-1 mb-3"
          onUpload={onAvatarUpload}
        />
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-A83AD5"
            label="Name"
            placeholder="Name"
            name="first_name"
            value={editState?.first_name}
            onChange={onChangeEdit}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-5F3F4E"
            onChange={onChangeEdit}
            label="Middle name (if applicable)"
            placeholder="Middle name"
            name="middle_name"
            value={editState?.middle_name}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-A80E20"
            onChange={onChangeEdit}
            label="Surname"
            placeholder="Surname"
            name="last_name"
            value={editState?.last_name}
          />
        </div>
        <div className="w-100 remo-form-input">
          <DateInput
            className="mb-3"
            maxDate={new Date()}
            onChange={onChangeBirthDate}
            label="Date of birth"
            value={editState?.birth_date}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-EBED22"
            onChange={onChangeEdit}
            label="Phone number"
            placeholder="Phone number"
            name="phone_number"
            type="text"
            value={editState?.phone_number}
          />
        </div>
        <div className="w-100 remo-form-input">
          <RadioButton
            label="Work eligibility"
            onChange={onChangeWorkPermit}
            value={editState.needs_work_permit}
            addText="Does this employee require a work eligibility document (work permit, visa, etc)?"
            options={[
              {
                id: 'needs_work_permit_true',
                value: 'true',
                text: 'Yes',
              },
              {
                id: 'needs_work_permit_false',
                value: 'false',
                text: 'No',
              },
            ]}
          />
        </div>
        {editState.needs_work_permit === 'true' && (
          <div className="w-100 remo-form-input">
            <Typography className="text_medium__14 remo-form-label">
              Work permit expiration date
            </Typography>
            <DatePicker
              selected={
                editState.work_permit_expiration
                  ? new Date(editState.work_permit_expiration)
                  : null
              }
              onChange={onChangeExpirationDate}
              minDate={new Date()}
              dateFormat="dd MMM yyyy"
              placeholderText="DD Mon YYYY"
              required
            />
          </div>
        )}
        <div className="w-100 remo-form-input">
          <RadioButton
            label="Resident type"
            onChange={onResidentTypeChange}
            value={editState.residency_type}
            addText="Is this employee a Resident or Non-Resident?"
            options={[
              {
                id: 'resident',
                value: 'resident',
                text: 'Resident',
              },
              {
                id: 'non_resident',
                value: 'non_resident',
                text: 'Non-Resident',
              },
            ]}
          />
        </div>
        {countries && (
          <div className="w-100 remo-form-input">
            <Select
              data-testid="Personal-2A2B2E"
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              name="living_country"
              id="living_country"
              placeholder="e.g United Kingdom"
              onChange={onChangeLivingCountry}
              value={editState?.living_country}
              label="Country"
            />
          </div>
        )}

        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-940370"
            onChange={onChangeEdit}
            label="State"
            placeholder="State"
            name="state"
            value={editState?.state}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-D91866"
            onChange={onChangeEdit}
            label="City"
            name="city"
            value={editState?.city}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-C22AE1"
            onChange={onChangeEdit}
            label="Address"
            placeholder="Address"
            name="street_address"
            value={editState?.street_address}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-5BCC5D"
            onChange={onChangeEdit}
            label="Postal code"
            placeholder="Postal code"
            name="postal_code"
            value={editState?.postal_code}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-0B2190"
            onChange={onChangeEdit}
            label="Address line"
            placeholder="Address line"
            name="address_line"
            value={editState?.address_line}
          />
        </div>
      </EditModal>
    </div>
  )
}
