/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { createElement, isValidElement } from 'react'

export const createSvgPath = (path) =>
  isValidElement(path)
    ? path
    : Array.isArray(path)
    ? path.map((n, i) => createElement('path', { d: n, key: i }))
    : createElement('path', { d: path })
