import { useApp } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { deletePartnerManager } from '@remoteam-front/services/partner.service'
import { PaginationResponse } from '@remoteam-front/types/legacy/pagination.types'
import { PartnerManager } from '@remoteam-front/types/legacy/partners-team.types'
import { Pagination } from '@remoteam-front/ui'
import { Spinner } from '@remoteam-front/ui/components/spinner/spinner'
import {
  AddFilledIcon,
  DeleteRegularIcon,
  EditRegularIcon,
} from '@remoteam-front/ui/icons'
import { getPartnerUsersByOwner } from '@services/team.service'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { KeyContactsConfirm } from './key-contacts-confirm.component'
import { KeyContactsModal } from './key-contacts-modal.component'

type Props = {
  onSave(): void
}

export const KeyContacts = ({ onSave }: Props) => {
  const { profile } = useApp() as any
  const { limit, page, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const createModal = useBoolean(false)

  const [managerToUpdate, setManagerToUpdate] = useState<PartnerManager | null>(
    null
  )

  const [managerToDelete, setManagerToDelete] = useState<PartnerManager | null>(
    null
  )

  const teamQuery = useQuery<PaginationResponse<PartnerManager[]>>(
    getPartnerUsersByOwner.key,
    {
      enabled: !!profile.id,
      queryFn: () =>
        getPartnerUsersByOwner.fetch(profile.id!, { limit: 1000, offset: 0 }),
      keepPreviousData: true,
    }
  )

  const deleteMutation = useMutation({
    mutationFn: () => deletePartnerManager(managerToDelete!.partner_manager.id),
    onSuccess: () => {
      setManagerToDelete(null)

      if (page !== 1 && teamQuery.data?.results?.length === 1) {
        // change page state will call query it self w/o refetch
        setPage((prev) => prev - 1)
        return
      }
      teamQuery.refetch()
    },
  })

  if (teamQuery.isLoading) {
    return <DotWave />
  }

  if (teamQuery.data) {
    return (
      <Spinner isFetching={teamQuery.isFetching}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box alignSelf="flex-end">
            <Button
              data-testid="key-contacts.component-AEB13F"
              variant="outlined"
              color="secondary"
              startIcon={<AddFilledIcon />}
              onClick={createModal.setTrue}
            >
              Add manager
            </Button>
          </Box>
          <Table data-testid="key-contacts.component-57C132">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job title</TableCell>
                <TableCell>Contact type</TableCell>
                <TableCell>Billing contact</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamQuery.data.results.map((manager) => (
                <TableRow key={manager.id}>
                  <TableCell>
                    {`${manager.profile.first_name} ${manager.profile.last_name}`}
                  </TableCell>
                  <TableCell>{manager.email}</TableCell>
                  <TableCell>{manager.profile?.job_title || '-'}</TableCell>
                  <TableCell>
                    {manager.responsibility.contact_types.length === 0 && '-'}

                    {manager.responsibility.contact_types
                      .map(({ name }) => mapEnum(capitalizeFirstLetter(name)))
                      .join(', ')}
                  </TableCell>

                  <TableCell>
                    {manager.is_billing_contact ? 'Yes' : 'No'}
                  </TableCell>

                  <TableCell>
                    <Box display="flex" gap={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<EditRegularIcon />}
                        color="secondary"
                        onClick={() => setManagerToUpdate(manager)}
                      >
                        Edit
                      </Button>
                      {manager.role.name !== 'partner_owner' && (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<DeleteRegularIcon />}
                          color="secondary"
                          onClick={() => setManagerToDelete(manager)}
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box alignSelf="end">
            <Pagination
              page={page}
              total={teamQuery.data.count}
              pageSize={limit}
              onChange={(_, page) => setPage(page)}
            />
          </Box>

          <Box alignSelf="center">
            <Button
              data-testid="key-contacts.component-64D768"
              variant="contained"
              onClick={onSave}
            >
              Save and continue
            </Button>
          </Box>

          {createModal.value && (
            <KeyContactsModal
              partnerManager={null}
              onClose={createModal.setFalse}
            />
          )}

          {!!managerToUpdate && (
            <KeyContactsModal
              partnerManager={managerToUpdate}
              onClose={() => setManagerToUpdate(null)}
            />
          )}

          {!!managerToDelete && (
            <KeyContactsConfirm
              partnerManager={managerToDelete}
              loading={deleteMutation.isLoading}
              onDelete={deleteMutation.mutate}
              onClose={() => setManagerToDelete(null)}
            />
          )}
        </Box>
      </Spinner>
    )
  }

  return null
}
