export const EmployeeSubStatusTooltip = () => (
  <div>
    Definition of FTE sub-statuses
    <div>
      <div>
        <strong>Onboarding</strong> sub-statuses:
      </div>
      <ul>
        <li>
          <strong>Waiting for required documents</strong> - The employee profile
          is created, and the onboarding process is pending the submission of
          necessary documents.
        </li>
        <li>
          <strong>Waiting for documents approval</strong> - The required
          documents have been submitted, and they are awaiting review by the
          local partner (LP).
        </li>
        <li>
          <strong>Waiting for agreement signing</strong> - All required
          documents are approved, and the process is waiting for the all parties
          to sign the agreement.
        </li>
        <li>
          <strong>On Hold</strong> - The onboarding process has been paused due
          to a specific reason.
        </li>
      </ul>
    </div>
    <div>
      <div>
        <strong>Active</strong> sub-status:
      </div>
      <ul>
        <li>
          <strong>Offboarding</strong> - The process of offboarding the employee
          has started, but they are still officially active until the process
          completes.
        </li>
      </ul>
    </div>
    <div>
      <div>
        <strong>Inactive</strong> sub-statuses:
      </div>
      <ul>
        <li>
          <strong>Offboarded</strong> - The employee has been offboarded,
          marking the end of their engagement with the company.
        </li>
        <li>
          <strong>Cancelled</strong> - The onboarding process or employee status
          was canceled.
        </li>
      </ul>
    </div>
  </div>
)
