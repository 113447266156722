import './CustomersDetailPage.scss'

import { useAxiosCall } from '@api/Api'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { TabLinks } from '@components/tab-links/tab-links.component'
import { keyboardArrowLeft } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { ReactComponent as RemofirstIcon } from '../../assets/icons/remofirst.svg'
import { CustomerAgreements } from './customer-agreements/customer-agreements.component'
import { CustomerEmployees } from './customer-employees/customer-employees.component'
import { CustomerInfo } from './customer-info/customer-info.component'

const tabs = [
  {
    title: 'Employees',
    link: '/employees',
  },
  {
    title: 'Information',
    link: '/information',
  },
  {
    title: 'Agreements',
    link: '/agreements',
  },
]

export default function CustomersDetailPage() {
  const { path, params } = useRouteMatch()

  const [{ data: company, loading }] = useAxiosCall({
    url: `companies/${params.id}/`,
  })

  return loading ? (
    <div className="d-flex w-100 h-100 align-items-center justify-content-center">
      <DotWave size={48} speed={1} color="black" />
    </div>
  ) : (
    <div className="customers-detail-page">
      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <Link to="/pages/customers">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                border: '1px solid #CCCCCC',
                boxSizing: 'border-box',
                borderRadius: '8px',
                height: 40,
                width: 40,
              }}
            >
              <Icon
                icon={keyboardArrowLeft}
                fill="black"
                style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
              />
            </div>
          </Link>
          <div className="d-flex align-items-center">
            <Typography className="heading_semibold__24 ml-3 mr-2">
              {company?.name}
            </Typography>
            {company?.from_remofirst && <RemofirstIcon />}
          </div>
        </div>
      </div>

      <TabLinks items={tabs} />

      <div>
        <Switch>
          <Route
            path={`${path}/employees`}
            render={() => <CustomerEmployees />}
          />
          <Route path={`${path}/information`} render={() => <CustomerInfo />} />
          <Route
            path={`${path}/agreements`}
            render={() => <CustomerAgreements />}
          />
          <Redirect from={path} to={`${path}/employees`} />
        </Switch>
      </div>
    </div>
  )
}
