import '../onboarding-checklist.styles.scss'

import Button from '@atoms/Button/Button'
import { getSumsubAccessToken } from '@services/profile.service'
import SumsubWebSdk from '@sumsub/websdk-react'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
  }
`

export default ({ onClose, onSave }) => {
  const config = {}
  const options = {}

  const [dialogHeight, setDialogHeight] = useState(700)

  const sumsubAccessTokenMutation = useQuery('getSumsubAccessToken', {
    queryFn: () =>
      getSumsubAccessToken({
        level_type: 'kyb',
      }),
  })

  const handleAccessTokenExpiration = () => {
    getSumsubAccessToken.fetch().then(({ token }) => token)
  }

  const handleMessage = (type, payload) => {
    console.log('onMessage', type, payload)
    if (type === 'idCheck.onResize' && payload.height) {
      setDialogHeight(payload.height)
    }
  }

  const handleError = () => {
    console.log('Error')
  }

  return (
    <StyledModal show centered onHide={onClose}>
      {sumsubAccessTokenMutation.isLoading && (
        <div className="d-flex w-100 vh-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      )}
      <Modal.Body>
        <div style={{ height: dialogHeight }}>
          {sumsubAccessTokenMutation.data?.token && (
            <SumsubWebSdk
              accessToken={sumsubAccessTokenMutation.data?.token}
              expirationHandler={handleAccessTokenExpiration}
              config={config}
              options={options}
              onMessage={handleMessage}
              onError={handleError}
            />
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            priority="secondary"
            size="small"
            className="mr-2"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
