import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { fetchPartnerProfile } from '@services/app.service'
import React from 'react'
import { useQueryClient } from 'react-query'

import css from '../onboarding.module.scss'
import { SuccessSvg } from './success-svg'

export const SuccessOnboardingComponent = () => {
  const queryClient = useQueryClient()
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className={css.success}>
        <SuccessSvg />
        <Typography className="heading_semibold__28">
          Congratulations, you have successfully registered
        </Typography>
        <Typography className="text_regular-normal__14">
          Please navigate to the Dashboard to continue
        </Typography>

        <Button
          data-testid="success-onboarding.component-3551D7"
          size="small"
          priority="primary_black"
          disabled={queryClient.isFetching({
            queryKey: fetchPartnerProfile.key,
          })}
          onClick={() => queryClient.refetchQueries(fetchPartnerProfile.key)}
        >
          Go to Dashboard
        </Button>
      </div>
    </div>
  )
}
