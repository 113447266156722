import './InvoicesPage.scss'

import { useAxiosCall } from '@api/Api'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { AppContext } from '@core/context'
import React, { useContext, useEffect, useState } from 'react'

import InvoiceModal from './InvoiceModal'
import { parseInvoice } from './parser'

export default function InvoicesPage() {
  const { profile } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [invoices, setInvoices] = React.useState([])
  const [displayInvoice, setDisplayInvoice] = React.useState(null)
  const [displayModal, setDisplayModal] = React.useState(false)
  const [{ data, loading }, getInvoice] = useAxiosCall(
    {
      url: `aggregated-invoices/?limit=${10}&offset=${(page - 1) * 10}`,
    },
    {
      manual: true,
    }
  )

  const fields = [
    {
      title: 'Customer',
      key: 'customer',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Date',
      key: 'date',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Amount',
      key: 'amount',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status',
      maxWidth: 138,
      minWidth: 138,
      width: 138,
    },
  ]

  React.useEffect(() => {
    if (data) {
      setInvoices(data.results)
      setTotal(data.count)
    }
  }, [data])

  const onRowClick = (item) => {
    if (item.status !== 'generating') {
      setDisplayInvoice(item)
      setDisplayModal(true)
    }
  }

  const onCloseModalClick = () => {
    setDisplayModal(false)
  }

  useEffect(() => {
    if (profile) getInvoice()
  }, [profile, page])

  return (
    <div className="invoices-page h-100">
      {displayModal && (
        <InvoiceModal
          invoice={displayInvoice}
          onCloseClick={onCloseModalClick}
        />
      )}

      <PageTitle styleClass="mb-4"> Invoices </PageTitle>
      <CustomTable
        page={page}
        loading={loading}
        onPage={setPage}
        total={total}
        fields={fields}
        data={parseInvoice(invoices)}
        onRowClick={onRowClick}
      />
    </div>
  )
}
