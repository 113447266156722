import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getDocumentsByContractId = async (id) => {
  const { data } = await Api.get(`documents/?contract_id=${id}`)
  return data
}

export const getContractDocuments = generateService(async (params) => {
  const { page = 1, limit = 10 } = params
  const offset = (page - 1) * limit
  const { data } = await Api.get(`contract-documents/`, {
    params: {
      ...params,
      offset,
      limit,
    },
  })
  return data
})

export const getContractByIdPayslips = async (params) => {
  const { data } = await Api.get(`contract-documents/`, {
    params,
  })
  return data
}
export const getContractPayslips = async (params) => {
  const { data } = await Api.get(`contract-documents/payslips/`, {
    params,
  })
  return data
}
export const addContractDocuments = async (data) =>
  Api.post(`contract-documents/create/`, data)

export const deleteDocumentsById = async (id, contractId) =>
  Api.delete(`contract-documents/${id}/delete/?contract_id=${contractId}`)

// Employee Documents
export const getEmployeeDocuments = generateService(
  async (limit = 10, page = 1) => {
    const offset = (page - 1) * limit
    const { data } = await Api.get(`documents/?limit=${limit}&offset=${offset}`)
    return data
  }
)
export const deleteEmployeeDocument = async (id) => {
  const { data } = await Api.delete(`documents/${id}/delete/`)
  return data
}
export const updateEmployeeDocument = async (id, body) => {
  const { data } = await Api.put(`documents/${id}/update/`, body)
  return data
}
export const createEmployeeDocument = async (body) => {
  const { data } = await Api.post(`documents/create/`, body)
  return data
}
