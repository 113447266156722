import './style.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import NotFound from '@atoms/NotFound/NotFound'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { Tooltip } from '@atoms/Tooltip'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { numberWithSpaces } from '@core/utils'
import { payrollsField } from '@pages/payrollsDetailPage/mock'
import { parserPayrollsBasePayment } from '@pages/payrollsDetailPage/parse'
import { DotWave } from '@uiball/loaders'
import moment from 'moment/moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import {
  approvePayroll,
  fetchDeletePayroll,
  getPayrollInvoiceTotal,
  getPayrollList,
  getPayrollListNeedChange,
  postCalculateLastDay,
} from '../../services/payroll.service'
import ChangesAccordion from './ChangesAccordion'

export default function PayrollDetailPage() {
  const { profile } = useApp()
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const history = useHistory()
  const [payrollsData, setPayrollsData] = useState([])
  const [payrollCurrent, setPayrollCurrent] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()
  const [invoiceId, setInvoiceId] = useState()
  const [selectedPayrollDelete, setSelectedPayrollDelete] = useState(null)

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)

  const { data, isLoading, isFetching, refetch } = useQuery('payrolls', {
    queryFn: () => getPayrollList({ limit: 100000, page: 1 }),
  })

  const deleteMutate = useMutation({
    mutationFn: (payrollId) => fetchDeletePayroll(payrollId),
    onSuccess: () => {
      refetch()
      toggleDelete()
      toast.success('Payroll successfully deleted')
    },
  })

  const approvePayrollRequest = useMutation('payrollsChangeStatus', {
    mutationFn: () => approvePayroll(),
    onSuccess: () => {
      toast.success('Successfully updated')
      refetch()
    },
  })
  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })
  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const { isLoading: invoiceTotalLoading } = useQuery(
    ['invoiceTotal', invoiceId],
    {
      queryFn: () => getPayrollInvoiceTotal(invoiceId),
      onSuccess: (res) => {
        setInvoiceTotal(res)
      },
      enabled: !!invoiceId,
    }
  )

  const onClickEdit = useCallback((rowData) => {
    history.push(`/pages/payrolls/edit/${rowData.id}`)
  }, [])

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayrollDelete(row.id)
  }

  const onCollapseClick = useCallback((row) => {
    setInvoiceId(row.id)
  }, [])

  const onCreateInvoice = useCallback(() => {
    approvePayrollRequest.mutate()
  }, [])

  const checkDisable = (lastDay) => {
    if (postCalculateLastDayMutate?.data?.last_day)
      return (
        currentDate <=
        new Date(
          moment(new Date(postCalculateLastDayMutate.data.last_day)).format(
            'YYYY-MM-DD'
          )
        )
      )
    return false
  }

  const checkDisableValue = useMemo(() => {
    if (postCalculateLastDayMutate?.data?.last_day)
      return (
        currentDate <=
        new Date(
          moment(new Date(postCalculateLastDayMutate.data.last_day)).format(
            'YYYY-MM-DD'
          )
        )
      )
    return false
  }, [postCalculateLastDayMutate?.data?.last_day])

  useEffect(() => {
    if (data) {
      const totalSum = data.reduce((a, b) => a + b.current.total, 0)
      const showTotal = data.reduce((a, b) => a + b.current.can_approve, 0)
      const disabledApprove = data.filter((a) => {
        return a.current.payrolls.some(
          (payroll) =>
            payroll.state === 'created' ||
            payroll.state === 'need_changes_from_partner'
        )
      })
      setPayrollCurrent({
        show: showTotal,
        total: totalSum,
        currency: data[0]?.current?.currency,
        disable: !(disabledApprove.length > 0),
      })
      setPayrollsData(data)
      setInvoiceId(data[0]?.current?.payrolls[0]?.id)
    }
  }, [data])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.allowed_countries[0]?.id,
        days: 3,
      })
    }
  }, [profile])

  const renderTable = React.useMemo(() => {
    return payrollsData.length > 0 ? (
      <div>
        {payrollsData?.map((payrollData) => (
          <div
            key={payrollData.current.name}
            className="d-flex flex-column payrolls-table mb-4"
          >
            <div className="row payrolls-table-header">
              <div className="col-5">
                <Typography className="text_medium__14">
                  {payrollData.current.name}
                </Typography>
              </div>
              <div className="col-4">
                <Typography className="text_regular-normal__14">
                  Month: {payrollData.current.month}
                </Typography>
              </div>
              <div className="col-3">
                <Typography className="text_regular-normal__14 text-right">
                  {payrollData.current?.currency?.sign ||
                    payrollData.current?.currency?.short_code}{' '}
                  {numberWithSpaces(payrollData.current.total)}
                </Typography>
              </div>
            </div>
            <div className="p-4">
              <CustomTable
                type="collapse"
                collapseId={invoiceId}
                className="customer-payroll-table"
                fields={payrollsField(onClickEdit, handleDelete)}
                onCollapseClick={onCollapseClick}
                data={parserPayrollsBasePayment(
                  payrollData.current?.payrolls,
                  invoiceTotal,
                  invoiceTotalLoading
                ).map((payroll) => ({
                  ...payroll,
                  state:
                    !checkDisableValue && payroll.state === 'created'
                      ? 'to_approve'
                      : payroll.state,
                }))}
              />
            </div>
          </div>
        ))}
      </div>
    ) : (
      <NotFound
        illustration="/assets/img/payrollEmpty.png"
        title="No payrolls yet"
        description="They will appear here in the next billing period"
      />
    )
  }, [payrollsData, invoiceTotal, invoiceTotalLoading, onClickEdit])
  const renderTotal = React.useMemo(() => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div>
          <Typography className="heading_semibold__20">
            Total: {numberWithSpaces(payrollCurrent?.total)}{' '}
            {payrollCurrent?.currency?.sign}
          </Typography>
        </div>
        <div className="d-flex align-items-center">
          <Button
            data-testid="PayrollDetailPage-EBC18F"
            className="px-5"
            priority="primary"
            size="medium"
            disabled={checkDisable()}
            loading={approvePayrollRequest.isLoading}
            onClick={onCreateInvoice}
          >
            Approve
          </Button>
          {checkDisable() && (
            <Tooltip
              id={v4()}
              content="The approval button is active
              from the 4th working day of the month. This provides
              clients the opportunity to add expenses & additional payments for the month."
              style={{ width: 400 }}
            />
          )}
        </div>
      </div>
    )
  }, [payrollCurrent, onCreateInvoice, approvePayrollRequest.isLoading])
  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
      </div>
      <Tabs>
        <Tab tabId={0} title="New Pending">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="customer-payroll-table-box">
              <div
                className="customer-payroll-table-wrap"
                style={{ overflowX: 'auto' }}
              >
                {renderTable}
                {payrollCurrent &&
                  payrollCurrent.show !== 0 &&
                  payrollCurrent.total > 0 && (
                    <div className="payroll-bottom-wrapper">
                      <div className="payroll-bottom d-flex justify-content-center">
                        {renderTotal}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="Requested Change" count={listNeedChange?.count}>
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
      <EditModal
        visible={visibleDelete}
        title="Delete payroll"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this payroll?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayrollDetailPage-FBE06F"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayrollDetailPage-40DD71"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayrollDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
    </div>
  )
}
