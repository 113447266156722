import { capitalizeFirstLetter, mapEnum, SplitNumber } from '@core/utils'
import moment from 'moment'

export const parseStatus = (status) => {
  if (
    status === 'CREATED' ||
    status === 'MOVED_TO_NEXT_MONTH' ||
    status === 'PENDING'
  ) {
    return 'created'
  }
  if (status === 'APPROVED_BY_COMPANY' || status === 'AWAITING_PAYMENT') {
    return 'AWAITING_PAYMENT'
  }
  if (status === 'PAID') {
    return 'PAID'
  }
  if (status === 'REJECTED' || status === 'CANCELLED' || status === 'DELETED') {
    return 'Cancelled'
  }
  return 'draft'
}

export const parserPayrollsBasePayment = (payments) => {
  return payments.map((payment) => ({
    ...payment,
    id: payment.id,
    name: payment?.contract_data?.full_name,
    title: payment?.name,
    status: parseStatus(payment.state),
    created_at: moment(payment.created_at).format('DD MMM YYYY'),
    company: payment.company_data.name,
    recurrence_frequency_label: mapEnum(
      capitalizeFirstLetter(payment.recurrence_frequency)
    ),
    amount: `${SplitNumber(payment.amount)} ${payment?.currency?.short_code}`,
    total_amount: `${SplitNumber(payment.amount + payment.tax)} ${
      payment?.currency?.short_code
    }`,
  }))
}
