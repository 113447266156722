import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import CustomTable from '@atoms/Table/CustomTable'
import { searchIcon } from '@core/icons/icons'
import { fetchContractorsByCompany } from '@pages/employees/EmployeesPage.service'
import { employeeTableParse } from '@pages/employees/parse'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'

import { fields } from '../mock'

export const CustomerEmployees = () => {
  const { params } = useRouteMatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 500)
  const [employees, setEmployees] = useState([])
  const { isFetching } = useQuery(
    ['contractors', params, debouncedValue, page],
    {
      queryFn: () => fetchContractorsByCompany(params.id, debouncedValue, page),
      onSuccess: ({ results, count }) => {
        setTotal(count)
        setEmployees(employeeTableParse(results, page))
      },
      enabled: !!params?.id,
    }
  )

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setPage(1)
  }

  const onRowClick = ({ id, status, documents_state }) => {
    if (status === 'onboarding' && documents_state !== 'signed') {
      history.push(`/pages/employees/${id}`)
      return
    }
    history.push(`/pages/employees/${id}`)
  }

  return (
    <>
      <Input
        data-testid="customer-employees.component-E101F9"
        onChange={onChangeSearch}
        placeholder="Search"
        type="text"
        name="search"
        value={search}
        endIcon={<Icon icon={searchIcon} />}
        styleClass="customers-detail-page-search"
      />
      <CustomTable
        page={page}
        loading={isFetching}
        onPage={setPage}
        total={total}
        fields={fields}
        data={employees}
        onRowClick={onRowClick}
      />
    </>
  )
}
