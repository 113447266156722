import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'

export default function ({ onNext }) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      default_currency: '',
    },
  })

  const { currenciesAC } = useContext(AppContext)

  const submit = ({ name, default_currency }) => {
    onNext({
      name,
      default_currency: default_currency.value,
    })
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformation-876881"
            {...register('name', {
              required: 'Company legal name is required',
            })}
            type="text"
            label="Company legal name"
            placeholder="e.g Int. Solutions B.V"
          />
          {errors.name && (
            <Typography className="text_regular__14 color_red">
              {errors.name.message}
            </Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="default_currency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <Select
                data-testid="BasicInformation-27DEB2"
                {...field}
                label="Default Currency"
                options={currenciesAC?.map((currency) => ({
                  value: currency.id,
                  label: currency.short_code,
                }))}
              />
            )}
          />
          {errors.default_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.default_currency.message}
            </Typography>
          )}
        </div>
        <div className="align-self-center pb-5">
          <Button
            data-testid="BasicInformation-7D2EB1"
            type="submit"
            className="align-self-end"
          >
            Continue
          </Button>
        </div>
      </section>
    </form>
  )
}
