import Icon from '@atoms/Icon/Icon'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { keyboardArrowLeft } from '@core/icons/icons'
import { fetchCompanyById } from '@pages/employees/EmployeesPage.service'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  getPayrollByCompanyId,
  getPayrollInvoiceTotal,
} from '../../services/payroll.service'
import { approvedPayrollsField, parserPayrollsBasePayment } from './mock'

export const FlagApprovedPayrollListPage = () => {
  const router = useRouteMatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [invoiceId, setInvoiceId] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getPayrollByCompanyId', currentPage, router.params.id],
    {
      queryFn: () =>
        getPayrollByCompanyId(router.params.id, {
          state: 'approved',
          limit: 100000,
        }),
    }
  )

  const { isLoading: invoiceTotalLoading } = useQuery(
    ['invoiceTotal', invoiceId],
    {
      queryFn: () => getPayrollInvoiceTotal(invoiceId),
      onSuccess: (res) => {
        setInvoiceTotal(res)
      },
      enabled: !!invoiceId,
    }
  )

  const { data: companyDetail } = useQuery(['companyQuery', router.params.id], {
    enabled: !!router.params.id,
    keepPreviousData: true,
    queryFn: () => fetchCompanyById(router.params.id),
  })

  const handleBack = () => {
    history.goBack()
  }

  const onCollapseClick = useCallback((row) => {
    setInvoiceId(row.id)
  }, [])

  return (
    <div className="invoices-page h-100">
      <div className="d-flex align-items-center mb-4">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            border: '1px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: 40,
            width: 40,
          }}
          role="button"
          aria-hidden
          onClick={handleBack}
        >
          <Icon
            icon={keyboardArrowLeft}
            fill="black"
            style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
          />
        </div>
        <PageTitle styleClass="ml-2"> {companyDetail?.name} </PageTitle>
      </div>
      {isFetching && !data?.payrolls ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <CustomTable
          fields={approvedPayrollsField}
          type="collapse"
          onCollapseClick={onCollapseClick}
          data={parserPayrollsBasePayment(
            data?.payrolls,
            invoiceTotal,
            invoiceTotalLoading
          )}
          loading={isFetching}
        />
      )}
    </div>
  )
}
