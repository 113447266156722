import { Button, styled, TableCell, TextField } from '@mui/material'

const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  borderRadius: theme.spacing(1),
  background: 'rgba(31, 31, 31, 0.04)',
  padding: theme.spacing(2),
}))

const Doc = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(0.5),
  width: '100%',
}))

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary[300],
}))

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 32,
  borderColor: theme.palette.secondary[700],

  '& span': {
    marginRight: 0,
  },
}))

export const StyledTextField = styled(TextField)({
  width: 72,
  backgroundColor: 'white',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      width: 72,
    },
  },
  '& .MuiInputBase-input': {
    width: 72,
  },
  '.MuiOutlinedInput-root.Mui-disabled': {
    width: 72,
    minWidth: 72,
    maxWidth: 72,
  },
  '& .Mui-error': {
    width: 200,
  },
})

export const Styled = {
  ActionButton,
  Card,
  Doc,
  TableHeadCell,
  StyledTextField,
}
