import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { EmployeeDocument } from '@remoteam-front/types/legacy'
import { AsyncButton } from '@remoteam-front/ui'

type Props = {
  document: EmployeeDocument
  loading: boolean
  onDelete(): void
  onClose(): void
}

export const RequiredDocumentsConfirm = ({
  document,
  loading,
  onDelete,
  onClose,
}: Props) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{document.name}</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this required document?
        <Box marginTop={2}>
          This document will be permanently removed from your list of required
          documents.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="required-documents-confirm.component-4883FC"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="required-documents-confirm.component-4883FC"
          isFetching={loading}
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
