/* eslint-disable no-shadow */
import React from 'react'

type Props = {
  page: number
  limit: number
}

const usePagination = (props: Props) => {
  const [_page, setPage] = React.useState(props.page)
  const [_limit, setLimit] = React.useState(props.limit)

  const handleSetLimit = (limit: number) => {
    setPage(props.page)
    setLimit(limit)
  }

  return {
    page: _page,
    setPage,
    limit: _limit,
    setLimit: handleSetLimit,
  }
}

export default usePagination
