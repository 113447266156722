import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import { copyIcon } from '@core/icons/icons'
import { RecoveryKeys } from '@pages/profile/security-2fa/recovery-keys'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const copyCodes = (recoveryCodes) => {
  navigator.clipboard.writeText(recoveryCodes[0])
}

export const SaveRecoveryCodeModal = ({ isOpen, recoveryCodes }) => {
  const { successAlert } = useToast()

  return (
    <Modal show={isOpen} size="md" onHide={() => null}>
      <Modal.Header>
        <Modal.Title>Save your new recovery code</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <BodyContainer>
          <Typography className="text_regular-normal__14">
            Your previous recovery code has been invalidated, keep this new one
            somewhere safe
          </Typography>
          <RecoveryKeys recoveryCodes={recoveryCodes} />
          <Button
            data-testid="save-recovery-code-modal.component-BC8C30"
            priority="secondary"
            size="small"
            onClick={() => {
              copyCodes(recoveryCodes)
              successAlert('Recovery code copied to clipboard')
            }}
          >
            <Icon icon={copyIcon} className="mr-2" />
            Copy code
          </Button>
        </BodyContainer>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="save-recovery-code-modal.component-653C9B"
          size="md"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          onClick={() => window.location.reload()}
        >
          I have saved the recovery code
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const BodyContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  row-gap: 16px;
`
