/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { AppContext } from '@core/context'
import { fetchContractors } from '@pages/employees/EmployeesPage.service'
import { employeeTableParse } from '@pages/employees/parse'
import { fetchUploadFile } from '@services/app.service'
import { fetchTeams } from '@services/team.service'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`
export const categoryTypes = [
  { value: 'job_contract', label: 'Employment contract' },
]

export default ({ onClose, onSubmit, loading, title, employee }) => {
  const { profile } = useContext(AppContext)
  const [documentRequire, setDocumentRequire] = useState(false)
  const [doc, setDoc] = useState({
    file_uuid: undefined,
  })
  const [contracts, setContracts] = useState([])
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      agreement_document_type: undefined,
      subject: undefined,
      title: undefined,
      signers_manager: undefined,
      signers_employee: undefined,
      signed_document: undefined,
    },
  })

  const companyWatch = watch('company')
  const { isFetching: managersLoading, data } = useQuery('managers', {
    queryFn: () => fetchTeams(),
  })
  const { isLoading } = useQuery(['contractors', companyWatch], {
    queryFn: () =>
      fetchContractors('&state_in=active,onboarding', 1, undefined, 1000),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
    },
  })
  const handleUploadAgreement = (formValues) => {
    const signers = [
      {
        name: employee
          ? `${employee.profile.first_name} ${employee.profile.last_name}`
          : formValues.signers_employee.label,
        email_address: employee
          ? employee.profile.email
          : formValues.signers_employee.email,
        role_name: 'employee',
      },
      {
        name: formValues.signers_manager.label,
        email_address: formValues.signers_manager.email,
        role_name: 'partner_manager',
      },
    ]

    const data = {
      signers,
      signed_document: doc.file_uuid,
      title: formValues.title,
      subject: formValues.subject,
      agreement_document_type: formValues.agreement_document_type.value,
      contract: employee ? employee.id : formValues.signers_employee.value,
      partner_profile: profile.partner_profile_id,
      date_of_signature: moment(formValues.date_of_signature).format(
        'YYYY-MM-DD'
      ),
    }
    if (!doc.file_uuid) {
      setDocumentRequire(true)
    } else {
      onSubmit(data)
    }
  }

  const handleUpload = async (file) => {
    setDocumentRequire(false)

    const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
    if (
      file.name !== 'item' &&
      typeof file.name !== 'undefined' &&
      filesize <= 3
    ) {
      const setProgress = (progress) => {
        setDoc((prev) => ({
          ...prev,
          isLoading: true,
          progress,
        }))
      }

      fetchUploadFile(file, setProgress).then(({ file_uuid }) => {
        setDoc((prev) => ({
          ...prev,
          file_uuid,
          isLoading: false,
          success: true,
          progress: 0,
        }))
      })
    } else {
      toast.error(
        'Your fle size exceeds the maximum limit. Please upload a file no larger than 3 Mb.',
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        }
      )
    }
  }

  const handleRemove = () => () => {
    setDoc({})
  }

  const managers = useMemo(() => {
    if (!managersLoading) {
      if (data.results.length > 0) {
        return [
          ...data.results,
          {
            user: { ...profile.user, email: profile.user.profile.email },
            id: profile.partner_profile_id,
          },
        ]
      }

      return [
        {
          user: { ...profile.user, email: profile.user.profile.email },
          id: profile.partner_profile_id,
        },
      ]
    }
    return []
  }, [profile, managersLoading, data])
  return (
    <StyledModal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        <form id="expense-form" onSubmit={handleSubmit(handleUploadAgreement)}>
          <UploadButton
            addText={doc.description}
            isLoading={doc.isLoading}
            isSuccess={doc.success}
            label="Upload agreement"
            isRequired
            progress={doc.progress}
            onRemove={handleRemove()}
            onChange={(_file) => handleUpload(_file)}
            accept="application/pdf, application/msword, .png, .jpg, .jpeg, .doc,.docx"
          />
          {documentRequire && (
            <Typography className="text_regular__14 color_red mt-2">
              Document required
            </Typography>
          )}

          <div className="row mb-3 mt-3">
            <div className="col pr-2">
              <Controller
                control={control}
                name="agreement_document_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="partner-agreement-modal-403F5C"
                      isRequired
                      {...field}
                      label="Agreement type"
                      placeholder="Select agreement type"
                      options={categoryTypes}
                    />
                  )
                }}
              />
              {errors.agreement_document_type && (
                <Typography className="text_regular__14 color_red">
                  {errors.agreement_document_type.message}
                </Typography>
              )}
            </div>
          </div>
          <div className="remo-form-input mt-2">
            <Input
              data-testid="partner-agreement-modal-EFC8CC"
              {...register('title', {
                required: 'Field is required',
              })}
              isRequired
              type="text"
              label="Title"
              placeholder="Enter title"
            />
            {errors.title && (
              <Typography className="text_regular__14 color_red">
                {errors.title.message}
              </Typography>
            )}
          </div>
          <TextArea
            label="Description"
            className="mb-3"
            placeholder="Provide more details"
            {...register('subject')}
          />
          {errors.subject && (
            <Typography className="text_regular__14 color_red">
              {errors.subject.message}
            </Typography>
          )}
          {!employee && (
            <div className="w-100 remo-form-input">
              <Controller
                control={control}
                name="signers_employee"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="partner-agreement-modal-A55B69"
                      {...field}
                      isDisabled={isLoading}
                      isRequired
                      label={
                        isLoading
                          ? 'Loading...'
                          : 'Select the signatory employee'
                      }
                      placeholder="Select the signatory employee"
                      options={contracts.map((contract) => ({
                        value: contract.id,
                        label: contract.full_name,
                        email: contract.email,
                      }))}
                    />
                  )
                }}
              />
              {errors.signers_employee && (
                <Typography className="text_regular__14 color_red">
                  {errors.signers_employee.message}
                </Typography>
              )}
            </div>
          )}
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="signers_manager"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="partner-agreement-modal-FB8E08"
                    {...field}
                    isDisabled={managersLoading}
                    isRequired
                    label={
                      managersLoading
                        ? 'Loading...'
                        : 'Select the company signatory'
                    }
                    addText="If this person is not on the list, please add him as a manager to your account"
                    placeholder="Select the company signatory"
                    options={[
                      ...managers.map((team) => ({
                        value: team.id,
                        label: `${team.user.profile.first_name} ${team.user.profile.last_name}`,
                        email: team.user.email,
                      })),
                    ]}
                  />
                )
              }}
            />
            {errors.signers_manager && (
              <Typography className="text_regular__14 color_red">
                {errors.signers_manager.message}
              </Typography>
            )}
          </div>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="date_of_signature"
              rules={{ required: 'Date of signature is required' }}
              render={({ field }) => (
                <DateInput
                  {...field}
                  label="Date of signature"
                  addText="Date of signature"
                  isRequired
                />
              )}
            />
            {errors?.startDate && (
              <Typography className="text_regular__14 color_red">
                {errors.startDate.message}
              </Typography>
            )}
          </div>
          <NotificationBlock text="After uploading the agreement, you need to activate the Employee" />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="partner-agreement-modal-7D605F"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="partner-agreement-modal-814DC8"
          form="expense-form"
          type="submit"
          size="small"
          loading={loading}
          priority="primary"
        >
          Upload
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
