import CurrencyInput from '@atoms/Input/CurrencyInput'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { getCompaniesShort } from '@services/company.service'
import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export default () => {
  const { currencies } = useContext(AppContext)
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()

  const companiesQuery = useQuery(['CompaniesShort'], {
    queryFn: () => getCompaniesShort({ limit: 1000 }),
  })

  return (
    <Card>
      <Typography className="color_grey text_light__12 font-weight-bold my-3">
        Required info
      </Typography>

      <div className="remo-form-input">
        <Controller
          name="residency"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <RadioButton
              {...field}
              options={[
                {
                  id: 'local',
                  value: 'local',
                  text: 'Resident',
                },
                {
                  id: 'non_resident',
                  value: 'non_resident',
                  text: 'Non Resident',
                },
              ]}
            />
          )}
        />
      </div>

      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="salary_type"
          rules={{ required: 'Please select salary type' }}
          render={({ field }) => (
            <RadioButton
              {...field}
              isRequired
              label="Gross salary type"
              options={[
                {
                  id: 'annual',
                  value: 'annual',
                  text: 'Annual',
                },
                {
                  id: 'monthly',
                  value: 'monthly',
                  text: 'Monthly',
                },
              ]}
            />
          )}
        />

        {errors.salary_type && (
          <Typography className="text_regular__14 color_red">
            {errors.salary_type.message}
          </Typography>
        )}
      </div>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="currency"
          rules={{ required: 'Salary currency is required' }}
          render={({ field }) => (
            <Select
              data-testid="cost-calculator-required-info.component-69AED3"
              {...field}
              isRequired
              label="Salary currency"
              options={currencies?.map((currency) => ({
                value: currency.id,
                label: currency.short_code,
              }))}
            />
          )}
        />
        {errors.currency && (
          <Typography className="text_regular__14 color_red">
            {errors.currency.message}
          </Typography>
        )}
      </div>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          rules={{ required: 'Gross salary is required' }}
          name="gross_salary"
          render={({ field }) => (
            <CurrencyInput
              {...field}
              isRequired
              label="Gross salary"
              placeholder="Type a number here"
              onChange={setCurrencyChangeEvent(field.onChange)}
            />
          )}
        />
        {errors.gross_salary && (
          <Typography className="text_regular__14 color_red">
            {errors.gross_salary.message}
          </Typography>
        )}
      </div>
      {/* <div className="remo-form-input"> */}
      {/*   <Controller */}
      {/*     control={control} */}
      {/*     name="company" */}
      {/*     render={({ field }) => ( */}
      {/*       <Select  data-testid="cost-calculator-required-info.component-81EF0C" */}
      {/*         {...field} */}
      {/*         isSearchable */}
      {/*         options={ */}
      {/*           companiesQuery.isSuccess */}
      {/*             ? companiesQuery.data.results.map((user) => ({ */}
      {/*                 value: user.id, */}
      {/*                 label: user.name, */}
      {/*               })) */}
      {/*             : [] */}
      {/*         } */}
      {/*         isClearable */}
      {/*         placeholder="Choose" */}
      {/*         label="Client" */}
      {/*       /> */}
      {/*     )} */}
      {/*   /> */}
      {/*   {errors.company && ( */}
      {/*     <Typography className="text_regular__14 color_red"> */}
      {/*       {errors.company.message} */}
      {/*     </Typography> */}
      {/*   )} */}
      {/* </div> */}
      {/* <div className="remo-form-input"> */}
      {/*   <div className="d-flex align-items-center justify-content-between"> */}
      {/*     <Typography className="text_medium__14">Remofirst fee</Typography> */}
      {/*     <Toggle {...register('remofirst_fee_included')} id={2} /> */}
      {/*   </div> */}
      {/* </div> */}
      {/* <div className="remo-form-input"> */}
      {/*   <div className="d-flex align-items-center justify-content-between"> */}
      {/*     <Typography className="text_medium__14">Remofirst fee</Typography> */}
      {/*     <Toggle {...register('remofirst_fee_included')} id={2} /> */}
      {/*   </div> */}
      {/* </div> */}
      <div className="remo-form-input">
        <div className="d-flex align-items-center justify-content-between">
          <Typography className="text_medium__14">Partner fee</Typography>
          <Toggle {...register('partner_fee_included')} id={3} />
        </div>
      </div>
    </Card>
  )
}

const Card = styled.div`
  width: 420px;
  border: 16px solid #fafafa;
  border-radius: 8px;
  padding: 0 16px;
`
