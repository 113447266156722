import Pagination from '@atoms/Pagination/Pagination'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React from 'react'

import { Styled } from './employee-table.styles'
import { EmployeesTableSorting } from './employees-table-sorting.component'

export const EmployeesTable = ({
  data,
  onRow,
  pagination,
  columns,
  isFetching,
  ordering,
  onSort,
}) => {
  const { page, pageSize = 10, total, onPage } = pagination

  return (
    <div className="d-flex flex-column gap-3">
      <Styled.Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.dataIndex}
                style={{
                  width: column.width,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                }}
              >
                <EmployeesTableSorting
                  isSortable={!!column.sortIndex}
                  order={ordering}
                  onSort={(_ordering) =>
                    onSort({ ordering: _ordering, orderBy: column.sortIndex })
                  }
                >
                  <Typography className="text_medium__14">
                    {column.title}
                  </Typography>
                </EmployeesTableSorting>
              </th>
            ))}
          </tr>
        </thead>
        <Spinner component="tbody" isFetching={isFetching}>
          {data.map((dataValue) => {
            return (
              <tr
                key={dataValue.id}
                className={classNames({ 'cursor-pointer': !!onRow })}
                onClick={() => onRow(dataValue)}
              >
                {columns.map((column) => {
                  if (column.render) {
                    return (
                      <td key={column.dataIndex}>{column.render(dataValue)}</td>
                    )
                  }
                  return (
                    <td key={column.dataIndex}>
                      {dataValue[column.dataIndex]}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </Spinner>
      </Styled.Table>

      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination
          page={page}
          pageSize={pageSize}
          total={total}
          onGetPage={onPage}
        />
      )}
    </div>
  )
}
