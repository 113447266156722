import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { fetchCompanies } from '@pages/customers/CustomersPage.service'
import { downloadExpenseCsv } from '@services/expense.service'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

export default ({ onClose }) => {
  const allAddPmtDownloadChecked = useBoolean()
  const [clients, setClients] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const clear = () => {
    setEndDate(undefined)
    setStartDate(undefined)
    setClients([])
  }

  const downloadCSVRequest = useMutation({
    mutationFn: downloadExpenseCsv,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `Expense_${moment(new Date()).format('DD_MM_YYYY')}.csv`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      clear()
      onClose()
    },
  })

  const { isLoading: isLoadingCompany, data: companyData } = useQuery(
    ['companies'],
    {
      queryFn: () => fetchCompanies('active', undefined, 1, 1000),
    }
  )
  const onSubmit = () => {
    downloadCSVRequest.mutate({
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
      start_date: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : undefined,
      companies: clients.map((e) => e.value).join(','),
    })
  }

  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Expense report</Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm className="d-flex flex-column" onSubmit={onSubmit}>
          <Select
            data-testid="DownloadReport-D60EB5"
            isMulti
            onChange={setClients}
            value={clients}
            isDisabled={isLoadingCompany || allAddPmtDownloadChecked.value}
            label={isLoadingCompany ? 'Loading...' : 'Client'}
            options={companyData?.results.map((company) => ({
              value: company.id,
              label: company.name,
            }))}
          />
          <div className="row">
            <div className="col">
              <div className="remo-form-input">
                <DateInput
                  className="mb-3"
                  onChange={setStartDate}
                  label="Start date"
                  value={startDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="remo-form-input">
                <DateInput
                  className="mb-3"
                  onChange={setEndDate}
                  label="End date"
                  value={endDate}
                />
              </div>
            </div>
          </div>
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="DownloadReport-E21304"
          priority="secondary"
          size="small"
          type="button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="DownloadReport-E86EC4"
          size="small"
          onClick={onSubmit}
          disabled={downloadCSVRequest.isLoading}
          loading={downloadCSVRequest.isLoading}
        >
          Download report
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
