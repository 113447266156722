import { PaginationResponse } from '../pagination.types'
import { CompanyShort } from './company.types-v2'
import { Currency } from './currency.types-v2'

export type InvoicesResponse = PaginationResponse<Array<Invoice>>

export interface Invoice {
  id: number
  date: string | null
  paid_at: string | null
  amount: number
  type: string
  payment_method: string
  status: InvoiceStatusEnum
  currency: Currency
  status_verbose: string
  file: string
  filename: string
  payroll: number | null
  contractor_payment: number | null
  transaction_id: string
  company: InvoiceCompany
  amount_in_receiving_currency: number
  receiving_currency: ReceivingCurrency
}

export enum InvoiceStatusEnum {
  generating = 'generating',
  in_progress = 'in_progress',
  complete = 'complete',
  created = 'created',
  confirming = 'confirming',
  paid = 'paid',
  information_requested = 'information_requested',
  failed = 'failed',
  cancelled = 'cancelled',
  refunded = 'refunded',
  payment_processing = 'payment_processing',
}

export type InvoiceCompany = Pick<CompanyShort, 'id' | 'name' | 'title'> & {
  is_allowed_ach_payment: string
  enabled_automatic_pay: boolean
}

export interface ReceivingCurrency {
  id: number
  name: string
  short_code: string
  sign: string
  allow_for_company: boolean
  allow_for_contractor: boolean
  priority: number
}

export type InvoicesParams = Partial<{
  company_id: number
  contract_id: number
  is_active: boolean
  ordering: string
  status: InvoiceStatusEnum
  status_in: string
}>

export interface InvoicesPayInResponse {
  result: InvoicesPayIn[]
}

export interface NiumReportHistory {
  invoice_class: string
  invoice_id: string
  event_type: string
  payment_process: string
  created_at: string
  fetched_by: {
    full_name: string
  }
  data: { [key: string]: string | { [key: string]: string } }
}

export interface InvoicesPayIn {
  rfiId: string
  requesterComment: string
  responderComment: string
  rfiStatus: RfiStatus
  documentTypeId: string
  documentType: DocumentType
}
export interface InvoicesPayout {
  payment_id: string
  rfi_id: string
  beneficiary: Remitter[]
  remitter: Remitter[]
}
export interface Remitter {
  rfi_entity_id: string
  status: RfiPayoutStatus
  rfi_entity: RfiEntity
  comment: string
  rfi_type: RfiType
  rfi_requested_datetime: string
}
export enum RfiPayoutStatus {
  RECEIVED = 'received',
  PENDING = 'pending',
}
export enum RfiStatus {
  RAISED = 'RAISED',
  RESPONDED = 'RESPONDED',
}
export enum DocumentType {
  INVOICE_COPY = 'INVOICE_COPY',
  PURCHASE_ORDER_COPY = 'PURCHASE_ORDER_COPY',
  ADVANCED_SHIPMENT_NOTICE = 'ADVANCED_SHIPMENT_NOTICE',
}
export enum RfiEntity {
  FULL_NAME = 'FULL_NAME',
  OFFICIAL_ID = 'OFFICIAL_ID',
  ADDRESS = 'ADDRESS',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  NATIONALITY = 'NATIONALITY',
  PURPOSE = 'PURPOSE',
  PROVIDE_SOURCE_OF_FUNDS = 'PROVIDE_SOURCE_OF_FUNDS',
  PURPOSE_OF_TRANSACTIONS = 'PURPOSE_OF_TRANSACTIONS',
  COMMENT = 'COMMENT',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  BANK_STATEMENT = 'BANK_STATEMENT',
  UTILITY_BILLS = 'UTILITY_BILLS',
  NRIC = 'NRIC',
  FIN = 'FIN',
  GOVERNMENT_LETTER = 'GOVERNMENT_LETTER',
  GOVERNMENT_DOCUMENT = 'GOVERNMENT_DOCUMENT',
  HKID = 'HKID',
  INVOICE = 'INVOICE',
  SHIPPING_INVOICE = 'SHIPPING_INVOICE',
}

export const RfiEntityValue = {
  [RfiEntity.FULL_NAME]: 'Full name',
  [RfiEntity.OFFICIAL_ID]: 'Official Id',
  [RfiEntity.ADDRESS]: 'Address',
  [RfiEntity.DATE_OF_BIRTH]: 'Date of birth',
  [RfiEntity.NATIONALITY]: 'Nationality',
  [RfiEntity.PURPOSE]: 'Purpose',
  [RfiEntity.PROVIDE_SOURCE_OF_FUNDS]: 'Provide source of funds',
  [RfiEntity.PURPOSE_OF_TRANSACTIONS]: 'Purpose of transactions',
  [RfiEntity.COMMENT]: 'Comment',
  [RfiEntity.PASSPORT]: 'Passport',
  [RfiEntity.DRIVING_LICENSE]: 'Driving license',
  [RfiEntity.BANK_STATEMENT]: 'Bank statement',
  [RfiEntity.UTILITY_BILLS]: 'Utility bills',
  [RfiEntity.NRIC]: 'Nric',
  [RfiEntity.FIN]: 'Fin',
  [RfiEntity.GOVERNMENT_LETTER]: 'Government letter',
  [RfiEntity.GOVERNMENT_DOCUMENT]: 'Government document',
  [RfiEntity.HKID]: 'Hkid',
  [RfiEntity.INVOICE]: 'Invoice',
  [RfiEntity.SHIPPING_INVOICE]: 'Shipping invoice',
}
export enum RfiType {
  FILE = 'FILE',
  TEXT = 'TEXT',
  DATE = 'DATE',
}

export interface PayinSubmit {
  invoice: number
  comments: string
  file: File
}
export interface PayoutSubmit {
  invoice: number
  rfi_id: string
  rfi_entity_id: string
  payment_id: string
  comment: string
  value: string
  document?: File
}

export interface ReportNiumParams {
  invoice_class: 'aggregated' | 'solo'
  invoice_id: number
}
