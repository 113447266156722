import { DotWave } from '@uiball/loaders'
import React from 'react'
import styled from 'styled-components'

const Absolute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ffffffd4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Spinner = ({ children, spinner, isFetching, component }) => {
  const Element = component || 'div'

  if (!isFetching) {
    return <Element>{children}</Element>
  }

  return (
    <Element style={{ position: 'relative', overflow: 'auto', width: '100%' }}>
      {children}
      <Absolute>
        {spinner || <DotWave size={48} speed={1} color="black" />}
      </Absolute>
    </Element>
  )
}
