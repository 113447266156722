import { Button as MuiButton, styled } from '@mui/material'

const Tabs = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 4,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}))

const Button = styled(MuiButton)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    borderBottom: isActive ? '1px solid #fff' : '',
    color: isActive ? theme.palette.primary.main : '',
    zIndex: 10,
    bottom: -1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  })
)

export const Styled = { Button, Tabs }
