import { numberWithSpaces } from '@core/utils'
import moment from 'moment'

export const parseInvoice = (invoices) =>
  invoices.map((invoice) => ({
    ...invoice,
    type: invoice.invoice_type,
    customer: invoice.company.name,
    amount:
      invoice.status === 'generating'
        ? 'calculating'
        : `${
            invoice.currency?.sign || invoice.currency?.short_code
          } ${numberWithSpaces(invoice.total)}`,
    date: moment(invoice.date).format('DD MMM YYYY'),
  }))
