import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

type Props = {
  visible: boolean
}

const StepContainer = ({ visible, children }: Props & PropsWithChildren) => (
  <Box sx={{ display: visible ? 'block' : 'none' }}>{children}</Box>
)

export default StepContainer
