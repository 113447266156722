import { v4 } from 'uuid'

type Options = {
  key: string
}

export const generateService = <T>(fetch: T, options?: Options) => ({
  key: options?.key || v4(),
  fetch,
})
