const purple = {
  100: '#2A135C',
  400: '#6F39E5',
  500: '#8754F6',
  600: '#AF8DF9',
  700: '#C3A9FB',
  800: '#D7C6FC',
  900: '#F3EDFF',
}

export default purple
