import { Api } from '@api/Api'

// TODO [refactor as params]
export const fetchContractors = async (
  contractorsState,
  page,
  search,
  ordering,
  limit = 10,
  stateIn = []
) => {
  const { data } = await Api.get(
    `contracts/?limit=${limit}&offset=${(page - 1) * limit}${contractorsState}${
      search ? `&search=${search}` : ''
    }`,
    { params: { state_in: stateIn, ordering } }
  )

  return data
}
export const fetchContractorsByCompany = async (
  id,
  search,
  page,
  contractorsState,
  limit = 10
) => {
  const { data } = await Api.get(
    `contracts/?limit=${limit}&offset=${(page - 1) * limit}&company_id=${id}${
      contractorsState ?? ''
    }${search ? `&search=${search}` : ''}`
  )

  return data
}

export const fetchCreateContractor = async (body) => {
  const { data } = await Api.post(`contracts/`, body)
  return data
}

export const fetchCompanies = async (state) => {
  const v = await Api.get(
    `companies/?limit=1000${state ? `&state_in=${state.join(',')}` : ''}`
  )
  return v.data
}
export const fetchCompanyById = async (id) => {
  const v = await Api.get(`companies/${id}/`)
  return v.data
}

export const fetchCountriesIdBurden = async ({
  working_country,
  yearly_salary,
  currency,
}) => {
  const { data } = await Api.post(
    `countries/${working_country}/calculate-burden/`,
    { yearly_salary, currency }
  )
  return data
}
