import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { getCostCalculatorList } from '@services/cost-calculation.service'
import { fetchGetProvinces } from '@services/countries.service'
import React, { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const EmployerTaxFieldCode = {
  age: 'age',
  citizenship: 'citizenship',
  contract_type: 'contract_type',
  gender: 'gender',
  marital_status: 'marital_status',
  regions: 'regions',
  fixed_contract_duration: 'fixed_contract_duration',
}

const getAdditionalFieldList = (data) => {
  const result = []
  data.forEach((item) => {
    item.needed_tax_fields.forEach((item2) => {
      result.push(item2.employer_tax_field.code)
    })
    if (item.regions?.length) result.push('regions')
  })

  return [...new Set(result)]
}

export default () => {
  const { profile, userProfile } = useContext(AppContext)
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext()

  const countryId = watch('country')
  const contractType = watch('contract_type')

  const taxTypesListQuery = useQuery([getCostCalculatorList.key, countryId], {
    queryFn: () =>
      getCostCalculatorList.fetch({
        limit: 1000,
        offset: 0,
        country_id: countryId,
        ignore_region: true,
        partner: {
          id: profile.partner_profile_id,
          name: profile.name,
          user_id: userProfile.id,
        },
      }),
    enabled: !!countryId,
  })

  const provincesQuery = useQuery(['getProvinces', countryId], {
    queryFn: () => fetchGetProvinces(countryId),
    enabled: !!countryId,
  })

  if (!taxTypesListQuery.data) return null
  const fields = getAdditionalFieldList(taxTypesListQuery.data?.results)
  if (!fields.length) return null

  const renderField = (code) => {
    switch (code) {
      case EmployerTaxFieldCode.age:
        return (
          <div className="remo-form-input">
            <Input
              data-testid="cost-calculator-additional-info.component-5B4DF0"
              {...register('age', { required: true })}
              label="Age"
              isRequired
              placeholder="Type a number here"
            />
          </div>
        )
      case EmployerTaxFieldCode.regions:
        return (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="regions"
              render={({ field }) => (
                <Select
                  data-testid="cost-calculator-additional-info.component-E44A4E"
                  {...field}
                  label="Province/State"
                  isClearable
                  options={
                    provincesQuery.data?.results?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) || []
                  }
                />
              )}
            />
          </div>
        )
      // case EmployerTaxFieldCode.citizenship:
      //   return (
      //     <div className="w-100 remo-form-input">
      //       <Controller
      //         control={control}
      //         name="citizenship"
      //         rules={{ required: 'This field is required' }}
      //         render={({ field }) => (
      //           <RadioButton
      //             {...field}
      //             label="Citizenship"
      //             isRequired
      //             options={[
      //               {
      //                 id: 'local',
      //                 value: 'local',
      //                 text: 'Resident',
      //               },
      //               {
      //                 id: 'non_resident',
      //                 value: 'non_resident',
      //                 text: 'Non-resident',
      //               },
      //             ]}
      //           />
      //         )}
      //       />
      //     </div>
      //   )
      case EmployerTaxFieldCode.contract_type:
        return (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contract_type"
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Contract type"
                  isRequired
                  options={[
                    {
                      id: 'indefinite',
                      value: 'indefinite',
                      text: 'Indefinite',
                    },
                    {
                      id: 'fixed',
                      value: 'fixed',
                      text: 'Fixed',
                    },
                  ]}
                />
              )}
            />
          </div>
        )
      case EmployerTaxFieldCode.fixed_contract_duration:
        if (contractType === 'fixed')
          return (
            <div className="d-flex flex-column my-3">
              <Typography className="text_medium__14 remo-form-label mb-1">
                Contract duration
              </Typography>
              <div className="d-flex gap-3">
                <Controller
                  name="fixed_contract_duration"
                  control={control}
                  rules={{ required: 'Please fill the duration' }}
                  render={({ field }) => (
                    <Input
                      data-testid="cost-calculator-additional-info.component-3BA388"
                      {...field}
                      isRequired
                      status={errors.fixed_contract_duration && 'error'}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="Type a number here"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="fixed_contract_duration_type"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      data-testid="cost-calculator-additional-info.component-3270AC"
                      {...field}
                      isRequired
                      placeholder="Choose"
                      style={{ width: 120 }}
                      options={[
                        { label: 'Year', value: 'year' },
                        { label: 'Month', value: 'month' },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          )
        return ''
      case EmployerTaxFieldCode.gender:
        return (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="gender"
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Gender"
                  isRequired
                  options={[
                    {
                      id: 'female',
                      value: 'female',
                      text: 'Female',
                    },
                    {
                      id: 'male',
                      value: 'male',
                      text: 'Male',
                    },
                    {
                      id: 'non_binary',
                      value: 'non_binary',
                      text: 'Non-binary',
                    },
                  ]}
                />
              )}
            />
          </div>
        )
      case EmployerTaxFieldCode.marital_status:
        return (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="marital_status"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  data-testid="cost-calculator-additional-info.component-A4837A"
                  {...field}
                  isRequired
                  label="Marital status"
                  options={[
                    { value: 'single', label: 'Single' },
                    { value: 'married', label: 'Married' },
                  ]}
                />
              )}
            />
          </div>
        )
      default:
        return ''
    }
  }
  return (
    <Card>
      <Typography className="color_grey text_light__12 font-weight-bold my-3">
        Additional info
      </Typography>
      {fields.map((item) => renderField(item))}
    </Card>
  )
}

const Card = styled.div`
  width: 420px;
  border: 16px solid #fafafa;
  border-radius: 8px;
  padding: 0 16px;
`
