import styled from 'styled-components'

const Root = styled.div`
  border-radius: 4px;
  border: 1px dashed
    ${(props) => (props.error ? 'red' : 'rgba(31, 31, 31, 0.12)')};
  background: #f5f5f5;
  padding: 16px;
`

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
`

const Title = styled.div`
  color: #121212;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;
`
const Description = styled.div`
  color: #8f8f8f;
  font-size: 12px;
  line-height: 16px;
`

const Error = styled.div`
  color: #d6331f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 2px;
`

export const Styled = {
  Root,
  Container,
  Content,
  Title,
  Description,
  Error,
}
