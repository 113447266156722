import Check from '@mui/icons-material/Check'
import { StepIconProps } from '@mui/material/StepIcon'
import { styled } from '@mui/material/styles'

const StepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean }
}>(({ theme, ownerState }) => ({
  color: theme.palette.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.secondary[400]}`,
  height: '20px',
  width: '20px',
  ...(ownerState.active && {
    borderColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  }),
  '& .StepIcon-completedIcon': {
    zIndex: 1,
    fontSize: 18,
  },
  '& .StepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? <Check className="StepIcon-completedIcon" /> : null}
    </StepIconRoot>
  )
}

export default StepIcon
