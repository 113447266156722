const blue = {
  100: '#172E65',
  300: '#1890FF',
  400: '#376BE5',
  500: '#497CF6',
  600: '#86A8F9',
  700: '#A4BDFB',
  800: '#C2D3FC',
  900: '#ECF1FE',
}

export default blue
