import { Box } from '@mui/material'
import { ReactNode } from 'react'

import { Styled } from './drawer.styles'

type Props = {
  primaryActions?: ReactNode[]
  secondaryActions: ReactNode[]
}

export const DrawerFooter = ({ primaryActions, secondaryActions }: Props) => (
  <Styled.Footer>
    <Box display="flex" gap={1.5}>
      {secondaryActions.map((action) => action)}
    </Box>
    {primaryActions && (
      <Box display="flex" gap={1.5}>
        {primaryActions.map((action) => action)}
      </Box>
    )}
  </Styled.Footer>
)
