import { useApp } from '@core/context'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { BillingInfoDTO } from '@remoteam-front/types/legacy'
import { theme } from '@remoteam-front/ui'
import { keyBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  onNext(): void
}

export const BankAccount = ({ onNext }: Props) => {
  const { currencies, countries } = useApp()

  const [currenciesHashMap, setCurrenciesHashMap] = useState(
    keyBy(currencies, 'id')
  )

  useEffect(() => {
    setCurrenciesHashMap(keyBy(currencies, 'id'))
  }, [currencies])

  const { handleSubmit, control } = useFormContext<BillingInfoDTO>()

  return (
    <form onSubmit={handleSubmit(onNext)}>
      <Box display="flex" flexDirection="column" gap={3} width={640}>
        {currencies?.length && (
          <Controller
            control={control}
            name="bank_account.currency"
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel required>Currency</InputLabel>
                <Select
                  data-testid="bank-account.component-53EC80"
                  {...field}
                  required
                  displayEmpty
                  renderValue={(value) =>
                    value ? (
                      currenciesHashMap[value].short_code
                    ) : (
                      <span style={{ color: theme.palette.secondary[400] }}>
                        Select currency
                      </span>
                    )
                  }
                >
                  {(currencies as any[]).map(({ id, short_code }) => (
                    <MenuItem key={id} value={id}>
                      {short_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        )}

        <Controller
          control={control}
          name="bank_account.account_holder"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-0C14A6"
              {...field}
              required
              fullWidth
              label="Account holder name"
              placeholder="Enter account holder name"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.swift_bic"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-D9622F"
              {...field}
              required
              fullWidth
              label="Swift ID"
              placeholder="Enter Swift ID"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.iban"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-733230"
              {...field}
              required
              fullWidth
              label="IBAN"
              placeholder="Enter IBAN"
            />
          )}
        />

        {countries?.length && (
          <Controller
            control={control}
            name="bank_account.address.living_country"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                select
                label="Country"
                {...field}
                data-testid="bank-account.component-733231"
              >
                {countries.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        <Controller
          control={control}
          name="bank_account.address.street_address"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-98CCC3"
              {...field}
              required
              fullWidth
              label="Bank address line 1"
              placeholder="Enter address"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.address.address_line"
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-DD892C"
              {...field}
              fullWidth
              label="Bank address line 2"
              placeholder="Enter address complement"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.address.city"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-800995"
              {...field}
              required
              fullWidth
              label="City"
              placeholder="Enter city"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.address.state"
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-43C565"
              {...field}
              fullWidth
              label="State"
              placeholder="Enter state"
            />
          )}
        />

        <Controller
          control={control}
          name="bank_account.address.postal_code"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="bank-account.component-03D800"
              {...field}
              required
              fullWidth
              label="Postal code"
              placeholder="Enter postal code"
            />
          )}
        />

        <Box mt={2} alignSelf="center">
          <Button
            data-testid="bank-account.component-967BA1"
            type="submit"
            variant="contained"
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </form>
  )
}
