import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Typography from '@atoms/Typography/Typography'
import { USER_ROLES_MAP } from '@core/constants'
import { formatDateHours, getDate } from '@core/utils'
import { getContractNotes } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { MdAdd } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { NoteAddModal } from './note-add-modal/note-add-modal.component'
import { Styled } from './notes.styles'

export const Notes = ({ contractId }) => {
  const noteAddModalOpen = useBoolean(false)

  const { data, isFetching } = useQuery(['notes', contractId], () =>
    getContractNotes(contractId)
  )

  return (
    <>
      <Styled.TopBar>
        <PageTitle>Notes</PageTitle>
        <Button
          data-testid="notes.component-E934EE"
          priority="secondary"
          size="small"
          onClick={noteAddModalOpen.setTrue}
        >
          <MdAdd />
          <Typography className="ml-2">Add note</Typography>
        </Button>
      </Styled.TopBar>
      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {data.length > 0 ? (
            <Styled.Comments>
              {data.map(({ created_at, created_by, text }) => (
                <Styled.Comment>
                  <Styled.Header>
                    <Typography className="text_medium__14 mb-1">
                      {created_by.full_name} ({USER_ROLES_MAP[created_by.role]})
                    </Typography>
                    <Typography className="text_light__12 mb-2">
                      {formatDateHours(created_at)}
                    </Typography>
                  </Styled.Header>
                  <Styled.Body>
                    <Typography className="text_regular__14">{text}</Typography>
                  </Styled.Body>
                </Styled.Comment>
              ))}
            </Styled.Comments>
          ) : (
            <NotFound title="There are no notes yet" />
          )}
        </div>
      )}
      {noteAddModalOpen.value && (
        <NoteAddModal
          contractId={contractId}
          onClose={noteAddModalOpen.setFalse}
        />
      )}
    </>
  )
}
