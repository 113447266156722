import BackButton from '@atoms/BackButton'
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import InvoiceAccordion from '@pages/payrollsDetailEditPage/InvoiceAccordion'
import {
  fetchCreateAdditionPayment,
  fetchDeleteAdditionPayment,
  fetchUpdateAdditionPayment,
} from '@services/payments.service'
import { getPayrollById, updatePayrollById } from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import MyAccordion from './Accordion'
import CreateModal from './CreateModal'

export default function PayrollDetailEditPage() {
  const router = useRouteMatch()
  const { value, toggle } = useBoolean(false)
  const { value: modalValue, toggle: modalToggle } = useBoolean(false)
  const { value: modalPositiveValue, toggle: modalPositiveToggle } =
    useBoolean(false)
  const [paymentIdForDelete, setPaymentIdForDelete] = useState(null)
  const { data, isLoading, refetch } = useQuery(
    ['payrolls', router.params.payrollId],
    {
      queryFn: () => getPayrollById(router.params.payrollId),
    }
  )

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdateAdditionPayment({ id, body }),
    onSuccess: () => {
      refetch()
    },
  })
  const updatePayrollMutate = useMutation({
    mutationFn: ({ id, body }) => updatePayrollById(id, body),
    onSuccess: () => {
      refetch()
    },
  })
  const deleteMutate = useMutation({
    mutationFn: (id) => fetchDeleteAdditionPayment(id),
    onSuccess: () => {
      refetch()
      setPaymentIdForDelete(null)
    },
  })
  const createMurate = useMutation({
    mutationFn: (body) => fetchCreateAdditionPayment(body),
    onSuccess: (res) => {
      refetch()
      if (res.state === 'MOVED_TO_NEXT_MONTH') modalToggle()
      if (res.state === 'APPROVED_BY_COMPANY') modalPositiveToggle()
      toggle()
    },
  })

  const handleSave = useCallback(
    ({ id, body }) => {
      updateMutate.mutate({
        id,
        body: { ...body, amount: Number(body.amount) },
      })
    },
    [updateMutate]
  )

  const handleSaveInvoice = useCallback(
    ({ id, body }) => {
      updatePayrollMutate.mutate({
        id,
        body: { ...body, original_partner_fee: data.original_partner_fee },
      })
    },
    [updateMutate]
  )
  const handleDelete = useCallback(
    (id) => {
      deleteMutate.mutate(id)
    },
    [deleteMutate]
  )

  // TODO TAX
  const handleCreate = useCallback(
    (body) => {
      createMurate.mutate({
        ...body,
        amount: Number(body.amount),
        payroll: Number(router.params.payrollId),
      })
    },
    [createMurate, router.params]
  )

  if (isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }
  return (
    <div className="employee-detail-page h-100">
      {isLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex align-items-center">
              <BackButton />
              <Typography className="heading_semibold__24 ml-3">
                Edit Invoice
              </Typography>
            </div>
            <Button
              data-testid="PayrollDetailEditPage-9AF47E"
              priority="secondary"
              size="small"
              onClick={() => toggle()}
            >
              Additional pay
            </Button>
          </div>
          <div>
            <Spinner isFetching={isLoading}>
              <InvoiceAccordion
                isLoading={updatePayrollMutate.isLoading}
                payments={data}
                onSave={handleSaveInvoice}
              />
            </Spinner>
            {data.additional_payments.length > 0 && (
              <Typography className="heading_semibold__18 my-4">
                Additional payments{' '}
              </Typography>
            )}
            <Spinner isFetching={isLoading}>
              <MyAccordion
                isLoading={updateMutate.isLoading}
                payrolls={data}
                onDelete={(id) => setPaymentIdForDelete(id)}
                onSave={handleSave}
              />
            </Spinner>

            {value && (
              <CreateModal
                isLoading={createMurate.isLoading}
                onHide={toggle}
                onCreate={handleCreate}
                isSuccess={createMurate.isSuccess}
              />
            )}

            {!!paymentIdForDelete && (
              <Confirm
                title="Delete Payment"
                isLoading={deleteMutate.isLoading}
                onCancel={() => setPaymentIdForDelete(null)}
                onYes={() => handleDelete(paymentIdForDelete)}
              >
                <Typography>
                  Are you sure you want to remove this payment from company
                  invoice?
                </Typography>
              </Confirm>
            )}
            {modalValue && (
              <Confirm
                title=""
                cancelButtonProps={{
                  text: 'Close',
                }}
                onCancel={modalToggle}
              >
                <Typography>
                  This payment will be automatically added to the next payroll
                  cycle as the cut-off date, day (5) of this month, has passed.
                </Typography>
              </Confirm>
            )}
            {modalPositiveValue && (
              <Confirm
                title=""
                cancelButtonProps={{
                  text: 'Close',
                }}
                onCancel={modalPositiveToggle}
              >
                <Typography>
                  This additional payment will be added to the current months
                  payroll.
                </Typography>
              </Confirm>
            )}
          </div>
        </>
      )}
    </div>
  )
}
