import { styled } from '@mui/material'

const Root = styled('div')(() => ({
  position: 'relative',
}))

const SpinContainer = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 4,
  display: 'block',
  width: '100%',
  height: '100%',
}))

const SpinWrapper = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  insetInlineStart: '50%',
  margin: '-10px',
}))

const ChildrenWrapper = styled('div')(() => ({
  clear: 'both',
  opacity: 0.5,
  userSelect: 'none',
  pointerEvents: 'none',
}))

export const Styled = {
  Root,
  SpinContainer,
  SpinWrapper,
  ChildrenWrapper,
}
