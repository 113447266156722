import { Box } from '@mui/material'
import { TabPanel } from '@remoteam-front/ui'
import { useState } from 'react'

import { BankAccountSubTab } from './bank-account/bank-account.component'
import { BillingAddressSubTub } from './billing-address/billing-address.component'
import { Styled } from './billing-details.styles'

export const BillingDetails = () => {
  const [value, setValue] = useState(1)

  const handleChange = (value: number) => () => setValue(value)

  return (
    <>
      <Styled.Tabs>
        <Styled.Button
          isActive={value === 1}
          variant="contained"
          color="secondary"
          onClick={handleChange(1)}
        >
          Bank account
        </Styled.Button>
        <Styled.Button
          isActive={value === 2}
          variant="contained"
          color="secondary"
          onClick={handleChange(2)}
        >
          Billing address
        </Styled.Button>
      </Styled.Tabs>

      <Box mt={4}>
        <TabPanel value={value} index={1}>
          <BankAccountSubTab />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <BillingAddressSubTub />
        </TabPanel>
      </Box>
    </>
  )
}
