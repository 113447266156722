import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getTimeOffBalances = generateService(async (params) => {
  const { data } = await Api.get(`contracts/time-off/balances/`, {
    params,
  })

  return data
})

export const getTimeOffBalanceBreakdown = generateService(
  async (id, params) => {
    const { data } = await Api.get(`contracts/${id}/time-off/balances/`, {
      params,
    })

    return data
  }
)

export const createAdHoc = generateService(async (id, body) => {
  const fd = new FormData()
  console.log(body.attached_document)
  fd.append('contract_id', body.contract_id)
  fd.append('number_of_days', body.number_of_days)
  fd.append('note', body.note)
  fd.append('attached_document', body.attached_document)

  const { data } = await Api.post(`contracts/${id}/ad-hoc/create/`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
})
