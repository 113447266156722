import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import SelectTable from '@components/SelectTable/select-table.components'
import { keyboardArrowLeft } from '@core/icons/icons'
import { fetchCompanyById } from '@pages/employees/EmployeesPage.service'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

export default function FlagPayrollDetailPage() {
  const router = useRouteMatch()

  const [isClickedBack, setIsClickedBack] = useState(false)

  const { data } = useQuery(['companyQuery', router.params.id], {
    enabled: !!router.params.id,
    keepPreviousData: true,
    queryFn: () => fetchCompanyById(router.params.id),
  })

  const handleBack = () => {
    setIsClickedBack(true)
  }
  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              border: '1px solid #CCCCCC',
              boxSizing: 'border-box',
              borderRadius: '8px',
              height: 40,
              width: 40,
            }}
            role="button"
            aria-hidden
            onClick={handleBack}
          >
            <Icon
              icon={keyboardArrowLeft}
              fill="black"
              style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
            />
          </div>
          <Typography className="heading_semibold__24 ml-3">
            {data?.name}
          </Typography>
        </div>
      </div>
      <SelectTable isClickedBack={isClickedBack} />
    </div>
  )
}
