import React from 'react'

import { Styled } from './data-card.styles'

export const DataCard = ({ title, value, detail }) => (
  <Styled.Root>
    <Styled.Label>{title}</Styled.Label>
    <Styled.Value>{value}</Styled.Value>
    <Styled.Detail>{detail}</Styled.Detail>
  </Styled.Root>
)
