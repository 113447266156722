import { styled, TableBody as MUITableBody } from '@mui/material'

const TableBody = styled('div')(({ theme }) => ({
  height: '312px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '544px',
  gap: theme.spacing(3),
}))

export const Styled = { TableBody }
