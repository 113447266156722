import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import { Tooltip } from '@atoms/Tooltip'
import { keyboardArrowLeft } from '@core/icons/icons'
import { SplitNumber } from '@core/utils'
import { isNumber } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { parseCostCalculator } from './const-calculator-table.utils'
import { CostCalculatorExpanded } from './cost-calculator-expanded.component'

export default ({ data }) => {
  const [taxes, setTaxes] = useState(null)
  const [taxToShowDescription, setTaxToShowDescription] = useState(null)

  useEffect(() => {
    if (data) {
      setTaxes(
        parseCostCalculator(data).reduce(
          (acc, obj) => ({
            ...acc,
            [obj.id]: { ...obj },
          }),
          {}
        )
      )
    }
  }, [data])

  const handleClick = (id, isExpanded) => () => {
    setTaxes((prev) => ({
      ...prev,
      [id]: { ...prev[id], isExpanded: !isExpanded },
    }))
  }

  return (
    <>
      <Card>
        <Table>
          <thead>
            <tr>
              <td className="actions"></td>
              <th className="breakdown">
                Cost breakdown
                {data && (
                  <Tooltip
                    id={v4()}
                    content={
                      <div>
                        Number of monthly gross salaries -{' '}
                        {data?.country?.yearly_salary_divider}
                        <br />
                        Number of months for Employer and other costs -{' '}
                        {data?.country?.yearly_salary_divider_for_employer_cost}
                      </div>
                    }
                    style={{ width: 'max-content' }}
                  />
                )}
              </th>
              <th>
                Monthly{' '}
                {data?.calculations.in_general_currency.currency &&
                  ` | ${data?.calculations.in_general_currency.currency.short_code}`}
              </th>
              <th>
                Annual{' '}
                {data?.calculations.in_general_currency.currency &&
                  ` | ${data?.calculations.in_general_currency.currency.short_code}`}
              </th>
            </tr>
          </thead>
          <tbody>
            {taxes &&
              Object.values(taxes).map(
                ({
                  id,
                  breakdown,
                  monthly,
                  summary,
                  annual,
                  expandData,
                  isExpanded,
                }) => {
                  return (
                    <>
                      <tr key={id}>
                        <td className="actions">
                          {expandData && (
                            <Icon
                              fill="#B2B2B2"
                              style={{
                                cursor: 'pointer',
                                transform: isExpanded
                                  ? 'rotate(90deg)'
                                  : 'none',
                              }}
                              icon={keyboardArrowLeft}
                              onClick={handleClick(id, isExpanded)}
                            />
                          )}
                        </td>
                        <td>
                          {breakdown}
                          {summary && (
                            <Tooltip
                              id={v4()}
                              content={summary}
                              style={{ width: 'max-content' }}
                            />
                          )}
                        </td>
                        <td>
                          {isNumber(monthly) ? SplitNumber(monthly) : monthly}
                        </td>
                        <td>
                          {isNumber(annual) ? SplitNumber(annual) : annual}
                        </td>
                      </tr>
                      {expandData &&
                        isExpanded &&
                        expandData.map((employerCost) => {
                          return (
                            <CostCalculatorExpanded
                              key={v4()}
                              currencyName={
                                data?.calculations.in_general_currency.currency
                                  .short_code || ''
                              }
                              employerCost={employerCost}
                              onExpand={setTaxToShowDescription}
                            />
                          )
                        })}
                    </>
                  )
                }
              )}
          </tbody>
        </Table>
        {!taxes && (
          <p style={{ color: '#00000073', textAlign: 'center', margin: 24 }}>
            No Data
          </p>
        )}
      </Card>

      {!!taxToShowDescription && (
        <ModalSide
          title={taxToShowDescription.name}
          onClose={() => setTaxToShowDescription(null)}
          onOk={() => setTaxToShowDescription(null)}
        >
          <Modal.Body>{taxToShowDescription.long_description}</Modal.Body>
        </ModalSide>
      )}
    </>
  )
}

const Table = styled('table')`
  .actions {
    width: 20px;
  }
  .breakdown {
    width: 300px;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    width: 150px;
  }
`

const Card = styled.div`
  border: 16px solid #fafafa;
  border-radius: 8px;
  padding: 0 16px;
  height: fit-content;

  table {
    tbody {
      tr:last-child {
        td {
          font-weight: bold;
        }
      }
    }
  }
`
