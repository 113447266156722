/* eslint-disable react/jsx-no-constructed-context-values */
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'

import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { IconContext } from 'react-icons'
import { QueryClient, QueryClientProvider } from 'react-query'

import { App } from './App'
import { AuthProvider } from './AuthProvider'
import theme from './remoteam-front/ui/theme'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, cacheTime: 0, retry: false },
  },
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        loginUrl={`${process.env.REACT_APP_BFF_API}/auth/login`}
        logoutUrl={`${process.env.REACT_APP_BFF_API}/auth/logout`}
      >
        <IconContext.Provider value={{ size: '20' }}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </IconContext.Provider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

console.log(new Date())
