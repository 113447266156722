import { useApp } from '@core/context'
import { Box, MenuItem, Select, TextField } from '@mui/material'
import { BillingInfoDTO } from '@remoteam-front/types/legacy'
import { AsyncButton } from '@remoteam-front/ui'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  isLoading: boolean
  onComplete(body: BillingInfoDTO): void
}

export const BillingAddress = ({ isLoading, onComplete }: Props) => {
  const { countries } = useApp() as any

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useFormContext<BillingInfoDTO>()

  return (
    <form onSubmit={handleSubmit(onComplete)}>
      <Box display="flex" flexDirection="column" gap={3} width={640}>
        {countries?.length && (
          <Controller
            control={control}
            name="billing_address.living_country"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                select
                {...field}
                disabled
                data-testid="billing-address.component-9F545E"
                required
                label="Country"
                error={!!errors.bank_account?.address?.living_country}
              >
                {(countries as any[]).map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        <Controller
          control={control}
          name="billing_address.street_address"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="billing-address.component-BE6568"
              {...field}
              fullWidth
              placeholder="Enter address"
              label="Address line 1"
              required
              error={!!errors.billing_address?.address_line}
            />
          )}
        />

        <Controller
          control={control}
          name="billing_address.address_line"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="billing-address.component-4899B2"
              {...field}
              fullWidth
              placeholder="Enter address complement"
              label="Address line 2"
              required
              error={!!errors.billing_address?.street_address}
            />
          )}
        />

        <Controller
          control={control}
          name="billing_address.city"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="billing-address.component-9F545E"
              {...field}
              fullWidth
              placeholder="Enter city"
              label="City"
              required
              error={!!errors.billing_address?.city}
            />
          )}
        />

        <Controller
          control={control}
          name="billing_address.state"
          render={({ field }) => (
            <TextField
              data-testid="billing-address.component-9F545E"
              {...field}
              fullWidth
              placeholder="Enter state"
              label="State"
              error={!!errors.billing_address?.state}
            />
          )}
        />

        <Controller
          control={control}
          name="billing_address.postal_code"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              data-testid="billing-address.component-9F545E"
              {...field}
              fullWidth
              placeholder="Enter postal code"
              label="Postal code"
              required
              error={!!errors.billing_address?.postal_code}
            />
          )}
        />

        <Box mt={2} alignSelf="center">
          <AsyncButton
            data-testid="billing-address.component-5CDDE9"
            isFetching={isLoading}
            type="submit"
            variant="contained"
          >
            Complete
          </AsyncButton>
        </Box>
      </Box>
    </form>
  )
}
