export const roles = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Company Manager`,
  partner_owner: `Super Admin`,
  partner_manager: `Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}

export const ROLE_TYPES = {
  COMPANY_OWNER: 'company_owner',
  COMPANY_ADMIN: 'company_admin',
  PARTNER_OWNER: 'partner_owner',
  PARTNER_MANAGER: 'partner_manager',
  ADMIN: 'admin',
  DUMMY: 'dummy',
  EMPLOYEE: 'employee',
}
export const getRoleMember = (role) => {
  switch (role) {
    case ROLE_TYPES.COMPANY_OWNER:
      return '(client owner)'
    case ROLE_TYPES.COMPANY_ADMIN:
      return '(client manager)'
    case ROLE_TYPES.PARTNER_OWNER:
      return '(partner owner)'
    case ROLE_TYPES.PARTNER_MANAGER:
      return '(partner manager)'
    case ROLE_TYPES.EMPLOYEE:
      return '(employee)'
    default:
      return ''
  }
}
export const parseProfileTeams = (teams, resetUserIds) =>
  teams.map((team) => ({
    is_billing_contact: team.is_billing_contact,
    id: team.partner_manager?.id,
    uuid: team.id,
    email: team.email,
    full_name: [team.profile?.first_name, team.profile?.last_name]
      .filter((v) => !!v)
      .join(' '),
    role: roles[team.role.name],
    first_name: team.profile?.first_name,
    last_name: team.profile?.last_name,
    contactTypesNames: team.responsibility?.contact_types,
    contact_types: team.responsibility?.contact_types.map(
      (contact_type) => contact_type.id
    ),
    avatar: team.profile?.avatar,
    resetSendButton: resetUserIds?.includes(team.id),
  }))
