import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { PartnerManager } from '@remoteam-front/types/legacy/partners-team.types'
import { AsyncButton } from '@remoteam-front/ui'

type Props = {
  partnerManager: PartnerManager
  loading: boolean
  onDelete(): void
  onClose(): void
}

export const KeyContactsConfirm = ({
  partnerManager,
  loading,
  onDelete,
  onClose,
}: Props) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{`${partnerManager.profile.first_name} ${partnerManager.profile.last_name}`}</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this user?
        <Box marginTop={2}>
          {`${partnerManager.profile.first_name} ${partnerManager.profile.last_name}`}{' '}
          will be permanently removed from your list of key contacts.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="key-contacts-confirm.component-B8C6B3"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="key-contacts-confirm.component-F66AB8"
          isFetching={loading}
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
