import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { FormLabel } from 'react-bootstrap'

export const RejectDialog = ({ reason, isLoading, onReject, onClose }) => {
  const [value, setValue] = useState(reason || '')

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Reject</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormLabel>
            Please provide a clear and concise rejection reason
          </FormLabel>
          <TextareaAutosize
            autoFocus
            minRows={5}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="reject-dialog.component-725487"
          variant="outlined"
          onClick={onClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          data-testid="reject-dialog.component-97159B"
          variant="contained"
          color="error"
          disabled={!value || isLoading}
          onClick={() => onReject(value)}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  )
}
