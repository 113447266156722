import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  padding: 32px 0;
  h2 {
    font-family: Manrope, sans-serif;
    color: #1a1e3c;
    font-size: 24px;
    margin: 2rem 0 1rem;
    line-height: 1;
  }
  p {
    margin-bottom: 1rem;
  }
`

export const TermsText = () => (
  <Root>
    <p>Last Update: December 13, 2022</p>
    <p>
      Welcome to Remofirst, a fully integrated platform providing a digital
      solution to EORs globally. As a Remofirst partner providing Employer of
      Record (“EOR”) services (“<b>Partner</b>”) through the Remofirst platform
      (“<b>Platform</b>”) hereinafter referred to as the “<b>Services</b>”, you
      will be connected to companies across the globe that are in need of your
      employer of record services (“<b>Clients</b>”). At Remofirst, we simplify
      the process from start to finish for our Partners and our Clients.
    </p>
    <p>
      The Platform and access to the Services is provided by Remofirst Inc. (“
      <b>Remofirst</b>”, “<b>We</b>” or “<b>Us</b>”). These Terms & Conditions
      will govern your use of the Platform, all Services offered through the
      Platform and all payments for Services. IN THE EVENT OF AN INCONSISTENCY
      BETWEEN THESE TERMS & CONDITIONS AND A PRIOR WRITTEN AGREEMENT BETWEEN
      REMOFIRST AND YOU, THESE TERMS & CONDITIONS WILL CONTROL.
    </p>
    <p>
      This will be a contract between Remofirst Inc. and you (hereafter “
      <b>Service Provider,</b>” “<b>You</b>”, “<b>Your</b>” or “<b>User</b>”)
      once You accept these terms by clicking below and registering to use the
      Platform to prepare to provide EOR services, as described below, to
      Clients we connect you with through the Platform. Please read these Terms
      & Conditions carefully and let Us know if You have any questions. Use of
      the Platform or Services is void where prohibited.
    </p>
    <p>
      When referring to You and Remofirst collectively, the reference will be to
      the “<b>Parties</b>” and individually, a “<b>Party</b>”.
    </p>
    <p>
      Your use of the Platform is subject to these Terms & Conditions. Your use
      of the Platform is also subject to our <u>Terms of Use</u>. Remofirst
      reserves all rights not expressly granted herein and may permanently or
      temporarily terminate, suspend, or otherwise refuse to permit Your access
      to the Platform without notice and liability, if, in our determination,
      You violate any of these Terms & Conditions.
    </p>
    <p>
      CLICKING “I AGREE“ IN AND AT THE END OF THESE TERMS & CONDITIONS CREATES A
      LEGALLY BINDING CONTRACT BETWEEN YOU AND REMOFIRST. MOREOVER, BY ACCESSING
      OR OTHERWISE USING THE PLATFORM, YOU AGREE TO BE BOUND BY THESE TERMS &
      CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS & CONDITIONS, YOU WILL NOT
      BE ABLE TO ACCESS OR USE THE PLATFORM OR ANY SERVICES. THE DATE THAT YOU
      CLICK “I AGREE“ OR FIRST ACCESS OR OTHERWISE USE THE PLATFORM OR SERVICES
      IS THE “EFFECTIVE DATE“ OF OUR CONTRACT.
    </p>
    <p>
      <b>I. REGISTRATION ON PLATFORM</b>
    </p>
    <p>
      Once you have clicked “I Agree” in and at the end of these Terms &
      Conditions, you will be granted access to the Platform and will be
      provided with an account. You will be then permitted to set Your own
      password which will not be known or visible to Remofirst or its Clients.
      By registering on the Platform, You:
    </p>
    <ol>
      <li>
        Agree that You are responsible for making all technical arrangements
        necessary to access the Platform.
      </li>
      <li>
        Agree You are responsible for ensuring that all persons who access the
        Platform through Your internet connection or account are aware of these
        Terms & Conditions and that they comply with them.
      </li>
      <li>
        Agree that you will provide truthful and accurate information when
        registering to use the Platform. The decision to accept a registration
        is in Remofirst’s discretion and We may revoke a registration at any
        time.
      </li>
      <li>
        Agree that You may not register to use the Platform if You are already
        registered or if You were previously a registered user and We cancelled
        Your account as a result of You being in breach of these Terms &
        Conditions.
      </li>
      <li>
        Are responsible for maintaining the confidentiality of Your account
        information and data within your account and You are solely responsible
        for all activities related to or changes to Your account information,
        and for any access to or use of the Platform by You or any person or
        entity using Your password or account information, whether or not such
        access or use has been authorized by You.
      </li>
      <li>
        Are responsible for ensuring that the information We hold about You is
        up to date. Please amend Your details via the Platform as appropriate
        from time to time or contact Us if You require assistance.
      </li>
    </ol>
    <p>
      <b>II. TERM OF CONTRACT & MODIFICATION</b>
    </p>
    <p>
      These Terms & Conditions agreed between You and Remofirst will continue in
      effect from Effective Date until terminated pursuant to <b>Section XII</b>{' '}
      below (“<b>Term</b>”).
    </p>
    <p>
      <b>III. PERMITS AND LICENSES</b>
    </p>
    <p>
      During the Term of these Terms & Conditions, You agree to maintain in
      effect, at Your own expense, any and all federal, national, state and/or
      local licenses, permits, registrations, or the like that may be required
      with respect to the business activity in which You are engaged.
    </p>
    <p>
      <b>IV. EOR SERVICES PROVIDED BY OUR PARTNERS</b>
    </p>
    <ol>
      <li>
        You will be exclusively liable for any and all employer obligations
        vis-à-vis the talent identified by our Clients through the Platform
        (“Talent”) including but not limited to the following (“EOR Services”):
        <ol type="a">
          <li>
            Obtaining and maintaining all legally mandated registrations,
            licenses, and insurances, including but not limited to workers’
            compensation/employer’s liability insurance.
          </li>
          <li>
            Verifying the Talent’s existing and continuing legal right to work
            in the location where the Talent will provide the Services through
            the Service Provider.
          </li>
          <li>Facilitating any and all pre-employment screening.</li>
          <li>Facilitating any and all mandatory or voluntary benefits.</li>
          <li>
            Providing the Talent with any and all legally mandated employment
            notices.
          </li>
          <li>
            Obtaining from the Talent any and all needed written consents and
            signed contracts to fulfill the spirit of these Terms & Conditions.
          </li>
          <li>Requiring the Talent’s compliance with terms outlined herein.</li>
          <li>
            Calculating and issuing the Talent’s wages including withholding and
            remitting taxes, social contributions, and all corollary payments.
          </li>
          <li>
            Facilitating expense reimbursements including any and all
            recordkeeping requirements.
          </li>
          <li>
            Maintaining any and all required employment records including
            fulfilling any and all reporting or filing requirements.
          </li>
          <li>
            Safeguarding the Talent’s personal data pursuant to applicable data
            legislation.
          </li>
          <li>
            Addressing any comments or inquiries from Talent concerning their
            employment such as payroll, human resources, or the like.
          </li>
        </ol>
      </li>
      <li>
        You will be the Talent’s employer such that You who will solely manage
        and control the Talent although the Talent may be required to liaise on
        a daily or weekly basis with the Client, to deliver to or confer with
        the Client on delivery of the assignment(s), to perform duties at the
        premises of the Client, or to undertake assignments at the Client’s
        premises or elsewhere.
      </li>
      <li>
        You will negotiate the Master Services Agreement (“<b>MSA</b>”) and
        Appendix provided within the Platform with each of our Clients with
        hiring needs in the jurisdictions where you operate (“
        <b>Service Locations</b>”). The MSA and Appendix will be an agreement
        between You and our Client, to which Remofirst will not be a party. The
        MSA terms are fixed and may not be modified. However, the Service
        Provider may notify Remofirst of any country-specific, non-negotiable
        terms at any time, which Remofirst shall incorporate as an appendix to
        the MSA within ten (10) calendar days. In the event that a Client
        requests an amendment to the MSA terms, You authorize Remofirst to
        negotiate those amendments on Your behalf to the extent that those
        amendments do not substantially alter the terms of the MSA. You
        understand and agree that You are not authorized to provide EOR Services
        to any Remofirst Client before executing an MSA with the Client through
        the Platform.
      </li>
    </ol>
    <p>
      <b>V. ADDITIONAL SERVICES PROVIDED BY OUR PARTNERS</b>
    </p>
    <ol>
      <li>
        Remofirst aims to provide its clients with a comprehensive suite of
        global workforce solutions. At the request of Remofirst or a Remofirst
        Client, You agree to provide the following corollary services as needed
        (“<b>Additional Services</b>”):
        <ul style={{ listStyleType: '"€   "' }}>
          <li>
            <u>Staffing</u> – You will identify and recruit individuals (“
            <b>Candidates</b>”) pursuant to the instructions and specifications
            of Remofirst or a Remofirst Client on a non-exclusive basis. You
            will earn a fee as outlined within the MSA if a Remofirst Client
            elects to move forward with a Candidate You identified either by
            employing them directly (“<b>Direct Hire</b>”) or by engaging them
            as Talent (“<b>Contract Hire</b>”). A Candidate will be considered
            Yours if You are first to notify the corresponding Remofirst Client
            of the Candidate.
          </li>
          <li>
            <u>Immigration Assistance</u> – You will assist Talent in obtaining
            any visa or other work authorization required in order for the
            Talent to perform work in exchange for a fee outlined within the
            MSA.
          </li>
          <li>
            <u>Background Checks</u> – You will perform background screening on
            Talent pursuant to the instructions and specifications of Remofirst
            or a Remofirst Client in a manner that is compliant with local law,
            in exchange for a fee outlined within the MSA.
          </li>
          <li>
            <u>Equipment Delivery</u> – You will coordinate the shipment and
            delivery of equipment to and from Talent in exchange for a fee
            outlined within the MSA.
          </li>
          <li>
            <u>Workspace</u> – You will provide a workspace for the Talent
            either within your own facility, through a leased space, or by some
            other means in exchange for a fee outlined within the MSA.
          </li>
          <li>
            <u>Other</u> – You will provide the service in exchange for a fee
            outlined within the MSA.
          </li>
        </ul>
      </li>
    </ol>
    <p>
      <b>VI. YOUR RESPONSIBILITIES</b>
    </p>
    <ol>
      <li>
        At the request of Remofirst or a Remofirst Client, You will fully
        cooperate with any and all compliance due diligence including but not
        limited to producing evidence of requisite licenses, registration, and
        insurances, identifying all third-party vendors used to provide the EOR
        Services, and demonstrating compliant payroll processes.
      </li>
      <li>
        You warrant that all employment agreements to be offered to Talent will
        be and will remain in full compliance with any and all local, state,
        national, or other applicable law on a continuing basis. Further, You
        warrant that You will (a) notify the Remofirst Client within a
        reasonable time before any addendums or amendments to such employment
        contracts are presented to impacted Talent, and (b) execute such
        addendums or amendments with Talent only after approval by the
        corresponding Remofirst client through the Platform.
      </li>
      <li>
        You are responsible for collecting any needed information from the
        Platform and/or Client to ensure payroll is complete and accurate.
      </li>
      <li>
        You shall pay wages to all Talent on time and in full each month in
        compliance with local law. Failure to do so will be considered a
        material breach of these Terms and Conditions.
      </li>
      <li>
        You shall both inform Remofirst and update the Platform prior to the
        next invoice date of any changes in tax, employment, or other
        legislation that impacts the cost or provision of EOR Services under
        this Agreement.
      </li>
      <li>
        Remofirst retains the right to request an audit report from Service
        Provider at any time Remofirst determines, in its sole discretion, that
        an audit is necessary.
      </li>
    </ol>
    <p>
      <b>VII. PARTNER SERVICES FEES</b>
    </p>
    <p>
      With regard to the efforts and manpower required for the ongoing
      administration associated with the EOR Services and Additional Services,
      You agree that (1) You will not charge our Clients an administrative set
      up fee to hire and onboard the Talent they have identified through the
      Platform; (2) You agree that the monthly service fee to be charged each
      Client will be the one which is shown on the Platform (“
      <b>Monthly Services Fee</b>”), and (3) You agree that any other fees for
      Additional Services will be the one which is shown on the Platform (“
      <b>Additional Services Fee</b>”). You further agree that You will not
      change the amount of the Monthly Service Fee or Additional Service Fee
      with any Client or at any time during the Term of these Terms & Conditions
      without prior written consent from Remofirst. You and Remofirst are aware
      and agree that the details of the assignments may be defined by, and may
      be changed by, Clients utilizing EOR services before and during the
      assignment. The Client may therefore agree with You to change the type or
      scope of the duties performed by the Talent before and during the
      assignment. If the Client changes the type or scope of the assignment, You
      agree that you will not change the Service Fee amount agreed with
      Remofirst in these Terms & Conditions.
    </p>
    <p>
      <b>VIII. PLATFORM USE FEE</b>
    </p>
    <p>
      Remofirst will undertake responsibility for providing a platform for You
      and our Clients to exchange information related to the Talent our Clients
      require in locations across the globe. Remofirst will also undertake
      responsibility for collecting payments from its Client(s) on Your behalf
      as defined in <b>Section IX(1)</b> below. Our Clients will each pay a
      monthly Platform use fee based on the number of individuals hired through
      the Platform by You (“Seats”) to provide services to our Clients
      (“Platform Seat Fee”). Our monthly Platform Seat Fee is based on the
      country and per Seat and will be separately invoiced to our Clients.
      Remofirst will charge You a monthly Platform Use Fee (“Partner Platform
      Use Fee”) in an amount to be discussed and agreed by the Parties.
      Remofirst will invoice You directly for the monthly Partner Platform Use
      Fee.
    </p>
    <p>
      <b>IX. PAYMENT OF PARTNER SERVICES FEE AND PLATFORM SEAT FEES</b>
    </p>
    <p>
      Using the Platform streamlines time consuming tasks for our Partners
      associated with finding new clients. Our fee and payment structure further
      simplifies the process.
    </p>
    <ol>
      <li>
        <u>Invoicing:</u> You will approve the payroll invoice for Client(s)
        through the Platform on a monthly basis on or about the fifth (5th) day
        of each month, for the payroll to be run on or about the 25th of the
        month. Our Client(s) will review and approve the invoice(s) within five
        (5) days of receipt or consult with You directly for the appropriate
        adjustment. Our Client(s) will transfer payment of each approved invoice
        to the Platform for deposit into the Partner’s account within 10 days of
        receipt from You. The Client(s) will choose the form of the payment on
        the Platform. If ACH, wire or any other transfer methods are selected,
        the Client(s) will bear the transaction costs of such payments.
      </li>
      <li>
        <u>All Payments Made Through Platform</u>: By agreeing to these Terms &
        Conditions, You agree that all payments by our Client(s) to You will be
        submitted through the Platform and You consent to Remofirst acting as
        agent of record for all payments made to You by our Clients. This is a
        non-negotiable term and violation of this term will constitute a
        material breach of the Terms & Conditions.
      </li>
    </ol>
    <p>
      <b>X. PROFIT SHARING</b>
    </p>
    <ol>
      <li>
        Remofirst encourages You to leverage the Remofirst partner network in
        order to offer global EOR services to Your existing clients (“Partner
        Client”). Remofirst offers the following profit sharing option for all
        Partners that refer Partner Clients to Remofirst:
        <ol type="a">
          <li>
            For each Partner Client you refer under this Section X, Remofirst
            will pay You 50% of the Platform Seat Fees collected.
          </li>
          <li>
            In any country where you do not provide EOR Services, You are
            permitted to negotiate a Monthly Services Fee with Partner Clients
            you refer that is higher than the Monthly Services Fee charged by
            the Partner providing EOR Services in that country (“EOR Services
            Provider”). The Platform will automatically add that negotiated
            Monthly Services Fee to (i) the Service Provider Appendix to the
            Master Services Agreement for the EOR Services Provider, and (ii)
            each corresponding Invoice issued to the Client in that country. The
            EOR Services Provider will not be permitted to amend the negotiated
            Monthly Services Fee. For each Partner Client you refer under this
            Section X, Remofirst will pay You 50% of the profit collected on the
            negotiated Monthly Services Fee. The profit is the total Monthly
            Services Fee charged to the Partner Client, less the Platform Seat
            Fee, less the EOR Services Provider’s standard Monthly Services Fee.
          </li>
        </ol>
      </li>
      <li>
        You understand and acknowledge that this Profit Sharing option is
        extended to all Remofirst partners, meaning that there may be occasions
        where You are the EOR Services Provider as defined above. In such cases,
        You understand that other Remofirst Partners may negotiate a Monthly
        Services Fee for Talent You employ pursuant to this Agreement that is
        higher than Your standard Monthly Services Fee. However, as the EOR
        Services Provider, You will not be entitled to any amounts above Your
        standard Monthly Services Fee. You also understand that when acting as
        an EOR Services Provider, You will not have the ability to amend the
        Monthly Services Fee reflected in the Service Provider Appendix to the
        Maser Services Agreement or the corresponding Invoices.
      </li>
      <li>
        All payments due to You under this profit sharing option will be made to
        You no later than 10 working day after the Partner Client pays the
        invoice(s).
      </li>
      <li>
        The terms outlined in this Section X only apply if (a) Partner has
        notified Remofirst of a Partner Client before that Partner Client
        executes Remofirst’s Terms & Conditions, and (b) Partner Client has both
        executed Remofirst’s Terms & Conditions and established an account on
        the Platform.
      </li>
    </ol>
    <p>
      <b>XI. SERVICE LEVEL AGREEMENT</b>
    </p>
    <p>
      You agree to respond to all Client communications within 24 hours of
      receipt unless the communication is received by You on a national holiday
      in Your jurisdiction. This is a non-negotiable term and violation of this
      term will constitute a material breach of the Terms & Conditions.
    </p>
    <p>
      <b>XII. CONFIDENTIALITY</b>
    </p>
    <ol>
      <li>
        Remofirst acknowledges that, in connection with the provision of the EOR
        Services under this Agreement, Service Provider may have access to
        Remofirst confidential information (as defined below). Service Provider
        agrees that Remofirst has a legitimate business interest in its
        confidential information.
      </li>
      <li>
        Confidential information will mean any privileged information, referring
        to the ownership right or the business, whether this is expressly
        specified or not as being confidential whether in the form of a
        document, or in electronic format or in any other form, and includes,
        but is not limited to: information that is not publicly known in respect
        of finance, technical and other intellectual property information of
        Remofirst, business and marketing plans, proposals, schedules, designs
        (whether in electronic or physical form), forecasts, current or
        potential clients, the identity of the customers, clients, or licensees,
        proprietary software code, progress of development computer programs,
        data bases, results of surveys, agreements concluded with clients,
        suppliers or other companies, market studies, operative, technical,
        procedural, scientific, business data, know-how in respect of the above
        (the “Confidential Information”). The Confidential Information will also
        include information referring to the Talent hired by Service Provider,
        to Remofirst and all companies in the Remofirst group of companies
        (“Remofirst Group”).
      </li>
      <li>
        Accordingly, Service Provider covenants and agrees with Remofirst that,
        without the prior written consent of Remofirst, it shall not, during the
        term of this Agreement or at any time thereafter, disclose any of such
        Confidential information, outside of Remofirst or Service Provider nor
        shall Service Provider use the same for any purpose other than the
        purposes of this Agreement unless Service Provider is obligated by law
        or instructed by a legal authority in the jurisdiction where Service
        Provider is located.
      </li>
      <li>
        As soon as reasonably practicable following the cessation of the
        legitimate business need for maintaining Confidential Information,
        Service Provider will ensure the return to Remofirst and/or Remofirst’s
        Client(s) any documents and information in whatever form or format,
        computer discs, and other tangible items in its possession or under its
        control which belong to Remofirst, or which contain or refer to any
        Confidential Information and delete all Confidential Information and
        information relating to Remofirst and Remofirst Client(s) from any
        computer, disks or other re-useable material in its possession or under
        its control and destroy all other documents and tangible items in its
        possession or under its control which contain or refer to any
        Confidential Information. This does not relate to Confidential
        information which Service Provider is legally required to retain in its
        archives for a period of time including but not limited to accounting
        data, employee data, and payroll data.
      </li>
      <li>
        Remofirst is entitled at any time to submit a request to Service Provide
        to identify what data, and in what form, Service Provider is retaining
        related to these Terms & Conditions. Service Provider agrees to provide
        the requested information to Remofirst within 15 days of receipt of its
        request.
      </li>
      <li>
        The Parties acknowledge that (i) any disclosure or use of the
        Confidential Information, not in accordance with the terms of these
        Terms & Conditions, by one the Parties, would be a breach of the Terms &
        Conditions and may cause immediate and irreparable harm to the other
        Party; (ii) the damages from such disclosure or use by it may be
        impossible to measure accurately; and (ii) injury sustained by the Party
        may be impossible to calculate and fully remedy. Therefore,
        notwithstanding anything contained in these Terms & Conditions, in the
        event of such breach, a Party may take any legal action against the
        other Party as may be available under applicable law, including not
        limited to, temporary restraining orders, preliminary injunctions, and
        permanent injunctions. Moreover, each Party will be entitled to recover
        all costs (including reasonable attorneys’ fees) which it or they may
        incur in connection with defending its interests and enforcement of
        legal rights arising due to a breach of confidentiality by the other
        Party under these Terms & Conditions.
      </li>
      <li>
        You agree to further protect the trade secrets and Confidential
        Information by (i) not soliciting for employment, engaging, or hiring –
        whether directly or indirectly – any Talent for another organization
        (whether a Remofirst Client or otherwise) written prior consent of the
        Client, and (ii) not contracting or otherwise doing business with any
        Remofirst Client outside of the Platform.
      </li>
    </ol>
    <p>
      <b>XIII. LIMITATION OF LIABILITY</b>
    </p>
    <p>
      In no event shall either Remofirst or You be liable to the other for any
      indirect, special, incidental, consequential, punitive or other damages
      (including, but not limited to data loss, business interruption, lost
      business, or lost profits), whether foreseeable or not, even if previously
      advised of the possibility of such damages as a result of utilizing the
      Platform to access our Clients.
    </p>
    <ol>
      <li>
        In no event shall either Party’s liability arising out of or related to
        these Terms & Conditions, whether in contract, tort or under any other
        theory of liability, exceed the amounts paid and/or payable by our
        Clients to You in the twelve (12) months preceding the incident that
        gave rise to the claim.
      </li>
      <li>
        The Parties agree that Remofirst will not be liable for any claims that
        it acted as an employer or joint employer of any individuals employed by
        You for our Client(s) or paid for through the Platform. The Parties
        agree that Remofirst’s sole roles with regard to these individuals is to
        provide a platform through which You and the Client exchange information
        related to the Talent and to act as a payment processor for the
        compensation and Service Fees by our Client(s) to You. Should any claims
        of this type or nature be brought against Us, You specifically agree to
        indemnify Remofirst against any loss, settlement, or damages, including
        costs and attorney fees consistent with <b>Section XIV(1)</b> below.
      </li>
      <li>
        The Parties agree that Remofirst will not be liable for any data
        protection regulations relating to the information exchanged by You and
        the Client through the Platform. You warrant that you will have and
        maintain all necessary releases prior to uploading personal data to the
        Platform.
      </li>
    </ol>
    <p>
      <b>XIV. INDEMNIFICATION</b>
    </p>
    <ol>
      <li>
        <u>
          <b>By You.</b>
        </u>{' '}
        You agree, at Your own expense, to indemnify, defend and hold harmless
        Remofirst, and any successors, its officers, directors, employees and
        agents of each (collectively, the ”Remofirst Indemnified Parties”), from
        and against all third party demands, claims, actions, suits, losses,
        damages (including, but not limited to, property damage, bodily injury
        and wrongful death), judgments, costs and expenses (including reasonable
        attorneys` fees, interest and penalties) (collectively, ”Damages”)
        imposed upon or incurred by any Remofirst Indemnified Party arising out
        of Your acts or omissions in the performance of these Terms & Condition,
        including but not limited to, any of the following:
        <ol type="a">
          <li>
            any claim by a third party that any deliverables, materials and/or
            services furnished to or obtained by anyone pursuant to these Terms
            & Conditions, or Terms of Use, or use thereof by anyone that
            infringes any patent, copyright, trademark, trade secret, license,
            or other proprietary right of any third party;
          </li>
          <li>
            any claim based on any act or lack of action on Your part or on the
            part of Your employees, consultants, representatives, or agents,
            including but not limited to any liability or damages resulting from
            a breach of any duty or theft of material or services by any such
            person(s), or for personal injury (including death) or damage to
            property arising out of any act or omission, negligent or otherwise,
            to the extent caused by You or Your employees, contractors,
            representatives, or agents;
          </li>
          <li>
            any claim under any labor, employment, social security, employee
            benefit, tax, or other laws or regulations of the United States or
            of any other country arising out of any action or inaction by You or
            Your contractors, employees, representatives or agents, provided,
            however, that You will not be liable under this clause to the
            extent, but only to the extent, that it is determined in a final and
            non-appealable judgment by a court of competent jurisdiction that
            such loss, liability, judgment, award, or cost resulted solely from
            Remofirst’s misconduct;
          </li>
          <li>
            any claim for payment of compensation (including benefits), social
            security, or pension contributions asserted by Talent or any
            government agency on behalf of itself or the Talent, and any other
            liabilities, costs, and expenses (including, but not limited to,
            attorneys` fees) associated with a determination by any national,
            federal, state, provincial, or local government agency, any court,
            or any other applicable entity that the Talent is an employee of
            Remofirst for any purpose, provided, however, that You shall not be
            liable under this sub-clause (d) to the extent, but only to the
            extent, that it is determined in a final and non-appealable judgment
            by a court of competent jurisdiction that such loss, liability,
            judgment, award, or cost resulted directly and solely from
            Remofirst’s misconduct;
          </li>
          <li>
            any claim by Talent made under applicable employment law including
            but not limited to discrimination and harassment; or,
          </li>
          <li>
            any breach by You of any of Your obligations, commitments or
            conditions contained in these Terms & Conditions.
          </li>
        </ol>
      </li>
      <li>
        <u>
          <b>By Remofirst.</b>
        </u>{' '}
        Remofirst agrees, at its own expense to indemnify, defend and hold You
        harmless from and against all Damages imposed upon or incurred by You
        arising out of the acts or omissions of Remofirst, its successor, or its
        directors, officers, managers, employees or agents relating to
        Remofirst`s failure to comply with all laws, regulations or orders
        applicable to these Terms & Conditions.
      </li>
      <li>
        <u>
          <b>Indemnification Process:</b>
        </u>{' '}
        If a Party becomes aware of a legal claim and is seeking
        indemnification, You and Remofirst agree to promptly notify the other
        Party in writing of the claim. Each Party agrees to cooperate with the
        other by providing necessary information and assistance as requested in
        support of efforts to respond to and defend any legal claim, with
        expenses to be borne by the indemnifying Party. As a condition of
        indemnification, the Party seeking indemnification must provide the
        indemnifying Party sole control of the applicable defense and
        settlement. The indemnified Party may participate in its own defense at
        its own expense. The indemnifying Party may not settle any claim unless
        such settlement includes a full release of the applicable claim(s)
        against the indemnified Party. The indemnifying Party may not settle any
        legal claim(s) without the indemnified party’s written consent, not to
        be unreasonably withheld, if the settlement imposes a payment, admission
        of fault or wrongdoing, or other obligation on that Party, or if
        settlement is deemed not to be confidential.
      </li>
    </ol>
    <p>
      <b>XV. WARRANTIES</b>
    </p>
    <ol>
      <li>
        <u>Remofirst Warranties</u>: Remofirst warrants that the Services it
        will provide under these Terms & Conditions (a) will be performed in all
        respects in accordance with the terms of these Terms & Conditions and
        within applicable law; (b) will be performed in a professional and
        diligent manner and at a standard no less than the standard in the
        industry in which Remofirst operates, and (c) will not violate any
        applicable laws in place at the time You execute these Terms &
        Conditions. Remofirst, however, is not responsible and will not make any
        warranties regarding any other agreement(s) You enter with our
        Client(s), as We are not party to those agreements and did not select or
        vet the Talent you will hire to provide services to the Client. You
        expressly acknowledge that Remofirst makes no warranties related to the
        Talent you employ for our Client(s). Except for the representations and
        warranties expressly stated in this <b>Section XV</b> and the Terms of
        Use, Remofirst expressly disclaims any and all other warranties,
        including fitness for a particular purpose, merchantability and
        non-infringement, and further makes no representations or warranties of
        any kind or type, whether oral or written, whether express, implied, or
        arising by statute, custom, course of dealing or trade usage, in
        connection with these Terms & Conditions. Use of the Platform and the
        Services are provided “as is” and as available without any warranty.
        Remofirst specifically disclaims all implied warranties or conditions of
        title, merchantability, fitness for a particular purpose, and
        non-infringement. Remofirst does not warrant uninterrupted access to its
        Platform or other services.
      </li>
      <li>
        <u>Your Warranties</u>: You warrant that (i) You have and will maintain
        all necessary licenses, registrations, and insurances to provide the EOR
        Services, (ii) You have five (5) years of experience providing the EOR
        Services in the countries you offer EOR Services to Remofirst Clients,
        (iii) Your providing the EOR Services will not violate any agreement
        between You and any third-party or any applicable laws, (iv) You will
        maintain compliance with all applicable laws for as long as you provide
        EOR Services to Remofirst Clients, (vi) You will obtain lawfully any
        information that You upload to the Platform or provide to Remofirst
        Clients, (vii) none of Your officers, directors, or affiliates have been
        subject to a conviction in a criminal proceeding or are subject to a
        pending criminal proceeding (excluding traffic violations and other
        minor offenses),(viii) all services provided to Remofirst Clients will
        be carried out with all due skill, care and diligence, in a good and
        workmanlike manner, and in conformity with industry standards and
        practice and all application legislation, and (ix) You take full
        responsibility for the employment relationship with Talent to be
        compliant with applicable local law.
      </li>
    </ol>
    <p>
      <b>XVI. TERMINATION</b>
    </p>
    <p>
      As stated in <b>Section II</b>, these Terms & Conditions are effective as
      of the Effective Date and continue indefinitely unless terminated by:
    </p>
    <ol>
      <li>
        <i>Termination for Convenience on Notice</i>. Either You or Remofirst
        may terminate this contract on 30 days written notice for any reason or
        no reason at all. Termination of these Terms & Conditions will
        automatically terminate the MSA(s) and Appendix(ices) You entered with
        Client(s) and will trigger all termination provisions related to the
        Talent.
      </li>
      <li>
        <i>Termination on Breach or Default</i>. Either You or Remofirst may
        terminate this contract with immediate effect and without any further
        obligation hereunder for a material breach or default of any of the
        terms, conditions or covenants of these Terms & Conditions by the other
        Party, by serving a written notice upon the breaching or defaulting
        Party. The written notice must contain any and all information about the
        alleged breach and should, if the circumstances permit, provide the
        breaching or defaulting Party the opportunity to cure the breach or
        default within 30 days. If the breach or default has not been cured to
        the satisfaction of the non-breaching or defaulting Party within this
        period, these Terms & Conditions will terminate and trigger automatic
        termination of the MSA(s) and Appendix (ices). If the breach has been
        cured, the Parties may agree in writing that the Terms & Conditions and
        MSA(S) and Appendix(ices) will continue in full force and effect.
      </li>
      <li>
        <i>Termination initiated by Remofirst</i>. Remofirst reserves the right
        in its sole discretion or at the direction of law enforcement or any
        government regulatory authority, to terminate this contract and any
        Services You are providing to our Clients through the Platform for any
        reason, including suspicion of or identification of fraud, harassment,
        unfair, deceptive or illegal acts or practices by You or anyone
        associated with You. Remofirst further reserves the right to terminate
        this contract if You fail to pay the Talent You employ on behalf of our
        Clients.
      </li>
      <li>
        <i>Termination for Insolvency</i>. You will be deemed in breach of this
        contract if You become or are declared insolvent or bankrupt; are the
        subject of any proceedings relating to its liquidation or insolvency, or
        for the appointment of a receiver, conservator, or similar officer; are
        unable to pay Your debts as they become due; make an assignment to or
        for the benefit of Your creditors; or cease to conduct business for any
        reason on an ongoing basis leaving no successor in interest.
      </li>
      <li>
        <i>Post-Term Rights and Obligations.</i>
        <ol type="a">
          <li>
            Neither You nor Remofirst will at any time after the termination or
            expiration of this contract, either personally or by an agent,
            directly or indirectly, represent himself/herself as being in any
            way connected with or interested in the business of the other party.
          </li>
          <li>
            The respective obligations of You and Remofirst under these Terms &
            Conditions that by their nature would continue beyond the
            termination, cancellation or expiration of these Terms & Conditions,
            shall survive termination, cancellation or expiration.
          </li>
          <li>
            Termination of this contract does not excuse You or terminate Your
            obligation to fulfill Your Talent payroll obligations.
          </li>
        </ol>
      </li>
      <li>
        <i>Actions on Termination.</i> Upon termination of this contract for any
        reason, unless otherwise requested by Remofirst, You agree to verify
        promptly through the Remofirst platform that You have met all payroll
        obligations for the Talent you have employed for Our Clients.
      </li>
    </ol>
    <p>
      <b>XVII. PLATFORM USAGE RESTRICTIONS</b>
    </p>
    <p>You agree that You will:</p>
    <ol>
      <li>Abide by and comply with the Terms of Service;</li>
      <li>
        Comply with all applicable laws, including, without limitation,
        employment laws, privacy laws, intellectual property laws, anti-spam
        laws, export control laws, and regulatory requirements;
      </li>
      <li>Provide accurate information to Us and keep it updated;</li>
      <li>
        Use your registered company name, address and related information in
        Your registration;
      </li>
      <li>Use the Platform in a professional manner;</li>
      <li>
        Report to Remofirst any direct or indirect solicitation to contract for
        EOR services directly with any Remofirst Client or former Client;
      </li>
      <li>
        Not knowingly seek, solicit, or accept the services of any Remofirst
        Client independent from the Platform. This means that You agree You will
        not enter into a direct contractual relationship with any Remofirst
        Client that does not require use of the Platform to manage the EOR
        services. You further agree not to enter into a direct contractual
        relationship with any Remofirst Client for 12 months after You terminate
        this contract with Remofirst;
      </li>
      <li>
        Not induce, or attempt to induce, any other user of the Platform to: (i)
        cease or refrain from using the Platform; (ii) reduce its use of the
        Platform; or, (iii) or do any other thing which is reasonably likely to
        have such an effect for a period of 12 months after you are no longer a
        Platform user.
      </li>
    </ol>
    <p>
      <b>XVIII. REMOFIRST’S LIMITED ROLE</b>
    </p>
    <p>
      You agree that We are acting only as a passive conduit to assist you in
      providing EOR services to clients. Remofirst is not responsible for
      policing the Platform or any content You upload to the Platform or
      exchange with our Client(s) (“User Content”). Remofirst does not and will
      not have any obligation to review User Content, and therefore We do not
      guarantee the accuracy, integrity, or quality of User Content. You
      understand and agree that any loss or damage of any kind that occurs as a
      result of the use of User Content that You upload, download, post,
      transmit, display, or otherwise make available or access through Your use
      of the Platform is solely Your responsibility. We do, however, to the
      extent permitted by law, reserve the right to review any or all User
      Content in our sole discretion and reserve the right to remove any User
      Content, in whole or in part. UNDER NO CIRCUMSTANCES WILL REMOFIRST BE
      LIABLE IN ANY WAY FOR ANY USER CONTENT POSTED ON OR MADE AVAILABLE THROUGH
      THE PLATFORM BY YOU OR ANY OTHER USER ON YOUR BEHALF.
    </p>
    <p>
      <b>XIX. OUR PROPRIETARY RIGHTS</b>
    </p>
    <p>
      Except for your User Content, the Platform and its materials, including,
      without limitation, software, images, text, graphics, illustrations,
      logos, patents, trademarks, service marks, copyrights, photographs, audio,
      videos and music (the “Platform Content”) and all intellectual property
      rights related thereto, are the exclusive property of Remofirst and any of
      its licensors. Except as explicitly provided herein, nothing in these
      Terms will be deemed to create a license in or under any such intellectual
      property rights, and You agree not to sell, license, rent, modify,
      distribute, copy, reproduce, transmit, publicly display, publicly perform,
      publish, adapt, edit, or create derivative works from any materials or
      content accessible on the Platform. Use of the Platform Content on the
      Platform for any purpose not expressly permitted by these Terms is
      strictly prohibited.
    </p>
    <p>
      <b>XX. INDEPENDENT CONTRACTOR RELATIONSHIP</b>
    </p>
    <p>
      These Terms & Conditions are a service contract under California Civil
      Code Section 1794.4 and therefore not a contract of employment and no
      employment relationship will result between Remofirst and You. You and
      Remofirst are independent from each other and these Terms & Conditions do
      not establish any relationship of partnership, joint venture, employment,
      franchise or agency between the parties. Neither party will have the power
      to bind the other party or to incur any obligations on its behalf without
      the other party’s prior written consent.
    </p>
    <p>
      <b>XXI. GOVERNING LAW, JURISDICTION AND VENUE</b>
    </p>
    <p>
      These Terms & Conditions, and any dispute arising hereunder, are governed
      by and construed in accordance with the laws of the State of California,
      USA, without regard to any conflicts of law principles that would require
      the application of the laws of any other jurisdiction. The Parties agree
      that any disputes arising from or relating to these Terms & Conditions and
      associated Terms of Use will be subject to the exclusive jurisdiction of
      the federal or state courts located in the County of San Francisco,
      California, USA. The Parties agree that this choice of law, venue, and
      jurisdiction provision is not permissive, but rather mandatory in nature.
    </p>
    <p>
      <b>XXII. NOTICES</b>
    </p>
    <p>
      Any notice either You or Remofirst may give the other concerning these
      Terms & Conditions must be in writing and given or made by means of
      electronic mail, addressed or directed to the respective Parties at the
      electronic mail address specified below or to such other address as may
      from time to time be designated by the other party by giving written
      notice. Written notices shall be deemed to have been given or made when
      actually received or seventy-two (72) hours after being sent, whichever
      occurs first. Timeframes shall be calculated based on the time zone of the
      receiving party.{' '}
    </p>
    <p>
      <b>XXIII. FORCE MAJEURE</b>
    </p>
    <p>
      If and to the extent that either Your or Remofirst’s performance of any of
      the obligations pursuant to these Terms & Conditions is delayed by
      revolution or other civil disorders, wars, acts of enemies or terrorism,
      cyber-attacks, fires, floods, acts of God, federal, state or municipal
      action, statute, ordinance or regulation, including government action due
      to local, national or international pandemics or health crises, or any
      other cause beyond the reasonable control of such Party (each, a “
      <b>Force Majeure Event</b>“), and such non-performance could not have been
      prevented by reasonable precautions, then the non-performing Party shall
      be excused from any further performance of those obligations for so long
      as such Force Majeure Event continues and such Party continues to use its
      best efforts to recommence performance whenever and to whatever extent
      possible without delay, including through the use of temporary alternate
      sources, temporary work around plans, or other means. The Party whose
      performance is delayed by a Force Majeure Event shall immediately notify
      the other Party by electronic mail or telephone or other expeditious means
      of the occurrence of the Force Majeure Event and describe in reasonable
      detail the nature of the Force Majeure Event and the expected duration of
      the delay (to be confirmed in writing within two (2) days of the inception
      of such delay). If any delay by You to perform any of Your obligations
      under these Terms & Conditions due to a Force Majeure Event continues for
      a period of thirty (30) days or more, Remofirst shall have the right to
      terminate this contract, with any and all outstanding monthly Platform
      Seat Fee invoices, due immediately.
    </p>
    <p>
      <b>XXIV. GENERAL</b>
    </p>
    <ol>
      <li>
        You and Remofirst agree to act reasonably and in good faith in our
        respective dealings with each other and to respond promptly to all
        communications.
      </li>
      <li>
        <u>Defects in Performance</u>. If Remofirst becomes aware of any defect
        in Your performance of the terms of these Terms & Conditions, Remofirst
        will promptly request such defect to be repaired or remedied at Your
        sole cost and expense no later than ten (10) days after Remofirst
        informs You of the defect.
      </li>
      <li>
        <u>Waiver of Terms and Conditions</u>. Failure to enforce any of these
        Terms or Conditions will not constitute a waiver of any such terms or
        conditions, or of any other terms or conditions. Only an additional
        written agreement can constitute waiver of any of these Terms and
        Conditions. No waiver of any Term or Condition will constitute a waiver
        of any other Term or Condition or of the same Term or Condition on a
        future date.
      </li>
      <li>
        <u>Severability</u>. All sections of these Terms & Conditions are
        distinct and severable, and if any section is deemed invalid, illegal or
        unenforceable in whole or in part, for any reason, and such section
        cannot be amended so as to make it enforceable, it will not affect the
        legality or enforceability of the remaining part of such section and any
        other section of these Terms & Conditions to the maximum extent
        permitted by law.
      </li>
      <li>
        <u>No Assignment</u>. These Terms & Conditions, or the rights granted
        hereunder, may not be assigned, sold, leased, or otherwise transferred
        in whole or part by You.
      </li>
      <li>
        <u>No Contra Preferentem</u>. These Terms & Conditions were negotiated
        at arms’ length and is not to be construed for or against any Party.
      </li>
      <li>
        <u>Entire Agreement</u>. These Terms & Conditions, together with the
        Terms of Use, represents the entire understanding between the Parties
        with respect to Remofirst’s provision of Services to You, and cancels
        and supersedes all prior agreements or understandings, whether written
        or oral, with respect to that subject matter. These Terms & Conditions
        may only be modified or amended in a writing signed by the Parties.
      </li>
      <li>
        <u>Successors.</u> These Terms & Conditions will be binding upon and
        inure to the benefit of the Parties and their respective successors and
        assigns, including any corporation with which, or into which, Remofirst
        may be merged or which may succeed to its assets or business.
      </li>
      <li>
        <u>Third Party Beneficiaries</u>. These Terms & Conditions are not
        intended to and shall not be construed to be for the benefit of any
        third parties except to the extent such third party is identified in the
        indemnification provisions contained in Section XIV above.
      </li>
      <li>
        <u>Language</u>. The Parties agree that all communications made or
        notice given pursuant to these Terms & Conditions will be in the English
        language.
      </li>
      <li>
        <u>Consent to Data Collection, Use, Processing, and Transfer</u>. You
        agree that in connection with these Terms & Conditions and the Services
        to be provided through the Platform, Remofirst may collect, use,
        process, and transfer personal data relating to You, as necessary and
        appropriate, for the following and similar purposes: (i) administration,
        regulatory and management purposes including assessment of performance
        of obligations under the Terms & Conditions; (ii) evaluating and
        revising the structure of the Platform; and (iii) such other legitimate
        business needs as are required to administer the Platform in the
        ordinary course of business. You further agree that, when necessary for
        these purposes or as required by law, Remofirst may make such data
        available to (1) its advisers; (2) parties providing products and/or
        services to Remofirst; (3) regulatory authorities; (4) any potential
        purchasers of Remofirst or its business; or (5) group companies and
        their affiliates, if any, to the extent necessary to administer the
        Parties’ relationship or for other enumerated reasons herein, to the
        extent allowable by applicable law, even if such companies are outside
        of the jurisdiction where You are located. You further confirm that the
        collection, processing and sharing of personal data as outlined in this{' '}
        <b>Section XXIV(11)</b> complies with the requirements of United States’
        laws or, if not, You agree to advise Remofirst of any revisions or
        alternative actions that must be taken to comply with local law.
      </li>
      <li>
        <u>Electronic Execution of Agreement</u>. This contract covering Terms &
        Conditions, including the Terms of Use, by whatever means accepted,
        including by electronic signature, will be treated in all manner and
        respects as an original contract and will be considered to have the same
        binding legal effect as if it were an original signed version delivered
        in person. Neither You nor Remofirst may argue that a contract was not
        formed hereunder based on either (i) the use of electronic means to
        indicate acceptance of this terms of these Terms & Conditions and
        associated agreements; or (ii) the fact that any signature or acceptance
        of these Terms & Conditions and associated agreements was transmitted or
        communicated through electronic means; and You and Remofirst forever
        waive any related defense to formation of this contract.
      </li>
    </ol>
    <p>
      IN WITNESS WHEREOF, the Parties have caused this contract to be executed
      by their respective duly authorized representatives as of the day and year
      shown below.
    </p>
  </Root>
)
