import { Api } from '@api/Api'
import {
  BankAccountDTO,
  PartnerBankAccounts,
} from '@remoteam-front/types/legacy'
import { PaginationParams } from '@remoteam-front/types/legacy/pagination.types'
import {
  PartnerManagerDTO,
  PartnerTeamResponse,
  UpdateManagerDTO,
} from '@remoteam-front/types/legacy/partners-team.types'
import { generateService } from '@remoteam-front/utils'

export const getPartnerManagers = generateService(
  async (params: PaginationParams) => {
    const res = await Api.get<PartnerTeamResponse>(`/partners/team/`, {
      params,
    })
    return res.data
  }
)

export const createPartnerManager = async (body: PartnerManagerDTO) => {
  const { data } = await Api.post(`/partners/team/`, body)
  return data
}

export const updatePartnerUsers = async (
  uuid: string,
  body: UpdateManagerDTO
) => {
  const { data } = await Api.patch(`/partner-users/${uuid}/`, body)

  return data
}

export const deletePartnerManager = async (id: number) =>
  Api.delete(`/partners/team/${id}/`)

export const getPartnerBankAccounts = generateService(async (id: string) => {
  const { data } = await Api.get<PartnerBankAccounts>(
    `partners/${id}/bank-accounts/`
  )
  return data
})

export const createPartnerBankAccounts = async (body: {
  partnerId: string
  payload: BankAccountDTO
}) => {
  const { data } = await Api.post<PartnerBankAccounts>(
    `partners/${body.partnerId}/bank-accounts/create/`,
    body.payload
  )
  return data
}

export const updatePartnerBankAccounts = async (body: {
  partnerId: string
  bank_account_pk: number
  payload: BankAccountDTO
}) => {
  const { data } = await Api.put<PartnerBankAccounts>(
    `partners/${body.partnerId}/bank-accounts/${body.bank_account_pk}/update/`,
    body.payload
  )
  return data
}

export const deletePartnerBankAccounts = async (body: {
  partnerId: string
  bank_account_pk: number
}) => {
  const { data } = await Api.delete<PartnerBankAccounts>(
    `partners/${body.partnerId}/bank-accounts/${body.bank_account_pk}/delete/`
  )
  return data
}
