import { IconButton, Typography } from '@mui/material'

import { DismissFilledIcon } from '../../icons'
import { Styled } from './drawer.styles'

type Props = {
  title: string
  onClose: () => void
}

export const DrawerHeader = ({ title, onClose }: Props) => (
  <Styled.Header>
    <Typography variant="h2_strong">{title}</Typography>
    <IconButton
      onClick={onClose}
      color="secondary"
      size="medium"
      sx={{ border: 0, borderRadius: '50%', backgroundColor: 'inherit' }}
    >
      <DismissFilledIcon />
    </IconButton>
  </Styled.Header>
)
