import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React, { memo, useEffect, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'

import InvoiceForm from './InvoiceForm'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: ${(p) => (p.open ? '#F9F9F9' : '#fff')};
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`
const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => p.open && 'rotate(90deg)'};
  color: #b2b2b2;
  margin-right: 8px;
`
const StyledBtn = styled(Button)`
  border: ${(p) => (p.open ? 'none' : '1px solid #F3F3F3')};
  padding: 6px 16px;
`

const Item = memo(
  ({ payments, isLoading, onSave, expandedId, setPaymentId }) => {
    const isOpen = expandedId === payments.id

    const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

    return (
      <Styled key={payments.id}>
        <Header className="d-flex align-items-center" open={isOpen}>
          <div
            className="d-flex w-100 align-items-center"
            role="button"
            aria-hidden
            onClick={handleToggle(payments.id)}
          >
            <StyledIcon open={isOpen} />
            <div className="d-flex w-100 justify-content-between">
              <Typography className="text_medium__14">
                {payments.user.full_name}
              </Typography>
              <Typography
                as="span"
                className={`${
                  isOpen ? 'heading_semibold__18' : 'text_medium__14'
                }`}
              >
                Payroll #{payments.id}
              </Typography>
            </div>
          </div>
        </Header>
        <Body open={isOpen}>
          <InvoiceForm
            payments={payments}
            isLoading={isLoading}
            onClose={handleToggle(null)}
            onSave={onSave}
          />
        </Body>
      </Styled>
    )
  }
)

const MyAccordion = ({ payments, isLoading, onSave }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)
  useEffect(() => {
    setExpandedItemId(payments?.id)
  }, [payments])

  return (
    <Item
      key={payments.id}
      isLoading={isLoading}
      payments={payments}
      onSave={(body) => onSave({ id: payments.id, body })}
      expandedId={expandedItemId}
      setPaymentId={setExpandedItemId}
    />
  )
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
