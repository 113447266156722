import { useToast } from '@core/hooks/useErrorNotification'
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { AddFilledIcon } from '@remoteam-front/ui/icons'
import { formatDate } from '@remoteam-front/utils'
import { uploadAgreement } from '@services/document-sign.service'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { AgreementConfirm } from './agreement-confirm.component'
import { EmptyState } from './empty-state'
import { UploadDrawer } from './partner-agreement-modal'

export const OnboardingTrackerUploadContract = ({
  agreementsData,
  employee,
  refetch,
}) => {
  const uploadOpen = useBoolean(false)
  const [agreementIdToDelete, setAgreementIdToDelete] = useState(null)
  const { successAlert } = useToast()

  const uploadAgreementMutation = useMutation({
    mutationFn: (payload) => uploadAgreement(payload),
    onSuccess: () => {
      uploadOpen.setFalse()
      refetch()
      successAlert('Successfully created!')
    },
  })

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="textLarge2">Employment agreement</Typography>
          <Button
            startIcon={<AddFilledIcon />}
            variant="contained"
            onClick={uploadOpen.setTrue}
          >
            Upload agreement
          </Button>
        </Box>

        {agreementsData.count === 0 ? (
          <EmptyState />
        ) : (
          <Table data-testid="onboarding-tracker-upload-contract.component-E157EE">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Company signee</TableCell>
                <TableCell>Date of signature</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agreementsData.results.map(
                ({ id, title, job_agreement, signed_agreement_document }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Link
                        data-testid="onboarding-tracker-upload-contract.component-D1BEB4"
                        color="primary"
                        target="_blank"
                        href={signed_agreement_document}
                        rel="noreferrer"
                        underline="hover"
                      >
                        {title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {job_agreement?.contract?.company.name}
                    </TableCell>
                    <TableCell>
                      {formatDate(job_agreement?.date_of_signature)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        data-testid="onboarding-tracker-upload-contract.component-34692C"
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setAgreementIdToDelete(id)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
      </Box>

      {uploadOpen.value && (
        <UploadDrawer
          onClose={uploadOpen.setFalse}
          employee={employee}
          onSubmit={uploadAgreementMutation.mutate}
          loading={uploadAgreementMutation.isLoading}
        />
      )}

      {agreementIdToDelete && (
        <AgreementConfirm
          agreementId={agreementIdToDelete}
          onClose={() => setAgreementIdToDelete(null)}
        />
      )}
    </>
  )
}
