import { Tooltip } from '@atoms/Tooltip'
import { SplitNumber } from '@core/utils'
import { isNumber } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'

const Expandable = styled.tr`
  td {
    color: #00000073;
    font-weight: 400 !important;
  }
  background-color: #f9f9f9;
`

export const CostCalculatorExpanded = ({
  employerCost,
  currencyName,
  onExpand,
}) => {
  return (
    <Expandable>
      <td className="actions"></td>
      <td>
        <div className="d-flex ml-4">
          {employerCost.name}
          <Tooltip
            id={v4()}
            content={
              <div>
                {employerCost.summary}
                {employerCost.long_description && (
                  <div
                    className="mt-1"
                    role="button"
                    aria-hidden
                    style={{ color: '#1890ff' }}
                    onClick={() => onExpand(employerCost)}
                  >
                    learn more
                  </div>
                )}
              </div>
            }
            style={{ width: 'max-content' }}
            clickable
          />
        </div>
      </td>
      <td>
        {isNumber(employerCost.monthly)
          ? SplitNumber(employerCost.monthly)
          : employerCost.monthly}
        {employerCost.annual_cap && (
          <Tooltip
            id={v4()}
            content={`${employerCost.monthly} ${currencyName} per month capped at ${employerCost.annual} ${currencyName} per year.`}
            style={{ width: 'max-content' }}
          />
        )}
      </td>
      <td>
        {isNumber(employerCost.annual)
          ? SplitNumber(employerCost.annual)
          : employerCost.annual}
      </td>
    </Expandable>
  )
}
