import { ContractInsuranceStatus } from '../health-insurance.types'
import { PaginationResponse } from '../pagination.types'
import { AddressDto } from './address.types-v2'
import { SalaryChange } from './agreement.types-v2'
import { CompanyShort } from './company.types-v2'
import { Country } from './country.types-v2'
import { Currency } from './currency.types-v2'
import { PaymentDetails } from './payment.types-v2'
import {
  Benefit,
  Compensation,
  ContractJobStartDelayReason,
  Job,
  Profile,
  Pronouns,
} from './profile.types-v2'
import { Region } from './regions.types-v2'
import { Sumsub } from './sumsub.types-v2'
import { UserShort } from './user.types-v2'

export type Contracts = PaginationResponse<Contract[]>

export interface Contract {
  id: number
  user_id: string
  full_name: string
  avatar: string | null
  birth_date: string
  nationality: string
  company: CompanyShort
  job_position: string
  email: string
  sex: string
  yearly_gross_salary: number
  contractor_rate: number
  contractor_wage_type: string
  requested_starts_at: string | null
  starts_at: string | null
  living_country: Country
  currency: Currency
  state: ContractState
  sub_state?: ContractSubState
  documents_state: string
  contract_type: ContractType
  department: string
  working_country: Country
  bank_account_country: Country | null
  is_test: boolean
  partner: {
    id: string
    name: string
    fee: number
    fee_currency_sign: string
    partner_does_not_use_platform: boolean
  } | null
  partner_status: 'trial' | 'default' | null
  external_id: string
  from_platform: string | null
  insurances: Insurance[]
  benefit: Benefit | null
  created_at: string
  airtable_external_id: string
  hubspot_external_id: string
  remofirst_fee: number
  fte_remofirst_fee: {
    contract: number
    fee_note: null
    id: number
    non_resident_rf_fee: number
    non_resident_rf_fee_currency: null | Currency
    resident_rf_fee: number
    resident_rf_fee_currency: null | Currency
  }
  payment_method: PAYMENT_METHOD
  remofirst_fee_currency_sign: string | null
  enable_mfa: boolean
  compensation: Compensation | null
  residency_type: ResidencyType
  hiring_manager: HiringManager | null
  onboarding_stage: OnboardingStage | null
  has_active_remohealth_package?: boolean
}

export type OnboardingStage = {
  stage: OnboardingMainStage
  substage: OnboardingSubstage
}

export type OnboardingMainStage =
  | 'employee_intake'
  | 'documents'
  | 'deposit'
  | 'agreement'
  | 'activation'

export type OnboardingSubstage = {
  fte_added_by_client: OnboardingSubstageStatus
  lp_confirmed_by_ops: OnboardingSubstageStatus
  hm_assigned_by_cs: OnboardingSubstageStatus
  fte_invited: OnboardingSubstageStatus
  fte_logged_in: OnboardingSubstageStatus
  fte_infodoc_submitted: OnboardingSubstageStatus
  fte_doc_approved_by_lp: OnboardingSubstageStatus
  deposit_invoice_issued_by_cs: OnboardingSubstageStatus
  deposit_paid_by_client: OnboardingSubstageStatus
  signed_ea_uploaded: OnboardingSubstageStatus
}

export type OnboardingSubstageStatus = 'completed' | 'pending' | 'rejected'

export type ContractType = 'full_time_employee' | 'contractor'

export enum PAYMENT_METHOD {
  NIUM = 'nium',
  WISE = 'wise',
  ACH = 'ach',
}
export const PaymentMethodLabel: { [key in PAYMENT_METHOD]: string } = {
  [PAYMENT_METHOD.WISE]: 'Wire transfer',
  [PAYMENT_METHOD.NIUM]: 'Nium',
  [PAYMENT_METHOD.ACH]: 'ACH',
}

export interface ContractDetail
  extends Omit<
    Contract,
    | 'full_name'
    | 'avatar'
    | 'birth_date'
    | 'nationality'
    | 'job_position'
    | 'email'
    | 'sex'
    | 'yearly_gross_salary'
    | 'contractor_rate'
    | 'contractor_wage_type'
    | 'currency'
    | 'partner'
    | 'external_id'
    | 'from_platform'
    | 'airtable_external_id'
    | 'hubspot_external_id'
    | 'remofirst_fee'
    | 'remofirst_fee_currency_sign'
  > {
  profile: Profile
  job: Job | null
  compensation: Compensation | null
  payment_details: PaymentDetails | null
  partner_fee: number
  salary_changes: SalaryChange[]
  saas_eor_platform_fee: SaasEorPlatformFee | null
  paid_leave: PaidLeave
  paid_bereavement_leave: PaidLeave
  tax_number: number | null
  sumsub?: Sumsub
  paid_parental_leave: PaidLeave
  paid_sick_leave: PaidLeave
  contractor_fee: ContractFee
  need_to_provide_documents: boolean
  agreement_signed: boolean
  has_agreement_to_sign: string
  terms_of_services_agreed: boolean
  terms_of_services_agreed_date: string | null
  needs_unisure_confirmation: boolean
  work_permit: WorkPermit | null
  hiring_manager: HiringManager
  direct_manager: DirectManager
  region?: Region
}

export type HiringManager = {
  email: string
  full_name: string
  id: string
}

export type DirectManager = HiringManager

export interface WorkPermit {
  created_at: string
  expiration_date: string | null
  status: 'invalid' | 'approved' | 'expired'
}

export interface PaidLeave {
  annual_days_by_contract: number
}

export interface SaasEorPlatformFee {
  id: number
  remofirst_platform_fee: number
  remofirst_platform_fee_currency: Currency
  remofirst_platform_fee_pay_role: string
}

export interface Insurance {
  insurance_price: InsurancePrice
  status: ContractInsuranceStatus
  starts_at: string
  family_members_amount: number
  purchased_price: number
  activated_at: string
  total_price: number
  expired_at: string
  created_at: string
  family_members: FamilyMembers[]
  unisure_purchased_price: number
  unisure_total_price: number
}
export interface FamilyMembers {
  age: number
  birth_date: string
  first_name: string
  last_name: string
  remofirst_purchased_price: number
  unisure_purchased_price: number
}
export interface InsurancePrice {
  id: number
  insurance_type: string
  min_age: number
  max_age: number
  price: number
}

export interface ContractFee {
  id: number
  fee: number
  fee_currency: Currency
}

export interface ActivateContractOnboardingDto {
  profile: ContractOnboardingProfileDto
  same_as_billing_address?: boolean
  emergency_contact?: ContractOnboardingEmergencyContactDto
  work_permit?: ContractOnboardingWorkPermitDto
  contract_documents?: ContractOnboardingDocumentsDto
  residency_type: ResidencyType
  region?: number
  working_country?: Country
  payment_details?: ContractOnboardingPaymentDetailsDto
}
export interface ActivateContractOnboardingV2Dto {
  profile: ContractorOnboardingProfileDto
  same_as_billing_address?: boolean
  emergency_contact?: ContractOnboardingEmergencyContactDto
  work_permit?: ContractOnboardingWorkPermitDto
  contract_documents?: ContractOnboardingDocumentsDto
  tax_number?: string
}

export type ResidencyType = 'resident' | 'non_resident'

export interface ContractOnboardingProfileDto {
  first_name: string
  middle_name?: string
  last_name: string
  birth_date: string
  phone_number: string
  address: AddressDto
  home_address?: AddressDto
  sex?: string
  nationality?: string
  passport_number?: string
  needs_work_permit?: boolean
  pronouns?: Pronouns
  occupation?: number
}

export interface ContractorOnboardingProfileDto {
  full_name?: string
  first_name: string
  last_name: string
  middle_name?: string
  birth_date: string
  phone_number: string
  address: AddressDto
  home_address?: AddressDto
  sex?: string
  nationality?: string
  passport_number?: string
  needs_work_permit?: boolean
  pronouns?: Pronouns
}

export interface ContractOnboardingEmergencyContactDto {
  name: string
  relationship: string
  email: string
  phone_number: string
}

export interface ContractOnboardingPaymentDetailsDto {
  holder_name?: string
  sort_code?: string
  account_number?: string
  bic?: string
  iban?: string
  routing_number?: string
  bank_name?: string
  additional_info?: string
  tax_id?: string
}

export interface ContractOnboardingWorkPermitDto {
  expiration_date: string
}
export type ContractOnboardingDocumentsDto = ContractOnboardingDocumentDto[]

export interface ContractOnboardingDocumentDto {
  employee_document: number
  attachments?: string[]
  unable_to_provide?: boolean
}

export interface CompensationUpdateDto {
  receiving_currency: number
}

export interface ContractStats {
  income: number
  currency: Currency
  payments: Array<ContractStatsPayment>
}

export interface ContractStatsPayment {
  month: string
  is_current: boolean
  total: number
}

export interface ContractCheckListData {
  check_list: ContractCheckList
  check_list_passed: boolean
  passed_percent: number
}

export interface ContractCheckList {
  uploaded_agreement: {
    passed: boolean
    datetime: string
    contract_is_sent: boolean
    contract_sent_date: string
  }
  w8ben_agreement: {
    passed: boolean
    datetime: string
  }
  sumsub_identity: {
    passed: boolean
    forced_by: string | null
    datetime: string
    current_status: string
  }
  sumsub_poa: {
    passed: boolean
    forced_by: string | null
    datetime: string
    current_status: string
  }
  withdrawal_method: {
    passed: boolean
    datetime: string
  }
}

export interface ReportUserCreatedDto {
  event_name: string
  metadata: {
    start_time: Date
  }
}

export interface ResetPasswordDto {
  email: string
}

export interface UpdateContractWorkPermitDto {
  id: number
  expiration_date: string
}

export interface UpdateContractResidencyTypeDto {
  residency_type: ResidencyType
}

export interface UpdateContractSubStateDto {
  sub_state: ContractSubState
}

export interface SubStateChangeInfo {
  id: number
  reason: ContractChangeSubStateReason
  request_type: ContractSubState
  get_reason_display: string
  last_working_day: string
  last_payment_date: string
  deactivation_date: string
  verified_cs_manager: {
    id: string
    full_name: string
    email: string
  }
  comment: string
}

export interface UpdateSubStateChangeInfoDto {
  last_working_day?: string
  last_payment_date?: string
  comment?: string
  reason?: string
  verified_cs_manager?: string
}

export interface CreateSubStateChangeInfoDto {
  reason: ContractChangeSubStateReason
  request_type: ContractSubState
  last_working_day?: string
  last_payment_date?: string
  verified_cs_manager?: string
  comment: string
}

export type ContractState =
  | 'active'
  | 'archived'
  | 'completed'
  | 'created'
  | 'draft'
  | 'inactive'
  | 'onboarding'
  | 'invited'
  | 'signing'
  | ''

export type ContractSubState =
  | 'dummy'
  | 'on_hold'
  | 'offboarding'
  | 'offboarded'
  | 'cancelled'
  | 'in_progress'

export type ContractStateList = Array<{ value: ContractState; label: string }>
export type ContractSubStateList = Array<{
  value: ContractSubState
  label: string
}>

export type ContractOffboardingReason =
  | 'poor_performance'
  | 'reducing_operations'
  | 'resignation'
  | 'set_up_own_entity'
  | 'shut_down'
  | 'visa_issue'
  | 'end_of_contract'
  | 'poor_remofirst_service'
  | 'remofirst_decision'
  | 'transfer_to_other_eor'
  | 'position_canceled'
  | 'ee_declined_offer'
  | 'hiring_with_another_eor'
  | 'hiring_as_contractor'
  | 'decided_not_to_hire_this_ee'
  | 'failed_background_check'
  | 'non_responsive_ee'
  | 'other'

export type ContractChangeSubStateReason =
  | 'poor_performance'
  | 'reducing_operations'
  | 'resignation'
  | 'set_up_own_entity'
  | 'shut_down'
  | 'visa_issue'
  | 'end_of_contract'
  | 'poor_remofirst_service'
  | 'remofirst_decision'
  | 'transfer_to_other_eor'
  | 'waiting_for_visa'
  | 'illness'
  | 'not_starting_work_yet'
  | 'poor_performance'
  | 'reducing_operations'
  | 'resignation'
  | 'set_up_own_entity'
  | 'shut_down'
  | 'visa_issue'
  | 'end_of_contract'
  | 'poor_remofirst_service'
  | 'remofirst_decision'
  | 'transfer_to_other_eor'
  | 'waiting_for_visa'
  | 'ee_on_leave'
  | 'not_starting_work_yet'
  | 'ee_declined_offer'
  | 'hiring_with_another_eor'
  | 'hiring_as_contractor'
  | 'failed_background_check'
  | 'non_responsive_ee'
  | 'decided_not_to_hire_this_ee'
  | 'lp_delay'
  | 'lp_office_closed'
  | 'immigration_process'
  | 'waiting_background_check_result'
  | 'client_non_responsive'
  | 'financial_issue'
  | 'client_takes_long_time_to_review_ea'
  | 'ee_takes_long_time_to_review_ea'
  | 'other'

export type ContractOffboardingReasonList = Array<{
  value: ContractOffboardingReason
  label: string
}>

export type ContractChangeSubStateReasonList = Array<{
  value: ContractChangeSubStateReason
  label: string
}>

export type Manager = {
  id: string
  full_name: string
  email: string
}

export interface ContractOffboardingInfo {
  id: number
  reason: ContractOffboardingReason
  get_reason_display: string
  last_working_day: string
  last_payment_date?: string | null
  deactivation_date?: string
  verified_cs_manager: Manager | null
  comment?: string
}

export interface CreateContractOffboardingDto {
  reason: ContractOffboardingReason
  last_working_day: string
  last_payment_date: string | null
  verified_cs_manager?: string | null
  comment?: string
}

export type UpdateContractOffboardingDto = Pick<
  ContractOffboardingInfo,
  'last_working_day' | 'last_payment_date'
>

export interface ContractReport {
  id: number
  fetched_by: UserShort
  comment: string
  created_at: string
}

export type ContractReportList = Array<ContractReport>

export interface AssignHiringManagerDto {
  hiring_manager: string
}

export interface UpdateContractInsuranceStatus {
  status: ContractInsuranceStatus
}

export interface DraftOnboardingDto {
  id?: number
  data: Partial<ActivateContractOnboardingDto>
  step: string
}

export interface DirectManagerInfo {
  id: string
  email: string
  is_active: boolean
  profile: {
    first_name: string
    middle_name: string
    last_name: string
  }
}

export type LineManagers = PaginationResponse<DirectManagerInfo[]>

// export interface IncomeTypeCertification {
//   income_not_effectively_connected: string;
//   income_effectively_connected: string;
//   partner_share: string;
//   amount_realized: string
// }

export interface TaxDetails {
  tax_form_type: string
  entity_type: string
  is_usa_tax_resident: boolean
  business_name?: string | null
  has_tax_number: boolean
  social_security_number?: string | null
  employer_identification_number?: string | null
  tax_classification?: string | null
  llc_classification?: string | null
  other_classification?: string | null
  does_have_partnership: boolean
  is_bank_account_outside_usa: boolean
  exempt_payee_code?: string | null
  exempt_reporting_fatca?: string | null
  account_numbers?: string | null
  certify_tin_correct: boolean
  certify_is_exempt: boolean
  certify_tax_resident: boolean
  certify_fatca: boolean
  certify_receive_1099: boolean
  is_saved: boolean
  foreign_tax_identification_number: string | null
  funds_received_in_disregarded_entity_account: boolean
  bank_name: string | null
  claiming_treaty_benefits_on_business_profits: boolean
  article_paragraph: string | null
  withholding_rate: number | undefined
  income_type: string | null
  additional_conditions: string | null
  certify_beneficial_owner: boolean
  income_type_certification: string
  certify_resident_of_treaty_country: boolean
  certify_authorize_form_provision: boolean
  certify_capacity_to_sign: boolean
  certify_is_not_subject_to_backup: boolean
}

export interface ContractTaxDetailsInfo extends TaxDetails {
  id: number
  contract: number
}

export type ContractTaxDetails = PaginationResponse<ContractTaxDetailsInfo[]>

export interface UpdateContractStartDate {
  requested_starts_at: string | null
  starts_at: string | null
  reason: ContractJobStartDelayReason
  comment?: string
}

export interface ContractFullName {
  first_name: string
  middle_name?: string
  last_name: string
}

export interface StatusLog {
  id: number
  fetched_by: UserShort
  comment: string
  change_request_reason: string
  change_request_comment: string
  created_at: string
}
