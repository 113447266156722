import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import BasicInformation from '@pages/customers/create-customer/forms/BasicInformation'
import CompanyLegalForm from '@pages/customers/create-customer/forms/CompanyLegalForm'
import UserInformationForm from '@pages/customers/create-customer/forms/UserInformationForm'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const step1 = 'Basic Information'
const step2 = 'User Information'
const step3 = 'Company legal address'

export default ({ loading, onClose, onSave }) => {
  const [activeStep, setStep] = useState(1)

  const [payload, setPayload] = useState({})

  const handleBack = () => setStep((prev) => prev - 1)

  const handleNext = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      ...formValues,
    }))
    setStep((prev) => Number(prev) + 1)
  }

  const handleSave = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }

      onSave(body)

      return body
    })
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Add a new customer"
          description="Adding a new customer is simple and fast. We'll craft the contract as soon as you're done."
        >
          <Step step="1" title={step1} setStep={setStep}>
            <StepContent title={step1} onClose={onClose}>
              <BasicInformation onNext={handleNext} />
            </StepContent>
          </Step>

          <Step
            step="2"
            title={step2}
            disabled={activeStep === 1}
            setStep={setStep}
          >
            <StepContent title={step2} onClose={onClose} onBack={handleBack}>
              <UserInformationForm
                working_country={payload.working_country}
                onNext={handleNext}
                onBack={handleBack}
              />
            </StepContent>
          </Step>

          <Step
            step="3"
            title={step3}
            disabled={activeStep <= 2}
            setStep={setStep}
          >
            <StepContent title={step3} onClose={onClose} onBack={handleBack}>
              <CompanyLegalForm
                onBack={handleBack}
                onFinish={handleSave}
                loading={loading}
              />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Modal>
  )
}
