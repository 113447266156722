/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { RiInformationLine } from 'react-icons/ri'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const Tooltip = React.forwardRef(
  ({ content, id, children, clickable, style, ...rest }, ref) => {
    return (
      <>
        {children || (
          <RiInformationLine
            data-tooltip-id={id}
            data-tip={id}
            data-for={id}
            className="ml-1"
          />
        )}

        <ReactTooltip
          ref={ref}
          style={{
            zIndex: 10,
            width: 'calc(100% - 20px)',
            background: 'white',
            color: '#121212',
            boxShadow: '0 2px 8px 0 #0000001F',
            ...style,
          }}
          id={id} // required
          clickable={clickable}
          content={content}
          opacity={1}
          {...rest}
        />
      </>
    )
  }
)
