import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { edit } from '@core/icons/icons'
import React, { memo, useState } from 'react'
import { CgTrash } from 'react-icons/cg'
import { MdChevronRight, MdFiberManualRecord } from 'react-icons/md'
import styled from 'styled-components'

import Form, { expenseTypes, paymentTypes } from './Form'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: ${(p) => (p.open ? '#F9F9F9' : '#fff')};
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`
const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => p.open && 'rotate(90deg)'};
  color: #b2b2b2;
  margin-right: 8px;
`
const StyledBtn = styled(Button)`
  border: ${(p) => (p.open ? 'none' : '1px solid #F3F3F3')};
  padding: 6px 16px;
`

const Item = memo(
  ({
    payment,
    isLoading,
    currency,
    onDelete,
    onSave,
    expandedId,
    setPaymentId,
  }) => {
    const isOpen = expandedId === payment.id

    const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

    return (
      <Styled key={payment.id}>
        <Header className="d-flex align-items-center" open={isOpen}>
          <div
            className="flex-grow-1"
            role="button"
            aria-hidden
            onClick={handleToggle(payment.id)}
          >
            <StyledIcon open={isOpen} />
            <Typography
              as="span"
              className={`${
                isOpen ? 'heading_semibold__18' : 'text_medium__14'
              }`}
            >
              {payment.source === 'EXPENSE_MANAGEMENT'
                ? expenseTypes.filter(
                    (type) => type.value === payment?.ap_type
                  )[0]?.label
                : paymentTypes.filter(
                    (type) => type.value === payment?.ap_type
                  )[0]?.label}
            </Typography>
            <MdFiberManualRecord size={8} className="ml-2 mr-2" />
            <Typography
              as="span"
              className={`${
                isOpen ? 'heading_semibold__18' : 'text_medium__14'
              }`}
            >
              {payment.name}
            </Typography>
            <MdFiberManualRecord size={8} className="ml-2 mr-2" />
            <Typography
              as="span"
              className={`${
                isOpen ? 'heading_semibold__18' : 'text_medium__14'
              }`}
            >
              {currency?.sign || currency?.short_code}
              {payment.amount_in_partner_currency}
            </Typography>
          </div>
          <StyledBtn
            open={isOpen}
            size="small"
            priority="secondary"
            type="button"
            style={{ marginRight: 10 }}
            onClick={handleToggle(payment.id)}
          >
            <Icon icon={edit} fill="none" className="mr-2" />
            {isOpen ? 'Editing...' : 'Edit'}
          </StyledBtn>
          <StyledBtn
            size="small"
            priority="danger_outlined"
            onClick={() => onDelete(payment.id)}
          >
            <CgTrash style={{ marginRight: 10 }} />
            Delete
          </StyledBtn>
        </Header>
        <Body open={isOpen}>
          <Form
            data={payment}
            isLoading={isLoading}
            onClose={handleToggle(null)}
            onSave={onSave}
          />
        </Body>
      </Styled>
    )
  }
)

const MyAccordion = ({ payrolls, isLoading, onSave, onDelete }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  return payrolls.additional_payments.map((v) => {
    return (
      <Item
        key={v.id}
        isLoading={isLoading}
        payment={v}
        currency={payrolls.partner_currency}
        onDelete={onDelete}
        onSave={(body) => onSave({ id: v.id, body })}
        expandedId={expandedItemId}
        setPaymentId={setExpandedItemId}
      />
    )
  })
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
