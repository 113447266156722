const cyan = {
  100: '#13344D',
  400: '#2871A8',
  500: '#4C92C7',
  600: '#89BBE1',
  700: '#A6CCE9',
  800: '#C4DDF0',
  900: '#EBF5FC',
}

export default cyan
