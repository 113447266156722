import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Input from '@atoms/Input/Input'
import SpinnerOrChildren from '@atoms/Spinner/SpinnerOrChildren'
import { Tooltip } from '@atoms/Tooltip'
import Typography from '@atoms/Typography/Typography'
import { getPartnerContactTypes } from '@services/team.service'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'

const editManagerFormId = 'editManagerFormId'

export const EditManagerModal = ({
  loading,
  selectedManager,
  onSubmit,
  ...rest
}) => {
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...selectedManager },
  })
  const contactTypes = watch('contact_types')

  const contactTypesQuery = useQuery(getPartnerContactTypes.key, {
    queryFn: () => getPartnerContactTypes.fetch({ limit: 1000, offset: 0 }),
  })

  const submit = ({
    first_name,
    last_name,
    is_billing_contact,
    contact_types,
  }) => {
    const payload = {
      profile: {
        first_name,
        last_name,
      },
      is_billing_contact,
      responsibility: { contact_types },
    }

    onSubmit(payload)
  }

  return (
    <Modal {...rest} centered show>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>{`${selectedManager.first_name} ${selectedManager.last_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id={editManagerFormId}
          className="d-flex flex-column gap-3"
          onSubmit={handleSubmit(submit)}
        >
          <div className="d-flex gap-3">
            <div className="w-100">
              <Input
                data-testid="edit-manager-modal-949929"
                autoFocus
                placeholder="First name"
                label="First name"
                isRequired
                {...register('first_name', {
                  required: 'First name is required',
                })}
              />
              {errors.first_name && (
                <Typography className="text_regular__14 color_red">
                  {errors.first_name.message}
                </Typography>
              )}
            </div>

            <div className="w-100">
              <Input
                data-testid="edit-manager-modal-6C882C"
                placeholder="Last name"
                label="Last name"
                isRequired
                {...register('last_name', {
                  required: 'Last name is required',
                })}
              />
              {errors.last_name && (
                <Typography className="text_regular__14 color_red">
                  {errors.last_name.message}
                </Typography>
              )}
            </div>
          </div>
          <Input
            data-testid="edit-manager-modal-EB2320"
            type="email"
            placeholder="Enter the email"
            label="Email"
            isRequired
            disabled
            {...register('email', {
              required: 'Please fill the email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <Typography className="text_regular__14 color_red">
              {errors.email.message}
            </Typography>
          )}

          <Controller
            control={control}
            name="contact_types"
            render={({ field }) => (
              <>
                <Typography className="text_medium__14">
                  Contact type <span className="text-danger ml-1">*</span>
                </Typography>

                <SpinnerOrChildren isLoading={contactTypesQuery.isLoading}>
                  <div className="d-flex flex-column gap-2 ml-4">
                    {contactTypesQuery.data?.results.map(
                      ({ id, name, description }) => (
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked)
                              setValue('contact_types', [...contactTypes, id])
                            else
                              setValue(
                                'contact_types',
                                contactTypes?.filter((_id) => _id !== id)
                              )
                          }}
                          checked={contactTypes.some((_id) => _id === id)}
                          label={name}
                          tooltip={<Tooltip id={v4()} content={description} />}
                        />
                      )
                    )}
                  </div>
                </SpinnerOrChildren>
              </>
            )}
          />
          <Controller
            control={control}
            name="is_billing_contact"
            render={({ field }) => (
              <Checkbox
                data-testid="edit-manager-modal-5E4929"
                {...field}
                checked={field.value}
                label="Is a Billing contact"
              />
            )}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="edit-manager-modal-8CA230"
          type="button"
          priority="secondary"
          size="small"
          onClick={rest.onHide}
        >
          Cancel
        </Button>
        <Button
          data-testid="edit-manager-modal-EC8686"
          type="submit"
          form={editManagerFormId}
          priority="primary"
          size="small"
          disabled={loading}
        >
          <Typography>Save</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
