import { WorkScheduleTable } from '@components/work-schedule-table/work-schedule-table.component'
import { Alert, Box, Button } from '@mui/material'
import { WorkSchedule as WorkScheduleType } from '@remoteam-front/types/legacy'

type Props = {
  data: WorkScheduleType
  onNext(): void
}

export const WorkSchedule = ({ data, onNext }: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {data ? (
        <>
          <Alert severity="info">
            If you spot any mistake in the tables below, please reach out to
            your Partner Relationship manager at Remofirst team.
          </Alert>
          <WorkScheduleTable data={data} />
        </>
      ) : (
        <Alert severity="info">
          No information on the work schedule is available for your country.
          Please reach out to your Partner Relationship Manager at the Remofirst
          team to discuss country-specific guides.
        </Alert>
      )}

      <Box alignSelf="center">
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
