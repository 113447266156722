import NotFound from '@atoms/NotFound/NotFound'
import CustomTable from '@atoms/Table/CustomTable'
import moment from 'moment'
import React, { useState } from 'react'

const DEFAULT_PAGE_LIMIT = 10

const fields = [
  // {
  //   title: 'Created at',
  //   key: 'created',
  //   width: 150,
  // },
  {
    title: 'Type',
    key: 'type',
    width: 650,
  },
  {
    title: 'Start date',
    key: 'started',
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
  },
]

const transformHealthInsuranceData = (employee) => {
  const result = []
  if (employee?.benefit.provides_work_equipment) {
    result.push({
      created: moment(employee?.benefit.created_at).format('DD MMM YYYY'),
      type: (
        <span>
          Equipment delivery{' '}
          <span style={{ color: '#6D6E85', fontSize: '12px' }}>
            ({employee.benefit.equipment_specification})
          </span>
        </span>
      ),
      started: employee?.benefit.delivered_at
        ? moment(employee?.benefit.delivered_at).format('DD MMM YYYY')
        : '-',
      status: '-',
    })
  }
  if (employee.insurances?.length) {
    employee.insurances.forEach((insurance) => {
      result.push({
        created: moment(insurance.created_at).format('DD MMM YYYY'),
        type: (
          <span>
            RemoHealth{' '}
            <span style={{ color: '#6D6E85', fontSize: '12px' }}>
              ({insurance.insurance_price?.insurance_type || '-'})
            </span>
          </span>
        ),
        started: insurance?.starts_at
          ? moment(insurance.starts_at).format('DD MMM YYYY')
          : '-',
        status: insurance.status,
      })
    })
  }

  return result
}

export default function Benefit({ employee }) {
  const data = employee ? transformHealthInsuranceData(employee) : []
  const [page, setPage] = useState(1)

  return (
    <div className="d-flex flex-column">
      {data?.length > 0 ? (
        <CustomTable
          fields={fields}
          data={data}
          loading={!employee}
          page={page}
          onPage={setPage}
          pageSize={DEFAULT_PAGE_LIMIT}
        />
      ) : (
        <NotFound
          title="There are no benefits yet"
          description="We'll let you know when we get any news for you"
        />
      )}
    </div>
  )
}
