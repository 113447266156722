/* eslint-disable jsx-a11y/label-has-associated-control */
import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { check } from '@core/icons/icons'
import React, { forwardRef, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'

export default forwardRef(({ label, tooltip, ...props }, ref) => {
  const [checked, setChecked] = useState(props.checked || false)
  const handleCheck = (e) => setChecked(e.target.checked)

  useUpdateEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <div>
      <label className="d-flex align-items-center">
        <div className="styledCheckbox">
          {checked && (
            <Icon
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              icon={check}
            />
          )}
        </div>
        {label && (
          <Typography className="ml-2 text_regular__14 color_black" as="p">
            {label}
          </Typography>
        )}
        {tooltip}
        <input
          data-testid="Checkbox-A7431B"
          ref={ref}
          hidden
          type="checkbox"
          {...props}
          onChange={(e) => {
            handleCheck(e)
            props.onChange(e)
          }}
        />
      </label>
    </div>
  )
})
