import { getAddress } from '@core/utils'
import React from 'react'

export const fields = ({ handleDelete, handleEdit, handleReset }) => [
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 320,
    minWidth: 320,
    width: 320,
  },
  {
    title: 'Email',
    key: 'email',
    type: 'string',
    maxWidth: 220,
    minWidth: 220,
    width: 220,
  },
  {
    title: 'Contact type',
    key: 'contactTypesNames',
    type: 'custom',
    maxWidth: 220,
    minWidth: 220,
    width: 220,
    render: ({ contactTypesNames }) =>
      (contactTypesNames.length && (
        <div className="d-flex flex-column my-3 gap-1">
          {contactTypesNames.map((item) => (
            <div>{item.name}</div>
          ))}
        </div>
      )) ||
      '',
  },
  {
    title: 'Role',
    key: 'role',
    type: 'string',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Assignments',
    key: 'custom',
    type: 'custom',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    render: ({ is_billing_contact }) =>
      is_billing_contact ? 'Billing contact' : '-',
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'actions',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    actions: [
      {
        action: handleReset,
        icon: 'blockIcon',
        actionText: 'Reset password',
        afterDisabledActionText: 'Check your email',
      },
      {
        action: handleEdit,
        icon: 'edit',
      },
      {
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]

export const getCompanyDetails = (profile) => [
  {
    label: 'Company name',
    value: profile.name,
  },
  {
    label: 'Address',
    value: getAddress(profile.legal_address),
  },
]
