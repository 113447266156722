import Typography from '@atoms/Typography/Typography'
import React, { memo, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: ${(p) => (p.open ? '#fff' : '#fff')};
`
const Body = styled.div`
  padding: 16px;
  background: #f9f9f9;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`
const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => p.open && 'rotate(90deg)'};
  color: #b2b2b2;
  margin-right: 8px;
`

const Item = memo(({ payment, month, expandedId, setPaymentId }) => {
  const isOpen = expandedId === payment.id

  const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

  return (
    <Styled key={payment.id}>
      <Header
        className="d-flex align-items-center justify-content-between"
        open={isOpen}
      >
        <div
          className="col-4"
          role="button"
          aria-hidden
          onClick={handleToggle(payment.id)}
        >
          <StyledIcon open={isOpen} />
          <Typography
            as="span"
            className={`${isOpen ? 'heading_semibold__18' : 'text_medium__14'}`}
          >
            {payment.user.full_name}
          </Typography>
        </div>
        <div className="col-3">
          <Typography as="span" className="text_regular-normal__14">
            {payment.company_name}
          </Typography>
        </div>
        <div className="col-2">
          <Typography as="span" className="text_regular-normal__14">
            {month}
          </Typography>
        </div>
        <div className="col-3">
          <Typography as="span" className="text_regular-normal__14">
            {payment.comment}
          </Typography>
        </div>
      </Header>
      <Body open={isOpen}>
        <div style={{ background: 'white', padding: 16, borderRadius: 8 }}>
          {payment.comment}
        </div>
      </Body>
    </Styled>
  )
})

const MyAccordion = ({ payments, isLoading, month }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  return payments.map((v) => {
    return (
      <Item
        key={v.id}
        isLoading={isLoading}
        payment={v}
        month={month}
        expandedId={expandedItemId}
        setPaymentId={setExpandedItemId}
      />
    )
  })
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
