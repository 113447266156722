/* eslint-disable react/button-has-type */
import './style.scss'

import classNames from 'classnames'
import React from 'react'

const Button = React.forwardRef(
  (
    {
      children,
      priority = 'primary',
      size = 'large',
      loading = false,
      active,
      ...rest
    },
    ref
  ) => (
    <button
      data-testid="Button-3D8DC3"
      {...rest}
      ref={ref}
      className={classNames([
        'button',
        `button__${priority}`,
        `button__${size} button__${active ? 'active' : ''}`,
        rest.className,
      ])}
    >
      <span className="label">{loading ? 'loading...' : children}</span>
    </button>
  )
)

export default Button
