import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { TEMPLATE_TYPES } from '@core/constants'
import { client } from '@core/hellosign-client'
import { useToast } from '@core/hooks/useErrorNotification'
import { createContractNote } from '@services/contract.service'
import {
  createTemplateEmbedded,
  getMergeFields,
} from '@services/document-sign.service'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

const StyledModal = styled(Modal)`
  .modal-header {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
    width: 430px;
  }
`

const formId = v4()

export const NoteAddModal = ({ contractId, onClose }) => {
  const { successAlert } = useToast()
  const queryClient = useQueryClient()
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const addNoteMutation = useMutation({
    mutationFn: ({ id, payload }) => createContractNote(id, payload),
    onSuccess: () => {
      successAlert('New note has been added!')
      queryClient.refetchQueries('notes')
      onClose()
    },
  })

  const onSubmit = ({ text }) => {
    addNoteMutation.mutate({
      id: contractId,
      payload: {
        text,
      },
    })
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography as="h4" className="heading_semibold__24">
          New note
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <TextArea
            className="mb-3"
            placeholder="Write your note here..."
            {...register('text')}
          />
          {errors.text && (
            <Typography className="text_regular__14 color_red">
              {errors.text.message}
            </Typography>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="note-add-modal.component-3A469C"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="note-add-modal.component-E98BF5"
          size="small"
          priority="primary"
          type="submit"
          form={formId}
          loading={addNoteMutation.isLoading}
          disabled={addNoteMutation.isLoading}
        >
          Add
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
