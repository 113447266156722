import { useApp } from '@core/context'
import { Box, Button, MenuItem, TextField } from '@mui/material'
import { ProfileMyDTO } from '@remoteam-front/types/legacy'
import { AsyncButton } from '@remoteam-front/ui'
import { EditRegularIcon } from '@remoteam-front/ui/icons'
import { patchProfile } from '@services/profile.service'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'

export const BillingAddressSubTub = () => {
  const isDisabled = useBoolean(true)
  const { profile, refetchPartnerProfile, countries } = useApp()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ProfileMyDTO>({
    defaultValues: {
      billing_address: {
        living_country: profile?.billing_address?.living_country?.id,
        street_address: profile?.billing_address?.street_address,
        address_line: profile?.billing_address?.address_line,
        city: profile?.billing_address?.city,
        state: profile?.billing_address?.state,
        postal_code: profile?.billing_address?.postal_code,
      },
    },
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: patchProfile,
    onSuccess: () => {
      refetchPartnerProfile()
      isDisabled.setTrue()
    },
  })

  const handleCancel = () => {
    isDisabled.setTrue()
    reset({
      billing_address: {
        living_country: profile?.billing_address?.living_country?.id,
        street_address: profile?.billing_address?.street_address,
        address_line: profile?.billing_address?.address_line,
        city: profile?.billing_address?.city,
        state: profile?.billing_address?.state,
        postal_code: profile?.billing_address?.postal_code,
      },
    })
  }

  const submit: SubmitHandler<ProfileMyDTO> = (formValues) => {
    mutate(formValues)
  }

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={3}
      width={640}
      onSubmit={handleSubmit(submit)}
    >
      {countries?.length && (
        <Controller
          control={control}
          name="billing_address.living_country"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              select
              {...field}
              disabled
              data-testid="billing-address.component-4399C3"
              required
              label="Country"
              error={!!errors?.billing_address?.living_country}
            >
              {(countries as any[]).map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}

      <Controller
        control={control}
        name="billing_address.street_address"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            disabled={isDisabled.value || isLoading}
            data-testid="billing-address.component-AD596D"
            {...field}
            fullWidth
            placeholder="Enter address"
            label="Address line 1"
            required
            error={!!errors.billing_address?.address_line}
          />
        )}
      />

      <Controller
        control={control}
        name="billing_address.address_line"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            disabled={isDisabled.value || isLoading}
            data-testid="billing-address.component-9D75CB"
            {...field}
            fullWidth
            placeholder="Enter address complement"
            label="Address line 2"
            required
            error={!!errors.billing_address?.street_address}
          />
        )}
      />

      <Controller
        control={control}
        name="billing_address.city"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            disabled={isDisabled.value || isLoading}
            data-testid="billing-address.component-0A2917"
            {...field}
            fullWidth
            placeholder="Enter city"
            label="City"
            required
            error={!!errors.billing_address?.city}
          />
        )}
      />

      <Controller
        control={control}
        name="billing_address.state"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            disabled={isDisabled.value || isLoading}
            data-testid="billing-address.component-BA71C3"
            required
            {...field}
            fullWidth
            placeholder="Enter city"
            label="State"
            error={!!errors.billing_address?.state}
          />
        )}
      />

      <Controller
        control={control}
        name="billing_address.postal_code"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            disabled={isDisabled.value || isLoading}
            data-testid="billing-address.component-3AB75A"
            {...field}
            fullWidth
            placeholder="Enter postal code"
            label="Postal code"
            required
            error={!!errors.billing_address?.postal_code}
          />
        )}
      />

      <Button
        data-testid="billing-address.component-F3B916"
        sx={{ alignSelf: 'flex-start' }}
        hidden={!isDisabled.value}
        startIcon={<EditRegularIcon />}
        variant="outlined"
        color="secondary"
        onClick={isDisabled.setFalse}
      >
        Edit
      </Button>

      <Box
        alignSelf="flex-end"
        display={isDisabled.value ? 'none' : 'flex'}
        gap={1}
      >
        <Button
          data-testid="billing-address.component-8A7721"
          variant="outlined"
          color="secondary"
          disabled={isLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <AsyncButton
          data-testid="billing-address.component-591CDE"
          isFetching={isLoading}
          variant="contained"
          type="submit"
        >
          Save
        </AsyncButton>
      </Box>
    </Box>
  )
}
