import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { infoIcon } from '@core/icons/icons'
import React, { ReactNode } from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const StyledAlertText = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 13px;
  color: #121212;
`

export const InfoMessage = ({ message }) => {
  return (
    <StyledAlert>
      <StyledAlertText>
        <div className="mr-2">
          <Icon icon={infoIcon} fill="#878787" />
        </div>
        <Typography className="">{message}</Typography>
      </StyledAlertText>
    </StyledAlert>
  )
}
