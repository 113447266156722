import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import { deleteIcon, downloadCloud } from '@core/icons/icons'
import UploadPaylipsModal from '@pages/employeePayslips/upload-paylips-modal'
import {
  addContractDocuments,
  deleteDocumentsById,
} from '@services/document.service'
import classNames from 'classnames'
import React from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;

  .dropdown-menu {
    background: #fff;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
  }
  .dropdown-item {
    font-weight: 500;
    font-size: 13px;
  }
`

const PayslipsEmployeeActions = ({ item, refetch }) => {
  const { successAlert } = useToast()
  const modalOpen = useBoolean(false)

  const confirmDelete = useBoolean(false)
  const uploadPayslipRequest = useMutation({
    mutationFn: (payload) => addContractDocuments(payload),
    onSuccess: () => {
      modalOpen.setFalse()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const deleteDocument = useMutation({
    mutationFn: (payload) =>
      deleteDocumentsById(payload.id, payload.contractId),
    onSuccess: () => {
      refetch()
    },
  })

  const onDownloadFile = () => {
    window.open(item.original.file, '_blank')
  }
  const onDeleteFile = () => {
    deleteDocument.mutate({
      id: item.original.id,
      contractId: item.original.contract,
    })
  }
  return (
    <Container>
      {item.original.file ? (
        <div className="d-flex align-items-center">
          <Button
            data-testid="payslips-employee-actions.component-FF4EF2"
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            className="px-2 pl-0 mr-2"
            onClick={onDownloadFile}
          >
            <Icon fill="none" icon={downloadCloud} />
          </Button>
          <Button
            data-testid="payslips-employee-actions.component-1DE8E1"
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            className="px-2 pl-0"
            onClick={confirmDelete.setTrue}
          >
            <Icon fill="none" icon={deleteIcon} />
          </Button>
        </div>
      ) : (
        <Button
          data-testid="payslips-employee-actions.component-6A2E21"
          priority="secondary"
          style={{ height: '32px' }}
          size="small"
          className="px-2 pl-0 mr-2"
          onClick={modalOpen.setTrue}
        >
          <Typography className={classNames('text_light__12')}>
            Upload
          </Typography>
        </Button>
      )}
      {modalOpen.value && (
        <UploadPaylipsModal
          title="Upload payslip"
          employee={item.original}
          loading={uploadPayslipRequest.isLoading}
          onClose={modalOpen.setFalse}
          onSubmit={(formData) => uploadPayslipRequest.mutate(formData)}
        />
      )}
      {confirmDelete.value && (
        <Confirm
          title="Delete payslip"
          yesButtonProps={{
            text: 'Delete',
          }}
          cancelButtonProps={{
            text: 'Cancel',
          }}
          isLoading={deleteDocument.isLoading}
          onCancel={confirmDelete.setFalse}
          onYes={onDeleteFile}
        >
          <Typography>
            Are you sure you want to delete this payslip? The document will no
            longer be accesible to the employee. This action cannot be undone.
          </Typography>
        </Confirm>
      )}
    </Container>
  )
}

export default PayslipsEmployeeActions
