import { styled } from '@mui/material'

const Container = styled('div')({
  height: '312px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const EmptyState = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="145"
      viewBox="0 0 121 145"
      fill="none"
    >
      <path
        d="M77.9479 104.166C78.3707 101.252 81.6639 96.7645 91.4543 102.126"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M84.9604 84.7699C85.343 82.2824 85.6188 77.1091 83.6619 76.3164C81.7049 75.5238 80.9517 77.9041 80.8198 79.1934L79.2725 88.2305"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M88.6592 87.1523C89.2071 84.9997 89.9958 78.8951 87.7853 77.6505C87.3707 77.4329 86.2688 77.1374 85.1775 77.6955"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M91.8423 85.3652C92.3321 83.4701 93.1376 80.4528 91.0208 80.2899L89.3606 80.2899"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M92.4819 82.1703C94.027 81.8437 94.4488 84.2098 94.4488 85.3642C94.6735 87.5326 94.4489 93.8307 91.454 102.123C90.9205 104.084 90.0998 108.729 91.0845 111.617"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M74.4265 92.4172C74.5918 93.6747 75.5288 98.4324 77.166 100.485C78.8033 102.537 77.9588 102.741 77.9475 104.165L77.166 118.066"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M79.3674 88.5822L64.7351 98.564M61.0096 14.1524L54.7663 8.20634C53.6154 7.11022 51.8124 7.09598 50.6697 8.20065C38.7947 19.6803 19.6953 41.2279 24.7159 49.1532C27.6638 53.8068 34.7974 65.4564 42.0725 77.3952"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M41.3847 16.3433C35.6544 10.7174 29.3566 9.87097 25.977 10.2232C25.1637 10.308 24.4871 10.8393 24.0823 11.5499L1.55133 51.1054C0.70577 52.5899 1.24255 54.5434 2.53714 55.6582C7.84615 60.2297 16.9502 71.3424 25.6967 82.7201"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M79.3672 88.4648L73.5532 88.7895C72.0581 88.873 70.7335 87.8477 70.4241 86.3826C67.9155 74.504 62.2418 52.7793 55.8363 43.8212C51.5391 37.8114 48.5699 34.058 46.7301 31.9102C45.6192 30.6133 45.5296 28.5642 46.702 27.3227C50.2564 23.5585 55.3072 18.7154 61.0094 14.152C68.0479 8.51923 76.0789 3.31264 83.5131 1.08757C84.8071 0.700268 86.1612 1.34018 86.7901 2.53556L92.2943 12.9974"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M95.0047 85.4902C96.4406 84.291 101.273 82.4488 110.035 83.292C112.201 83.5004 114.06 81.5389 113.675 79.3971C111.947 69.7721 110.577 53.2305 114.63 39.3099C118.5 26.0159 119.742 13.5734 119.962 6.36422C120.025 4.30216 117.962 2.93796 116.042 3.69075L92.295 12.9975L76.8921 19.0342C75.7341 19.488 74.9748 20.5984 74.9953 21.842C75.2209 35.5502 76.6389 63.2787 80.8813 79.4203"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M90.4966 68.1122C95.474 66.4064 105.544 62.7143 106.003 61.5918"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M90.4961 58.2095C90.4141 57.4391 93.6959 55.5848 101.572 53.3398"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M89.1836 48.9655C95.3917 47.0121 108.235 42.6597 109.941 40.877"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M87.9531 39.2266L100.096 34.1094"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M87.9531 28.7447C91.1256 26.7363 99.9646 21.9272 109.941 18.7578"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M67.6074 38.4934L73.0286 36.1914"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M62.9258 31.4236L72.6182 26.2441"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M70.564 45.8937L73.6852 44.4961"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M58.2207 75.2086L61.5988 71.3789"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M53.3604 68.2822C54.5413 66.2723 57.3481 61.9917 61.1052 60.2969"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M47.0161 58.017L54.9258 49.2168"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M41.248 50.7652L50.7231 40.8242"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M19.5986 58.5942L24.4932 53.8457"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M24.4941 63.0151L28.2467 59.7402"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M28.2461 69.1523L32.1618 66.123"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <path
        d="M33.2217 75.05L36.24 72.6758"
        stroke="#04C53A"
        strokeLinecap="round"
      />
      <ellipse
        cx="38.1067"
        cy="109.67"
        rx="25.5843"
        ry="25.5843"
        transform="rotate(30 38.1067 109.67)"
        fill="#04C53A"
      />
      <path
        d="M46.7312 100.417C47.0127 100.417 47.2828 100.304 47.4819 100.104C47.681 99.9046 47.7929 99.6335 47.7929 99.3508C47.7929 99.0681 47.681 98.797 47.4819 98.5972C47.2828 98.3973 47.0127 98.285 46.7312 98.285L28.3283 98.2793C28.0467 98.2793 27.7767 98.3916 27.5776 98.5915C27.3785 98.7914 27.2666 99.0625 27.2666 99.3451C27.2666 99.6278 27.3785 99.8989 27.5776 100.099C27.7767 100.299 28.0467 100.411 28.3283 100.411L46.7312 100.417ZM37.3853 126.69L37.5297 126.7C37.7865 126.7 38.0346 126.607 38.2279 126.437C38.4213 126.268 38.5469 126.033 38.5815 125.778L38.5914 125.634L38.59 106.186L43.8589 111.473C44.0388 111.653 44.2773 111.763 44.5308 111.782C44.7843 111.8 45.0362 111.727 45.2405 111.575L45.3609 111.473C45.5408 111.292 45.6501 111.053 45.6688 110.798C45.6875 110.543 45.6144 110.29 45.4628 110.085L45.3594 109.965L38.2857 102.865C38.1059 102.685 37.8676 102.575 37.614 102.556C37.3605 102.537 37.1085 102.61 36.904 102.762L36.7837 102.864L29.7014 109.965C29.511 110.155 29.3993 110.41 29.3888 110.68C29.3783 110.949 29.4699 111.212 29.645 111.417C29.8201 111.621 30.0658 111.751 30.3327 111.781C30.5995 111.81 30.8676 111.737 31.0831 111.577L31.202 111.473L36.4666 106.196L36.468 125.634C36.468 126.174 36.8672 126.619 37.3853 126.69Z"
        fill="white"
      />
    </svg>
  )
}
