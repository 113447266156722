import 'bootstrap-4-grid'
import 'flag-icons/sass/flag-icons.scss'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DotWave } from '@uiball/loaders'
import { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import Pages from './router/Router'

export const App = () => {
  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Suspense fallback={<DotWave />}>
          <Switch>
            <Route path="/pages" render={() => <Pages />} />
            <Route path="/">
              <Redirect to={{ pathname: `/pages/overview` }} />
            </Route>
          </Switch>
        </Suspense>
      </LocalizationProvider>
    </Router>
  )
}
