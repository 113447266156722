import React, { useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'

const getOrdering = (ordering) => {
  if (ordering === '') {
    return 'asc'
  }
  if (ordering === 'asc') {
    return 'desc'
  }

  return ''
}

export const EmployeesTableSorting = ({
  isSortable,
  order,
  onSort,
  children,
}) => {
  const [ordering, setOrdering] = useState(order || '')

  const handleSort = () => {
    setOrdering((prev) => {
      const newOrdering = getOrdering(prev)
      setOrdering(newOrdering)
      onSort(newOrdering)
    })
  }

  return (
    <div
      className="w-100 d-flex align-items-center"
      role="button"
      aria-hidden
      onClick={handleSort}
    >
      {children}
      {isSortable && (
        <div>
          {ordering === '' && (
            // <div className="d-flex flex-column">
            //   <HiChevronUp style={{ marginBottom: '-5px' }} />
            //   <HiChevronDown style={{ marginTop: '-5px' }} />
            // </div>
            <HiChevronDown opacity={0.3} />
          )}

          {ordering === 'desc' && <HiChevronUp />}
          {ordering === 'asc' && <HiChevronDown />}
        </div>
      )}
    </div>
  )
}
