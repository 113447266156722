import '../CustomersDetailPage.scss'

import { useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getContactPersonList, getLocationList, getMainList } from '../mock'

export const CustomerInfo = () => {
  const { countries, currencies } = useContext(AppContext)
  const { successAlert } = useToast()
  const { params } = useRouteMatch()
  const [company, setCompany] = useState()
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [visibleContact, setVisibleContact] = useState(false)
  const [visibleEditLocation, setVisibleEditLocation] = useState(false)
  const [editState, setEditState] = useState({
    company_name: '',
    default_currency: '',
    living_country: '',
    street_address: '',
    address_line: '',
    state: '',
    city: '',
    head_quarters: '',
    postal_code: '',
    first_name: '',
    last_name: '',
    email: '',
    title: '',
  })

  const [, patchCompany] = useAxiosCall(
    {
      url: `companies/${company?.id}/`,
      method: 'patch',
    },
    {
      manual: true,
    }
  )

  const [{ data, loading }] = useAxiosCall({
    url: `companies/${params.id}/`,
  })

  useEffect(() => {
    if (data) {
      setCompany(data)
      setEditState({
        company_name: data.name,
        default_currency: data.default_currency?.id,
        living_country: data.legal_address?.living_country?.id,
        street_address: data.legal_address?.street_address,
        address_line: data.legal_address?.address_line,
        state: data.legal_address?.state,
        city: data.legal_address?.city,
        postal_code: data.legal_address?.postal_code,
        title: data.title,
        head_quarters: data.head_quarters,
        first_name: data.owner?.profile?.first_name,
        last_name: data.owner?.profile?.last_name,
        email: data.owner?.profile?.email,
      })
    }
  }, [data])

  const onSaveEdit = () => {
    setVisibleEdit(false)
    const editData = {
      name: editState.company_name,
      title: editState.company_name,
      default_currency: editState.default_currency,
    }
    patchCompany({ data: editData }).then((res) => {
      setCompany(res.data)
      successAlert('Successfully updated!')
    })
  }
  const onSaveContact = () => {
    setVisibleContact(false)
    const editData = {
      title: editState.title,
      profile: {
        first_name: editState.first_name,
        last_name: editState.last_name,
        email: editState.email,
      },
    }
    patchCompany({ data: editData }).then((res) => {
      setCompany(res.data)
      successAlert('Successfully updated!')
    })
  }

  const onSaveLocationEdit = () => {
    setVisibleEditLocation(false)
    const locationData = {
      head_quarters: editState.head_quarters,
      legal_address: {
        living_country: editState.living_country,
        street_address: editState.street_address,
        address_line: editState.address_line,
        postal_code: editState.postal_code,
        state: editState.state,
        city: editState.city,
      },
    }
    patchCompany({ data: locationData }).then((res) => {
      setCompany(res.data)
      successAlert('Successfully updated!')
    })
  }

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }

  const onChangeDefaultCurrency = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      default_currency: option.value,
    }))
  }
  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }

  return loading ? (
    <div className="d-flex w-100 h-100 align-items-center justify-content-center">
      <DotWave size={48} speed={1} color="black" />
    </div>
  ) : (
    <>
      {company && (
        <div style={{ maxWidth: 540 }}>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Typography className="heading_semibold__20 color_black">
                Main info
              </Typography>
            </div>
            <List lists={getMainList(company)} />
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Typography className="heading_semibold__20 color_black">
                Contact person
              </Typography>
            </div>
            <List lists={getContactPersonList(company)} />
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Typography className="heading_semibold__20 color_black">
                Location
              </Typography>
            </div>
            <List lists={getLocationList(company)} />
          </div>
        </div>
      )}
      <EditModal
        visible={visibleContact}
        title="Contact person"
        onSave={onSaveContact}
        closeModal={() => setVisibleContact(false)}
      >
        <Input
          data-testid="customer-info.component-0BAA88"
          label="Title"
          onChange={onChangeEdit}
          name="title"
          styleClass="mb-4"
          value={editState?.title}
        />
        <Input
          data-testid="customer-info.component-43E04F"
          label="First name"
          onChange={onChangeEdit}
          name="first_name"
          styleClass="mb-4"
          value={editState?.first_name}
        />
        <Input
          data-testid="customer-info.component-87DDD2"
          label="Last name"
          onChange={onChangeEdit}
          name="last_name"
          styleClass="mb-4"
          value={editState?.last_name}
        />
        <Input
          data-testid="customer-info.component-12A7D2"
          label="Email"
          onChange={onChangeEdit}
          name="email"
          styleClass="mb-4"
          value={editState?.email}
        />
      </EditModal>
      <EditModal
        visible={visibleEdit}
        title="Main info"
        onSave={onSaveEdit}
        closeModal={() => setVisibleEdit(false)}
      >
        <Input
          data-testid="customer-info.component-7F7BE8"
          label="Company name"
          onChange={onChangeEdit}
          name="company_name"
          styleClass="mb-4"
          value={editState?.company_name}
        />
        <Select
          data-testid="customer-info.component-B618B4"
          options={currencies.map((currency) => ({
            value: currency.id,
            label: `${currency.sign || currency.short_code} ${currency.name}`,
          }))}
          name="default_currency"
          id="default_currency"
          onChange={onChangeDefaultCurrency}
          value={editState?.default_currency}
          label="Default currency"
        />
      </EditModal>
      <EditModal
        visible={visibleEditLocation}
        title="Location"
        onSave={onSaveLocationEdit}
        closeModal={() => setVisibleEditLocation(false)}
      >
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-1DB7F8"
            onChange={onChangeEdit}
            label="Headquarters"
            placeholder="e.g Country"
            name="head_quarters"
            value={editState?.head_quarters}
          />
        </div>
        <div className="remo-form-input">
          <Select
            data-testid="customer-info.component-81483F"
            options={countries.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
            name="living_country"
            id="living_country"
            placeholder="e.g United Kingdom"
            onChange={onChangeLivingCountry}
            value={editState?.living_country}
            label="Country"
          />
        </div>
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-660D5B"
            onChange={onChangeEdit}
            label="State"
            name="state"
            value={editState?.state}
          />
        </div>
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-E0C608"
            onChange={onChangeEdit}
            label="City"
            name="city"
            value={editState?.city}
          />
        </div>
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-3A2830"
            onChange={onChangeEdit}
            label="Street address"
            name="street_address"
            value={editState?.street_address}
          />
        </div>
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-2A08CC"
            onChange={onChangeEdit}
            label="Address line"
            name="address_line"
            value={editState?.address_line}
          />
        </div>
        <div className="remo-form-input">
          <Input
            data-testid="customer-info.component-B3233F"
            onChange={onChangeEdit}
            label="Postal code"
            name="postal_code"
            value={editState?.postal_code}
          />
        </div>
      </EditModal>
    </>
  )
}
