import Button from '@atoms/Button/Button'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { disableMFA, recoveryDisableMFA } from '@services/mfa.service'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'
import { useMutation } from 'react-query'
import styled from 'styled-components'

import { useAuth } from '../../../AuthProvider'
import { StyledInput } from './enabling-mfa-modal'

// Removes [-\s] from recovery code for MFA so that user can copy-paste it
export const cleanRecoveryCode = (recoveryCode) => {
  return recoveryCode.replace(/[\s-]/g, '')
}

export const DisablingMfaModal = ({ open, onClose, onKeyDown }) => {
  const { successAlert, failedAlert } = useToast()
  const { tokens } = useAuth()
  const { refetchProfile } = useApp()

  const [authCode, setAuthCode] = useState('')
  const [recoveryCode, setRecoveryCode] = useState('')
  const [disableMethod, setDisableMethod] = useState('OTP')
  const [inputError, setInputError] = useState('')

  const { mutate: mutateDisableMFA, isLoading: isLoadingDisableMFA } =
    useMutation('disableMFA', disableMFA, {
      onSuccess: () => {
        // eslint-disable-next-line no-use-before-define
        handleSuccessfullyDisabled()
      },
      onError: (error) => {
        const { message } = error.response.data.errors[0]
        if (message.includes('Invalid otp_code'))
          setInputError('The code you entered is incorrect, please try again')
        else failedAlert(message)
      },
    })

  const {
    mutate: mutateRecoveryDisableMFA,
    isLoading: isLoadingRecoveryDisableMFA,
  } = useMutation('recoveryDisableMFA', recoveryDisableMFA, {
    onSuccess: () => {
      // eslint-disable-next-line no-use-before-define
      handleSuccessfullyDisabled()
    },
    onError: (error) => {
      const { message } = error.response.data.errors[0]
      if (message.includes('MFA Authorization rejected'))
        setInputError(
          'The recovery code you entered is incorrect, please try again'
        )
      else failedAlert(message)
    },
  })

  const isFetching = isLoadingDisableMFA || isLoadingRecoveryDisableMFA
  const refresh_token = tokens ? tokens.refresh_token : ''

  const handleComplete = () => {
    setInputError('')

    if (disableMethod === 'OTP')
      if (authCode.length === 6)
        mutateDisableMFA({ otp: authCode, refresh_token })
      else setInputError('The code must contain 6 digits.')
    else if (disableMethod === 'Recovery')
      if (recoveryCode.length === 24)
        mutateRecoveryDisableMFA({
          recovery_code: recoveryCode,
          refresh_token,
        })
      else setInputError('The code must contain 24 digits.')
  }

  const handleClose = () => {
    setDisableMethod('OTP')
    setInputError('')
    setRecoveryCode('')
    setAuthCode('')
    onClose()
  }
  const handleSuccessfullyDisabled = () => {
    successAlert('2FA successfully disabled')
    refetchProfile()
    handleClose()
  }

  const renderContent = () => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    let content = <></>

    switch (disableMethod) {
      case 'OTP':
        content = (
          <>
            <Typography className="heading_semibold__24 mb-3">
              Verification required
            </Typography>
            <Typography className="text_light__14 color_black_light mb-3">
              Enter the code from your authenticator app
            </Typography>
            <div className="d-flex flex-column gap-3 align-items-center">
              <div className="d-flex flex-column gap-3">
                <OtpInput
                  value={authCode}
                  onChange={(value) => {
                    setInputError('')
                    setAuthCode(value)
                  }}
                  numInputs={6}
                  renderInput={(props) => (
                    <StyledInput
                      {...props}
                      onKeyDown={(e) => {
                        if (!(e.ctrlKey && e.key === 'v')) {
                          props.onKeyDown(e)
                        }
                      }}
                    />
                  )}
                  inputType="number"
                  containerStyle={{ display: 'flex', columnGap: '16px' }}
                  shouldAutoFocus
                />

                {inputError && (
                  <Typography className="color_red align-self-center">
                    {inputError}
                  </Typography>
                )}
              </div>
              <Button
                data-testid="disabling-mfa-modal-55ADDF"
                size="small"
                priority="secondary"
                onClick={() => {
                  setInputError('')
                  setDisableMethod('Recovery')
                }}
                style={{ width: 205 }}
              >
                Can&apos;t enter the code?
              </Button>
            </div>
          </>
        )
        break

      case 'Recovery':
        content = (
          <>
            <Typography className="text_light__14 color_black_light mb-3">
              Please enter your recovery code
            </Typography>
            <div className="d-flex flex-column gap-3 align-items-center">
              <div className="d-flex flex-column gap-3 align-items-center">
                <StyledTextField
                  autoFocus
                  value={recoveryCode}
                  onChange={(e) => {
                    setInputError('')
                    setRecoveryCode(cleanRecoveryCode(e.target.value))
                  }}
                />
                {inputError && (
                  <Typography className="color_red align-self-center">
                    {inputError}
                  </Typography>
                )}
              </div>
              <Button
                data-testid="disabling-mfa-modal-0D20BA"
                size="small"
                priority="secondary"
                onClick={() => setDisableMethod('Support')}
                style={{ width: 205 }}
              >
                Lost your recovery code?
              </Button>
            </div>
          </>
        )
        break

      case 'Support':
        content = (
          <>
            <div className="d-flex flex-column align-items-center">
              <Typography className="heading_semibold__24 mb-3">
                Contact the support service
              </Typography>
              <SeventyContainer className="width-80%">
                <Typography className="text_light__14 color_black_light mb-3">
                  In order to disable 2FA, you need to write to the support
                  service from your work email. After confirmation by the
                  administrator, 2FA will be disabled.
                </Typography>
              </SeventyContainer>
            </div>
            <div className="d-flex flex-column align-items-center">
              <Typography className="text_light__13 color_black_light mb-3">
                Please send an email to
              </Typography>
              <a
                data-testid="disabling-mfa-modal-B68229"
                href="emailto:access.recovery@remofirst.com"
              >
                access.recovery@remofirst.com
              </a>
            </div>
          </>
        )
        break

      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        content = <></>
    }

    return content
  }

  return open ? (
    <ModalSide
      title="Disable Two-factor authentication (2FA)"
      okText={disableMethod === 'Support' ? '' : 'Disable 2FA'}
      onClose={handleClose}
      onOk={handleComplete}
      okButtonProps={{ loading: isFetching, id: 'next-step' }}
      onKeyDown={onKeyDown}
    >
      <div className="d-flex flex-column align-items-center m-auto">
        {renderContent()}
      </div>
    </ModalSide>
  ) : null
}

const SeventyContainer = styled.div`
  width: 70%;
  text-align: center;
`

const StyledTextField = styled.input`
  border-radius: 8px;
  width: 300px;
  height: 3rem;
  padding: 0 6px 0 6px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  :focus {
    outline: none;
    border-color: #04c53a;
  }
`
