import { ProbationPeriodTable } from '@components/probation-period-table/probation-period-table.component'
import { Alert, Box, Button } from '@mui/material'
import { ProbationResponse } from '@remoteam-front/types/legacy'

type Props = {
  data: ProbationResponse
  onNext(): void
}

export const ProbationPeriod = ({ data, onNext }: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Alert severity="info">
        If you spot any mistake in the table below, please reach out to your
        Partner Relationship manager at Remofirst team
      </Alert>
      <ProbationPeriodTable data={data} />

      <Box alignSelf="center">
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
