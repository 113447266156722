import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { omitBy } from 'lodash'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { v4 } from 'uuid'

const formId = v4()

export const PaidTimeOffModal = ({
  title,
  paidLeave,
  loading,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...paidLeave },
  })

  const handleCreateTimeOff = (formValues) => {
    onSubmit({ paid_leave: omitBy(formValues, (value) => value === '') })
  }

  return (
    <Modal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>

      <Modal.Body className="px-4 pb-4">
        <form
          className="d-flex flex-column gap-3"
          id={formId}
          onSubmit={handleSubmit(handleCreateTimeOff)}
        >
          <div>
            <Input
              data-testid="PaidTimeOffModal-7E166A"
              {...register('annual_days_by_contract', {
                required: 'Field is required',
                pattern: {
                  value: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                  message: 'Please enter valid number',
                },
              })}
              type="text"
              label="Annual paid leaves by contract"
              placeholder="Enter number of days"
            />
            {errors.annual_days_by_contract && (
              <Typography className="text_regular__14 color_red">
                {errors.annual_days_by_contract.message}
              </Typography>
            )}
          </div>

          <div>
            <Input
              data-testid="PaidTimeOffModal-ACE692"
              {...register('accrued_days', {
                required: 'Field is required',
                pattern: {
                  value: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                  message: 'Please enter valid number',
                },
              })}
              type="text"
              label="Accrued paid time off days"
              placeholder="Enter number of days"
            />
            {errors.accrued_days && (
              <Typography className="text_regular__14 color_red">
                {errors.accrued_days.message}
              </Typography>
            )}
          </div>

          <div>
            <Input
              data-testid="PaidTimeOffModal-8D502A"
              {...register('used_days', {
                required: 'Field is required',
                pattern: {
                  value: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                  message: 'Please enter valid number',
                },
              })}
              type="text"
              label="Used paid time off (vacation) days"
              placeholder="Enter number of days"
            />
            {errors.used_days && (
              <Typography className="text_regular__14 color_red">
                {errors.used_days.message}
              </Typography>
            )}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="PaidTimeOffModal-1EC0C3"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="PaidTimeOffModal-252892"
          form={formId}
          type="submit"
          size="small"
          disabled={loading}
          priority="primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
