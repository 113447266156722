import { useApp } from '@core/context'
import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  createPartnerBankAccounts,
  getPartnerBankAccounts,
  updatePartnerBankAccounts,
} from '@remoteam-front/services'
import {
  BankAccount,
  BankAccountDTO,
  BankAccountType,
} from '@remoteam-front/types/legacy'
import {
  AsyncButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  theme,
} from '@remoteam-front/ui'
import { Spinner } from '@remoteam-front/ui/components/spinner/spinner'
import { keyBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { v4 } from 'uuid'

const formId = v4()

type Props = {
  bankAccount?: BankAccount
  onClose(): void
  onDelete?(bankAccount: BankAccount): void
}

export const BankAccountDrawer = ({
  bankAccount,
  onDelete,
  onClose,
}: Props) => {
  const { currencies, countries, profile } = useApp()

  const isEdit = !!bankAccount

  const [currenciesHashMap, setCurrenciesHashMap] = useState(
    keyBy(currencies, 'id')
  )
  const [countriesHashMap, setCountriesHashMap] = useState(
    keyBy(countries, 'id')
  )

  useEffect(() => {
    setCurrenciesHashMap(keyBy(currencies, 'id'))
    setCountriesHashMap(keyBy(countries, 'id'))
  }, [currencies, countries])

  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: createPartnerBankAccounts,
    onSuccess: () => {
      queryClient.refetchQueries(getPartnerBankAccounts.key)
      onClose()
    },
  })

  const updateMutation = useMutation({
    mutationFn: updatePartnerBankAccounts,
    onSuccess: () => {
      queryClient.refetchQueries(getPartnerBankAccounts.key)
      onClose()
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BankAccountDTO>({
    defaultValues: {
      ...bankAccount,
      account_type: BankAccountType.local,
      currency: bankAccount?.currency.id,
      address: {
        ...bankAccount?.address,
        living_country: bankAccount?.address.living_country.id,
      },
    },
  })

  const submit: SubmitHandler<BankAccountDTO> = (payload) => {
    if (isEdit) {
      updateMutation.mutate({
        partnerId: profile!.id,
        bank_account_pk: bankAccount.id,
        payload,
      })
    } else {
      createMutation.mutate({
        partnerId: profile!.id,
        payload,
      })
    }
  }

  return (
    <Drawer anchor="right" open>
      <DrawerHeader
        title={isEdit ? 'Manage bank account' : 'Add bank account'}
        onClose={onClose}
      />
      <DrawerBody>
        <Spinner
          isFetching={updateMutation.isLoading || createMutation.isLoading}
        >
          <Box
            component="form"
            id={formId}
            display="flex"
            flexDirection="column"
            gap={3}
            onSubmit={handleSubmit(submit)}
          >
            <Typography variant="h3_strong">Bank account details</Typography>

            {currencies?.length && (
              <Controller
                control={control}
                name="currency"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel required>Currency</InputLabel>
                    <Select
                      data-testid="bank-account-drawer.component-978449"
                      {...field}
                      required
                      displayEmpty
                      renderValue={(value) =>
                        value ? (
                          currenciesHashMap[value].short_code
                        ) : (
                          <span style={{ color: theme.palette.secondary[400] }}>
                            Select currency
                          </span>
                        )
                      }
                    >
                      {(currencies as any[]).map(({ id, short_code }) => (
                        <MenuItem key={id} value={id}>
                          {short_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}

            <Controller
              control={control}
              name="account_holder"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-157BC8"
                  {...field}
                  fullWidth
                  placeholder="Enter account holder"
                  label="Bank account holder"
                  required
                  error={!!errors.account_holder}
                />
              )}
            />

            <Controller
              control={control}
              name="swift_bic"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-5C7C4D"
                  {...field}
                  required
                  fullWidth
                  label="SWIFT/BIC"
                  placeholder="Enter SWIFT/BIC"
                />
              )}
            />

            <Controller
              control={control}
              name="iban"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-904A1F"
                  {...field}
                  fullWidth
                  placeholder="Enter IBAN"
                  label="IBAN"
                  required
                  error={!!errors.iban}
                />
              )}
            />

            <Typography
              variant="h3_strong"
              data-testid="bank-account-drawer.component-7183DB"
            >
              Bank address
            </Typography>

            <Controller
              control={control}
              name="address.street_address"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-8B4C59"
                  {...field}
                  required
                  fullWidth
                  label="Address line 1"
                  placeholder="Enter address"
                />
              )}
            />

            <Controller
              control={control}
              name="address.address_line"
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-94EA37"
                  {...field}
                  fullWidth
                  label="Address line 2"
                  placeholder="Enter address complement"
                />
              )}
            />

            <Controller
              control={control}
              name="address.city"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-AF350C"
                  {...field}
                  required
                  fullWidth
                  label="City/Town"
                  placeholder="Enter city"
                />
              )}
            />

            <Controller
              control={control}
              name="address.state"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-E14813"
                  {...field}
                  required
                  fullWidth
                  label="State"
                  placeholder="Enter state"
                />
              )}
            />

            {countries?.length && (
              <Controller
                control={control}
                name="address.living_country"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel required>Country</InputLabel>
                    <Select
                      data-testid="bank-account-drawer.component-D0B349"
                      {...field}
                      required
                      displayEmpty
                      renderValue={(value) =>
                        value ? (
                          countriesHashMap[value].name
                        ) : (
                          <span style={{ color: theme.palette.secondary[400] }}>
                            Select country
                          </span>
                        )
                      }
                    >
                      {countries.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}

            <Controller
              control={control}
              name="address.postal_code"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="bank-account-drawer.component-39EC4D"
                  {...field}
                  required
                  fullWidth
                  label="Postal code"
                  placeholder="Enter postal code"
                />
              )}
            />
          </Box>
        </Spinner>
      </DrawerBody>
      <DrawerFooter
        primaryActions={[
          ...[
            isEdit && onDelete ? (
              <Button
                data-testid="bank-account-drawer.component-035109"
                key={1}
                color="error"
                variant="contained"
                disabled={updateMutation.isLoading || createMutation.isLoading}
                onClick={() => onDelete(bankAccount)}
              >
                Remove
              </Button>
            ) : (
              []
            ),
          ],
          <AsyncButton
            data-testid="bank-account-drawer.component-7EFF61"
            key={2}
            isFetching={updateMutation.isLoading || createMutation.isLoading}
            type="submit"
            form={formId}
            variant="contained"
          >
            Save
          </AsyncButton>,
        ]}
        secondaryActions={[
          <Button
            data-testid="bank-account-drawer.component-98BD74"
            key={1}
            disabled={updateMutation.isLoading || createMutation.isLoading}
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>,
        ]}
      ></DrawerFooter>
    </Drawer>
  )
}
