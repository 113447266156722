import React from 'react'

const shortHexToRgb = (hex: React.CSSProperties['color']) => {
  if (!hex) return
  const r = hex.slice(1, 2)
  const g = hex.slice(2, 3)
  const b = hex.slice(3, 4)

  const rr = parseInt(r + r, 16)
  const gg = parseInt(g + g, 16)
  const bb = parseInt(b + b, 16)

  return `${rr} ${gg} ${bb}`
}

export const hexToRgb = (hex: React.CSSProperties['color']) => {
  if (!hex) return
  if (hex.length === 4) {
    return shortHexToRgb(hex)
  }
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  // return {r, g, b}
  return `${r} ${g} ${b}`
}
