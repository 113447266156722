import '../onboarding-checklist.styles.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { externalLinkIcon } from '@core/icons/icons'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
    .modal-body {
      padding: 16px 24px;
    }
  }
`

export default ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20 color_black">
          Provide employer costs information
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="text_regular__14">
          As part of our collaborative efforts, we request our Local Partners to
          contribute to our Employment Cost Calculators. Your input is crucial
          in accurately estimating employment costs, ensuring transparency and
          efficiency in our operations.
        </Typography>

        <Typography className="mt-4 text_regular__14">
          To participate, you will need to access the file and update the
          provided Google Spreadsheet with the following details:
        </Typography>

        <ul className="text_regular__14 mt-4">
          <li>
            <b>Cost Names:</b> Provide descriptive names for each cost
            component.
          </li>
          <li>
            <b>Brief and Detailed Descriptions:</b> Offer clear explanations of
            each cost element for better understanding.
          </li>
          <li>
            <b>Formulas:</b> Include formulas for calculating costs, along with
            examples of simulations to illustrate calculations effectively.
          </li>
        </ul>
        <Typography className="mt-4 text_regular__14">
          Your contribution will streamline our cost estimation processes,
          enabling informed decision-making and fostering a collaborative
          partnership.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Click Access the file to navigate to the Cost Calculator spreadsheet.
        </Typography>

        <Typography className="mt-4 text_regular__14">
          Refer to{' '}
          <a
            data-testid="partner-profile.component-BF8692"
            href="https://remofirst.atlassian.net/wiki/external/YjMyOTJlYjFmODZhNGY1ZDhkNTYzNWMwMDU3OGJlODM"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            our guides
          </a>{' '}
          for more information.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            priority="secondary"
            size="small"
            className="mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            priority="primary"
            onClick={onSave}
          >
            Access the file
            <div className="externalIcon ml-2">
              <Icon icon={externalLinkIcon} viewBox="0 0 20 20" width="12px" />
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
