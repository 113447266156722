import styled from 'styled-components'

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`
const Comments = styled.div``
const Comment = styled.div`
  border-bottom: 1px solid rgb(243, 243, 243);
  padding: 0;
  margin-bottom: 1rem;

  &:last-of-type {
    border: none;
  }
`
const Header = styled.div``
const Body = styled.div``

export const Styled = {
  Comments,
  Comment,
  Header,
  Body,
  TopBar,
}
