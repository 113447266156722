import { Api } from '@api/Api'
import { PartnerContactTypeList } from '@remoteam-front/types/legacy'
import { PaginationParams } from '@remoteam-front/types/legacy/pagination.types'
import { generateService } from '@remoteam-front/utils'

export const getPartnerContactTypes = generateService(
  async (params: PaginationParams) => {
    const { data } = await Api.get<PartnerContactTypeList>(
      '/partner-contact-types/',
      { params }
    )

    return data
  }
)
