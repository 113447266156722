import { RequiredDocumentModal } from '@atoms/documents/required-document-modal'
import usePagination from '@core/hooks/usePagination'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  EmployeeDocument,
  EmployeeDocumentDto,
  EmployeeDocuments,
} from '@remoteam-front/types/legacy'
import { AsyncButton, Pagination } from '@remoteam-front/ui'
import { Spinner } from '@remoteam-front/ui/components/spinner/spinner'
import {
  AddFilledIcon,
  DeleteRegularIcon,
  EditRegularIcon,
  UnknowAttachment,
} from '@remoteam-front/ui/icons'
import { cyan } from '@remoteam-front/ui/theme/colors'
import {
  createEmployeeDocument,
  deleteEmployeeDocument,
  getEmployeeDocuments,
  updateEmployeeDocument,
} from '@services/document.service'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { EmptyState } from './empty-state.component'
import { Styled } from './required-documents.styles'
import { RequiredDocumentsConfirm } from './required-documents-confirm.component'

type Props = {
  isLoading: boolean
  onSave(): void
}

export const RequiredDocuments = ({ isLoading, onSave }: Props) => {
  const { limit, page, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const createModal = useBoolean(false)

  const [documentToUpdate, setDocumentToUpdate] =
    useState<EmployeeDocument | null>(null)

  const [documentToDelete, setDocumentToDelete] =
    useState<EmployeeDocument | null>(null)

  const documentsQuery = useQuery<EmployeeDocuments>(
    [getEmployeeDocuments.key, limit, page],
    {
      queryFn: () => getEmployeeDocuments.fetch(limit, page),
      keepPreviousData: true,
    }
  )
  const createMutation = useMutation({
    mutationFn: createEmployeeDocument,
    onSuccess: () => {
      documentsQuery.refetch()
      createModal.setFalse()
    },
  })
  const deleteMutate = useMutation({
    mutationFn: (id: number) => deleteEmployeeDocument(id),
    onSuccess: () => {
      setDocumentToDelete(null)

      if (page !== 1 && documentsQuery.data?.results?.length === 1) {
        // change page state will call query it self w/o refetch
        setPage((prev) => prev - 1)
        return
      }
      documentsQuery.refetch()
    },
  })
  const updateMutation = useMutation({
    mutationFn: (body: EmployeeDocumentDto) =>
      updateEmployeeDocument(documentToUpdate!.id, body),
    onSuccess: () => {
      documentsQuery.refetch()
      setDocumentToUpdate(null)
    },
  })

  const handleDelete = () => deleteMutate.mutate(documentToDelete!.id)

  if (documentsQuery.isLoading) {
    return <DotWave />
  }

  if (documentsQuery.data?.results.length === 0) {
    return (
      <>
        <Styled.TableBody>
          <EmptyState />
          <Button
            data-testid="required-documents.component-C0C07A"
            variant="contained"
            startIcon={<AddFilledIcon />}
            onClick={createModal.setTrue}
          >
            Add document
          </Button>
          <Alert severity="info">
            <Typography variant="textSmall2">
              Please specify the list of documents that the employees must
              provide. We will request these documents during the onboarding of
              new employees in your country.
            </Typography>
          </Alert>
        </Styled.TableBody>

        {createModal.value && (
          <RequiredDocumentModal
            title="Add document"
            document={null}
            submitTitle="Add document"
            onClose={createModal.setFalse}
            loading={createMutation.isLoading}
            onSubmit={createMutation.mutate}
          />
        )}
      </>
    )
  }

  if (documentsQuery.data) {
    return (
      <Spinner isFetching={documentsQuery.isFetching}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box alignSelf="flex-end">
            <Button
              variant="contained"
              startIcon={<AddFilledIcon />}
              onClick={createModal.setTrue}
            >
              Add document
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document name</TableCell>
                <TableCell>Requirement</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>File</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentsQuery.data.results.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>
                    {mapEnum(capitalizeFirstLetter(document.requirement))}
                  </TableCell>
                  <TableCell>{document.description}</TableCell>
                  <TableCell>
                    {document.attachments.map(
                      ({ filename, file_uuid, file }) => (
                        <Box key={file_uuid} gap={1}>
                          <UnknowAttachment />
                          <Link
                            target="_blank"
                            color={cyan[500]}
                            href={file}
                            underline="none"
                          >
                            {filename}
                          </Link>
                        </Box>
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => setDocumentToUpdate(document)}
                      >
                        <EditRegularIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => setDocumentToDelete(document)}
                      >
                        <DeleteRegularIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box alignSelf="end">
            <Pagination
              page={page}
              total={documentsQuery.data.count}
              pageSize={limit}
              onChange={(_, page) => setPage(page)}
            />
          </Box>

          <Box alignSelf="center">
            <AsyncButton
              isFetching={isLoading}
              variant="contained"
              onClick={onSave}
            >
              Save
            </AsyncButton>
          </Box>

          {createModal.value && (
            <RequiredDocumentModal
              title="Add document"
              document={null}
              submitTitle="Add document"
              onClose={createModal.setFalse}
              loading={createMutation.isLoading}
              onSubmit={createMutation.mutate}
            />
          )}

          {!!documentToUpdate && (
            <RequiredDocumentModal
              title={documentToUpdate.name}
              document={documentToUpdate}
              submitTitle="Save"
              onClose={() => setDocumentToUpdate(null)}
              loading={updateMutation.isLoading}
              onSubmit={updateMutation.mutate}
            />
          )}

          {!!documentToDelete && (
            <RequiredDocumentsConfirm
              document={documentToDelete}
              loading={deleteMutate.isLoading}
              onDelete={handleDelete}
              onClose={() => setDocumentToDelete(null)}
            />
          )}
        </Box>
      </Spinner>
    )
  }

  return null
}
