import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchTeams = async () => {
  const { data } = await Api.get(`partners/team/`)
  return data
}

export const getPartnerUsersByOwner = generateService(async (id, params) => {
  const { data } = await Api.get(`/partner-users-by-partner-owner/${id}/`, {
    params,
  })
  return data
})

export const fetchAddPartnerTeam = async (body) => {
  const { data } = await Api.post(`partners/team/`, body)
  return data
}

export const fetchDeletePartnerTeam = async (managerId) => {
  const { data } = await Api.delete(`partners/team/${managerId}/`)
  return data
}

export const getPartnerContactTypes = generateService(async (params) => {
  const { data } = await Api.get(`partner-contact-types/`, { params })

  return data
})

export const updatePartnerUsers = async (uuid, body) => {
  const { data } = await Api.patch(`/partner-users/${uuid}/`, body)

  return data
}
