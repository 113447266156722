import Button from '@atoms/Button/Button'
import classNames from 'classnames'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import css from '../onboarding.module.scss'
import { TermsText } from './terms-text.component'

export const TermsComponent = ({ onNext }) => {
  const { setValue } = useFormContext()
  const ref = useRef(null)
  const [scrolled, setScrolled] = useState(false)

  useLayoutEffect(() => {
    if (ref && ref.current.scrollHeight <= ref.current.clientHeight) {
      setScrolled(true)
    }
  }, [])

  const handleScroll = () => {
    if (ref.current) {
      const spare = 50
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      if (scrollTop + clientHeight >= scrollHeight - spare) {
        setScrolled(true)
      }
    }
  }

  const handleAgree = () => {
    setValue('terms_of_services.terms_of_services_agreed', true)
    onNext()
  }

  return (
    <div className={css.terms}>
      <div className={css.header}>
        <p className="heading_semibold__24">Terms & Conditions</p>
      </div>
      <div ref={ref} className={css.body} onScroll={handleScroll}>
        <TermsText />
      </div>
      <div className={classNames('text_medium__14', [css.footer])}>
        <div>
          Any questions? Contact us at{' '}
          <span>
            <a
              data-testid="terms.component-0255FD"
              href="mailto:help@remofirst.com"
              target="_blank"
              rel="noreferrer"
            >
              help@remofirst.com
            </a>
          </span>
        </div>
        <Button
          data-testid="terms.component-C3DB8C"
          priority="primary"
          size="small"
          disabled={!scrolled}
          onClick={handleAgree}
        >
          Agree
        </Button>
      </div>
    </div>
  )
}
