import moment from 'moment'
import { v4 } from 'uuid'

export const StringToBoolean = (value) => JSON.parse(value)
export const setCurrencyChangeEvent = (changeFn) => (e) => {
  changeFn({
    ...e,
    target: { ...e.target, value: e.target.value.replace(/ /g, '') },
  })
}

export const getDate = (date) => {
  if (moment(date).isSame(new Date(), 'week')) {
    return moment(date).format('dddd, HH:mm')
  }
  return moment(date).format('DD MMM YYYY, HH:mm')
}

export const numberWithSpaces = (x) => {
  const parts = Number(x).toFixed(2).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const SplitNumber = (num) =>
  String(Number(num).toFixed(2)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

export const capitalizeFirstLetter = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

export const mapEnum = (value) => value.split('_').join(' ')

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const getAddress = (address) => {
  const addressList = [
    address?.living_country?.name || '',
    address?.state || '',
    address?.city || '',
    address?.street_address || '',
    address?.address_line || '',
    address?.postal_code || '',
  ].filter((v) => !!v)

  let result = ''
  addressList.forEach((item, index) => {
    if (item) {
      result += index ? `, ${item}` : item
    }
  })

  return result
}

export const SplitAmount = (num) =>
  String(Number(num)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

/**
 *
 * @param currency object
 * @returns currency string
 */
export const getCurrency = (currency = {}) => {
  const { sign, short_code } = currency
  return sign || short_code || ''
}

export const generateService = (fetch) => ({
  key: v4(),
  fetch,
})

export const formatDate = (date) => moment(date).format('DD MMM YYYY')
export const formatDateHours = (date) =>
  moment(date).format('DD MMM YYYY, HH:mm')

export const parseLinkedAccount = (id, profile, entity_name) => {
  return {
    uuid: id,
    full_name: `${profile.first_name} ${profile.middle_name} ${profile.last_name}`,
    avatar_url: profile.avatar,
    email: profile.email,
    entity_name,
  }
}

export const parseLinkedAccountsResponse = (linkedAccountsResponse) => {
  const res = []
  res.push(
    parseLinkedAccount(
      linkedAccountsResponse.id,
      linkedAccountsResponse.profile,
      linkedAccountsResponse.entity_name
    )
  )

  res.push(
    ...linkedAccountsResponse.linked_accounts.map((account) =>
      parseLinkedAccount(account.id, account.profile, account.entity_name)
    )
  )

  return res
}
