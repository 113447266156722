/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

export default forwardRef((props, ref) => {
  const { label, addText, name, value, options, ...rest } = props
  return (
    <div {...rest} className="radio">
      <label className="d-flex align-items-start">
        <div className="d-flex align-items-center">
          {options?.map((option) => (
            <div key={option.id} className="d-flex align-items-center">
              <Typography
                as="label"
                component="label"
                htmlFor={option.id}
                className="text_regular__14 mr-2"
              >
                <input
                  data-testid="RadioButtonHorizontal-337B38"
                  ref={ref}
                  type="radio"
                  checked={value ? value === option.value : undefined}
                  name={name}
                  id={option.id}
                  value={option.value}
                  {...rest}
                />
                <span className="ml-1">{option.text}</span>
              </Typography>
            </div>
          ))}
        </div>
        <div>
          <Typography
            className={classNames([
              'text_medium__14',
              !addText && 'remo-form-label',
            ])}
          >
            {label}
          </Typography>
          {addText && (
            <Typography
              className="text_light__12 color_text_300 mt-1"
              style={{ marginBottom: 12, whiteSpace: 'pre-line' }}
            >
              {addText}
            </Typography>
          )}
        </div>
      </label>
    </div>
  )
})
