import './CustomersPage.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { EOR_TYPES } from '@core/constants'
import { AppContext } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { searchIcon } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useBoolean, useDebounce } from 'usehooks-ts'

import { ReactComponent as RemofirstIcon } from '../../assets/icons/remofirst.svg'
import CreateCompanyModal from './create-customer/CreateCompanyModal'
import { fetchCompanies, fetchCreateCompany } from './CustomersPage.service'
import { parseCompaniesTable } from './parser'

export default function CustomersPage() {
  const history = useHistory()
  const { successAlert } = useToast()
  const { countries, currencies } = useContext(AppContext)
  const [state, setState] = useState('')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const newCustomerModalState = useBoolean(false)
  const [search, setSearch] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)
  const debouncedValue = useDebounce(search, 500)

  const fields = [
    {
      title: 'Name',
      key: 'name',
      type: 'textMedium',
      maxWidth: 320,
      minWidth: 320,
      width: 320,
      iconRight: <RemofirstIcon />,
    },
    {
      title: 'Employees',
      key: 'employees',
      type: 'string',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Profit',
      key: 'profit',
      type: 'string',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
  ]

  const { isFetching, refetch } = useQuery(
    ['companies', state, debouncedValue, page],
    {
      queryFn: () => fetchCompanies(state, debouncedValue, page),
      onSuccess: ({ results, count }) => {
        setCompanies(results)
        setTotal(count)
      },
    }
  )

  const newCustomerMutation = useMutation({
    mutationFn: (payload) => fetchCreateCompany(payload),
    onSuccess: () => {
      refetch()
      successAlert('Company successfully created!')
      newCustomerModalState.setFalse()
      setSelectedTab(1)
    },
  })

  const handleSaveNewCustomer = (payload) => {
    newCustomerMutation.mutate({
      ...payload,
      eor_type: EOR_TYPES.SAAS,
    })
  }

  const onRowClick = (row) => {
    history.push(`customers/${row.id}`)
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setPage(1)
    setSearch(value)
  }

  const handleTabChange = (id) => {
    setSelectedTab(id)
    setPage(1)
    setSearch('')
    setState(id ? 'onboarding' : '')
  }

  return (
    <div className="customers-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Customers </PageTitle>
      </div>
      <Input
        data-testid="CustomersPage-FE5049"
        onChange={onChangeSearch}
        placeholder="Search"
        type="text"
        name="search"
        value={search}
        endIcon={<Icon icon={searchIcon} />}
        styleClass="customers-page-search mb-3"
      />

      <Tabs selectedTab={selectedTab} onTabChange={handleTabChange}>
        <Tab tabId={0} title="List">
          <CustomTable
            page={page}
            loading={isFetching}
            onPage={setPage}
            total={total}
            fields={fields}
            data={parseCompaniesTable(companies)}
            onRowClick={onRowClick}
          />
        </Tab>
        <Tab tabId={1} title="Onboarding">
          <CustomTable
            page={page}
            loading={isFetching}
            onPage={setPage}
            total={total}
            fields={fields}
            data={parseCompaniesTable(companies)}
            onRowClick={onRowClick}
          />
        </Tab>
      </Tabs>

      {newCustomerModalState.value && (
        <CreateCompanyModal
          loading={newCustomerMutation.isLoading}
          onClose={newCustomerModalState.setFalse}
          onSave={handleSaveNewCustomer}
        />
      )}
    </div>
  )
}
