import Button from '@atoms/Button/Button'
import PageTitle from '@atoms/PageTitle/PageTitle'
import { AppContext } from '@core/context'
import {
  costCalculateDownloadPdf,
  fetchGetCostCalculationTax,
} from '@services/cost-calculation.service'
import { isArray, isObject } from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { HiDownload } from 'react-icons/hi'
import { useMutation } from 'react-query'

import CostCalculatorAdditionalInfo from './cost-calculator-additional-info/cost-calculator-additional-info.component'
import CostCalculatorRequiredInfo from './cost-calculator-required-info/cost-calculator-required-info.component'
import CostCalculatorTable from './cost-calculator-table/cost-calculator-table.component'

function parseRequiredData(obj) {
  let newObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj[key] = obj[key]
    }
    if (isObject(obj[key])) {
      newObj[key] = obj[key].value
    }
    if (key === 'regions') {
      newObj[key] = isObject(obj[key]) ? [obj[key].value] : []
    }
  })
  if (newObj?.fixed_contract_duration_type === 'year') {
    newObj = {
      ...newObj,
      fixed_contract_duration: newObj.fixed_contract_duration * 12,
    }
  }
  delete newObj.fixed_contract_duration_type
  if (newObj.contract_type === 'indefinite')
    delete newObj.fixed_contract_duration
  return newObj
}

export default function CostCalculator() {
  const { profile } = useContext(AppContext)
  const form = useForm({
    defaultValues: {
      partner: profile.partner_profile_id,
      country: profile.allowed_countries && profile.allowed_countries[0]?.id,
      salary_type: 'annual',
      currency: profile.default_currency.id,
      residency: 'local',
    },
  })

  const { watch, getValues } = form
  const currency = watch('currency')
  const grossSalary = watch('annual_gross_salary')
  const isExistAllParams = !!currency && !!grossSalary

  const { data, isLoading, mutate } = useMutation({
    mutationFn: fetchGetCostCalculationTax,
    onSuccess: (response) => {
      form.setValue('request_id', response.request_id)
    },
    enabled: isExistAllParams,
  })

  const costCalcGeneratePdfMutation = useMutation({
    mutationFn: costCalculateDownloadPdf,
    onSuccess: (response) => {
      const file = new Blob([response], { type: 'application/pdf' })
      const href = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `${
          profile.allowed_countries && profile.allowed_countries[0]?.name
        }_Cost Calculator_${moment(new Date()).format('DD_MMM_YYYY')}.pdf`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
  })

  const submit = (formValues) => {
    const body = parseRequiredData(formValues)
    if (formValues.salary_type === 'annual') {
      mutate({ ...body, annual_gross_salary: formValues.gross_salary })
    } else {
      mutate({ ...body, monthly_gross_salary: formValues.gross_salary })
    }
  }

  const onDownload = () => {
    const body = parseRequiredData(getValues())
    if (body.salary_type === 'annual') {
      costCalcGeneratePdfMutation.mutate({
        ...body,
        annual_gross_salary: body.gross_salary,
      })
    } else {
      costCalcGeneratePdfMutation.mutate({
        ...body,
        monthly_gross_salary: body.gross_salary,
      })
    }
  }

  return (
    <FormProvider {...form}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle>Cost calculator</PageTitle>
        <Button
          data-testid="cost-calculator-index-F9F48C"
          priority="secondary"
          size="small"
          disabled={!data}
          loading={costCalcGeneratePdfMutation.isLoading}
          onClick={onDownload}
        >
          <HiDownload className="mr-1" />
          Download PDF
        </Button>
      </div>
      <div className="d-flex gap-4">
        <form
          onSubmit={form.handleSubmit(submit)}
          className="d-flex flex-column gap-3"
        >
          <CostCalculatorRequiredInfo />
          <CostCalculatorAdditionalInfo />
          <Button
            data-testid="cost-calculator-index-35F8F5"
            priority="primary"
            type="submit"
            size="small"
            disabled={isLoading}
          >
            Submit
          </Button>
        </form>
        <div className="flex-grow-1">
          <CostCalculatorTable loading={isLoading} data={data} />
        </div>
      </div>
    </FormProvider>
  )
}
