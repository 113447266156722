import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import PayslipsEmployeeActions from '@pages/employeeDetailPage/libs/Payslips/payslips-employee-actions.component'
import { parserPayslips } from '@pages/employeeDetailPage/utils'
import UploadPaylipsModal from '@pages/employeePayslips/upload-paylips-modal'
import {
  addContractDocuments,
  getContractByIdPayslips,
} from '@services/document.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const DEFAULT_PAGE_LIMIT = 10

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

export default function Payslips({ employee }) {
  const { successAlert } = useToast()
  const modalOpen = useBoolean(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const {
    isFetching,
    data: payslipsResponse,
    refetch,
  } = useQuery(['getContractByIdPayslips', employee.id, page], {
    queryFn: () =>
      getContractByIdPayslips({
        contract_id: employee.id,
        page,
        document_type: 'payslip',
        search: '',
        limit: DEFAULT_PAGE_LIMIT,
        offset: (page - 1) * DEFAULT_PAGE_LIMIT,
      }),
    onSuccess: ({ count }) => {
      setTotal(count)
    },
  })

  const uploadPayslipsRequest = useMutation({
    mutationFn: (payload) => addContractDocuments(payload),
    onSuccess: () => {
      modalOpen.setFalse()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const fields = [
    {
      title: 'Document name',
      key: 'name',
      type: 'textMedium',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Payslip for the month of',
      key: 'payslip_for_date',
      type: 'text',
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      title: 'Created at',
      key: 'created',
      type: 'textMedium',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Actions',
      key: 'actions',
      type: 'custom',
      render: (item) => (
        <PayslipsEmployeeActions item={item} refetch={refetch} />
      ),
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
  ]
  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>Payslips</PageTitle>
        {payslipsResponse?.results?.length > 0 && (
          <Button
            data-testid="Payslips-C57334"
            priority="secondary"
            size="small"
            onClick={modalOpen.setTrue}
          >
            <MdAdd />
            <Typography className="ml-2"> Upload Payslips</Typography>
          </Button>
        )}
      </StyledTopbar>
      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {payslipsResponse?.results?.length > 0 ? (
            <CustomTable
              type="main"
              fields={fields}
              data={parserPayslips(payslipsResponse)}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <div>
              <NotFound
                title="There are no payslip yet"
                description="Click on Upload payslips to add new payslip for this employee"
                action="Upload payslip"
                onClickAction={modalOpen.setTrue}
              />
            </div>
          )}
        </div>
      )}
      {modalOpen.value && (
        <UploadPaylipsModal
          title="Upload payslip"
          onClose={modalOpen.setFalse}
          loading={uploadPayslipsRequest.isLoading}
          employee={{
            ...employee,
            name: `${employee.profile.first_name} ${employee.profile.last_name}`,
            original: {
              job: employee.job,
            },
          }}
          onSubmit={(formData) => uploadPayslipsRequest.mutate(formData)}
        />
      )}
    </StyledContainer>
  )
}
