import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { PAYMENT_FREQUENCY } from '@core/constants'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { getJobDetails } from '@pages/employeeDetailPage/mock'
import { patchContractById } from '@services/app.service'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { SalaryChangeDetails } from './SalaryChangeDetails'

export default function JobDetails({
  employee,
  setEmployee,
  occupations,
  regions,
}) {
  const { currencies } = useApp()
  const [salaryChange] = employee.salary_changes

  const [visibleEdit, setVisibleEdit] = useState(false)
  const [editState, setEditState] = useState({
    ends_at: employee.job.ends_at ? new Date(employee.job.ends_at) : null,
    starts_at: new Date(employee.job.starts_at),
    requested_starts_at: new Date(employee.job.requested_starts_at),
    position: employee.job.position,
    is_permanent: employee.job.is_permanent,
    position_description: employee.job.position_description,
    currency: employee.compensation.currency.id,
    contractor_payment_frequency:
      employee.compensation.contractor_payment_frequency,
    yearly_gross_salary: employee.compensation.yearly_gross_salary,
    yearly_gross_salary_in_country_currency:
      employee.compensation.yearly_gross_salary_in_country_currency,
    contractor_rate: employee.compensation.contractor_rate,
    estimated_working_hours: employee.compensation.estimated_working_hours,
    contractor_wage_type: employee.compensation.contractor_wage_type,
    department: employee.department,
    occupation: employee?.job?.occupation?.id,
    region: employee?.region?.id,
    probation_period: employee?.job.probation_period,
  })

  const hasOccupations = useMemo(
    () => employee?.working_country?.has_occupations,
    [employee]
  )
  const hasRegions = useMemo(() => !!regions && regions.length > 0, [regions])

  const putJobDetail = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      toast.success('Successfully updated')
      setVisibleEdit(false)
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeEditOccupation = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      occupation: value,
    }))
  }
  const onChangeEditRegions = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      region: value,
    }))
  }
  const onChangeWage = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      contractor_wage_type: opt.value,
    }))
  }
  const onChangeCurrency = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      currency: opt.value,
    }))
  }
  const onPaymentFrequency = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      contractor_payment_frequency: opt.value,
    }))
  }
  const onChangeStartDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      starts_at: value,
      requested_starts_at: value,
    }))
  }
  const onChangeEndDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: value,
    }))
  }
  const onRemove = useCallback(() => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: null,
    }))
  }, [])
  const onSaveEdit = () => {
    const compensation =
      employee.contract_type === 'contractor'
        ? {
            ...employee.compensation,
            contractor_rate: editState.contractor_rate,
            estimated_working_hours: editState.estimated_working_hours,
            currency: editState.currency,
            contractor_payment_frequency:
              editState.contractor_payment_frequency,
            contractor_wage_type: editState.contractor_wage_type,
            receiving_currency:
              employee.compensation?.receiving_currency?.id || null,
          }
        : {
            currency: editState.currency,
            yearly_gross_salary: editState.yearly_gross_salary,
            yearly_gross_salary_in_country_currency:
              editState.yearly_gross_salary_in_country_currency,
          }
    const data = {
      job: {
        is_permanent: editState.is_permanent,
        ends_at:
          editState.ends_at && !editState.is_permanent
            ? moment(editState.ends_at).format('YYYY-MM-DD')
            : null,
        starts_at: moment(editState.starts_at).format('YYYY-MM-DD'),
        requested_starts_at: moment(editState.requested_starts_at).format(
          'YYYY-MM-DD'
        ),
        position: editState.position,
        position_description: editState.position_description,
      },
      department: editState.department,
      compensation,
    }
    if (hasOccupations) {
      data.job.occupation = editState?.occupation
    }
    if (hasRegions) {
      data.region = editState?.region
    }
    putJobDetail.mutate({ id: employee.id, data })
  }

  const handleCheckChange = (event) => {
    const { name, checked } = event.target
    setEditState((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">
            Job details
          </Typography>
          <Button
            data-testid="JobDetails-2AF729"
            priority="secondary"
            size="small"
            onClick={() => setVisibleEdit(true)}
          >
            Edit
          </Button>
        </div>
        <List lists={getJobDetails(employee, hasOccupations, hasRegions)} />
        {salaryChange && <SalaryChangeDetails salaryChange={salaryChange} />}
      </div>
      <EditModal
        visible={visibleEdit}
        title="Job details"
        onSave={onSaveEdit}
        loading={putJobDetail.isLoading}
        closeModal={() => setVisibleEdit(false)}
      >
        <div className="remo-form-input">
          <Input
            data-testid="JobDetails-BCF1E8"
            onChange={onChangeEdit}
            label="Job title"
            type="text"
            name="position"
            value={editState?.position}
          />
        </div>
        <div className="remo-form-input">
          <TextArea
            onChange={onChangeEdit}
            label="Job description"
            type="text"
            maxLength={4000}
            name="position_description"
            value={editState?.position_description}
          />
        </div>
        {hasOccupations && (
          <div className="remo-form-input">
            <Select
              onChange={onChangeEditOccupation}
              label="Occupation type"
              name="occupation"
              value={editState?.occupation}
              options={occupations.map((o) => ({ value: o.id, label: o.name }))}
            />
          </div>
        )}
        {hasRegions && (
          <div className="remo-form-input">
            <Select
              onChange={onChangeEditRegions}
              label="Region"
              name="region"
              value={editState?.region}
              options={regions.map((o) => ({ value: o.id, label: o.name }))}
            />
          </div>
        )}
        <div className="remo-form-input">
          <Input
            data-testid="JobDetails-4E2A48"
            onChange={onChangeEdit}
            label="Department"
            type="text"
            name="department"
            value={editState?.department}
          />
        </div>
        {employee.contract_type === 'contractor' ? (
          <>
            <div className="remo-form-input">
              <Select
                data-testid="JobDetails-07575F"
                onChange={onChangeWage}
                value={editState?.contractor_wage_type}
                label="Wage type"
                name="contractor_wage_type"
                options={[
                  { value: 'hourly', label: 'Hourly' },
                  { value: 'monthly', label: 'Monthly' },
                ]}
              />
            </div>

            <div className="remo-form-input">
              <Input
                data-testid="JobDetails-352680"
                onChange={onChangeEdit}
                type="number"
                label="Contractor rate"
                name="contractor_rate"
                value={editState?.contractor_rate}
              />
            </div>

            <div className="remo-form-input">
              <Select
                data-testid="JobDetails-31CEDC"
                label="Currency"
                onChange={onChangeCurrency}
                value={editState.currency}
                options={currencies.map((country) => ({
                  value: country.id,
                  label: country.name || country.short_code,
                }))}
              />
            </div>

            <div className="remo-form-input">
              <Input
                data-testid="JobDetails-24F6E2"
                onChange={onChangeEdit}
                type="number"
                label="Estimated working hours"
                name="estimated_working_hours"
                value={editState?.estimated_working_hours}
              />
            </div>
            <div className="remo-form-input">
              <Select
                data-testid="JobDetails-9E8083"
                label="Payment frequency"
                value={editState.contractor_payment_frequency}
                onChange={onPaymentFrequency}
                options={PAYMENT_FREQUENCY}
              />
            </div>
          </>
        ) : null}
        <div className="remo-form-input">
          <Checkbox
            data-testid="JobDetails-21A917"
            name="is_permanent"
            checked={editState.is_permanent}
            onChange={handleCheckChange}
            label="Contract is permanent"
          />
        </div>
        <div className="remo-form-input">
          <DateInput
            className="mb-3"
            onChange={onChangeStartDate}
            disabled={
              employee.state === 'active' || employee.state === 'inactive'
            }
            label="Start date"
            value={editState?.starts_at}
          />
        </div>

        {!editState.is_permanent && (
          <div className="remo-form-input">
            <DateInput
              onChange={onChangeEndDate}
              label="End date"
              onRemove={onRemove}
              value={editState?.ends_at}
            />
          </div>
        )}
      </EditModal>
    </div>
  )
}
