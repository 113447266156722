import styled from 'styled-components'

export const Root = styled.div`
  position: relative;
`

export const Menu = styled.ul`
  position: absolute;
  left: 0;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  z-index: 100;
  min-width: 100%;

  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
`

export const MenuItem = styled.li`
  margin: 0;
  padding: 0;

  button {
    width: 100%;
    margin: 0;
    padding: 8px 14px;
    border: 0;
    background: none;
    font-size: 14px;
    text-align: start;
    white-space: nowrap;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`

export const Styled = {
  Root,
  Menu,
  MenuItem,
}
