import { Api } from '@api/Api'
import { BillingInfoDTO } from '@remoteam-front/types/legacy'

export const completeBillingAndBankInfo = async (
  id: string,
  body: BillingInfoDTO
) => {
  const { data } = await Api.post(
    `/partners/${id}/onboarding/check-list/key-contacts-billing-info-complete/`,
    body
  )

  return data
}
