import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'

export default ({ onNext }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      title: '',
    },
  })

  const submit = (formValues) => {
    onNext({
      title: formValues.title,
      profile: {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
      },
    })
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className=" flex-grow-1 d-flex flex-column  justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="UserInformationForm-7FD0DF"
            {...register('title')}
            type="text"
            label="Job title"
            placeholder="Title"
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="UserInformationForm-8867AB"
            {...register('first_name', { required: 'First name is required' })}
            type="text"
            label="First name"
            placeholder="First name"
          />
          {errors.first_name && (
            <Typography className="text_regular__14 color_red">
              {errors.first_name.message}
            </Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="UserInformationForm-660D9F"
            {...register('last_name', { required: 'Last name is required' })}
            type="text"
            label="Last name"
            placeholder="Last name"
          />
          {errors.last_name && (
            <Typography className="text_regular__14 color_red">
              {errors.last_name.message}
            </Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="UserInformationForm-711D25"
            {...register('email', {
              required: 'Email is required',
              validate: {
                isEmail: (v) => {
                  return /.+@.+\..+/.test(v) || 'Please enter correct email'
                },
              },
            })}
            type="email"
            label="Email"
            placeholder="Email"
          />
          {errors.email && (
            <Typography className="text_regular__14 color_red">
              {errors.email.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="align-self-center pb-5">
        <Button
          data-testid="UserInformationForm-D3A989"
          type="submit"
          className="align-self-end"
        >
          Continue
        </Button>
      </div>
    </form>
  )
}
