import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { AsyncButton, theme } from '@remoteam-front/ui'
import { deleteCompensation, getContractById } from '@services/app.service'
import { useMutation, useQueryClient } from 'react-query'

export const CompensationDeleteDialog = ({ compensation, onClose }) => {
  const queryClient = useQueryClient()
  const deleteMutation = useMutation({
    mutationFn: deleteCompensation,
    onSuccess: () => {
      onClose()
      queryClient.refetchQueries(getContractById.key)
    },
  })

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Delete compensation</DialogTitle>
      <DialogContent>
        <Typography color={theme.palette.secondary[300]} width={500}>
          You are about to delete ‘{compensation.name}’.
          <br />
          <br />
          Are you sure you want to delete?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="reject-dialog.component-725487"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <AsyncButton
          data-testid="reject-dialog.component-97159B"
          variant="contained"
          color="error"
          isFetching={deleteMutation.isLoading}
          onClick={() => deleteMutation.mutate(compensation.id)}
        >
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
