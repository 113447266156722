import { useApp } from '@core/context'
import { KeyboardArrowLeft } from '@mui/icons-material'
import {
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { completeBillingAndBankInfo } from '@remoteam-front/services'
import { BankAccountType, BillingInfoDTO } from '@remoteam-front/types/legacy'
import { Logo, StepContainer, StepIcon, theme } from '@remoteam-front/ui'
import { ClearFilledIcon } from '@remoteam-front/ui/icons'
import { getOnboardingChecklist } from '@services/profile.service'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { BankAccount } from '../bank-account/bank-account.component'
import { BillingAddress } from '../billing-address/billing-address.component'
import { KeyContacts } from '../key-contacts/key-contacts.component'
import { Styled } from './key-contacts-billing-info-stepper.styles'

type Props = {
  onClose(): void
}

export const KeyContactsBillingInfoStepper = ({ onClose }: Props) => {
  const { profile, refetchPartnerProfile } = useApp() as any

  const [activeStep, setActiveStep] = useState<number>(0)
  const matchesDownMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const form = useForm<BillingInfoDTO>({
    defaultValues: {
      bank_account: {
        account_type: BankAccountType.local,
        address: {
          living_country: profile?.allowed_countries?.length
            ? profile?.allowed_countries[0]?.id
            : '',
        },
      },
      billing_address: {
        living_country: profile?.allowed_countries?.length
          ? profile?.allowed_countries[0]?.id
          : '',
      },
    },
  })

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const queryClient = useQueryClient()

  const completeMutation = useMutation({
    mutationFn: (body: BillingInfoDTO) =>
      completeBillingAndBankInfo(profile.id, body),
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingChecklist.key)
      refetchPartnerProfile()
      onClose()
    },
  })

  const steps = [
    {
      label: 'Key contacts',
      content: <KeyContacts onSave={handleNext} />,
    },
    {
      label: 'Bank account',
      content: <BankAccount onNext={handleNext} />,
    },
    {
      label: 'Billing address',
      content: (
        <BillingAddress
          isLoading={completeMutation.isLoading}
          onComplete={completeMutation.mutate}
        />
      ),
    },
  ]

  return (
    <Dialog open fullScreen onClose={onClose}>
      <Styled.Root>
        <Styled.Sidebar>
          <Styled.SidebarHeader>
            <Styled.LogoContainer>
              <Logo />
            </Styled.LogoContainer>
            <Typography variant="h2_strong" mb={matchesDownMobile ? 1.5 : 3}>
              Key contacts and billing information
            </Typography>
            <Typography variant="body1" color="product.secondaryVariant">
              This step is crucial for mitigating risks and maintaining legal
              integrity within our collaboration.
            </Typography>
          </Styled.SidebarHeader>
          <Styled.SidebarBody>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(({ label }, index) => (
                <Step key={label}>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        Step {index + 1}
                      </Typography>
                    }
                    StepIconComponent={StepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Styled.SidebarBody>
        </Styled.Sidebar>
        <Styled.Main>
          <Styled.MainHeader>
            <IconButton
              color="secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <Typography variant="h2_strong">
              {steps[activeStep].label}
            </Typography>
            <IconButton color="secondary" onClick={onClose}>
              <ClearFilledIcon />
            </IconButton>
          </Styled.MainHeader>
          <Styled.MainBody>
            <FormProvider {...form}>
              {steps.map(({ label, content }, index) => (
                <StepContainer key={label} visible={activeStep === index}>
                  {activeStep === index && content}
                </StepContainer>
              ))}
            </FormProvider>
          </Styled.MainBody>
        </Styled.Main>
      </Styled.Root>
    </Dialog>
  )
}
