import { getCurrency, SplitAmount } from '@core/utils'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { Card } from 'react-bootstrap'

import styles from './job-details.module.scss'

export function SalaryChangeDetails({ salaryChange }) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Latest salary change</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className={classNames([styles.cardItem, styles.secondary])}>
          <span>New salary</span>
          <span>{`${getCurrency(salaryChange.currency)} ${SplitAmount(
            salaryChange.new_salary
          )}`}</span>
        </div>

        <div className={classNames([styles.cardItem])}>
          <span>Effective date</span>
          <span>
            {moment(salaryChange.effective_date).format('DD MMM YYYY')}
          </span>
        </div>

        {salaryChange.new_position && (
          <div className={classNames([styles.cardItem, styles.secondary])}>
            <span>New job title</span>
            <span>{salaryChange.new_position}</span>
          </div>
        )}

        {salaryChange.new_position_description && (
          <div
            className={classNames(
              'mt-2 flex-grow-1 align-items-start text_medium__14 overflow-auto',
              [styles.cardItem]
            )}
          >
            {salaryChange.new_position_description}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
