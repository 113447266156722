import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'

export default ({ onFinish, loading }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      living_country: '',
      street_address: '',
      city: '',
      postal_code: '',
    },
  })

  const { countries } = useContext(AppContext)

  const submit = (formValues) => {
    onFinish({
      legal_address: {
        ...formValues,
        living_country: formValues.living_country.value,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100">
      <section className=" flex-grow-1 d-flex flex-column  justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="living_country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="CompanyLegalForm-E93519"
                  {...field}
                  label="Country"
                  placeholder="Select country"
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                />
              )
            }}
          />
          {errors.living_country && (
            <Typography className="text_regular__14 color_red">
              {errors.living_country.message}
            </Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="CompanyLegalForm-1A6176"
            {...register('city')}
            type="text"
            label="Town / City"
            placeholder="Town / City"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="CompanyLegalForm-637EF5"
            {...register('state')}
            type="text"
            label="State"
            placeholder="e.g Staringplein"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="CompanyLegalForm-123282"
            {...register('street_address')}
            type="text"
            label="Street Address"
            placeholder="Street name, house number"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="CompanyLegalForm-E8292F"
            {...register('address_line')}
            type="text"
            label="Address Line"
            placeholder="Address line"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="CompanyLegalForm-C2D189"
            {...register('postal_code')}
            type="text"
            label="Zip / Postal code"
            placeholder="94601"
          />
        </div>
      </section>

      <div className="align-self-center pb-5">
        <Button
          data-testid="CompanyLegalForm-EE501A"
          type="submit"
          className="align-self-end"
          loading={loading}
          disabled={loading}
        >
          Continue
        </Button>
      </div>
    </form>
  )
}
