import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { Tooltip } from '@atoms/Tooltip'
import Typography from '@atoms/Typography/Typography'
import { numberWithSpaces } from '@core/utils'
import ChangesAccordion from '@pages/payrollsDetailPage/ChangesAccordion'
import { mapCurrency } from '@remoteam-front/utils/currency'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 } from 'uuid'

import {
  getPayrollListCompany,
  getPayrollListNeedChange,
  getPayrollsTotal,
} from '../../services/payroll.service'
import { approvedPayrollsField, payrollsField } from './mock'

export const FlagPayrollCompanyListPage = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const activeTab = queryParams.get('tab') || 'pending'

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const { data: dataTotal, isLoading: isLoadingTotals } = useQuery(
    ['getPayrollsTotal'],
    {
      queryFn: () => getPayrollsTotal(),
    }
  )

  const { data: companyPayrollList, isFetching } = useQuery(
    ['getPayrollListCompany', activeTab],
    {
      queryFn: () => getPayrollListCompany({ state: activeTab }),
    }
  )
  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    history.push({ search: queryParams.toString() })
  }
  const onRowClick = ({ company_id }) => {
    history.push(`/pages/flag_payrolls/${company_id}`)
  }
  const onRowApprovedClick = ({ company_id }) => {
    history.push(`/pages/flag_payrolls/${company_id}/approved`)
  }
  return (
    <div className="invoices-page h-100">
      <PageTitle styleClass="mb-4"> Payroll </PageTitle>
      <Tabs onTabChange={handleTabChange} selectedTab={activeTab}>
        <Tab tabId="pending" title="Pending">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isFetching && !companyPayrollList ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : companyPayrollList.length > 0 ? (
            <CustomTable
              fields={payrollsField}
              data={companyPayrollList}
              loading={isFetching}
              onRowClick={onRowClick}
            />
          ) : (
            <NotFound
              illustration="/assets/img/ec-empty-shopping-cart.png"
              title="No pending payroll"
            />
          )}
        </Tab>
        <Tab tabId="approved" title="Approved">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isFetching && !companyPayrollList ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : companyPayrollList.length > 0 ? (
            <CustomTable
              fields={approvedPayrollsField}
              data={companyPayrollList}
              loading={isFetching}
              onRowClick={onRowApprovedClick}
            />
          ) : (
            <NotFound
              illustration="/assets/img/ec-empty-shopping-cart.png"
              title="No pending payroll"
            />
          )}
        </Tab>
        <Tab
          tabId="change_requests"
          title="Change Request"
          count={listNeedChange?.count}
        >
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
      {dataTotal && (
        <div className="payroll-bottom-wrapper">
          <div className="payroll-bottom d-flex justify-content-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Total of current payroll cycle:{' '}
                  {dataTotal?.total_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.approved_payroll_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Already approved: {dataTotal?.approved_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.approved_payroll_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Pending: {dataTotal?.pending_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.pending_payroll_companies_count} companies
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
