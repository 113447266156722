import { ProfileMy } from '@remoteam-front/types/legacy'
import { createContext, useContext } from 'react'

// TODO
export const AppContext = createContext<{
  profile: ProfileMy | undefined
  countries: any[]
  currencies: any[]
  refetchPartnerProfile(): void
}>({
  profile: undefined,
  refetchPartnerProfile: () => '',
  countries: [],
  currencies: [],
})

export const useApp = () => useContext(AppContext)
