import '../onboarding-checklist.styles.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
  }
`

export default ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20 color_black">
          Complete compliance
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="text_regular__14">
          Compliance with relevant regulations is vital for our partnership with
          you. During this step, you will be asked to complete our Compliance
          Questionnaire to assess your adherence to applicable laws and
          regulations. This step is crucial for mitigating risks and maintaining
          legal integrity within our collaboration.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Take action by filling out the Compliance Questionnaire to demonstrate
          your commitment to compliance.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Refer to{' '}
          <a
            href="https://remofirst.atlassian.net/wiki/external/NTU5MzVkMTM5NDhlNGNlNTg4OTVmMTRkNDI1NzE2YmQ"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            our guides
          </a>{' '}
          for more information.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            priority="secondary"
            size="small"
            className="mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            priority="primary"
            onClick={onSave}
          >
            Complete
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
