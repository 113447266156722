import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import { ChangeEvent, DragEvent, forwardRef, HTMLProps, useState } from 'react'

import { DeleteIcon, FileMiniActive } from '../../icons/icons'
import { cyan } from '../../theme/colors'
import { Styled } from './single-upload.styles'

export type SingleUploadItem = {
  file: File | null
  isLoading: boolean
  isSuccess: boolean
}

type Props = SingleUploadItem & {
  helpText?: string
  label?: string
  isRequired?: boolean
  onSelect(file: SingleUploadItem): void
  onDelete(): void
} & Omit<HTMLProps<HTMLInputElement>, 'multiple' | 'onSelect'>

const SingleUpload = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    file,
    href,
    isLoading,
    isSuccess,
    isRequired,
    onSelect,
    onDelete,
    helpText,
    label,
    ...rest
  } = props

  const [isOver, setIsOver] = useState<boolean>(false)

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOver(true)
  }
  const handleDragLeave = () => {
    setIsOver(false)
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const file = event.dataTransfer.files.item(0)
    const { length } = event.dataTransfer.files

    if (length > 1) {
      // TODO
      alert('drop only one file')
      return
    }

    if (file) {
      setIsOver(false)
      onSelect({ file, isLoading: false, isSuccess: false })
    }
  }

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0)
    if (file) {
      onSelect({ file, isLoading: false, isSuccess: false })
      // reset value to allow select the same file again
      // eslint-disable-next-line no-param-reassign
      event.target.value = ''
    }
  }

  const handleDelete = () => {
    onDelete()
  }

  return (
    <Styled.Root
      isOver={isOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!file && (
        <>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">
              {label || 'Drag and drop your file here'}{' '}
              {isRequired && (
                <Typography component="span" color="error">
                  *
                </Typography>
              )}
            </Typography>
            <Typography variant="textSmall1" className="secondary-variant">
              {helpText}
            </Typography>
          </Box>
          <Button
            data-testid="single-upload-1F3942"
            variant="contained"
            color="secondary"
            component="label"
          >
            Select file
            <input
              data-testid="single-upload-E36D70"
              {...rest}
              ref={ref}
              type="file"
              hidden
              multiple={false}
              onChange={handleSelect}
            />
          </Button>
        </>
      )}

      {file && (
        <>
          <Box display="flex" alignItems="center" gap={1}>
            {isLoading ? <CircularProgress /> : <FileMiniActive />}
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                component="a"
                href={URL.createObjectURL(file)}
                target="_blank"
                variant="body2"
                color={cyan[500]}
                rel="noreferrer"
              >
                {file.name}
              </Typography>
              <Typography variant="body2" className="secondary-variant">
                ({file.size / 1000}) Kb
              </Typography>
            </Box>
          </Box>
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </Styled.Root>
  )
})

export default SingleUpload
