import { numberWithSpaces } from '@core/utils'
import { Box, Button, Typography } from '@mui/material'
import { theme } from '@remoteam-front/ui'
import { capitalizeFirstLetter, mapEnum } from '@remoteam-front/utils'
import { useBoolean } from 'usehooks-ts'

import {
  calculateAmountPerPayment,
  calculateHoursPerPayment,
} from '../onboarding-tracker-tab.utils'
import { CompensationApproveConfirm } from './compensation-approve-confirm.component'
import { OnboardingTrackerCompensationTable } from './onboarding-tracker-compensation-table.component'
import { Styled } from './onboarding-tracker-required-compensation.styles'

export const OnboardingTrackerRequiredCompensation = ({
  employee,
  isApproved,
}) => {
  const approveOpen = useBoolean()

  const compensations = [
    ...(employee.compensation?.additional_compensations || []),
    ...(employee.compensation?.allowances || []),
    ...(employee.compensation?.signing_bonus
      ? [employee.compensation.signing_bonus]
      : []),
  ]

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="textLarge2">Compensation</Typography>
        {!isApproved && !!compensations?.length && (
          <Button variant="contained" onClick={approveOpen.setTrue}>
            Approve
          </Button>
        )}
      </Box>
      <Styled.Card>
        <Typography variant="subtitle2">{`Pay type: ${
          employee.compensation &&
          capitalizeFirstLetter(employee.compensation?.employee_pay_type)
        }`}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          {employee.compensation?.employee_pay_type === 'salaried' ? (
            <>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Annual gross salary
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >{`${
                  employee.compensation?.currency?.sign ||
                  employee.compensation?.currency?.short_code
                } ${numberWithSpaces(
                  employee.compensation.yearly_gross_salary
                )}`}</Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  No. of payments per year
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {employee.working_country.yearly_salary_divider}
                </Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Amount per payment
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >{`${
                  employee.compensation?.currency?.sign ||
                  employee.compensation?.currency?.short_code
                } ${numberWithSpaces(
                  employee.compensation.monthly_salary
                )}`}</Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Payment frequency
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {mapEnum(
                    capitalizeFirstLetter(
                      employee.compensation?.employee_payment_frequency
                    )
                  )}
                </Typography>
              </Styled.Doc>
            </>
          ) : (
            <>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Base hourly rate
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >{`${
                  employee.compensation?.currency?.sign ||
                  employee.compensation?.currency?.short_code
                } ${numberWithSpaces(
                  employee.compensation.employee_base_hourly_rate
                )}`}</Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Payment frequency
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {mapEnum(
                    capitalizeFirstLetter(
                      employee.compensation?.employee_payment_frequency
                    )
                  )}
                </Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Weekly hours
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {employee.compensation?.employee_work_hours_per_week}
                </Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Hours per payment
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {calculateHoursPerPayment(
                    employee.compensation?.employee_work_hours_per_week,
                    employee.compensation?.employee_payment_frequency
                  )}
                </Typography>
              </Styled.Doc>
              <Styled.Doc>
                <Typography
                  variant="body1"
                  color={theme.palette.secondary[300]}
                >
                  Amount per payment
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary[300]}
                >
                  {employee.compensation?.currency?.sign ||
                    employee.compensation?.currency?.short_code}{' '}
                  {calculateAmountPerPayment(
                    employee.compensation?.employee_base_hourly_rate,
                    calculateHoursPerPayment(
                      employee.compensation?.employee_work_hours_per_week,
                      employee.compensation?.employee_payment_frequency
                    )
                  )}
                </Typography>
              </Styled.Doc>
            </>
          )}
        </Box>
      </Styled.Card>

      <OnboardingTrackerCompensationTable
        compensationData={compensations}
        isApproved={isApproved}
      />
      {approveOpen.value && (
        <CompensationApproveConfirm
          onClose={approveOpen.setFalse}
          compensationId={employee.compensation?.id}
        />
      )}
    </Box>
  )
}
