import '../onboarding-checklist.styles.scss'

import Typography from '@atoms/Typography/Typography'
import { Button } from '@mui/material'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
  }
`

export const KeyContactsBillingInfoModal = ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20 color_black">
          Provide Key contacts and billing information
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="text_regular__14">
          To streamline financial transactions, we kindly ask our Local Partners
          to provide accurate bank details and billing address. This information
          ensures the smooth processing of payments and invoices, minimizing
          administrative hurdles and enabling efficient financial management
          between our entities.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Please provide your bank details and billing address below.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Refer to{' '}
          <a
            data-testid="key-contacts-billing-info-modal.component-5F9D61"
            href="https://remofirst.atlassian.net/wiki/external/ZGNhOThkNmRiY2JmNGZiMzlmODM3MDgwYWE4YThiNmM"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            our guides
          </a>{' '}
          for more information.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            data-testid="key-contacts-billing-info-modal.component-68A424"
            variant="outlined"
            color="secondary"
            className="mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="key-contacts-billing-info-modal.component-800431"
            variant="contained"
            color="primary"
            priority="primary"
            onClick={onSave}
          >
            Provide details
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
