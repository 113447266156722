export const getOverviews = (stats) => [
  {
    title: 'Add a new hire',
    description: 'Adding a new hire is simple and fast',
    link: '/pages/employees?openAddModal=true',
    image: '/assets/img/overview/1.png',
  },
  {
    title: 'Employees Onboarding',
    description: 'Review profiles to approve',
    link: '/pages/employees?tabId=3',
    image: '/assets/img/overview/3.png',
    number: stats.employees_stats.onboarding_count,
  },
  {
    title: 'Companies Signed Up',
    description: 'Companies with active, onboarding and draft employees',
    link: '/pages/customers',
    image: '/assets/img/overview/4.png',
    number: stats.company_count,
  },
  {
    title: 'Total employees',
    description: 'Active, onboarding and draft employees',
    link: '/pages/employees',
    image: '/assets/img/overview/5.png',
    number: stats.employees_stats.total_count,
  },
]
