import { useApp } from '@core/context'
import { Box } from '@mui/material'
import { getPartnerBankAccounts } from '@remoteam-front/services'
import { BankAccount } from '@remoteam-front/types/legacy'
import { AsyncButton, theme } from '@remoteam-front/ui'
import { AddFilledIcon } from '@remoteam-front/ui/icons'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { BankAccountCard } from './bank-account-card.component'
import { BankAccountConfirm } from './bank-account-confirm.component'
import { BankAccountDrawer } from './bank-account-drawer.component'

export const BankAccountSubTab = () => {
  const { profile } = useApp()

  const newBankAccountIsOpen = useBoolean(false)

  const [accountToManage, setAccountToManage] = useState<BankAccount | null>(
    null
  )
  const [accountToDelete, setAccountToDelete] = useState<BankAccount | null>(
    null
  )

  const bankAccountsQuery = useQuery([getPartnerBankAccounts.key], {
    enabled: !!profile,
    keepPreviousData: true,
    queryFn: () => getPartnerBankAccounts.fetch(profile!.id),
  })

  if (bankAccountsQuery.isLoading) {
    return <DotWave />
  }

  if (bankAccountsQuery.data) {
    return (
      <>
        {!!bankAccountsQuery.data.bank_accounts.length && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={2}
            sx={{
              padding: 2,
              background: theme.palette.secondary[900],
              width: 'max-content',
            }}
          >
            {bankAccountsQuery.data.bank_accounts.map((account) => (
              <BankAccountCard
                {...account}
                onMange={() => setAccountToManage(account)}
              />
            ))}
          </Box>
        )}

        <Box mt={4}>
          <AsyncButton
            isFetching={bankAccountsQuery.isFetching}
            data-testid="bank-account.component-9C7A70"
            variant="contained"
            startIcon={bankAccountsQuery.isFetching ? null : <AddFilledIcon />}
            onClick={newBankAccountIsOpen.setTrue}
          >
            Add bank account
          </AsyncButton>
        </Box>

        {accountToManage && (
          <BankAccountDrawer
            bankAccount={accountToManage}
            onDelete={(bankAccount) => {
              setAccountToDelete(bankAccount)
              setAccountToManage(null)
            }}
            onClose={() => setAccountToManage(null)}
          />
        )}

        {newBankAccountIsOpen.value && (
          <BankAccountDrawer onClose={newBankAccountIsOpen.setFalse} />
        )}

        {!!accountToDelete && (
          <BankAccountConfirm
            {...accountToDelete}
            isLast={bankAccountsQuery.data.bank_accounts.length === 1}
            onClose={() => setAccountToDelete(null)}
          />
        )}
      </>
    )
  }

  return null
}
