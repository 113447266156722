import Avatar from '@atoms/Avatar/Avatar'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import { EmployeeStatusTooltip } from '@features/team/employee-status-tooltip/employee-status-tooltip'
import { EmployeeSubStatusTooltip } from '@features/team/employee-sub-status-tooltip/employee-sub-status-tooltip'
import moment from 'moment/moment'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const fields = [
  // {
  //   title: '№',
  //   dataIndex: 'number',
  //   maxWidth: 50,
  //   minWidth: 50,
  //   width: 50,
  // },
  {
    title: 'Name',
    dataIndex: 'full_name',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
    render: ({ avatar, full_name }) => (
      <div className="d-flex align-items-center">
        <Avatar className="my-auto" src={avatar} width={48} height={48} />
        <Typography className="text_medium__14 ml-3">{full_name}</Typography>
      </div>
    ),
  },
  {
    title: 'Role',
    dataIndex: 'job_position',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Created',
    dataIndex: 'created',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
    render: ({ created_at }) => moment(created_at).format('DD MMM YYYY'),
  },
  {
    title: 'Start date',
    dataIndex: 'start_at',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    render: ({ start_at, state }) => {
      const now = moment()
      const startDate = moment(start_at)
      const daysDiff = startDate.diff(now, 'days')
      let description = ''
      let colorClass = ''

      if (daysDiff > 29) {
        description = `in ${daysDiff} days`
        colorClass = 'color_primary' // 30 days or more
      } else if (daysDiff >= 15 && daysDiff <= 29) {
        description = `in ${daysDiff} days`
        colorClass = 'color_warning' // 15 to 29 days
      } else if (daysDiff >= 0 && daysDiff <= 14) {
        description = `in ${daysDiff} days`
        colorClass = 'color_red' // 14 days or less
      } else {
        description = `${Math.abs(daysDiff)} days passed`
        colorClass = 'color_red' // If start date is now or in the past
      }
      return (
        <div className="d-flex flex-column align-items-start">
          {moment(start_at).format('DD MMM YYYY')}
          {state === 'onboarding' && (
            <Typography className={`text_regular__14 ${colorClass}`}>
              {description}
            </Typography>
          )}
        </div>
      )
    },
  },
  {
    title: 'Company',
    dataIndex: 'customer',
    sortIndex: 'company__name',
    maxWidth: 220,
    minWidth: 220,
    width: 220,
  },
  {
    title: 'Resident',
    dataIndex: 'residency_type',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
    render: ({ residency_type }) => {
      return residency_type ? (
        <span>{residency_type === 'resident' ? 'Yes' : 'No'}</span>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  // {
  //   title: 'Location',
  //   dataIndex: 'location',
  //   maxWidth: 140,
  //   minWidth: 140,
  //   width: 140,
  // },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    dataIndex: 'status',
    sortIndex: 'state',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (contractor) => {
      return (
        <Status
          status={contractor.status}
          isOnboardingTab={contractor.onboarding}
          documents_state={contractor.documents_state}
        />
      )
    },
  },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeSubStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Sub-status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    dataIndex: 'sub_state',
    render: (item) =>
      item.sub_state === 'dummy' ? (
        '-'
      ) : (
        <div className="status status_default">
          {item.sub_state.toString().replace('_', ' ')}
        </div>
      ),
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
]
