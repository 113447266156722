import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { AsyncButton } from '@remoteam-front/ui'
import { deleteDocument, getAgreements } from '@services/document-sign.service'
import { useMutation, useQueryClient } from 'react-query'

type Props = { agreementId: number; onClose(): void }

export const AgreementConfirm = ({ agreementId, onClose }: Props) => {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: deleteDocument,
    onSuccess: () => {
      queryClient.refetchQueries(getAgreements.key)

      onClose()
    },
  })

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Remove agreement</DialogTitle>
      <DialogContent>
        Are you sure you want to remove the agreement?
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="agreement-confirm.component-2AED5C"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="agreement-confirm.component-EFB545"
          isFetching={isLoading}
          variant="contained"
          color="error"
          onClick={() => mutate(agreementId)}
        >
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
