import Icon from '@atoms/Icon/Icon'
import NotFound from '@atoms/NotFound/NotFound'
import Select from '@atoms/Select/Select'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import CustomTable from '@atoms/Table/CustomTable'
import { Loader } from '@components/loader/loader.component'
import {
  LeaveType,
  TIME_OFF_LEAVE_TYPES,
  TIME_OFF_STATUSES_MAP,
} from '@core/constants'
import { useApp } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { noteIcon } from '@core/icons/icons'
import { formatDate } from '@core/utils'
import { leaveTypes, reasonTypes } from '@features/time-off/time-off.data'
import { fetchTimeOff } from '@services/absences.service'
import { getCompaniesShort } from '@services/company.service'
import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useQuery } from 'react-query'

export const timeOffTableFields = [
  {
    title: 'Name',
    key: 'contract.full_name',
    type: 'avatar',
  },
  {
    title: 'Company',
    key: 'contract.company.name',
    type: 'string',
  },
  {
    title: 'Country',
    key: 'current_country.name',
    type: 'string',
  },
  {
    title: 'Period',
    type: 'custom',
    render: ({ start_date, end_date }) => {
      return `${formatDate(start_date)} - ${formatDate(end_date)}`
    },
  },
  {
    title: 'Work days',
    key: 'total_days',
    type: 'string',
  },
  {
    title: 'Type',
    type: 'custom',
    render: ({ leave_reason_type }) => {
      return TIME_OFF_LEAVE_TYPES[leave_reason_type] ?? ''
    },
  },
  {
    title: 'Paid/Unpaid',
    type: 'custom',
    render: ({ leave_type }) => {
      return LeaveType[leave_type]
    },
  },
  {
    title: 'Note',
    type: 'custom',
    render: ({ comment }) => {
      return comment ? (
        <OverlayTrigger overlay={<Tooltip>{comment}</Tooltip>}>
          <span>
            <Icon icon={noteIcon} />
          </span>
        </OverlayTrigger>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
  },
]

export const TimeOffRequests = ({ search, onRowClick, onAdd }) => {
  const { countries, userGroups } = useApp()
  const [countryId, setCountryId] = useState(null)
  const [status, setStatus] = useState(null)
  const [leaveType, setLeaveType] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })

  const {
    isLoading,
    isFetching,
    isSuccess,
    data: timeOffData,
  } = useQuery(
    [fetchTimeOff.key, companyId, countryId, status, leaveType, page, search],
    {
      queryFn: () =>
        fetchTimeOff.fetch({
          current_country: countryId,
          company_in: companyId,
          status_in: status,
          leave_reason_type: leaveType,
          limit,
          offset: (page - 1) * limit,
          search,
        }),
      keepPreviousData: true,
    }
  )

  const { data: companiesData } = useQuery(['CompaniesShort'], {
    queryFn: () => getCompaniesShort({ limit: 1000 }),
  })

  const handleCompanyChange = (option) => {
    setCompanyId(option?.value || null)
    setPage(1)
  }

  const handleCountryChange = (option) => {
    setCountryId(option?.value || null)
    setPage(1)
  }

  const handleStatusChange = (option) => {
    setStatus(option?.value || null)
    setPage(1)
  }

  const handleLeaveTypeChange = (option) => {
    setLeaveType(option?.value || null)
    setPage(1)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="timeOff-table-box">
      <div className="timeOff-table-wrap">
        <div className="d-flex mb-4 align-items-center gap-3">
          <div style={{ width: 190 }}>
            <Select
              placeholder="Select company"
              value={companyId}
              onChange={handleCompanyChange}
              options={companiesData?.results?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              isClearable
            />
          </div>
          <div style={{ width: 190 }}>
            <Select
              placeholder="Select country"
              value={countryId}
              onChange={handleCountryChange}
              options={[
                { value: 0, label: 'All' },
                ...countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                })),
              ]}
              isClearable
            />
          </div>
          <div style={{ width: 190 }}>
            <Select
              placeholder="Type"
              value={leaveType}
              onChange={handleLeaveTypeChange}
              options={Object.keys(TIME_OFF_LEAVE_TYPES).map((key) => ({
                value: key,
                label: TIME_OFF_LEAVE_TYPES[key],
              }))}
              isClearable
            />
          </div>
          <div style={{ width: 190 }}>
            <Select
              placeholder="Status"
              value={status}
              onChange={handleStatusChange}
              options={Object.keys(TIME_OFF_STATUSES_MAP).map((key) => ({
                value: key,
                label: TIME_OFF_STATUSES_MAP[key],
              }))}
              isClearable
            />
          </div>
        </div>
        <AbsoluteSpinner isFetching={isFetching}>
          {timeOffData?.count > 0 ? (
            <CustomTable
              fields={timeOffTableFields}
              page={page}
              total={timeOffData.count}
              onPage={setPage}
              pageSize={limit}
              data={timeOffData.results}
              onRowClick={(data) => onRowClick(data)}
            />
          ) : (
            <NotFound
              title="It's quiet in here ..."
              description="There are no time off requests "
              action="Add time off"
              onClickAction={onAdd}
            />
          )}
        </AbsoluteSpinner>
      </div>
    </div>
  )
}
