/* eslint-disable react/no-array-index-key */
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function List({ lists, textPosition, hideLastBorder }) {
  return (
    <div className="list">
      {lists.map(
        (list, index) =>
          (list?.value?.length > 0 || list?.value) && (
            <div
              className={classNames('list__item', {
                'd-none': list.hidden,
              })}
              key={list.label + index}
            >
              <div
                className={classNames([
                  textPosition === 'vertical'
                    ? 'd-flex flex-column'
                    : 'd-flex justify-content-between',
                ])}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <Typography className="text_regular-normal__14 color_black_light mr-1">
                      {list.label}
                    </Typography>

                    {list.description && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{list.description}</Tooltip>}
                      >
                        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
                          <Icon
                            icon={icons.infoIcon}
                            style={{ fill: '#878787' }}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>

                  {list.hint && (
                    <Typography
                      className="text_light__12 color_text_300"
                      style={{ marginBottom: 12 }}
                    >
                      {list.hint}
                    </Typography>
                  )}
                </div>
                <Typography className="text_regular-normal__14 color_black mb-3">
                  {list.value}
                </Typography>
              </div>
              {hideLastBorder && index + 1 === lists.length ? null : (
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    background: '#F3F3F3',
                    marginBottom: 16,
                  }}
                />
              )}
            </div>
          )
      )}
    </div>
  )
}
