import { Api } from '@api/Api'

export const fetchCompanies = async (state, search, page, limit = 10) => {
  const { data } = await Api.get(
    `companies/?limit=${limit}&offset=${(page - 1) * limit}${
      state ? `&state=${state}` : ''
    }${search ? `&search=${search}` : ''}`
  )

  return data
}

export const fetchCreateCompany = async (body) => {
  const { data } = await Api.post(`companies/`, body)
  return data
}
