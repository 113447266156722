/* eslint-disable jsx-a11y/label-has-associated-control */
import Checkbox from '@atoms/Checkbox/Checkbox'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border: 2px solid #f3f3f3;
  border-radius: 8px;

  .label {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    cursor: pointer;

    .label__title {
    }
    .label__description {
    }
  }
`

export const billingContactFormId = 'billingContactFormId'

export const BillingContactForm = ({
  checkboxes,
  profile,
  onChange,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      self: { email: profile.user.profile.email, value: profile.user.id },
      byEmail: { email: '', value: null, first_name: '', last_name: '' },
    },
  })

  const handleCheckChange = (event) => {
    const { name, checked } = event.target
    onChange(name, checked)
  }

  const submit = (formValues) => {
    const payload = Object.keys(checkboxes)
      .filter((key) => !!checkboxes[key])
      .map((key) => ({
        user: formValues[key].value,
        email: formValues[key].email,
        first_name:
          formValues[key].first_name || profile.user.profile.first_name,
        last_name: formValues[key].last_name || profile.user.profile.last_name,
      }))
    onSubmit(payload)
  }

  return (
    <form
      id={billingContactFormId}
      onSubmit={handleSubmit(submit)}
      className="d-flex flex-column gap-2"
    >
      <Item>
        <Checkbox
          data-testid="billing-contact-form-70D6EE"
          id="self"
          name="self"
          checked={checkboxes.self}
          onChange={handleCheckChange}
        />
        <label htmlFor="self" className="label">
          <Typography className="text_medium__14">
            I’m the billing contact of the company
          </Typography>
          <Typography className="text_light__13">
            You will be defined as the billing contact of your company
          </Typography>
        </label>
      </Item>

      <Item>
        <Checkbox
          data-testid="billing-contact-form-3BB070"
          id="byEmail"
          name="byEmail"
          checked={checkboxes.byEmail}
          onChange={handleCheckChange}
        />
        <div className="label">
          <label htmlFor="byEmail" className="label">
            <Typography className="text_medium__14">
              Add an email as a billing contact
            </Typography>
            <Typography className="text_light__13">
              Provide an email for receiving invoices
            </Typography>
          </label>
          {checkboxes.byEmail && (
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2 justify-content-between">
                <div>
                  <Input
                    data-testid="billing-contact-form-B84B7B"
                    autoFocus
                    {...register('byEmail.first_name', {
                      required: 'First name is required',
                    })}
                    placeholder="First name"
                  />
                  {errors.byEmail?.first_name && (
                    <Typography className="text_regular__14 color_red">
                      {errors.byEmail.first_name.message}
                    </Typography>
                  )}
                </div>

                <div>
                  <Input
                    data-testid="billing-contact-form-7EBEF1"
                    {...register('byEmail.last_name', {
                      required: 'Last name is required',
                    })}
                    placeholder="Last name"
                  />
                  {errors.byEmail?.last_name && (
                    <Typography className="text_regular__14 color_red">
                      {errors.byEmail.last_name.message}
                    </Typography>
                  )}
                </div>
              </div>
              <Input
                data-testid="billing-contact-form-36D334"
                {...register('byEmail.email', {
                  required: 'Please fill the email',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                type="email"
                placeholder="Enter the email"
              />
              {errors.byEmail?.email && (
                <Typography className="text_regular__14 color_red">
                  {errors.byEmail.email.message}
                </Typography>
              )}
            </div>
          )}
        </div>
      </Item>
    </form>
  )
}
