import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { MdChevronLeft, MdClose } from 'react-icons/md'

export default ({ title, onClose, children, onBack, displayHeader = true }) => {
  return (
    <section className="d-flex flex-column h-100 w-100">
      {displayHeader && (
        <header
          className="d-flex align-items-center pl-5 pr-5"
          style={{
            justifyContent: onBack && 'space-between',
            marginTop: 40,
          }}
        >
          {onBack && (
            <Button
              data-testid="StepContent-87CBA4"
              priority="secondary"
              type="button"
              size="small"
              style={{ padding: '12px 12px', minWidth: 40 }}
              onClick={onBack}
            >
              <MdChevronLeft />
            </Button>
          )}
          <div style={{ flexGrow: !onBack && 1, textAlign: 'center' }}>
            <Typography as="h4" className="heading_semibold__24">
              {title}
            </Typography>
          </div>
          <Button
            data-testid="StepContent-2B11C0"
            priority="secondary"
            type="button"
            size="small"
            style={{ padding: '12px 12px', minWidth: 40 }}
            onClick={onClose}
          >
            <MdClose />
          </Button>
        </header>
      )}

      <main className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className="col-sm-12 col-md-10 col-lg-8 col-xl-7 h-100 step-content">
          {children}
        </div>
      </main>
    </section>
  )
}
