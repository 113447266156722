/* eslint-disable import/no-unresolved */
import { TimelineBase } from '@components/timeline-base/timeline-base.component'
import { Box, Chip, Typography } from '@mui/material'
import {
  ContractDetail,
  ContractDocumentHandlers,
  ContractDocumentHandlersResponse,
} from '@remoteam-front/types/v2'
import { theme } from '@remoteam-front/ui'
import { Spinner } from '@remoteam-front/ui/components/spinner/spinner'
import { getOnboardingDocuments } from '@services/app.service'
import { getAgreements } from '@services/document-sign.service'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { OnboardingTrackerRequiredCompensation } from './onboarding-tracker-required-compensation/onboarding-tracker-required-compensation.component'
import { OnboardingTrackerRequiredDocuments } from './onboarding-tracker-required-documents/onboarding-tracker-required-documents.component'
import { OnboardingTrackerUploadContract } from './onboarding-tracker-upload-contract/onboarding-tracker-upload-contract.component'

const getRequiredDocumentsTabStatus = (docs: ContractDocumentHandlers[]) => {
  if (!docs) {
    return
  }

  if (
    docs
      .filter(({ is_required }) => !!is_required)
      .every(({ state }) => state === 'approved')
  ) {
    return 'You’ve approved the documents'
  }

  if (docs.every(({ state }) => state === 'not_uploaded')) {
    return 'Employee has been invited to upload documents'
  }

  return 'Available for review'
}

type TabType =
  | 'required_documents'
  | 'required_compensation'
  | 'upload_signed_contract'

type Props = {
  employee: ContractDetail
}

export const OnboardingTrackerTab = ({ employee }: Props) => {
  const [activeTab, setActiveTab] = useState<TabType>('required_documents')
  const compensations = [
    ...(employee.compensation?.additional_compensations || []),
    ...(employee.compensation?.signing_bonus
      ? [employee.compensation.signing_bonus]
      : []),
    ...(employee.compensation?.allowances || []),
  ]
  const isCheckedCompensationStep =
    !!compensations.length &&
    compensations.filter((ap) => ap.approved_by_partner).length ===
      compensations.length

  const employeeDocsQuery = useQuery<ContractDocumentHandlersResponse>(
    [getOnboardingDocuments.key, employee.id],
    {
      keepPreviousData: true,
      queryFn: () => getOnboardingDocuments.fetch(employee.id, 1000, 1),
    }
  )
  const agreementsQuery = useQuery([getAgreements.key, employee.id], {
    keepPreviousData: true,
    queryFn: () =>
      getAgreements.fetch({
        contract_id: employee.id,
        search: '',
        limit: 1000,
        offset: 0,
      }),
  })

  const handleChangeTabs = (tab: TabType) => () => setActiveTab(tab)

  if (employeeDocsQuery.isLoading || agreementsQuery.isLoading) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <DotWave />
      </Box>
    )
  }

  const docsStatusMsg = getRequiredDocumentsTabStatus(
    employeeDocsQuery.data?.results || []
  )

  if (employeeDocsQuery.data) {
    return (
      <>
        <Typography variant="h3_strong" color={theme.palette.secondary.main}>
          Onboarding{' '}
          <span style={{ color: theme.palette.primary[300] }}>in progress</span>
        </Typography>

        <Box display="flex" gap={2} alignItems="center" mt={2}>
          <TimelineBase
            title="Review documents"
            isActive={activeTab === 'required_documents'}
            checked={docsStatusMsg === 'You’ve approved the documents'}
            onClick={handleChangeTabs('required_documents')}
          >
            <Typography variant="textSmall1">{docsStatusMsg}</Typography>
            {docsStatusMsg === 'Available for review' && (
              <Chip label="Pending your review" size="small" color="warning" />
            )}
          </TimelineBase>
          <TimelineBase
            title="Review compensation"
            isActive={activeTab === 'required_compensation'}
            checked={isCheckedCompensationStep}
            hideCheckmark={!compensations.length}
            onClick={handleChangeTabs('required_compensation')}
          >
            <Typography
              variant="textSmall1"
              color={theme.palette.secondary[300]}
            >
              {!compensations.length &&
                'No actions required. There are no additional compensations'}
              {!!compensations.length &&
                (isCheckedCompensationStep
                  ? 'You’ve approved compensation'
                  : 'Approve the compensation package')}
            </Typography>
            {!!compensations.length && !isCheckedCompensationStep && (
              <Chip label="Pending your review" size="small" color="warning" />
            )}
          </TimelineBase>
          <TimelineBase
            title="Upload signed contract"
            isActive={activeTab === 'upload_signed_contract'}
            checked={agreementsQuery.data?.count !== 0}
            onClick={handleChangeTabs('upload_signed_contract')}
          >
            <Typography variant="textSmall1">
              {agreementsQuery.data?.count === 0
                ? 'Finalise the employment agreement asap'
                : 'Signed contract has been received'}
            </Typography>
            {agreementsQuery.data?.count === 0 && (
              <Chip label="Pending your upload" size="small" color="warning" />
            )}
          </TimelineBase>
        </Box>

        <Spinner
          isFetching={
            agreementsQuery.isFetching || employeeDocsQuery.isFetching
          }
        >
          <Box mt={4}>
            {activeTab === 'required_documents' && (
              <OnboardingTrackerRequiredDocuments
                employeeDocs={employeeDocsQuery.data.results}
              />
            )}
            {activeTab === 'required_compensation' && (
              <OnboardingTrackerRequiredCompensation
                employee={employee}
                isApproved={isCheckedCompensationStep}
              />
            )}
            {activeTab === 'upload_signed_contract' && (
              <OnboardingTrackerUploadContract
                agreementsData={agreementsQuery.data}
                employee={employee}
                refetch={agreementsQuery.refetch}
              />
            )}
          </Box>
        </Spinner>
      </>
    )
  }

  return null
}
