import './style.scss'

import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import Tab from './libs/Tab/Tab'

export default function Tabs({
  className,
  children,
  selectedTab,
  mode,
  onTabChange,
}) {
  const [active, setActive] = useState(selectedTab || 0)
  const onClickTab = useCallback(
    (id) => {
      if (onTabChange) {
        onTabChange(id)
      }
      setActive(id)
    },
    [onTabChange]
  )

  useEffect(() => {
    if (selectedTab === 0 || selectedTab) setActive(selectedTab)
  }, [selectedTab])
  const renderTabs = React.useMemo(() => {
    return children?.map((tab, index) => {
      return tab && tab.props ? (
        <Tab
          tabId={tab.props.tabId}
          title={tab.props.title}
          count={tab.props.count}
          mode={mode}
          active={active === tab.props.tabId}
          onClick={onClickTab}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          hidden={tab.props.hidden}
        />
      ) : null
    })
  }, [active, children, mode, onClickTab])

  const renderTabContent = React.useMemo(() => {
    return children?.find((tab) => tab?.props?.tabId === active)?.props.children
  }, [children, active])
  return (
    <div className={classNames(className, 'tabs-wrapper')}>
      <div className="d-flex align-items-center tabs">
        {renderTabs}
        <div className="tabs__line" />
      </div>
      <div className="tab-content mt-4">{renderTabContent}</div>
    </div>
  )
}
