import moment from 'moment'

export const getFileName = (file) => {
  const queryIndex = file.indexOf('?')
  const textBeforeQuery = file.substring(0, queryIndex)
  const fileName = textBeforeQuery.split('/')
  return fileName[fileName.length - 1]
}

export const parserPayslips = (payslips) => {
  if (!payslips || !payslips.results) return []
  return payslips.results.map((item) => ({
    id: item.id,
    name: item.filename,
    payslip_for_date: moment(item.payslip_for_date).format('MMMM YYYY'),
    created: moment(item.created_at).format('DD MMM YYYY'),
    original: item,
  }))
}
