/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import './style.scss'

import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

const Input = forwardRef(
  (
    {
      placeholder,
      id,
      value,
      disabled,
      style,
      onChange,
      type,
      label,
      readOnly,
      styleClass,
      defaultValue,
      currency,
      name,
      endIcon,
      outlined,
      isRequired,
      addText,
    },
    ref
  ) => (
    <div className={classNames(['input', styleClass])}>
      <div className="d-flex flex-column w-100">
        {label && (
          <label
            className={classNames([
              'text_medium__14',
              !addText && 'remo-form-label',
            ])}
            htmlFor={id}
            style={style}
          >
            {label} {isRequired && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        {addText && (
          <Typography
            className="text_light__12 color_text_300 mt-1"
            style={{ marginBottom: 12 }}
          >
            {addText}
          </Typography>
        )}
      </div>
      <div
        className={classNames([
          'input-wrapper d-flex align-items-center',
          outlined && 'input_outlined',
        ])}
      >
        {currency && <span className="currency mx-2">{currency}</span>}
        <input
          data-testid="Input-180E17"
          style={{ ...style }}
          id={id}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          name={name}
          autoComplete="off"
          readOnly={readOnly}
          onChange={onChange}
        />
        <span className="input-end-icon">{endIcon}</span>
      </div>
    </div>
  )
)

export default Input
