import './EmployeesPage.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { EMPLOYEE_STATES } from '@core/constants'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { searchIcon } from '@core/icons/icons'
import { capitalizeFirstLetter } from '@core/utils'
import { fetchContractors } from '@pages/employees/EmployeesPage.service'
import { exportEmployeesXLS } from '@services/employee.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useBoolean, useDebounce, useLocalStorage } from 'usehooks-ts'

import { EmployeesTable } from './components/employee-table/employees-table.component'
import { fields } from './employeeMock'
import { employeeTableParse } from './parse'

const getSorting = (order, orderBy) => {
  if (order === '') {
    return ''
  }

  return order === 'asc' ? orderBy : `-${orderBy}`
}

export default function EmployeesPage() {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [contracts, setContracts] = useState([])
  const [contractorsState, setContractorsState] = useState('')
  const [search, setSearch] = useState()
  const debounceSearch = useDebounce(search, 1500)
  const routeQuery = useRouteQuery()
  const [selectedTab, setSelectedTab] = useState(
    Number(routeQuery.get('tabId'))
  )
  const dropdownOpenState = useBoolean(false)

  const [statusLocal, setStatuses] = useLocalStorage('team_status', [
    EMPLOYEE_STATES.ACTIVE,
    EMPLOYEE_STATES.ONBOARDING,
  ])

  const [stateIn, setStateIn] = useState(
    statusLocal.map((item) => ({
      value: item,
      label: capitalizeFirstLetter(item),
    }))
  )

  const [sorting, setSorting] = useState({
    ordering: '',
    orderBy: '',
  })

  const { isLoading, isFetching, refetch } = useQuery(
    ['contractors', contractorsState, page, debounceSearch, stateIn, sorting],
    {
      keepPreviousData: true,
      queryFn: () =>
        fetchContractors(
          contractorsState,
          page,
          debounceSearch,
          getSorting(sorting.ordering, sorting.orderBy),
          10,
          (stateIn.length
            ? stateIn.map((s) => s.value)
            : [
                EMPLOYEE_STATES.ACTIVE,
                EMPLOYEE_STATES.ONBOARDING,
                EMPLOYEE_STATES.INACTIVE,
              ]
          ).join(',')
        ),
      onSuccess: ({ results, count }) => {
        setTotal(count)
        setContracts(employeeTableParse(results, page))
      },
    }
  )

  const exportEmployeesXLSMutation = useMutation({
    mutationFn: exportEmployeesXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `full_time_employees_${moment(new Date()).format('DD_MM_YYYY')}.xls`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      dropdownOpenState.setFalse()
    },
  })

  React.useEffect(() => {
    if (routeQuery.get('tabId') === '3') {
      setContractorsState('&state=onboarding')
    }
  }, [routeQuery])

  const onTabChange = (id) => {
    setPage(1)
    setSorting({
      ordering: '',
      orderBy: '',
    })

    switch (id) {
      case 0:
        routeQuery.set('tabId', '0')
        history.replace({
          search: routeQuery.toString(),
        })
        return setContractorsState('')
      case 1:
        return setContractorsState('&state=active&sub_state=dummy')
      case 2:
        return setContractorsState('&state=active&sub_state=offboarding')
      case 3:
        routeQuery.set('tabId', '3')
        history.replace({
          search: routeQuery.toString(),
        })
        return setContractorsState('&state=onboarding')
      default:
        return null
    }
  }

  const onRowClick = ({ id, status, documents_state }) => {
    if (status === 'onboarding' && documents_state !== 'signed') {
      history.push(`employees/${id}`)
      return
    }
    history.push(`employees/${id}`)
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setPage(1)
  }

  const handleStatusChange = (statuses) => {
    setStateIn(statuses)
    setStatuses(statuses.map((s) => s.value))
    setPage(1)
  }

  return (
    <div className="employees-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Employees </PageTitle>
        <div className="d-flex">
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Button
                data-testid="EmployeesPage-6D8CA7"
                priority="secondary"
                size="small"
                onClick={dropdownOpenState.toggle}
              >
                <HiDownload className="mr-1" />
                Download report
              </Button>
            }
            menu={[
              <button
                data-testid="EmployeesPage-56F2D6"
                type="button"
                onClick={exportEmployeesXLSMutation.mutate}
                disabled={exportEmployeesXLSMutation.isLoading}
              >
                Download FTE data as CSV
              </button>,
            ]}
          />
        </div>
      </div>
      <div className="d-flex align-items-center gap-3 mb-4">
        <div style={{ width: 248 }}>
          <Input
            data-testid="EmployeesPage-62EBF8"
            onChange={onChangeSearch}
            placeholder="Search"
            type="text"
            name="search"
            endIcon={<Icon icon={searchIcon} />}
            value={search}
          />
        </div>
        <div style={{ width: 350 }}>
          <Select
            data-testid="EmployeesPage-AA7DEC"
            options={[
              EMPLOYEE_STATES.ACTIVE,
              EMPLOYEE_STATES.ONBOARDING,
              EMPLOYEE_STATES.INACTIVE,
            ].map((item) => ({
              value: item,
              label: capitalizeFirstLetter(item),
            }))}
            value={stateIn}
            isMulti
            isSearchable
            placeholder="Status"
            onChange={handleStatusChange}
          />
        </div>
      </div>
      <Tabs onTabChange={onTabChange} selectedTab={selectedTab}>
        <Tab tabId={3} title="Onboarding">
          {isLoading ? (
            <DotWave />
          ) : (
            <EmployeesTable
              isFetching={isFetching}
              columns={fields}
              data={contracts.map((v) => ({ ...v, onboarding: true }))}
              ordering={sorting.ordering}
              pagination={{ page, onPage: setPage, total }}
              onSort={setSorting}
              onRow={onRowClick}
            />
          )}
        </Tab>
        <Tab tabId={1} title="Active">
          {isLoading ? (
            <DotWave />
          ) : (
            <EmployeesTable
              isFetching={isFetching}
              columns={fields.filter(
                (f) => !['status', 'sub_state'].includes(f.dataIndex)
              )}
              data={contracts.map((v) => ({ ...v, active: true }))}
              ordering={sorting.ordering}
              pagination={{ page, onPage: setPage, total }}
              onSort={setSorting}
              onRow={onRowClick}
            />
          )}
        </Tab>
        <Tab tabId={2} title="Offboarding">
          {isLoading ? (
            <DotWave />
          ) : (
            <EmployeesTable
              isFetching={isFetching}
              columns={fields.filter(
                (f) => !['status', 'sub_state'].includes(f.dataIndex)
              )}
              data={contracts.map((v) => ({ ...v, offboarding: true }))}
              ordering={sorting.ordering}
              pagination={{ page, onPage: setPage, total }}
              onSort={setSorting}
              onRow={onRowClick}
            />
          )}
        </Tab>
        <Tab tabId={0} title="All">
          {isLoading ? (
            <DotWave />
          ) : (
            <EmployeesTable
              isFetching={isFetching}
              columns={fields}
              data={contracts}
              ordering={sorting.ordering}
              pagination={{ page, onPage: setPage, total }}
              onSort={setSorting}
              onRow={onRowClick}
            />
          )}
        </Tab>
      </Tabs>
    </div>
  )
}
