import { DotWave } from '@uiball/loaders'
import React from 'react'

function SpinnerOrChildren({ isLoading, centered = true, children }) {
  if (!isLoading) return children

  if (centered)
    return (
      <div
        data-testid="centered"
        className="h-100 d-flex justify-content-center align-items-center"
      >
        <DotWave />
      </div>
    )

  return <DotWave />
}

export default SpinnerOrChildren
