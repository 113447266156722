import { EmployeePaymentFrequency } from '@remoteam-front/types/v2'

export const calculateHoursPerPayment = (
  weeklyHours?: number,
  paymentFrequency?: EmployeePaymentFrequency
) => {
  if (!weeklyHours || !paymentFrequency) return 0
  const map = {
    [EmployeePaymentFrequency.Monthly]: 4,
    [EmployeePaymentFrequency.SemiMonthly]: 2,
    [EmployeePaymentFrequency.EveryTwoWeeks]: 2,
    [EmployeePaymentFrequency.Weekly]: 1,
  }

  return weeklyHours * map[paymentFrequency]
}

export const calculateAmountPerPayment = (
  baseHourlyRate?: number,
  hoursPerPayment?: number
) => {
  if (!baseHourlyRate || !hoursPerPayment) return 0
  return baseHourlyRate * hoursPerPayment
}
