import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import { arrowUpRight } from '@core/icons/icons'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import css from '../onboarding.module.scss'

export const PartnerProfileComponent = ({
  loading,
  countryName,
  currency,
  onSubmit,
}) => {
  const [checkboxes, setCheckboxes] = React.useState({
    terms: false,
    privacy: false,
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const handleCheck = (e) => {
    setCheckboxes((prev) => ({ ...prev, [e.target.name]: e.target.checked }))
  }

  return (
    <form
      className="d-flex flex-column justify-content-center gap-3 h-100 mt-4 mb-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        data-testid="partner-profile.component-753B68"
        label="Company name"
        {...register('partner_information.name')}
        disabled
        readOnly
      />

      <Input
        data-testid="partner-profile.component-ED5B6F"
        label="Country"
        value={countryName}
        disabled
        readOnly
      />

      <div>
        <Input
          data-testid="partner-profile.component-43289F"
          label="Street address"
          isRequired
          {...register('partner_information.legal_address.street_address', {
            required: 'Field is required',
          })}
        />
        {errors.partner_information?.legal_address?.street_address && (
          <p className="text_regular__14 color_red">
            {errors.partner_information.legal_address.street_address.message}
          </p>
        )}
      </div>

      <div>
        <Input
          data-testid="partner-profile.component-365A70"
          label="Address line"
          {...register('partner_information.legal_address.address_line')}
        />
      </div>

      <div>
        <Input
          data-testid="partner-profile.component-E5382F"
          label="City"
          isRequired
          {...register('partner_information.legal_address.city', {
            required: 'Field is required',
          })}
        />
        {errors.partner_information?.legal_address?.city && (
          <p className="text_regular__14 color_red">
            {errors.partner_information.legal_address.city.message}
          </p>
        )}
      </div>

      <Input
        data-testid="partner-profile.component-2B7C42"
        label="State"
        {...register('partner_information.legal_address.state')}
      />

      <div>
        <Input
          data-testid="partner-profile.component-6F0D40"
          label="Zip/ Postal code"
          isRequired
          {...register('partner_information.legal_address.postal_code', {
            required: 'Field is required',
          })}
        />
        {errors.partner_information?.legal_address?.postal_code && (
          <p className="text_regular__14 color_red">
            {errors.partner_information.legal_address.postal_code.message}
          </p>
        )}
      </div>

      <div>
        <Controller
          control={control}
          name="partner_information.phone_number"
          rules={{
            required: 'Field is required',
          }}
          render={({ field }) => {
            return (
              <PhoneInput
                {...field}
                isRequired
                international
                withCountryCallingCode
                label="Phone number"
                placeholder="Enter phone number"
              />
            )
          }}
        />
        {errors.partner_information?.phone_number && (
          <p className="text_regular__14 color_red">
            {errors.partner_information.phone_number.message}
          </p>
        )}
      </div>

      <Input
        data-testid="partner-profile.component-BF9731"
        label="Default currency"
        value={currency}
        readOnly
        disabled
      />

      <div className="d-flex flex-column gap-1">
        <Checkbox
          data-testid="partner-profile.component-C1CDA1"
          label={
            <span>
              I agree to the&nbsp;
              <a
                data-testid="partner-profile.component-BF8692"
                href="https://www.remofirst.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className={css.link}
              >
                Terms of use
                <Icon icon={arrowUpRight} className="ml-1" />
              </a>
            </span>
          }
          name="terms"
          value={checkboxes.terms}
          onChange={handleCheck}
        />
        <Checkbox
          data-testid="partner-profile.component-0ABC55"
          label={
            <span>
              I agree to the&nbsp;
              <a
                data-testid="partner-profile.component-F3E481"
                href="https://www.remofirst.com/privacy"
                target="_blank"
                rel="noreferrer"
                className={css.link}
              >
                Privacy policy
                <Icon icon={arrowUpRight} className="ml-1" />
              </a>
            </span>
          }
          name="privacy"
          value={checkboxes.privacy}
          onChange={handleCheck}
        />
      </div>
      <Button
        data-testid="partner-profile.component-F3169E"
        type="submit"
        priority="primary"
        size="small"
        disabled={!checkboxes.privacy || !checkboxes.terms || loading}
      >
        Continue
      </Button>
    </form>
  )
}
