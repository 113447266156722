import { Api } from '@api/Api'

export const getEmployeeDetails = async (id) => {
  const { data } = await Api.get(`/contracts/${id}/`)
  return data
}

export const exportEmployeesXLS = async () => {
  const { data } = await Api.get('/contracts/export-fte-information/', {
    responseType: 'blob',
  })

  return data
}

export const exportContractorsCSV = async () => {
  const { data } = await Api.get('/contracts/export-contractors-information/', {
    responseType: 'blob',
  })

  return data
}
