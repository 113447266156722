/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './NumberCard.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { east } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

export default function NumberCard({
  number,
  title,
  subtitle,
  onClick,
  image,
  link,
  styleClass,
}) {
  const Component = link ? Link : 'div'
  return (
    <Component
      to={link}
      className={classNames('link number-card_link', styleClass)}
    >
      <div className="number-card" onClick={onClick}>
        <div className="d-flex flex-column justify-content-between">
          <div className="number-card__text-content">
            {number !== undefined && (
              <span className={classNames('number', 'mb-3')}>{number}</span>
            )}
            <Typography
              className={classNames('text_medium__14 color_black', 'mb-1')}
            >
              {title}
            </Typography>
            <Typography className="text_regular-normal__14 color_text_300">
              {subtitle}
            </Typography>
          </div>
          {link && (
            <Icon icon={east} fill="#B2B2B2" style={{ cursor: 'pointer' }} />
          )}
        </div>
        <img className="number-card__img" src={image} alt={title} />
      </div>
    </Component>
  )
}
