/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './InvoiceModal.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { downloadIcon } from '@core/icons/icons'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { fetchGetInvoiceById } from '../../services/aggregated-invoices.service'

export default function InvoiceModal({ invoice, onCloseClick }) {
  const { id } = invoice
  const [downloadLink, setDownloadLink] = useState(false)
  const { data, isLoading } = useQuery(['invoiceById', id], {
    queryFn: () => fetchGetInvoiceById(id),
    onSuccess: () => setDownloadLink(false),
    enabled: downloadLink,
  })
  const onClickDownload = useCallback(() => {
    setDownloadLink(true)
  }, [])

  useEffect(() => {
    if (data) {
      window.open(data.file, '_blank')
    }
  }, [data])
  return (
    <div className="invoice-modal">
      <div
        data-testid="InvoiceModal-328297"
        className="invoice-modal-bg"
        onClick={onCloseClick}
      />
      <div className="invoice-modal-content-wrap">
        <div className="invoice-modal-header">
          <div className="invoice-modal-title">{invoice.amount}</div>
          <div className="invoice-modal-subtitle">
            {moment(invoice.date).format('MMMM')} payroll
          </div>
        </div>
        <div className="invoice-modal-divider" />
        <div className="invoice-modal-info-section">
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Status</div>
            <div className="invoice-modal-row-subtitle">
              {invoice.status_verbose}
            </div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Date</div>
            <div className="invoice-modal-row-subtitle">{invoice.date}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Customer</div>
            {invoice.company && (
              <div className="invoice-modal-row-subtitle">
                {invoice.company?.name}
              </div>
            )}
          </div>
        </div>
        <div className="invoice-modal-buttons-section">
          {invoice.file && (
            <Button
              data-testid="InvoiceModal-2B117C"
              priority="primary_black"
              size="small"
              className="w-100 mb-2 d-flex align-items-center"
              style={{ height: 48 }}
              loading={isLoading}
              onClick={onClickDownload}
            >
              <Icon icon={downloadIcon} className="mr-2" />
              Download Invoice
            </Button>
          )}
          <Button
            data-testid="InvoiceModal-34086C"
            priority="secondary"
            size="small"
            className="w-100"
            onClick={onCloseClick}
            style={{ height: 48 }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}
