import { Api } from '@api/Api'

export const getExpenseManagements = async (params) => {
  const { data } = await Api.get(`expense-managements/`, { params })

  return data
}

export const createExpense = async (data) =>
  Api.post(`expense-managements/bulk-create/`, data)
export const cancelExpense = async (body) =>
  Api.post(`expense-managements/${body.id}/status-update/`, { ...body.data })
export const updateExpense = async (body) =>
  Api.put(`expense-managements/${body.id}/update/`, { ...body.data })

export const downloadExpenseCsv = async (params) => {
  const { data } = await Api.get('expense-managements/reports/', {
    responseType: 'blob',
    params,
  })

  return data
}
