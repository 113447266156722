import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { v4 } from 'uuid'

export const parseCostCalculator = (data) => {
  return [
    {
      id: v4(),
      breakdown: 'Gross Salary',
      annual: data.calculations.in_general_currency.gross_salary.annual,
      monthly: data.calculations.in_general_currency.gross_salary.monthly,
    },
    {
      id: v4(),
      breakdown: 'Total employer cost',
      annual:
        data.calculations.in_general_currency.total.annual_total
          .employer_cost_total,
      monthly:
        data.calculations.in_general_currency.total.monthly_total
          .employer_cost_total,
      expandData: data.calculations.in_general_currency.employer_costs,
      isExpanded: true,
    },
    ...data.calculations.in_general_currency.fees.map((e) => ({
      id: v4(),
      breakdown:
        e.name !== 'VAT' ? mapEnum(capitalizeFirstLetter(e.name)) : e.name,
      annual: e.annual,
      summary: e.summary,
      monthly: e.monthly,
    })),
    {
      id: v4(),
      breakdown: 'Total',
      annual: data.calculations.in_general_currency.total.annual_total.total,
      monthly: data.calculations.in_general_currency.total.monthly_total.total,
    },
  ]
}
