import { ProbationPeriodTable } from '@components/probation-period-table/probation-period-table.component'
import { Alert, Box, Link } from '@mui/material'
import { ProbationResponse } from '@remoteam-front/types/legacy'

type Props = {
  data: ProbationResponse
}

export const ProbationPeriodTab = ({ data }: Props) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={4}>
      <Alert severity="info">
        If you spot any mistake in the table below, please reach out to your
        Partner Relationship manager at Remofirst team.
      </Alert>
      <ProbationPeriodTable data={data} />
    </Box>
  )
}
