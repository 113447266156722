import { PaginationParams, PaginationResponse } from '../pagination.types'
import { AgreementContract, Partner } from './agreement.types-v2'
import { Currency } from './currency.types-v2'

export type ExpensesResponse = PaginationResponse<Array<Expense>>

export interface Expense {
  id: number
  contract: ExpenseContract
  category: ExpenseCategory
  expense_name: string
  description: string
  expense_date: string | null
  amount: number
  currency: ExpenseCurrency
  receipt: string | null
  filename: string
  contract_type: ContractType
  state: ExpenseState
  payout_date: string | null
  created_at: string
  events: ExpenseEvent[]
  created_by: CreatedBy | null
  reject_reason: string
  creator_role: string | null
  approved_date: string | null
}

export enum ExpenseState {
  CREATED = 'CREATED',
  APPROVED_BY_COMPANY = 'APPROVED_BY_COMPANY',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  PAID = 'PAID',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  DELETED = 'DELETED',
  MOVED_TO_NEXT_MONTH = 'MOVED_TO_NEXT_MONTH',
}

enum ContractType {
  CONTRACTOR = 'CONTRACTOR',
  FTE = 'FTE',
}

export enum ExpenseCategory {
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
  RETRO = 'RETRO',
  EXPENSES = 'EXPENSES',
  OVERTIME = 'OVERTIME',
  DEDUCTION = 'DEDUCTION',
  WORK_EQUIPMENT = 'WORK_EQUIPMENT',
  COWORKING_SPACE = 'COWORKING_SPACE',
  EDUCATION = 'EDUCATION',
  FOOD = 'FOOD',
  OFFICE_SUPPLIES = 'OFFICE_SUPPLIES',
  TRAVEL = 'TRAVEL',
  OTHER = 'OTHER',
}

type ExpenseCurrency = Currency & { priority: number }

type ExpenseContract = Pick<AgreementContract, 'id' | 'full_name'> & {
  user_id: string
  company_name: string
  company_id: number
  partner_profile: Partner | null
}

export interface ExpenseActionUser {
  id: string
  full_name: string
  organization_name: string
}

export interface CreatedBy {
  id: string
  full_name: string
  organization_name: string
}

export interface ExpenseEvent {
  id: number
  status: ExpenseStatusEnum
  reject_reason: string
  action_user: ExpenseActionUser
  event_side: EventSide
}

enum EventSide {
  PARTNER = 'PARTNER',
  COMPANY = 'COMPANY',
  CONTRACT = 'CONTRACT',
}

export type ExpenseOrderBy =
  | keyof Pick<
      Expense,
      'amount' | 'created_at' | 'expense_date' | 'payout_date'
    >
  | 'ap_type'
  | 'company'
  | 'contract_full_name'
  | 'expense_state_order'
  | 'name'
  | ''

export type ExpenseDto = {
  contract: number
  expense_date: string
  expense_name: string
  amount: number
  currency: number
  category: string
  description: string
  receipt: string
}

export type BulkExpenseDto = {
  expense_managements: Array<ExpenseDto>
}

export type ExpenseCancelDt0 = Partial<{
  event_side: string
  status: string
}>
export type ExpenseDownloadCSV = {
  partners: string
}
export type ExpenseDownloadReport = {
  partners?: number | string | null
  companies?: string | null
  end_date?: string
  payout_month?: string
  statuses?: string
  start_date?: string
}

export type ExpenseDownloadReceipts = {
  partner_id?: number | string | null
  zip_name?: string
  start_date?: string | Date
  end_date?: string | Date
}
export type ExpenseParams = Partial<{
  ordering: string
  state_in: string
  expense_name: string
  ap_type_in: string
  company_in: string
  contract_type: string
  search: string
  finance_manager: string
  cs_manager_in: string
  partner: string
}> &
  PaginationParams

export enum ExpenseStatusEnum {
  REJECTED = 'REJECTED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  NEUTRAL = 'NEUTRAL',
}

export enum ExpenseCategoryEnum {
  WORK_EQUIPMENT = 'WORK_EQUIPMENT',
  COWORKING_SPACE = 'COWORKING_SPACE',
  EDUCATION = 'EDUCATION',
  FOOD = 'FOOD',
  OFFICE_SUPPLIES = 'OFFICE_SUPPLIES',
  TRAVEL = 'TRAVEL',
  OTHER = 'OTHER',
}
