import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { Alert, Box, Chip } from '@mui/material'
import { InfoRegularIcon } from '@remoteam-front/ui/icons'

export const EmployeeBankAccount = ({ employee }) => {
  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">
            Bank account
          </Typography>
        </div>
        <Chip
          label="The employee is responsible to provide and update bank account information. Please contact them if needed."
          icon={<InfoRegularIcon />}
          color="info"
          sx={{
            height: 'auto',
            minHeight: '32px',
            padding: 0.5,
            minWidth: '100%',
            justifyContent: 'flex-start',
            mb: 4,
            '& .MuiChip-label': {
              whiteSpace: 'normal',
            },
          }}
        />
        <List
          lists={[
            {
              label: 'Currency',
              value: employee.compensation?.working_country_currency?.name,
              description: 'This is the currency of the country of hire',
            },
            {
              label: 'Account holder name',
              value: employee.payment_details?.holder_name,
              hidden: !employee.payment_details?.holder_name,
              description: 'This is a full legal name as listed with the bank.',
            },
            {
              label: 'ACH and Wire routing number',
              value: employee.payment_details?.routing_number,
              hidden: !employee.payment_details?.routing_number,
              description:
                'The routing number for the bank, used for domestic transactions.',
            },
            {
              label: 'Bank name',
              value: employee.payment_details?.bank_name,
              hidden: !employee.payment_details?.bank_name,
              description: 'The name of the bank where the account is held.',
            },
            {
              label: 'Sort code',
              value: employee.payment_details?.sort_code,
              hidden: !employee.payment_details?.sort_code,
              description: '6 digit number that identifies the bank.',
            },
            {
              label: 'Account number',
              value: employee.payment_details?.account_number,
              hidden: !employee.payment_details?.account_number,
              description: 'The unique number assigned to the bank account.',
            },
            {
              label: 'SWIFT/BIC code',
              value: employee.payment_details?.bic,
              hidden: !employee.payment_details?.bic,
              description:
                'Global identifier for international wire transfers.',
            },
            {
              label: 'IBAN',
              value: employee.payment_details?.iban,
              hidden: !employee.payment_details?.iban,
              description:
                'International Bank Account Number for cross-border payments.',
            },
            {
              label: 'Additional information',
              value: employee.payment_details?.additional_info,
              hidden: !employee.payment_details?.additional_info,
              description:
                'Please add any additional important details such as: Bank number, Routing number, IBAN or SWIFT/BIC code.',
            },
          ]}
        />
      </div>
    </div>
  )
}
