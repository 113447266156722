import '../ProfilePage.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import {
  complianceIconStart,
  complianceIconTodo,
  searchDocument,
  success,
} from '@core/icons/icons'
import { capitalizeFirstLetter } from '@core/utils'
import SumsubModalComponent from '@pages/overview/onboarding-checklist/sumsub-modal/sumsub-modal.component'
import { getOnboardingChecklist } from '@services/profile.service'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

export const ComplianceTab = () => {
  const { profile } = useContext(AppContext)
  const openSumSub = useBoolean(false)

  const onboardingChecklist = useQuery(getOnboardingChecklist.key, {
    queryFn: () => getOnboardingChecklist.fetch(profile.id),
    enabled: !!profile.id,
  })
  const compliance = onboardingChecklist.data?.check_list?.compliance

  if (onboardingChecklist.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 mt-5">
        <DotWave />
      </div>
    )
  }

  return (
    <div className="compliance-tab">
      <div className="compliance-tab__content">
        <Icon icon={searchDocument} className="mt-2" />
        <div>
          <Typography className="heading_semi__16">
            Company compliance questionnaire
          </Typography>
          <Typography className="text_light__12 color_grey">
            Proof of your compliance
          </Typography>
        </div>

        <ComplianceStatus status={compliance?.current_status || 'start'} />
        {!compliance?.current_status ||
        compliance?.current_status === 'start' ? (
          <Button
            size="small"
            className="verify-button"
            onClick={openSumSub.setTrue}
          >
            Verify now
          </Button>
        ) : (
          compliance?.current_status === 'retry' && (
            <Button
              size="small"
              priority="secondary"
              className="verify-button"
              onClick={openSumSub.setTrue}
            >
              Start
            </Button>
          )
        )}
      </div>

      {openSumSub.value && (
        <SumsubModalComponent onClose={openSumSub.setFalse} />
      )}
    </div>
  )
}

const getStatusLabel = (status) => {
  switch (status) {
    case 'start':
      return 'To do'
    case 'retry':
      return 'Resubmission requested'
    default:
      return capitalizeFirstLetter(status)
  }
}

const getStatusIcon = (status) => {
  switch (status) {
    case 'start':
      return complianceIconTodo
    case 'approved':
      return success
    default:
      return complianceIconStart
  }
}

export const ComplianceStatus = ({ status }) => {
  return (
    <div
      className={classNames(
        'compliance-tab__status',
        `compliance-tab__status__${status}`
      )}
    >
      <Icon icon={getStatusIcon(status)} />
      <Typography className="text_light__12" style={{ fontWeight: 500 }}>
        {getStatusLabel(status)}
      </Typography>
    </div>
  )
}
