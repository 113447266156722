import Button from '@atoms/Button/Button'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import AgreementActions from '@pages/documents/agreement-actions/agreement-actions.component'
import { transformAgreements } from '@pages/documents/documents.utils'
import PartnerAgreementModal from '@pages/documents/partner-agreement-modal/partner-agreement-modal'
import {
  getAgreement,
  getAgreements,
  uploadAgreement,
} from '@services/document-sign.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import ContentPlaceholder from '../../../components/content-placeholder/content-placeholder.component'

const DEFAULT_PAGE_LIMIT = 10

const fields = [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Company',
    key: 'company',
    type: 'text',
    maxWidth: 220,
    minWidth: 220,
    width: 220,
  },
  {
    title: 'Employee',
    key: 'employee',
    type: 'text',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Created',
    key: 'created',
    type: 'textMedium',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    render: (item) => <AgreementActions item={item} />,
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
]

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

export const CustomerAgreements = () => {
  const { id: customerId } = useParams()
  const { successAlert } = useToast()
  const modalOpen = useBoolean(false)
  const [agreementId, setAgreementId] = useState()
  const [agreementDetail, setAgreementDetail] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const {
    isFetching,
    data: agreementsResponse,
    refetch,
  } = useQuery([getAgreements.key, page, customerId], {
    queryFn: () =>
      getAgreements.fetch({
        company_id: customerId,
        page,
        search: '',
        limit: DEFAULT_PAGE_LIMIT,
        offset: (page - 1) * DEFAULT_PAGE_LIMIT,
      }),
    onSuccess: ({ count }) => {
      setTotal(count)
    },
  })

  const { isLoading } = useQuery(['getAgreement', agreementId], {
    queryFn: () => getAgreement(agreementId),
    onSuccess: (data) => {
      setAgreementDetail(data)
    },
    enabled: !!agreementId,
  })

  const uploadAgreementRequest = useMutation({
    mutationFn: (payload) => uploadAgreement(payload),
    onSuccess: () => {
      modalOpen.setFalse()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const handleCollapse = (row) => {
    if (row.id === agreementId) return
    setAgreementId(row.id)
  }

  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>Agreements</PageTitle>
        <Button
          data-testid="customer-agreements.component-53B1E3"
          priority="secondary"
          size="small"
          onClick={modalOpen.setTrue}
        >
          <MdAdd />
          <Typography className="ml-2"> Upload agreement</Typography>
        </Button>
      </StyledTopbar>
      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {agreementsResponse?.results?.length > 0 ? (
            <CustomTable
              type="collapse"
              fields={fields}
              data={transformAgreements(
                agreementsResponse,
                agreementDetail,
                isLoading
              )}
              onCollapseClick={handleCollapse}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ paddingTop: 200 }}
            >
              <ContentPlaceholder
                imgSrc="/assets/img/quiet.png"
                title={`It's quiet in here ...`}
              />
            </div>
          )}
        </div>
      )}
      {modalOpen.value && (
        <PartnerAgreementModal
          title="Upload agreement"
          onClose={modalOpen.setFalse}
          loading={uploadAgreementRequest.isLoading}
          onSubmit={(formData) => uploadAgreementRequest.mutate(formData)}
        />
      )}
    </StyledContainer>
  )
}
