import { PaginationParams, PaginationResponse } from '../pagination.types'
import { CompanyShort } from './company.types-v2'
import { Contract } from './contract.types-v2'
import { CountryShort } from './country.types-v2'
import { Profile } from './profile.types-v2'

export type AbsencesResponse = PaginationResponse<Array<Absence>>
export type AbsenceLeaveReasonTypeResponse = {
  leave_reason_list: Array<AbsenceLeaveReasonType>
}

export interface AbsenceDto {
  contract: number
  current_country: number
  start_date: string
  end_date?: string
  leave_type?: string
  leave_reason_type: string
  start_date_is_half_day?: boolean
  comment?: string
  attached_document?: string
  is_paid?: boolean // deprecated
  status_update_note?: string
  leave_type_update_note?: string
  end_date_is_half_day?: boolean
  status?: AbsenceStatus
}

export interface AbsenceFormValues {
  contract: number
  currentCountry: number
  startDate: string
  endDate: string
  leaveType: string
  leaveReasonType: string
  startDateIsHalfDay: boolean
  comment?: string
  attachedDocument: string
  isPaid?: boolean
}

export interface AbsenceChangeStatusDto {
  id: number
  payload: {
    event_side?: string
    status: string
    reject_reason?: string
  }
}
export interface AbsenceCalculateDto {
  start_date: string
  end_date?: string
  start_date_is_half_day?: boolean
  country: number
}
export interface AbsenceHolidayDto {
  country_id: number
  year?: string
  regions?: string
}
export interface AbsenceHoliday {
  name: number
  id: number
  date: string
}

export interface AbsenceCalculate {
  'total-off-days': number
}

export type LeaveDays = {
  paid_leave: null | PaidLeaveDays
  paid_bereavement_leave: null | PaidLeaveDays
  paid_sick_leave: null | PaidLeaveDays
  paid_parental_leave: null | PaidLeaveDays
}
export interface AbsencePaidLeaves {
  leave_days: LeaveDays
}

export type PaidLeaveDays = {
  annual_days_by_contract: number
  accrued_days: number
  used_days: number
  available_days: number
  available_days_to_be_accrued: number
  carryover_days: number
  ad_hoc_days: number
}

export type AbsencesHolidayResponse = PaginationResponse<Array<AbsenceHoliday>>

export interface AbsenceUpdateDto {
  id: number
  payload: AbsenceDto
}

export interface Absence {
  id: number
  created_at: string
  contract: AbsenceContract
  current_country: CountryShort
  start_date: string
  end_date: string
  leave_type: LeaveType
  leave_reason_type: LeaveReasonType
  start_date_is_half_day: boolean
  end_date_is_half_day: boolean
  comment: string | null
  attached_document: string | null
  request_creator_role: RequestCreateRole
  request_creator: string
  events: Event[]
  total_days: number
  status: AbsenceStatus
  public_holidays: number
  is_paid: boolean
  filename: string
  is_historical: boolean
  balance_transition: {
    balance_before: number
    balance_after: number
  }
  reason: LeaveReasonType
  notes: [
    {
      created_by: {
        id: string
        full_name: string
        email: string
      }
      note: string
      created_by_role: UserRole
      note_type: NoteType
    }
  ]
}

export type BalanceBreakdown = {
  bb_type: BalanceBreakdownType
  bb_action_type: BalanceBreakdownActionType
  number_of_days: number
  created_by: {
    id: string
    full_name: string
    email: string
  }
  created_at: string
  comment: string
  absence: Absence
  attached_document: string | null
}

export enum BalanceBreakdownType {
  VACATION = 'vacation',
  ADHOC = 'adhoc',
  ANNUAL_ENTITLEMENT = 'annual_entitlement',
  CARRYOVER = 'carryover',
}

export enum BalanceBreakdownActionType {
  USER_GENERATED = 'user_generated',
  AUTO_GENERATED = 'auto_generated',
}

export enum NoteType {
  STATUS_UPDATE = 'STATUS_UPDATE',
  LEAVE_TYPE_UPDATE = 'LEAVE_TYPE_UPDATE',
  ISSUE_FLAG = 'ISSUE_FLAG',
}

export enum UserRole {
  PARTNER = 'PARTNER',
  COMPANY = 'COMPANY',
  CONTRACT = 'CONTRACT',
  ADMIN = 'ADMIN,',
}

export enum RequestCreateRole {
  PARTNER = 'PARTNER',
  COMPANY = 'COMPANY',
  CONTRACT = 'CONTRACT',
  ADMIN = 'ADMIN',
}

type AbsenceContract = Pick<Contract, 'id' | 'full_name' | 'email'> & {
  company: Pick<CompanyShort, 'id' | 'name'>
  working_country: CountryShort
}

export enum LeaveType {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum LeaveReasonType {
  VACATION = 'VACATION',
  SICK_LEAVE = 'SICK_LEAVE',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  BEREAVEMENT = 'BEREAVEMENT',
  CAREGIVER_LEAVE = 'CAREGIVER_LEAVE',
  EDUCATIONAL_LEAVE = 'EDUCATIONAL_LEAVE',
  MARRIAGE_LEAVE = 'MARRIAGE_LEAVE',
  MILITARY_TRAINING_ASSEMBLY = 'MILITARY_TRAINING_ASSEMBLY',
  MILITARY_MEDICAL_EXAMINATION = 'MILITARY_MEDICAL_EXAMINATION',
  MILITARY_LEAVE = 'MILITARY_LEAVE',
  SABBATICAL = 'SABBATICAL',
  CIVIC_DUTIES = 'CIVIC_DUTIES',
  RELIGIOUS_OBSERVANCE = 'RELIGIOUS_OBSERVANCE',
}

export enum AbsenceStatus {
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  TAKEN = 'TAKEN',
}

export enum AbsenceEventStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  NEUTRAL = 'NEUTRAL',
}

export interface Event {
  id: number
  status: AbsenceEventStatus
  reject_reason: string | null
  event_side: EventSide
  action_user: AbsenceActionUser | null
  modified_at: string
}

export enum EventSide {
  COMPANY = 'COMPANY',
  PARTNER = 'PARTNER',
  CONTRACT = 'CONTRACT',
}

export interface AbsenceActionUser {
  id: string
  profile: Profile
  is_billing_contact: boolean
  enable_mfa: boolean
  groups: Group[]
}

export interface Group {
  id: number
  name: string
  group_description: GroupDescription
}

export interface GroupDescription {
  short_description: string
  long_description: string[]
}

export type AbsenceOrderBy =
  | keyof Pick<
      Absence,
      | 'created_at'
      | 'leave_reason_type'
      | 'leave_type'
      | 'status'
      | 'total_days'
    >
  | 'company'
  | 'user_full_name'
  | ''

export interface AbsenceLeaveReasonType {
  can_be_unpaid: boolean
  is_paid_time_off: boolean
  reason_type: LeaveReasonType
  name: string
  reason_type_display_name: string
}

export type AbsenceParams = Partial<{
  ordering: string
  company_in: string
  status_in: string
  leave_reason_type: string
  search: string
  contract_type: string
  cs_manager_in: string
  partner: string
}> &
  PaginationParams
