import { AppContext } from '@core/context'
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  Link as MUILink,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { fetchContractors } from '@pages/employees/EmployeesPage.service'
import { getPartnerManagers } from '@remoteam-front/services'
import { PartnerTeam } from '@remoteam-front/types/legacy/partners-team.types'
import {
  AsyncButton,
  DrawerFooter,
  DrawerHeader,
  theme,
} from '@remoteam-front/ui'
import {
  SingleUpload,
  SingleUploadItem,
} from '@remoteam-front/ui/components/single-upload'
import { fetchUploadFile } from '@services/app.service'
import { keyBy } from 'lodash'
import moment from 'moment'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'

const formId = v4()

type Props = {
  loading: boolean
  employee: any
  onSubmit(payload: any): void
  onClose(): void
}

export const UploadDrawer = ({
  onClose,
  onSubmit,
  loading,
  employee,
}: Props) => {
  const { profile } = useContext(AppContext)

  const [singleUploadFile, setSingleUploadFile] = useState<SingleUploadItem>({
    file: null,
    isLoading: false,
    isSuccess: false,
  })

  const [managersHashMap, setManagersHashMap] = useState<{
    [key: string]: PartnerTeam
  }>({})

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      agreement_document_type: undefined,
      subject: undefined,
      title: undefined,
      signers_manager: undefined,
      signers_employee: undefined,
      signed_document: undefined,
    },
  })

  const companyWatch = watch('company')

  const managersQuery = useQuery(getPartnerManagers.key, {
    queryFn: () => getPartnerManagers.fetch({ offset: 0, limit: 1000 }),
    onSuccess: ({ results }) => {
      setManagersHashMap(keyBy(results, 'id'))
    },
  })

  useQuery(['contractors', companyWatch], {
    queryFn: () =>
      fetchContractors('&state_in=active,onboarding', 1, undefined, 1000),
  })

  const handleUploadAgreement = (formValues: any) => {
    const { user } = managersHashMap[formValues.signers_manager]

    const signers = [
      {
        name: `${employee.profile.first_name} ${employee.profile.last_name}`,
        email_address: employee.profile.email,
        role_name: 'employee',
      },
      {
        name: `${user.profile.first_name} ${user.profile.last_name}`,
        email_address: user.email,
        role_name: 'partner_manager',
      },
    ]

    const data = {
      signers,
      signed_document: formValues.file_uuid,
      title: formValues.title,
      agreement_document_type: 'job_contract',
      contract: employee.id,
      partner_profile: profile!.partner_profile_id,
      date_of_signature: moment(formValues.date_of_signature).format(
        'YYYY-MM-DD'
      ),
    }
    onSubmit(data)
  }

  const handleUpload = async ({ file }: SingleUploadItem) => {
    if (file) {
      setSingleUploadFile((prev) => ({ ...prev, file, isLoading: true }))
      fetchUploadFile(file).then(({ file_uuid }) => {
        setSingleUploadFile((prev) => ({
          ...prev,
          isLoading: false,
          isSuccess: true,
          file,
        }))

        setValue('file_uuid', file_uuid)
      })
    }
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <DrawerHeader title="Upload agreement" onClose={onClose} />
      <Box padding={theme.spacing(2, 3)} flex={1}>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={4}
          id={formId}
          onSubmit={handleSubmit(handleUploadAgreement)}
        >
          <FormControl fullWidth required>
            <InputLabel required>Upload signed agreement</InputLabel>
            <Controller
              control={control}
              name="file_uuid"
              rules={{ required: true }}
              render={() => (
                <SingleUpload
                  {...singleUploadFile}
                  label="Drag and drop your file here"
                  helpText="Supported file formats: pdf, jpeg, docx; file size up to 3 MB."
                  isRequired
                  onDelete={() => {
                    setSingleUploadFile({
                      file: null,
                      isLoading: false,
                      isSuccess: false,
                    })
                  }}
                  onSelect={handleUpload}
                />
              )}
            />
            {errors.file_uuid ? (
              <FormHelperText error>Please upload a file</FormHelperText>
            ) : null}
          </FormControl>

          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel required>Title</InputLabel>
                <TextField
                  {...field}
                  placeholder="Enter title"
                  required
                  data-testid="partner-agreement-modal-EFC8CC"
                  helperText="This will be the new name of your uploaded file "
                />
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="signers_manager"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel required>Company signee</InputLabel>
                  <Select
                    data-testid="partner-agreement-modal-FB8E08"
                    {...field}
                    disabled={managersQuery.isFetching}
                    required
                    displayEmpty
                    renderValue={(value) =>
                      value ? (
                        `${managersHashMap[value].user.profile.first_name} ${managersHashMap[value].user.profile.last_name}`
                      ) : (
                        <span style={{ color: theme.palette.secondary[400] }}>
                          Select
                        </span>
                      )
                    }
                  >
                    {managersQuery.data?.results.map(({ id, user }) => (
                      <MenuItem key={id} value={id}>
                        {`${user.profile.first_name} ${user.profile.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    If the person is not on the list, please add them as a
                    manager to{' '}
                    <Link
                      to="/pages/profile"
                      style={{ textDecoration: 'none' }}
                    >
                      <MUILink>your account</MUILink>
                    </Link>{' '}
                    first
                  </FormHelperText>
                </FormControl>
              )
            }}
          />

          <Controller
            control={control}
            name="date_of_signature"
            rules={{ required: 'Date of signature is required' }}
            render={({ field }) => (
              <FormControl fullWidth required>
                <InputLabel>Date of signature</InputLabel>
                <DatePicker
                  {...field}
                  slotProps={{ textField: { required: true } }}
                  format="dd MMM yyyy"
                  value={field.value ? moment(field.value).toDate() : null}
                  onChange={(newValue) =>
                    field.onChange(moment(newValue).format('YYYY-MM-DD'))
                  }
                />
                <FormHelperText>
                  On this day the uploaded agreement is signed by all parties
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </Box>
      <DrawerFooter
        secondaryActions={[
          <Button
            data-testid="partner-agreement-modal-7D605F"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>,
        ]}
        primaryActions={[
          <AsyncButton
            isFetching={loading}
            data-testid="partner-agreement-modal-814DC8"
            form={formId}
            variant="contained"
            type="submit"
          >
            Submit
          </AsyncButton>,
        ]}
      />
    </Drawer>
  )
}
