import { WorkScheduleTable } from '@components/work-schedule-table/work-schedule-table.component'
import { Alert, Box } from '@mui/material'
import { WorkSchedule } from '@remoteam-front/types/legacy'

type Props = {
  data: WorkSchedule
}

export const WorkScheduleTab = ({ data }: Props) => {
  if (!data) {
    return (
      <Alert severity="info">
        No information on the work schedule is available for your country.
        Please reach out to your Partner Relationship Manager at the Remofirst
        team to discuss country-specific guides.
      </Alert>
    )
  }
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={4}>
      <Alert severity="info">
        If you spot any mistake in the tables below, please reach out to your
        Partner Relationship manager at Remofirst team.
      </Alert>
      <WorkScheduleTable data={data} />
    </Box>
  )
}
