/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './style.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { CloseIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'

export default function ModalSide({
  wrapperStyle,
  className,
  title,
  isFullScreen,
  footer = true,
  secondaryActions = [],
  primaryActions = [],
  okText,
  onOk,
  okButtonProps = { loading: false },
  onClose,
  ...rest
}) {
  return (
    <div
      data-testid="ModalSide-4172E0"
      className="modalSideWrapper"
      onClick={onClose}
      style={wrapperStyle}
    >
      <div
        {...rest}
        onClick={(e) => e.stopPropagation()}
        className={classNames([
          'modalSideBox',
          className,
          isFullScreen && 'modalBox_full',
        ])}
      >
        <div className="modalSideHeader heading_semibold__24">
          {title}
          <Icon icon={CloseIcon} onClick={onClose} />
        </div>
        <div className="modalSideContent">{rest.children}</div>
        {footer && (
          <div className="modalSideFooter">
            {secondaryActions.length === 0 && (
              <Button
                data-testid="ModalSide-E4D27C"
                priority="secondary"
                size="small"
                onClick={onClose}
              >
                Close
              </Button>
            )}
            {(primaryActions.length === 0 && okText && (
              <Button
                data-testid="ModalSide-245571"
                size="small"
                onClick={onOk}
                disabled={okButtonProps.loading || okButtonProps.disabled}
                loading={okButtonProps.loading}
                {...okButtonProps}
              >
                {okText}
              </Button>
            )) ||
              null}
          </div>
        )}

        {primaryActions.length || secondaryActions.length ? (
          <div className="modalSideFooter">
            <div className="d-flex gap-2">
              {secondaryActions.map((action) => action)}
            </div>

            <div className="d-flex gap-2">
              {primaryActions.map((action) => action)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
