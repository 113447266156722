import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const deactivateContract = async (id) => Api.delete(`contracts/${id}/`)

export const forceActivateContract = async (id) => {
  const { data } = await Api.patch(`contracts/${id}/activate/`)
  return data
}

export const mutationUploadAvatar = async (id, file) => {
  const fd = new FormData()
  fd.append('file', file)

  return Api.post(`/contracts/${id}/avatar/`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getContracts = async (params) => {
  const { data } = await Api.get(`contracts/`, {
    params,
  })
  return data
}

export const getContractsShort = generateService(async (params) => {
  const { data } = await Api.get(`contracts-short-list/`, {
    params,
  })
  return data
})

export const getContractEmergencyContact = async (id, user_id) => {
  const { data } = await Api.get(`user/emergency-contacts/?user_id=${user_id}`)

  return data
}

export const patchEmergencyContact = async (id, data) =>
  Api.put(`user/emergency-contacts/${id}/update/`, data)

export const getContractsPaidLeaves = async (id) => {
  const { data } = await Api.get(`contracts/${id}/paid-leaves/`)
  return data
}

export const getContractXLSFile = async (id) => {
  const { data } = await Api.get(`contracts/employee/${id}/information/`, {
    responseType: 'blob',
  })
  return data
}

export const postContractsPaidLeaves = async (id, data) => {
  const v = await Api.post(`/contracts/${id}/paid-leaves/update/`, data)
  return v.data
}

export const getContractNotes = async (id) => {
  const { data } = await Api.get(`contracts/${id}/notes/`)
  return data
}

export const createContractNote = async (id, payload) => {
  const { data } = await Api.post(`/contracts/${id}/notes/create/`, payload)
  return data
}

export const updateStartDate = async (id, payload) => {
  const { data } = await Api.put(`/contracts/${id}/update-start-date/`, payload)
  return data
}
