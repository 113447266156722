import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { numberWithSpaces, SplitNumber } from '@core/utils'
import PayrollDetails from '@pages/payrollsDetailPage/PayrollDetails'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const payrollsField = [
  {
    title: 'Company',
    key: 'company_name',
    type: 'custom',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
    render: (item) => (
      <div>
        {item.full_name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at
                        ? moment(item?.job?.starts_at).format('DD MMM YYYY')
                        : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at
                        ? moment(item?.job?.ends_at).format('DD MMM YYYY')
                        : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${
                item.indication === 'first' ? 'color_blue' : 'color_red'
              }`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${
                  item.indication === 'first' ? '#2967bc' : '#ef4343'
                }`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Month',
    key: 'current_month',
    type: 'string',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
  },
  {
    title: 'Approved employees',
    key: 'count',
    type: 'custom',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item.total_count - item.count}/{item.total_count}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total Amount',
    key: 'total_amount',
    type: 'custom',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {SplitNumber(item.total_amount)}{' '}
            {item?.currency?.sign || item?.currency?.short_code}{' '}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
]

export const approvedPayrollsField = [
  {
    title: 'Name',
    key: 'company_name',
    type: 'custom',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
    render: (item) => (
      <div>
        {item?.user?.full_name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at
                        ? moment(item?.job?.starts_at).format('DD MMM YYYY')
                        : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at
                        ? moment(item?.job?.ends_at).format('DD MMM YYYY')
                        : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${
                item.indication === 'first' ? 'color_blue' : 'color_red'
              }`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${
                  item.indication === 'first' ? '#2967bc' : '#ef4343'
                }`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    key: 'current_month',
    type: 'custom',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
    render: (item) => {
      return <div>{item.country?.name}</div>
    },
  },
  {
    title: 'Gross salary',
    key: 'total_amount',
    type: 'custom',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item?.partner_currency?.sign || item?.partner_currency?.short_code}{' '}
            {numberWithSpaces(item?.gross_pay_in_partner_currency)}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'state',
    type: 'custom',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
    render: (item) => <Status status={item.state} />,
  },
]

export const parserPayrollsBasePayment = (payrolls, invoiceTotal, loading) => {
  return payrolls.map((payroll) => ({
    ...payroll,
    state: `flag_payroll_${payroll.state}`,
    collapseContent: loading ? (
      <div
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ height: 200 }}
      >
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <PayrollDetails payroll={payroll} invoiceTotal={invoiceTotal} />
    ),
  }))
}
