export const EmployeeStatusTooltip = () => (
  <div>
    Definition of FTE status
    <div>
      <ul>
        <li>
          <strong>Onboarding</strong>: The onboarding process for the employee
          has begun.
        </li>
        <li>
          <strong>Active</strong>: The employee is currently active on the
          platform.
        </li>
        <li>
          <strong>Inactive</strong>: The employee is currently inactive.
        </li>
      </ul>
    </div>
  </div>
)
