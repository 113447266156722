import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import React, { memo, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

export const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'EXPENSES', label: 'Expenses' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

export const expenseTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

const Form = ({ data, isLoading, onClose, onSave }) => {
  const { currencies } = useContext(AppContext)

  const {
    control,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      ap_type: data.ap_type || '',
      amount: data.amount || 0,
      name: data.name || '',
      currency: data.currency.id || '',
      description: data.description || '',
      tax: data.tax,
    },
  })
  const submit = (formValues) => {
    let body = {
      amount: formValues.amount,
      name: formValues.name,
      description: formValues.description,
      currency: formValues.currency.value,
      ap_type: formValues.ap_type.value,
    }
    if (formValues.tax) {
      body = { ...body, tax: formValues.tax }
    }
    onSave(body)
  }
  return (
    <StyledForm
      noValidate
      className="d-flex flex-column"
      onSubmit={handleSubmit(submit)}
    >
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="ap_type"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-0E7102"
                  {...field}
                  label="Payment type"
                  options={
                    data?.source === 'EXPENSE_MANAGEMENT'
                      ? expenseTypes
                      : paymentTypes
                  }
                />
              )
            }}
          />
          {errors.ap_type && (
            <Typography className="text_regular__14 color_red">
              {errors.ap_type.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            rules={{
              required: 'asd is required',
            }}
            name="amount"
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Amount"
                placeholder="Enter the adjustment amount"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.amount && (
            <Typography className="text_regular__14 color_red">
              {errors.amount.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="tax"
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Tax"
                placeholder="Enter the tax"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.tax && (
            <Typography className="text_regular__14 color_red">
              {errors.tax.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Currency is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-88B99B"
                  {...field}
                  label="Currency"
                  options={currencies.map((c) => ({
                    value: c.id,
                    label: c.name || c.short_code,
                  }))}
                />
              )
            }}
          />
          {errors.currency && (
            <Typography className="text_regular__14 color_red">
              {errors.currency.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Input
            data-testid="Form-189414"
            {...register('name', { required: 'Field is required' })}
            label="Reason name"
            placeholder="Title"
          />
          {errors.name && (
            <Typography className="text_regular__14 color_red">
              {errors.name.message}
            </Typography>
          )}
        </div>
        <div className="col-12">
          <TextArea placeholder="Description..." {...register('description')} />
          {errors.description && (
            <Typography className="text_regular__14 color_red">
              {errors.description.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="align-self-end mb-0">
        <Button
          data-testid="Form-BF8089"
          className="mr-3"
          priority="secondary"
          size="small"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="Form-2D1518"
          size="small"
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </StyledForm>
  )
}

Form.displayName = 'New Additional Pay Form'

export default memo(Form)
