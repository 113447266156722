import './PageTitle.scss'

import classNames from 'classnames'
import React from 'react'

export default function PageTitle({ children, styleClass }) {
  return (
    <div className={classNames(['page-title-text', styleClass])}>
      {children}
    </div>
  )
}
