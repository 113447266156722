import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { AsyncButton, theme } from '@remoteam-front/ui'
import { approveCompensation, getContractById } from '@services/app.service'
import { useMutation, useQueryClient } from 'react-query'

type Props = { compensationId: number; onClose(): void }

export const CompensationApproveConfirm = ({
  compensationId,
  onClose,
}: Props) => {
  const queryClient = useQueryClient()

  const approveMutation = useMutation({
    mutationFn: approveCompensation,
    onSuccess: () => {
      onClose()
      queryClient.refetchQueries(getContractById.key)
    },
  })

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Approve compensation</DialogTitle>
      <DialogContent>
        <Typography color={theme.palette.secondary[300]} width={500}>
          You are about to approve the employee compensation. Once approved,
          automatic invoicing will be scheduled based on this info.
          <br />
          <br />
          Can you confirm that all items have been verified?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="agreement-confirm.component-2AED5C"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="agreement-confirm.component-EFB545"
          isFetching={approveMutation.isLoading}
          variant="contained"
          onClick={() => approveMutation.mutate(compensationId)}
        >
          Confirm and approve
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
