/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useTable } from 'react-table'
import './CollapseTable.scss'
import { keyboardArrowLeft } from '@core/icons/icons'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { v4 as uuidv4 } from 'uuid'
import Pagination from '@atoms/Pagination/Pagination'

export default function CollapseTable({
  columns: columnsData,
  data,
  onCollapseClick,
  collapseId,
  id,
  page,
  total,
  pageSize = 5,
  onPage,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnsData,
      data,
    })

  const [activeId, setActiveId] = useState()

  const onClickCollapse = (row) => {
    onCollapseClick(row)
    setActiveId((prevState) => (prevState === row.id ? undefined : row.id))
  }

  useEffect(() => {
    setActiveId(collapseId)
  }, [collapseId])

  return (
    <>
      <table
        id={id}
        className="collapse-table mb-4"
        {...getTableProps()}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                  }}
                >
                  <div className="d-flex align-items-center table_header">
                    <Typography className="text_medium__14">
                      {column.render('Header')}
                    </Typography>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <React.Fragment key={uuidv4()}>
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  className="collapse-table-row"
                  {...row.getRowProps()}
                  role="button"
                  aria-hidden
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {index === 0 &&
                          onCollapseClick &&
                          row.original.collapseContent && (
                            <div
                              style={{ padding: 12 }}
                              onClick={() => {
                                onClickCollapse(row.original)
                              }}
                            >
                              <Icon
                                fill="#B2B2B2"
                                style={{
                                  cursor: 'pointer',
                                  transform:
                                    activeId === row.original.id
                                      ? 'rotate(90deg)'
                                      : 'none',
                                }}
                                icon={keyboardArrowLeft}
                              />
                            </div>
                          )}

                        {cell.render('Cell')}
                      </div>
                    </td>
                  ))}
                </tr>
                {activeId === row.original.id && (
                  <tr className="collapse-content_row">
                    <td
                      colSpan={row.cells.length}
                      className="collapse-content"
                      style={{ height: 200, width: '100%' }}
                    >
                      {row.original.collapseContent}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      {!data.length && (
        <p style={{ color: '#00000073', textAlign: 'center', margin: 24 }}>
          No Data
        </p>
      )}
      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination
          page={page}
          pageSize={pageSize}
          total={total}
          onGetPage={onPage}
        />
      )}
    </>
  )
}
