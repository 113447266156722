import { Country } from './country.types-v2'

export interface ProbationResponse {
  country: Pick<Country, 'id' | 'name'>
  fixed_contract_is_applicable: boolean
  fixed_contract_minimum_day: number | null
  fixed_contract_maximum_day: number | null
  fixed_contract_suggested_day: number | null
  fixed_contract_note: string | null
  permanent_contract_is_applicable: boolean
  permanent_contract_minimum_day: number | null
  permanent_contract_maximum_day: number | null
  permanent_contract_suggested_day: number | null
  probation_type: ProbationType
  permanent_contract_note: string | null
  segments?: ProbationSegment[] | null
}

export interface ProbationSegment {
  min_period_in_months: number
  max_period_in_months?: number
  probation_days?: number
}

export enum ProbationType {
  STANDARD = 'standard',
  SPECIFIC_CALCULATION = 'specific_calculation',
  SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT = 'specific_to_duration_of_fixed_contract',
}

export const ProbationTypes = {
  [ProbationType.STANDARD]: 'Standard',
  [ProbationType.SPECIFIC_CALCULATION]: 'Specific calculation',
  [ProbationType.SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT]:
    'Based on Contract duration',
}
