import { DotWave } from '@uiball/loaders'
import React from 'react'

export const Loader = () => {
  return (
    <div className="d-flex w-100 h-100 align-items-center justify-content-center p-5">
      <DotWave size={40} speed={1} color="black" />
    </div>
  )
}
