/* eslint-disable react/jsx-boolean-value */
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { StringToBoolean } from '@core/utils'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import {
  createPartnerManager,
  getPartnerContactTypes,
  updatePartnerUsers,
} from '@remoteam-front/services'
import {
  ManagerDTO,
  PartnerManager,
  UpdateManagerDTO,
} from '@remoteam-front/types/legacy/partners-team.types'
import { AsyncButton, theme } from '@remoteam-front/ui'
import { getPartnerUsersByOwner } from '@services/team.service'
import { DotWave } from '@uiball/loaders'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const modalTitle = (
  isCreateMode: boolean,
  isPartnerOwner: boolean,
  partnerManager: PartnerManager | null
) => {
  if (isCreateMode) {
    return 'Add key contact'
  }

  if (isPartnerOwner) {
    return 'Edit main contact'
  }

  return `${partnerManager?.profile.first_name} ${partnerManager?.profile.last_name}`
}

type Props = {
  partnerManager: PartnerManager | null
  onClose(): void
}

export const KeyContactsModal = ({ partnerManager, onClose }: Props) => {
  const { successAlert } = useToast()
  const isPartnerOwner = partnerManager?.role.name === 'partner_owner'

  const isCreateMode = !partnerManager

  const { profile } = useApp() as any
  const {
    formState: { errors },
    handleSubmit,
    watch,
    control,
  } = useForm<ManagerDTO>({
    defaultValues: {
      email: partnerManager?.email || '',
      is_billing_contact: partnerManager?.is_billing_contact || false,
      profile: {
        first_name: partnerManager?.profile?.first_name || '',
        last_name: partnerManager?.profile?.last_name || '',
        job_title: partnerManager?.profile?.first_name || '',
      },
      responsibility: {
        contact_types:
          partnerManager?.responsibility?.contact_types?.map(({ id }) => id) ||
          [],
      },
    },
  })

  const contactTypes = watch('responsibility.contact_types')

  const contactTypesQuery = useQuery(getPartnerContactTypes.key, {
    queryFn: () => getPartnerContactTypes.fetch({ limit: 1000, offset: 0 }),
  })

  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: createPartnerManager,
    onSuccess: () => {
      successAlert('Contact is created')
      queryClient.refetchQueries(getPartnerUsersByOwner.key)
      onClose()
    },
  })

  const updateMutation = useMutation({
    mutationFn: (body: UpdateManagerDTO) =>
      updatePartnerUsers(partnerManager!.id, body),
    onSuccess: () => {
      successAlert('Contact is updated')
      queryClient.refetchQueries(getPartnerUsersByOwner.key)
      onClose()
    },
  })

  const submit: SubmitHandler<ManagerDTO> = (manager) => {
    if (isCreateMode) {
      createMutation.mutate({ partner: profile.id, manager })
    } else {
      updateMutation.mutate({
        is_billing_contact: manager.is_billing_contact,
        profile: manager.profile,
        responsibility: manager.responsibility,
      })
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {modalTitle(isCreateMode, isPartnerOwner, partnerManager)}
      </DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              name="profile.first_name"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="key-contacts-modal.component-E8AF87"
                  {...field}
                  required
                  label="First name"
                  fullWidth
                  placeholder="Enter manager name"
                  error={!!errors.profile?.first_name}
                />
              )}
            />

            <Controller
              control={control}
              name="profile.last_name"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="key-contacts-modal.component-6E9A33"
                  {...field}
                  required
                  label="Last name"
                  placeholder="Enter manager name"
                  fullWidth
                  error={!!errors.profile?.last_name}
                />
              )}
            />

            <Controller
              control={control}
              name="profile.job_title"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  data-testid="key-contacts-modal.component-9A10AB"
                  {...field}
                  required
                  label="Job title"
                  placeholder="Enter"
                  fullWidth
                  error={!!errors.profile?.job_title}
                />
              )}
            />

            {contactTypesQuery.isLoading && <DotWave size={40} />}
            {contactTypesQuery.data && (
              <Controller
                control={control}
                name="responsibility.contact_types"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl
                    required
                    error={!!errors.responsibility?.contact_types}
                  >
                    <FormLabel>Contact types</FormLabel>
                    <FormGroup>
                      <Box display="flex" flexDirection="column" gap={1}>
                        {contactTypesQuery.data.results.map(
                          ({ id, name, description }) => (
                            <FormControlLabel
                              key={id}
                              control={
                                <Checkbox
                                  data-testid="key-contacts-modal.component-DCA348"
                                  value={id}
                                  checked={contactTypes.includes(id)}
                                  onChange={(e) => {
                                    const value = Number(e.target.value)
                                    if (contactTypes.includes(value)) {
                                      field.onChange(
                                        contactTypes.filter(
                                          (id) => id !== value
                                        )
                                      )
                                    } else {
                                      field.onChange([...contactTypes, id])
                                    }
                                  }}
                                />
                              }
                              label={
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignContent="flex-start"
                                  mt={1}
                                >
                                  <Typography>{name}</Typography>
                                  <Typography
                                    variant="textSmall2"
                                    color={theme.palette.secondary[500]}
                                  >
                                    {description}
                                  </Typography>
                                </Box>
                              }
                            />
                          )
                        )}
                      </Box>
                    </FormGroup>
                    {errors.responsibility?.contact_types && (
                      <FormHelperText>
                        Select at least one contact type
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            )}

            <Controller
              control={control}
              name="email"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  disabled={isPartnerOwner}
                  data-testid="key-contacts-modal.component-2048EE"
                  type="email"
                  {...field}
                  required
                  label="Email"
                  placeholder="Enter email"
                  fullWidth
                  error={!!errors.email}
                />
              )}
            />

            <Controller
              control={control}
              name="is_billing_contact"
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Billing contact</FormLabel>
                  <RadioGroup
                    row
                    {...field}
                    onChange={(e) => {
                      field.onChange(StringToBoolean(e.target.value))
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          data-testid="key-contacts-modal.component-87848F"
                          value={false}
                        />
                      }
                      label="No"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          data-testid="key-contacts-modal.component-818DAD"
                          value={true}
                        />
                      }
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="key-contacts-modal.component-75DADF"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <AsyncButton
            data-testid="key-contacts-modal.component-1A7CBA"
            isFetching={createMutation.isLoading || updateMutation.isLoading}
            type="submit"
            variant="contained"
          >
            {isCreateMode ? 'Add manager' : 'Save'}
          </AsyncButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
