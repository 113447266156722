const Logo = () => (
  <svg
    data-testid="logo-98DB8B"
    width="104"
    height="18"
    viewBox="0 0 104 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.80385 6.65044C2.80385 6.10173 2.80385 5.53101 2.80385 4.9818C2.79905 4.97793 0.00411097 4.9855 0 4.98735C0 9.22692 0 13.4936 0 17.7333C0.971902 17.7333 1.94397 17.7333 2.94174 17.7333C2.94174 17.6298 2.94174 17.5447 2.94174 17.4597C2.94174 15.3872 2.93985 13.3147 2.94379 11.2424C2.94431 10.949 2.95801 10.6541 2.99073 10.3626C3.13855 9.04794 3.95047 8.16379 5.28328 7.95741C5.91723 7.85927 6.57155 7.88851 7.21663 7.86112C7.30211 7.85742 7.38792 7.86062 7.4758 7.86062C7.4758 6.8874 7.4758 5.9483 7.4758 4.99726C5.68564 4.91945 4.00614 5.09322 2.80385 6.65044Z"
      fill="#04C53A"
    />
    <path
      d="M42.7771 9.05028C42.4588 7.56163 41.6801 6.37162 40.2976 5.62124C39.3038 5.08178 38.2276 4.91457 37.102 5.00532C35.9535 5.09808 34.9129 5.46428 34.013 6.17869C33.6764 6.4459 33.3609 6.73849 33.0426 7.01343C32.2477 5.75603 30.9876 5.10682 29.4203 5.00044C27.8791 4.89574 26.4955 5.26211 25.3592 6.43363C25.3592 6.00862 25.3592 5.36495 25.3592 4.97876C24.4354 4.97876 23.5386 4.97876 22.6333 4.97876C22.6333 9.14926 22.6333 13.5705 22.6333 17.7266C23.5889 17.7266 24.5281 17.7266 25.492 17.7266C25.492 17.6308 25.492 17.5471 25.492 17.4634C25.492 15.2993 25.4903 13.1351 25.494 10.971C25.4945 10.6964 25.5012 10.4186 25.5428 10.148C25.6845 9.22741 26.1006 8.46628 26.957 8.00228C27.6804 7.61037 28.4589 7.56298 29.2526 7.73843C30.1255 7.93153 30.7203 8.45687 31.0623 9.26069C31.2819 9.77679 31.3472 10.3208 31.3468 10.8761C31.3451 13.0729 31.3462 15.2699 31.3462 17.4667C31.3462 17.5501 31.3462 17.6333 31.3462 17.7225C32.3133 17.7225 33.2567 17.7225 34.2112 17.7225C34.2112 17.6207 34.2112 17.5356 34.2112 17.4508C34.2112 15.3326 34.21 13.2144 34.2127 11.096C34.213 10.834 34.2177 10.5704 34.2475 10.3105C34.3451 9.45765 34.6682 8.71383 35.3886 8.18294C36.0399 7.70313 36.7905 7.58499 37.576 7.67037C38.6491 7.78683 39.4129 8.32545 39.816 9.32707C40.0261 9.84906 40.0772 10.3952 40.078 10.9476C40.0811 13.125 40.0792 15.3022 40.0792 17.4794C40.0792 17.5614 40.0792 17.6432 40.0792 17.7314C41.046 17.7314 41.9797 17.7314 42.935 17.7314C42.9389 17.6516 42.9444 17.5936 42.9444 17.5358C42.9447 15.2012 42.9535 12.8665 42.9372 10.532C42.9334 10.0373 42.8805 9.53445 42.7771 9.05028Z"
      fill="#04C53A"
    />
    <path
      d="M18.8829 6.79393C16.9182 4.86917 13.6743 4.47508 11.2171 5.84256C8.32679 7.45103 7.24081 10.916 8.35111 13.8448C9.28739 16.3144 11.5592 17.9023 14.2473 17.9882C18.1727 18.0901 20.0739 15.5102 20.509 14.1293H17.5619C16.4019 15.5484 14.6138 15.7774 13.2072 15.3568C11.7774 14.9291 10.9511 13.6982 10.7771 12.3704C14.0854 12.3704 17.3783 12.3704 20.6852 12.3704C20.7524 11.658 20.7505 10.968 20.6385 10.2809C20.4201 8.94153 19.8677 7.75875 18.8829 6.79393ZM10.8633 10.3151C11.0995 8.97581 12.2798 7.33642 14.6315 7.47271C16.5767 7.58548 17.6767 8.98808 17.8242 10.3151C15.5033 10.3151 13.1839 10.3151 10.8633 10.3151Z"
      fill="#04C53A"
    />
    <path
      d="M56.2413 7.05195C54.5546 5.346 52.4776 4.7173 50.1081 5.11492C46.1098 5.78598 43.7227 9.61583 44.887 13.4295C45.7184 16.1529 48.3255 18.0027 51.3291 18.0002C52.8167 17.999 54.2771 17.5454 55.3994 16.6895C57.3172 15.2269 58.1709 13.2875 57.9929 10.9186C57.8813 9.43013 57.2997 8.12247 56.2413 7.05195ZM55.1278 12.0209C54.9986 13.1185 54.5089 14.0278 53.5822 14.6831C52.9102 15.1582 52.1461 15.3548 51.0293 15.3679C49.2176 15.3667 47.6011 13.8668 47.4815 11.8031C47.4082 10.5391 47.7652 9.43029 48.7431 8.56984C49.6139 7.8035 50.6602 7.5447 51.8075 7.66889C52.9445 7.79208 53.8564 8.30885 54.4964 9.24627C55.0705 10.0871 55.2446 11.0289 55.1278 12.0209Z"
      fill="#04C53A"
    />
    <path
      d="M93.4928 11.8039C92.9918 11.3196 92.3822 11.0013 91.7119 10.8354C90.7777 10.6041 89.8251 10.4453 88.8865 10.2302C88.4487 10.1299 88.0158 9.99275 87.5982 9.82923C87.2835 9.70605 87.0735 9.45094 86.9973 9.11483C86.8515 8.47251 87.1307 7.89221 87.7289 7.5887C88.1105 7.39493 88.5225 7.3351 88.9469 7.32787C90.1317 7.30787 90.9752 7.83356 91.4303 8.87164H94.37C94.3434 8.66981 94.2972 8.3305 94.2057 8.08901C93.7324 6.84051 92.8041 6.00981 91.5723 5.49942C90.3937 5.01104 89.1616 4.92062 87.9057 5.10465C87.1748 5.21187 86.4788 5.43337 85.8514 5.8241C84.5216 6.65228 83.9934 7.85356 84.159 9.37195C84.2476 10.1835 84.5518 10.8875 85.2111 11.4142C85.6564 11.77 86.1619 12.0315 86.7138 12.1644C87.5912 12.3756 88.4843 12.5232 89.3642 12.7252C89.8669 12.8406 90.3661 12.986 90.8491 13.164C91.1792 13.2855 91.3935 13.5519 91.4664 13.9081C91.6144 14.6288 91.2803 15.2131 90.5499 15.4882C90.0888 15.662 89.6057 15.7003 89.1158 15.6922C87.9672 15.6732 87.1038 15.0941 86.6734 14.049H83.7373C83.7914 14.2896 83.879 14.6847 83.8863 14.7063C84.1845 15.5818 84.7157 16.2951 85.4608 16.8533C86.3313 17.5056 87.327 17.844 88.4096 17.9484C89.6746 18.0702 90.9082 17.9561 92.0906 17.4642C93.6184 16.8288 94.4553 15.5662 94.382 13.988C94.3436 13.1606 94.1131 12.4034 93.4928 11.8039Z"
      fill="#04C53A"
    />
    <path
      d="M63.6993 4.01226C63.7836 3.20777 64.2099 2.75469 65.0235 2.60495C65.3156 2.55117 65.619 2.54815 65.9175 2.53924C66.2764 2.52865 66.6356 2.53672 66.9999 2.53672C66.9999 1.68215 66.9999 0.851615 66.9999 0.00141389C65.9377 0.0168751 64.8875 -0.0725328 63.8605 0.221399C63.2238 0.403573 62.6402 0.680866 62.1394 1.11008C61.2236 1.89508 60.7978 2.90493 60.75 4.081C60.7382 4.37291 60.7483 4.66567 60.7483 4.98733C60.0667 4.98733 59.4102 4.98733 58.7524 4.98733C58.7524 5.88173 58.7524 6.74622 58.7524 7.62919C59.422 7.62919 60.0734 7.62919 60.7507 7.62919C60.7507 11.0066 60.7507 14.3652 60.7507 17.7254C61.7341 17.7254 62.6911 17.7254 63.6673 17.7254C63.6673 14.3575 63.6673 11.0038 63.6673 7.62229C64.8601 7.62229 66.0342 7.62229 67.2144 7.62229C67.2144 6.73428 67.2144 5.87383 67.2144 4.98111C66.0306 4.98111 64.8567 4.98111 63.694 4.98111C63.694 4.63642 63.6669 4.32132 63.6993 4.01226Z"
      fill="#04C53A"
    />
    <path
      d="M104 7.62436C104 6.74341 104 5.8838 104 4.98267C102.847 4.98267 101.713 4.98267 100.552 4.98267C100.552 3.91081 100.552 2.86566 100.552 1.81665C99.5687 1.81665 98.6121 1.81665 97.6368 1.81665C97.6368 1.93076 97.6368 2.02991 97.6368 2.12906C97.6366 2.83793 97.6429 3.5468 97.634 4.25549C97.6277 4.76303 97.4007 4.97965 96.8971 4.98385C96.502 4.98704 96.1066 4.98385 95.7113 4.98553C95.6398 4.98587 95.5684 4.99646 95.4985 5.00234C95.4985 5.88682 95.4985 6.74593 95.4985 7.6331C96.218 7.6331 96.9216 7.6331 97.6368 7.6331C97.6368 7.74066 97.6368 7.82032 97.6368 7.89998C97.6368 9.83247 97.6318 11.7649 97.6409 13.6974C97.6427 14.0967 97.6654 14.5008 97.7313 14.894C97.955 16.2255 98.7229 17.1117 100.052 17.4925C101.344 17.8626 102.67 17.7323 103.993 17.7175C103.993 16.8336 103.993 15.9497 103.993 15.049C103.892 15.049 103.811 15.0493 103.73 15.049C103.13 15.0461 102.53 15.0562 101.93 15.037C101.223 15.0142 100.759 14.6683 100.618 14.0725C100.574 13.8858 100.559 13.6887 100.559 13.4963C100.555 11.6172 100.556 9.73835 100.556 7.85931C100.556 7.78805 100.556 7.71679 100.556 7.62453C101.716 7.62436 102.851 7.62436 104 7.62436Z"
      fill="#04C53A"
    />
    <path
      d="M80.3764 5.18463C79.4832 5.43268 78.7534 5.91517 78.1674 6.65059C78.1674 6.10273 78.1674 5.55486 78.1674 5.00296C77.2203 5.00296 76.306 5.00296 75.3877 5.00296C75.3877 9.25598 75.3877 13.4897 75.3877 17.7261C76.362 17.7261 77.3193 17.7261 78.3022 17.7261C78.3022 17.6282 78.3022 17.5434 78.3022 17.4583C78.3022 15.3864 78.3005 13.3144 78.3043 11.2422C78.3048 10.949 78.3185 10.654 78.351 10.3626C78.4985 9.04809 79.3111 8.16243 80.642 7.95673C81.2758 7.85875 81.93 7.88766 82.575 7.86027C82.6607 7.85657 82.7465 7.85976 82.8411 7.85976C82.8411 6.89293 82.8411 5.95887 82.8411 4.99052C82.0016 4.97556 81.18 4.96145 80.3764 5.18463Z"
      fill="#04C53A"
    />
    <path
      d="M72.59 5.02224C72.59 5.01502 72.59 5.00796 72.59 5.00073C71.6158 5.00073 70.6593 5.00073 69.6902 5.00073C69.6902 5.87967 69.6902 6.75626 69.6902 7.63402C71.2856 7.63234 72.5788 6.46535 72.59 5.02224Z"
      fill="#04C53A"
    />
    <path
      d="M72.59 17.7235C72.59 14.7848 72.59 11.8538 72.59 8.92285H69.6902C69.6902 11.8573 69.6902 14.7894 69.6902 17.7235C70.6648 17.7235 71.6261 17.7235 72.59 17.7235Z"
      fill="#04C53A"
    />
  </svg>
)

export default Logo
