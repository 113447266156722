import { ReactNode } from 'react'

import { Styled } from './drawer.styles'

type Props = {
  children?: ReactNode
}

export const DrawerBody = ({ children }: Props) => (
  <Styled.Body>{children}</Styled.Body>
)
