const { useMemo } = require('react')
const { useLocation, useHistory } = require('react-router-dom')

export function useRouteQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useSearchParams = (initParams = window.location.search) => {
  const { replace } = useHistory()
  const params = new URLSearchParams(initParams)

  const setParams = (object = {}) => {
    Object.keys(object).forEach((key) => {
      params.set(key, object[key])
    })
    replace({ pathname: window.location.pathname, search: params.toString() })
  }

  return { params, setParams }
}
