import { useNotification } from 'react-hook-notification'
import { toast } from 'react-toastify'

export const useErrorNotification = () => {
  const notification = useNotification()

  const errorAlert = async (asyncFunction) => {
    try {
      await asyncFunction
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        notification.error({
          text: error.response.data.errors[0].message,
        })
      } else {
        notification.error({
          text: 'Something went wrong!',
        })
      }
    }
  }

  return { errorAlert }
}

export const useToast = () => {
  const successAlert = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    })
  }

  const failedAlert = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    })
  }

  return { successAlert, failedAlert }
}
