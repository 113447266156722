import { Box, Typography } from '@mui/material'
import { theme } from '@remoteam-front/ui'
import { CheckCircle, RadioButtonUnchecked } from '@remoteam-front/ui/icons'
import { HTMLAttributes, PropsWithChildren } from 'react'

import { Styled } from './timeline-base.style'

type Props = {
  title: string
  isActive: boolean
  checked: boolean
  hideCheckmark?: boolean
} & PropsWithChildren &
  HTMLAttributes<HTMLDivElement>

export const TimelineBase = ({
  title,
  checked,
  isActive,
  children,
  hideCheckmark,
  ...rest
}: Props) => {
  return (
    <Styled.Root isActive={isActive} role="button" {...rest}>
      {!hideCheckmark &&
        (checked ? (
          <CheckCircle
            data-testid="timeline-base.component-7C1615"
            fill={theme.palette.primary.main}
          />
        ) : (
          <RadioButtonUnchecked
            data-testid="timeline-base.component-8FA76E"
            fill={theme.palette.primary.main}
          />
        ))}
      <Typography
        variant="textLarge3"
        color={isActive ? theme.palette.primary.main : 'black'}
      >
        {title}
      </Typography>

      {children}
    </Styled.Root>
  )
}
