import { useToast } from '@core/hooks/useErrorNotification'
import {
  addUserSession,
  linkUserAccount,
  resetUserPassword,
} from '@services/auth.service'
import { React, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'

import { LoginForm } from './login-form.component'
import { LostRecoveryCode } from './lost-recovery-code.component'
import { MFAForm } from './mfa-form.component'
import { RecoveryCodeForm } from './recovery-code-form.component'
import { SaveRecoveryCodeModal } from './save-recovery-code-modal.component'

const getModalTitle = (prefillEmail, MFAToken, recoveryCode) => {
  let modalTitle = 'Link new Account'

  if (prefillEmail) modalTitle = 'Sign in'
  if (MFAToken) modalTitle = 'Enter verification code'
  if (recoveryCode !== undefined)
    if (recoveryCode === 'lost') modalTitle = 'Having trouble signing in?'
    else modalTitle = 'Enter recovery code'

  return modalTitle
}

export const AuthModal = ({ isOpen, onClose, prefillEmail }) => {
  const { successAlert } = useToast()
  const [MFAToken, setMFAToken] = useState(undefined)
  const [recoveryCode, setRecoveryCode] = useState(undefined)
  const saveCodeModalOpen = recoveryCode && recoveryCode !== 'lost'

  const modalTitle = useMemo(
    () => getModalTitle(prefillEmail, MFAToken, recoveryCode),
    [prefillEmail, MFAToken, recoveryCode]
  )

  const linkUserAccountMutation = useMutation({
    mutationFn: linkUserAccount,
    onSuccess: (res) => {
      successAlert(res.message)

      if (res.recovery_code) setRecoveryCode(res.recovery_code)
      else window.location.reload()
    },
    onError: (e) => {
      if (
        e.response.data.message &&
        e.response.data.message.error === 'mfa_required'
      )
        setMFAToken(e.response.data.message.mfa_token)
    },
  })

  const addUserSessionMutation = useMutation({
    mutationFn: addUserSession,
    onSuccess: (res) => {
      successAlert(res.message)

      if (res.recovery_code) setRecoveryCode(res.recovery_code)
      else window.location.reload()
    },
    onError: (e) => {
      if (
        e.response.data.message &&
        e.response.data.message.error === 'mfa_required'
      )
        setMFAToken(e.response.data.message.mfa_token)
    },
  })

  const resetUserPasswordMutation = useMutation({
    mutationFn: resetUserPassword,
    onSuccess: (res) => {
      successAlert(res)
      onClose()
    },
  })

  const isLoading =
    addUserSessionMutation.isLoading ||
    linkUserAccountMutation.isLoading ||
    resetUserPasswordMutation.isLoading

  const onSubmit = (data) => {
    let payload = data
    if (MFAToken && recoveryCode !== undefined)
      payload = { mfa_token: MFAToken, ...data }
    else if (MFAToken) payload = { mfa_token: MFAToken, otp: data }

    if (prefillEmail) addUserSessionMutation.mutate(payload)
    else linkUserAccountMutation.mutate(payload)
  }

  const handleResetPassword = (data) => {
    resetUserPasswordMutation.mutate(data)
  }

  const handleCantEnterCode = () => {
    setRecoveryCode('')
  }

  const handleLostRecoveryCode = () => {
    setRecoveryCode('lost')
  }

  const handleHide = () => {
    setMFAToken(undefined)
    setRecoveryCode(undefined)
    onClose()
  }
  const content = useMemo(() => {
    if (MFAToken)
      if (recoveryCode !== undefined) {
        if (recoveryCode === 'lost') return <LostRecoveryCode />

        return (
          <RecoveryCodeForm
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClickLostRecoveryCode={handleLostRecoveryCode}
          />
        )
      } else
        return (
          <MFAForm
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClickCantEnterCode={handleCantEnterCode}
          />
        )
    return (
      <LoginForm
        prefillEmail={prefillEmail}
        isModalOpen={isOpen}
        isLoading={isLoading}
        onResetPassword={handleResetPassword}
        onSubmit={onSubmit}
      />
    )
  }, [MFAToken, recoveryCode, isLoading, prefillEmail, isOpen])

  return (
    <>
      <Modal show={isOpen} size="md" onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>
      </Modal>

      <SaveRecoveryCodeModal
        isOpen={saveCodeModalOpen}
        recoveryCodes={[recoveryCode]}
      />
    </>
  )
}
