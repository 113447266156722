import './style.scss'

import classNames from 'classnames'
import React, { forwardRef } from 'react'
import SelectField from 'react-select'

export default forwardRef((props, ref) => {
  const {
    label,
    options,
    id,
    value,
    addText,
    styleClass,
    isRequired,
    isDisabled,
    ...rest
  } = props

  const selectedValue = options?.find((o) => o.value === value)
  return (
    <div className={classNames(['select', styleClass])}>
      {label && (
        <label className="text_medium__14 remo-form-label" htmlFor={id}>
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </label>
      )}
      {addText && (
        <p
          className="text_light__12 color_text_300 mt-1"
          style={{ marginBottom: 12 }}
        >
          {addText}
        </p>
      )}
      {options && (
        <div className="select">
          <SelectField
            className="w-100 react-select"
            classNamePrefix="react-select"
            ref={ref}
            minMenuHeight={200}
            maxMenuHeight={220}
            id={id}
            isDisabled={isDisabled}
            defaultValue={isDisabled ? options[0] : undefined}
            options={options}
            value={selectedValue || value}
            {...rest}
          />
        </div>
      )}
    </div>
  )
})
